import React from 'react';

import { isEmpty } from 'lodash';

import Helpers from '../../../common/helpers';
import inspectionWorkflowConstants from '../../inspection-workflow/constants/inspection-workflow-constants';

import '../styles/image-description.scss';

const ImageDescription = props => {
  const { activeItem } = props;

  if (isEmpty(activeItem)) return null;

  return (
    <div className="image-description">
      <div className="image-description__left">
        <p className="f-white" title={activeItem[inspectionWorkflowConstants.formConstants.fields.name]}>
          {Helpers.getShortenedText(activeItem[inspectionWorkflowConstants.formConstants.fields.name], 35)}
        </p>
        <p className="f-white">
          {activeItem[inspectionWorkflowConstants.formConstants.fields.createdAt] ? Helpers.getDateFromUnix(activeItem[inspectionWorkflowConstants.formConstants.fields.createdAt]) : ''}{' '}
        </p>
      </div>
    </div>
  );
};

export default ImageDescription;
