// todo: rename to createdAt
export const fields = {
  id: 'ID',
  name: 'Name',
  createdAt: 'CreatedAt',
  duration: 'Duration',
};

export const streamHistoryTableConfig = [
  {
    title: 'STREAM_HISTORY.TABLE.NAME',
    key: fields.name,
    type: fields.name,
    class: 'name-column',
    isSort: true,
  },
  {
    title: 'TABLE.CREATED_DATE_AND_TIME',
    key: fields.createdAt,
    type: fields.createdAt,
    class: 'created-at-and-time-column',
    isSort: true,
  },
  {
    title: 'TABLE.DURATION',
    key: fields.duration,
    type: fields.duration,
    class: 'duration-column',
    isSort: true,
  },
];

export const filterProps = {
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  searchText: 'SearchText',
  hasNext: 'HasNext',
  totalItems: 'TotalItems',
};
