import React from 'react';

import Login from '../app/login/components/login';
import Members from '../app/members/components/members';
import Profile from '../app/profile/components/profile';
import Project from '../app/project/components/project';
// import CreateProject from '../app/create-project/components/create-project';
import AugmentedReality from '../app/augmented-reality/augmented-reality';
import LiveStreams from '../app/augmented-reality/components/live-streams/live-streams';
import RoboticLiveStreams from '../app/augmented-reality/components/live-streams/robotic-live-streams';
import PostVideoRelocalisation from '../app/augmented-reality/components/post-video-relocalisation/post-video-relocalisation';
import StreamHistory from '../app/augmented-reality/components/stream-history/stream-history';
import Stream from '../app/augmented-reality/components/stream/stream';
import ChangePassword from '../app/change-password/components/change-password';
import ChecklistsProceduresList from '../app/checklists-procedures-management/components/checklists-procedures-list';
import ChecklistsProceduresManagement from '../app/checklists-procedures-management/components/checklists-procedures-management';
import ComponentHierarchy from '../app/component-hierarchy/components/component-hierarchy';
import ContractorManagement from '../app/contractor-management/components/contractor-management';
import EditProject from '../app/create-project/components/edit-project';
import DocumentManagement from '../app/document-management/components/document-management';
import FullScreen from '../app/full-screen/components/full-screen';
import GuestUser from '../app/guest-user/components/guest-user';
import Workflow from '../app/inspection-workflow/components/inspection-workflow';
import Inspections from '../app/inspections/components/inspection';
import AssignComponents from '../app/inspections/components/left-toolbar/components/assign-components/assign-components';
import Integrations from '../app/integrations/components/integrations';
import Keybox from '../app/keybox/components/keybox';
import Landing from '../app/landing/components/landing';
import MaintenanceRegime from '../app/maintenance-regime/components/maintenance-regime';
import MfaSetup from '../app/mfa/components/mfa';
import ModulesHistory from '../app/modules-history/components/modules-history';
import PageNotFound from '../app/page-not-found/components/page-not-found';
import PdfTagList from '../app/pdf-tag-list/components/pdf-tag-list';
import PdfTag from '../app/pdf-tag/components/pdf-tag';
import ProjectSettings from '../app/project-settings/components/project-settings';
import Register from '../app/register/components/register';
import ResetPassword from '../app/reset-password/components/reset-password';
import SetPassword from '../app/reset-password/components/set-password';
import PermitTemplateManagement from '../app/templates/components/permits/components/permit-template-management';
import Templates from '../app/templates/components/templates';
import { FEATURES } from './constants';
import { PERMISSIONS, PERMISSION_TYPES } from './permissions-constants';
import HeaderTitle from './route-components/components/header-title';

const appRoutePrefix = '/app';
export const routes = {
  unProtectedRoutes: {
    login: {
      path: '/login',
      fullPath: '/login',
      component: Login,
    },
    guestUser: {
      path: 'guest-user',
      fullPath: '/guest-user',
      component: GuestUser,
    },
    resetPassword: {
      path: 'reset-password',
      fullPath: '/reset-password',
      component: ResetPassword,
    },
    setPassword: {
      path: 'set-password',
      fullPath: '/set-password',
      component: SetPassword,
    },
    register: {
      path: 'register',
      fullPath: '/register',
      component: Register,
    },
    notFound: {
      path: 'not-found',
      fullPath: appRoutePrefix + '/not-found',
      exact: true,
      includeSidebar: true,
      component: PageNotFound,
    },
  },
  protectedRoutes: {
    project: {
      path: 'project',
      fullPath: appRoutePrefix + '/project',
      includeSidebar: true,
      component: Project,
    },

    members: {
      path: 'members',
      fullPath: appRoutePrefix + '/members',
      includeSidebar: true,
      includeSpacing: true,
      pageTitle: 'NAVIGATION.MEBMERS',
      component: Members,
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.people].view.name],
      subPaths: {
        users: {
          path: 'users',
          fullPath: appRoutePrefix + '/members/users',
          includeSidebar: true,
          includeSpacing: true,
          pageTitle: 'NAVIGATION.MEBMERS',
          component: Members,
        },
        teams: {
          path: 'teams',
          fullPath: appRoutePrefix + '/members/teams',
          includeSidebar: true,
          includeSpacing: true,
          pageTitle: 'NAVIGATION.MEBMERS',
          component: Members,
        },
        roles: {
          path: 'roles',
          fullPath: appRoutePrefix + '/members/roles',
          includeSidebar: true,
          includeSpacing: true,
          pageTitle: 'NAVIGATION.MEBMERS',
          component: Members,
        },
      },
    },
    profile: {
      path: 'profile',
      fullPath: appRoutePrefix + '/profile',
      includeSidebar: true,
      component: Profile,
    },
    changePassord: {
      path: 'profile/change-password',
      fullPath: appRoutePrefix + '/profile/change-password',
      includeSidebar: true,
      includeSpacing: true,
      component: ChangePassword,
    },
    mfaSetup: {
      path: 'profile/mfa-setup',
      pageTitle: 'MFA.TITLE',
      fullPath: appRoutePrefix + '/profile/mfa-setup',
      includeSidebar: true,
      includeSpacing: true,
      component: MfaSetup,
    },
    // createProject: {
    //   path: 'project/create-project',
    //   fullPath: appRoutePrefix + '/project/create-project',
    //   includeSidebar: true,
    //   includeSpacing: false,
    //   alignTitleCentral: true,
    //   pageTitle: 'PAGE_TITLE.CREATE_PROJECT',
    //   component: CreateProject,
    //   visibleFor: [],
    //   isAllowed: FEATURES.addProject.visible,
    // },
    editProject: {
      path: 'project/edit-project',
      fullPath: appRoutePrefix + '/project/edit-project',
      includeSidebar: true,
      includeSpacing: false,
      alignTitleCentral: true,
      pageTitle: 'PAGE_TITLE.EDIT_PROJECT',
      component: EditProject,
      isAllowed: FEATURES.updateProject.visible,
    },
    inspections: {
      path: 'project/inspections',
      fullPath: appRoutePrefix + '/project/inspections',
      includeSidebar: true,
      includeSpacing: false,
      component: Inspections,
    },
    fullScreen: {
      path: 'project/inspections/full-screen',
      fullPath: appRoutePrefix + '/project/inspections/full-screen',
      includeSidebar: true,
      includeSpacing: false,
      component: FullScreen,
    },
    integrations: {
      path: 'project/integrations',
      fullPath: appRoutePrefix + '/project/integrations',
      includeSidebar: true,
      includeSpacing: false,
      component: Integrations,
    },
    startWorkflow: {
      path: 'project/inspections/start-workflow',
      fullPath: appRoutePrefix + '/project/inspections/start-workflow',
      includeSidebar: true,
      includeSpacing: false,
      rightMargin: '10px',
      titleComponent: () => <HeaderTitle text={'WORKFLOW.PAGE_TITLE.INSPECTION_PAGE'} includeHeader={false} previousPathname={routes.protectedRoutes.inspectionWorkflow.fullPath} />,
      component: Inspections,
      isAllowed: FEATURES.workflow?.visible,
    },
    inspectionWorkflow: {
      path: 'project/workflow',
      fullPath: appRoutePrefix + '/project/workflow',
      includeSidebar: true,
      includeSpacing: false,
      rightMargin: '10px',
      titleComponent: () => <HeaderTitle text={'WORKFLOW.PAGE_TITLE.IMAGE_SELECTION'} includeHeader={false} previousPathname={routes.protectedRoutes.inspections.fullPath} />,
      component: Workflow,
      isAllowed: FEATURES.workflow?.visible,
    },
    pdfTagList: {
      path: 'project/pdf-tag-list',
      fullPath: `${appRoutePrefix}/project/pdf-tag-list`,
      includeSidebar: true,
      includeSpacing: false,
      titleComponent: () => <HeaderTitle text={'PAGE_TITLE.PDF_TAG'} previousPathname={routes.protectedRoutes.inspections.fullPath} />,
      alignTitleCentral: true,
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.smartDrawing].view.name],
      component: PdfTagList,
    },
    pdfTag: {
      path: 'project/pdf-tag',
      fullPath: appRoutePrefix + '/project/pdf-tag',
      includeSidebar: true,
      includeSpacing: false,
      alignTitleCentral: true,
      titleComponent: () => <HeaderTitle reducedHeader={true} previousPathname={routes.protectedRoutes.pdfTagList.fullPath} />,
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.smartDrawing].view.name],
      component: PdfTag,
    },
    landing: {
      path: 'landing',
      fullPath: appRoutePrefix + '/landing',
      component: Landing,
    },
    maintenanceRegime: {
      path: 'project/maintenance-regime',
      fullPath: appRoutePrefix + '/project/maintenance-regime',
      includeSidebar: true,
      includeSpacing: false,
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.maintenanceRegimes].view.name],
      alignTitleCentral: true,
      component: MaintenanceRegime,
    },
    modulesHistory: {
      path: 'project/inspections/modules/history',
      fullPath: appRoutePrefix + '/project/inspections/modules/history',
      includeSidebar: true,
      includeSpacing: false,
      component: ModulesHistory,
      isAllowed: FEATURES.modulesHistory?.visible,
    },
    contractorManagement: {
      path: 'project/contractor-management',
      fullPath: appRoutePrefix + '/project/contractor-management',
      includeSidebar: true,
      includeSpacing: false,
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.contractors].view.name],
      alignTitleCentral: true,
      component: ContractorManagement,
    },
    templates: {
      path: 'project/templates',
      fullPath: appRoutePrefix + '/project/templates',
      includeSidebar: true,
      includeSpacing: false,
      alignTitleCentral: true,
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.permitTemplates].view.name, PERMISSIONS[PERMISSION_TYPES.isolationTemplates].view.name, PERMISSIONS[PERMISSION_TYPES.senseChecks].view.name],
      component: Templates,
      parentComponentClass: 'default-background',
      titleComponentProps: {
        className: 'default-background',
      },
    },
    keybox: {
      path: 'project/keybox',
      fullPath: appRoutePrefix + '/project/keybox',
      includeSidebar: true,
      includeSpacing: false,
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.keybox].view.name],
      titleComponentProps: {
        className: 'default-background',
      },
      alignTitleCentral: true,
      component: Keybox,
    },
    checklistsProceduresList: {
      path: 'project/checklists',
      fullPath: appRoutePrefix + '/project/checklists',
      includeSidebar: true,
      includeSpacing: false,
      component: ChecklistsProceduresList,
      isAllowed: FEATURES.checklistsAndProcedures?.visible,
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.checklists].view.name],
    },
    checklistsProceduresManagement: {
      path: 'project/checklists/:mode/:step(/:id)',
      fullPath: appRoutePrefix + '/project/checklists',
      includeSidebar: true,
      includeSpacing: false,
      component: ChecklistsProceduresManagement,
      isAllowed: FEATURES.checklistsAndProcedures?.visible,
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.checklists].view.name],
      parentComponentClass: 'default-background',
    },
    permitTemplateManagement: {
      path: 'project/permit-templates/:mode/:step(/:id)',
      fullPath: appRoutePrefix + '/project/permit-templates',
      includeSidebar: true,
      includeSpacing: false,
      component: PermitTemplateManagement,
      isAllowed: FEATURES.workManagement?.visible,
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.permitTemplates].view.name],
    },
    projectSettings: {
      path: 'project/project-settings',
      titleComponentProps: {
        className: 'default-background',
      },
      fullPath: `${appRoutePrefix}/project/project-settings`,
      includeSidebar: true,
      alignTitleCentral: true,
      includeSpacing: false,
      component: ProjectSettings,
    },
    componentHierarchy: {
      path: 'project/project-settings/component-hierarchy',
      fullPath: `${appRoutePrefix}/project/project-settings/component-hierarchy`,
      includeSidebar: true,
      component: ComponentHierarchy,
    },
    assignComponents: {
      path: 'project/components/assign',
      fullPath: `${appRoutePrefix}/project/components/assign`,
      includeSidebar: true,
      component: AssignComponents,
    },
    documentManagement: {
      path: 'project/document-management',
      fullPath: `${appRoutePrefix}/project/document-management`,
      includeSidebar: true,
      component: DocumentManagement,
    },
    augmentedReality: {
      path: 'project/augmented-reality',
      fullPath: `${appRoutePrefix}/project/augmented-reality`,
      includeSidebar: true,
      component: AugmentedReality,
      isAllowed: FEATURES.augmentedReality?.visible,
      visibleFor: FEATURES.augmentedReality?.visible,
    },
    liveStreams: {
      path: 'project/augmented-reality/live-streams',
      fullPath: `${appRoutePrefix}/project/augmented-reality/live-streams`,
      includeSidebar: true,
      component: LiveStreams,
      isAllowed: FEATURES.augmentedReality.liveStreams?.visible,
      visibleFor: FEATURES.augmentedReality.liveStreams?.visible,
    },
    roboticLiveStreams: {
      path: 'project/augmented-reality/live-streams/robotic-live-streams',
      fullPath: `${appRoutePrefix}/project/augmented-reality/live-streams/robotic-live-streams`,
      includeSidebar: true,
      component: RoboticLiveStreams,
      parentComponentClass: 'default-background',
      isAllowed: FEATURES.augmentedReality.liveStreams?.roboticLiveStreams?.visible,
      visibleFor: FEATURES.augmentedReality.liveStreams?.roboticLiveStreams?.visible,
    },
    streamHistory: {
      path: 'project/augmented-reality/stream-history',
      fullPath: `${appRoutePrefix}/project/augmented-reality/stream-history`,
      includeSidebar: true,
      component: StreamHistory,
      parentComponentClass: 'default-background',
      isAllowed: FEATURES.augmentedReality.streamHistory?.visible,
      visibleFor: FEATURES.augmentedReality.streamHistory?.visible,
    },
    stream: {
      path: 'project/augmented-reality/stream/:id',
      fullPath: `${appRoutePrefix}/project/augmented-reality/stream`,
      includeSidebar: true,
      component: Stream,
      parentComponentClass: 'default-background',
      isAllowed: FEATURES.augmentedReality?.visible,
      visibleFor: FEATURES.augmentedReality?.visible,
    },
    postVideoRelocalisation: {
      path: 'project/augmented-reality/post-video-relocalisation',
      fullPath: `${appRoutePrefix}/project/augmented-reality/post-video-relocalisation`,
      includeSidebar: true,
      component: PostVideoRelocalisation,
      parentComponentClass: 'default-background',
      isAllowed: FEATURES.augmentedReality.postVideoRelocalisation?.visible,
      visibleFor: FEATURES.augmentedReality.postVideoRelocalisation?.visible,
    },
  },
};

const defaultRoute = routes.protectedRoutes.project.fullPath;

const queryAlias = {
  iid: 'inspection_id',
  pid: 'project_id',
  cid: 'company_id',
  view: 'view',
  details: 'details',
};

export default {
  routes,
  queryAlias,
  defaultRoute,
};
