import { debounce, isEmpty, toInteger } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import DoubleTable from '../../../../../../common/double-table/components/double-table';
import Helpers from '../../../../../../common/helpers';
import { setGenericNotification } from '../../../../../../common/notification/actions/action-creators';
import PathViewer from '../../../../../../common/path-viewer/components/path-viewer';
import { fetchComponentHierarchyLevels } from '../../../../../component-hierarchy/actions/component-hierarchy-levels-actions';
import { ComponentHierarchyUnassignedLevelID } from '../../../../../component-hierarchy/constants/constants';
import ComponentHierarchyHelpers from '../../../../../component-hierarchy/helpers/helpers';
import {
  setCHHideAll,
  setCHLevels,
  setDisplayUnassignedComponentsLevel,
  setHideUnassignedComponents,
  setHierarchyID,
  setSearchText,
  setShowCHSubLevels,
  toggleLevel,
} from '../../../../actions/action-creators';
import { formatComponentCells, formatComponentCellsFS, formatLevelCells, formatLevelCellsFS } from './actions/actions';
import { SearchPropName, componentsTableConfig, componentsTableConfigFullScreen, defaultLevelFilters, filterProps, levelsTableConfig, levelsTableConfigFullScreen } from './constants/constants';
import './styles/ch-display.scss';
import ThreeDotMenu from './three-dot-menu';

class CHDisplay extends Component {
  constructor(props, context) {
    super(props);

    const { location } = props;
    const { query } = location;
    const { t } = context;
    this.state = {
      componentLevels: [],
      filters: defaultLevelFilters,
      TotalItems: 0,
      levelPath: [{ Code: t('COMPONENT_HIERARCHY.ALL_LEVELS'), Name: '', ID: null }],
      isLoading: false,
      [SearchPropName.levels]: '',
      [SearchPropName.components]: '',
      currentLevelID: null,
    };
    this.ProjectID = toInteger(query?.project_id);
    this.searchLevelsChangeDebounced = debounce(this.handleSearchLevels, 300);
    this.searchComponentsChangeDebounced = debounce(this.handleSearchComponents, 300);
    this.maxLevelSearchCharacters = 80; // this is due the max level name is 80 characters
  }

  fetchLevels = (filters, loadMore) => {
    const { [SearchPropName.levels]: levelsSearchText } = this.state;
    const { fetchComponentHierarchyLevels, setCHLevels, chLevels } = this.props;

    const onSuccessFetch = newState => {
      const { levelPath } = this.state;
      const { hideUnassignedComponents } = this.props;
      const { t } = this.context;
      const { componentLevels, ...rest } = newState;
      const isAllLevels = levelPath.length === 1;
      const componentLevelsToBeSet = ComponentHierarchyHelpers.addUnassignedLevel(componentLevels, levelPath, hideUnassignedComponents, t);
      setCHLevels(componentLevelsToBeSet);
      this.setState({ ...rest, TotalItems: isAllLevels ? rest.TotalItems + 1 : rest.TotalItems });

      if (loadMore && componentLevels) {
        Helpers.scrollIntoView('level-table', `row-${filters[filterProps.lastSeen] - 1}`, isEmpty(levelsSearchText) ? -210 : -180);
      }
    };

    fetchComponentHierarchyLevels({ ProjectID: this.ProjectID, ...filters }, onSuccessFetch, loadMore, chLevels);
  };

  fetchComponents = (filters, loadMore) => {
    const { getComponents } = this.props;
    const { currentLevelID } = this.state;

    getComponents({ filters, HierarchyID: currentLevelID }, loadMore);
  };

  stateCallback = newState => this.setState({ ...newState });

  onRowClick = row => {
    const { levelPath, filters } = this.state;
    const { getComponents, componentsFilters, defaultComponentsFilters, setHierarchyID, setCHHideAll, setDisplayUnassignedComponentsLevel, setCHLevels } = this.props;
    if (row.ID === ComponentHierarchyUnassignedLevelID) {
      const { t } = this.context;
      this.setState({ levelPath: [...levelPath, { ID: ComponentHierarchyUnassignedLevelID, Name: t('COMPONENT_HIERARCHY.UNASSIGNED'), Code: t('COMPONENT_HIERARCHY.UNASSIGNED') }] });
      setCHLevels([]);
      setHierarchyID(null);
      setDisplayUnassignedComponentsLevel(true);
    } else {
      setHierarchyID(row?.ID);
      ComponentHierarchyHelpers.onRowLevelClick(row, levelPath, filters, defaultLevelFilters, this.fetchLevels, this.stateCallback, {}, SearchPropName.levels);
    }
    setCHHideAll(false);
    ComponentHierarchyHelpers.onComponentLevelClick(row, componentsFilters, defaultComponentsFilters, getComponents, this.stateCallback, { ProjectID: this.ProjectID }, SearchPropName.components);
  };

  handlePathBackClick = () => {
    const { levelPath, filters } = this.state;
    if (levelPath.length <= 1) return;
    const { getComponents, componentsFilters, defaultComponentsFilters, setHierarchyID, setCHHideAll, setDisplayUnassignedComponentsLevel, displayUnassignedComponentsLevel } = this.props;
    setDisplayUnassignedComponentsLevel(false);
    setCHHideAll(false);
    ComponentHierarchyHelpers.onPathBackClick(levelPath, filters, defaultLevelFilters, this.fetchLevels, this.stateCallback, null, SearchPropName.levels, setHierarchyID);
    !displayUnassignedComponentsLevel &&
      ComponentHierarchyHelpers.onPathBackClickComponents(
        levelPath,
        componentsFilters,
        defaultComponentsFilters,
        getComponents,
        this.stateCallback,
        { ProjectID: this.ProjectID },
        SearchPropName.components
      );
  };

  handlePathClick = ID => {
    const { levelPath, filters } = this.state;
    const { componentsFilters, defaultComponentsFilters, setHierarchyID, getComponents, setCHHideAll, setDisplayUnassignedComponentsLevel, displayUnassignedComponentsLevel } = this.props;
    if (levelPath.length <= 1) return;
    setDisplayUnassignedComponentsLevel(false);
    setHierarchyID(ID);
    setCHHideAll(false);
    ComponentHierarchyHelpers.onPathClick(ID, levelPath, filters, defaultLevelFilters, this.fetchLevels, this.stateCallback, null, SearchPropName.levels);
    !displayUnassignedComponentsLevel &&
      ComponentHierarchyHelpers.onPathClickComponent(
        ID,
        componentsFilters,
        defaultComponentsFilters,
        getComponents,
        this.stateCallback,
        { ProjectID: this.ProjectID, HierarchyID: ID },
        SearchPropName.components
      );
  };

  onLevelColumnSort = SortByColumn => {
    const { filters } = this.state;
    ComponentHierarchyHelpers.onColumnSort(SortByColumn, filters, this.fetchLevels);
  };

  onComponentsColumnSort = SortByColumn => {
    const { onComponentsColumnSort } = this.props;
    const { currentLevelID } = this.state;
    onComponentsColumnSort(SortByColumn, currentLevelID);
  };

  handleSearchLevels = SearchText => {
    const { filters, levelPath } = this.state;

    const isCurrentLevelUnassigned = levelPath?.length > 0 && levelPath[levelPath.length - 1].Name === ComponentHierarchyUnassignedLevelID;

    if (!isCurrentLevelUnassigned) {
      // disables the search in Unassigned folder
      ComponentHierarchyHelpers.onSearch(SearchText, filters, defaultLevelFilters, this.fetchLevels);
    }
  };

  handleSearchComponents = SearchText => {
    const { setSearchText } = this.props;
    setSearchText(SearchText);
  };

  handleInputLevelChange = e => {
    this.setState({ [SearchPropName.levels]: e.target.value });
    this.searchLevelsChangeDebounced(e.target.value);
  };

  handleInputComponentsChange = e => {
    this.setState({ [SearchPropName.components]: e.target.value });
    this.searchComponentsChangeDebounced(e.target.value);
  };

  loadMoreLevels = () => {
    const { filters } = this.state;
    this.fetchLevels(filters, true);
  };

  loadMoreComponents = () => {
    const { componentsFilters } = this.props;
    this.fetchComponents(componentsFilters, true);
  };

  handleHideElements = (e, row) => {
    const { hideElements } = this.props;
    e?.stopPropagation();
    hideElements(e, row);
  };

  handleNestedComponentsAction = () => {
    const { showCHSublevels, setShowCHSubLevels } = this.props;
    setShowCHSubLevels(!showCHSublevels);
  };

  handleToggleLevel = (e, row) => {
    e?.stopPropagation();
    const { toggleLevel, showCHSublevels, setGenericNotification, setCHHideAll, setHideUnassignedComponents, hideUnassignedComponents } = this.props;
    const { t } = this.context;
    if (showCHSublevels) {
      if (row.ID === ComponentHierarchyUnassignedLevelID) {
        setHideUnassignedComponents(!hideUnassignedComponents);
      }
      setCHHideAll(false);
      toggleLevel(row?.ID);
    } else {
      setGenericNotification({ isDisplayed: true, type: 'info', text: t('COMPONENTS_THREE_DOT_MENU.TOGGLE_LEVEL_NOTIFICATION'), icon: 'info' });
    }
  };

  componentDidMount = () => {
    const { filters } = this.state;
    this.fetchLevels(filters);
  };

  componentWillUnmount = () => {
    const { setHierarchyID } = this.props;
    setHierarchyID(null);
  };

  handleFormatCellsFS = (
    value,
    type,
    searchInputValue,
    row,
    onDropdownClick,
    t,
    _pointerDotID,
    _user,
    _onToggleClick,
    _onDeleteRow,
    _statusData,
    _handleChangeStatus,
    _onLinkFileClick,
    _selectedItem,
    _cell,
    _hasLinkDMSFilePermissions,
    onRowClick
  ) => {
    return formatComponentCellsFS(value, type, searchInputValue, row, onDropdownClick, t, onRowClick);
  };

  render() {
    const { elements, totalItems: componentsTotal, onElementClick, isLoadingComponents, location, componentsFilters, selectAll, deselectAll, showCHSublevels, chLevels, fullScreen } = this.props;
    const { query } = location;
    const { TotalItems, levelPath, filters, isLoading, [SearchPropName.levels]: levelSearchValue, [SearchPropName.components]: componentsSearchValue } = this.state;
    const { t } = this.context;

    const selected_item = toInteger(query?.selected_item);

    return (
      <div className="ch-display">
        <div className={`ch-display__path`}>
          <div className="ch-display__path__component">
            <PathViewer levels={levelPath} onPathClick={this.handlePathClick} onBackClick={this.handlePathBackClick} />
          </div>
          <div className={`ch-display__path__action ${fullScreen ? 'hide' : ''}`}>
            <button className="ch-display__path__action__button">
              <ThreeDotMenu showAll={selectAll} hideAll={deselectAll} showNested={showCHSublevels} nestedComponentsAction={this.handleNestedComponentsAction} />
            </button>
          </div>
        </div>
        <div className={`ch-display__table path-displayed ${fullScreen ? 'full-screen-mode' : 'three-d-mode'} `}>
          <DoubleTable
            firstTableProps={{
              data: chLevels,
              tableConfig: fullScreen ? levelsTableConfigFullScreen : levelsTableConfig,
              filters,
              sortDirection: filterProps.sortDirection,
              sortByColumn: filterProps.sortByColumn,
              handleFormatCells: fullScreen ? formatLevelCellsFS : formatLevelCells,
              TotalItems,
              searchPlaceholder: t('ASSIGN_COMPONENTS.SEARCH_LEVEL'),
              noDataText: t('ASSIGN_COMPONENTS.LEVEL_EMPTY_STATE'),
              onRowClick: this.onRowClick,
              onSortClick: this.onLevelColumnSort,
              isLoading,
              hasNext: filters[filterProps.hasNext],
              loadMoreOnClick: this.loadMoreLevels,
              customTableClass: 'level-table',
              searchInputValue: levelSearchValue,
              searchInputOnChange: this.handleInputLevelChange,
              onDropdownClick: this.handleToggleLevel,
              maxSearchCharacters: this.maxLevelSearchCharacters,
            }}
            secondTableProps={{
              data: elements,
              tableConfig: fullScreen ? componentsTableConfigFullScreen : componentsTableConfig,
              filters: componentsFilters,
              sortDirection: filterProps.sortDirection,
              sortByColumn: filterProps.sortByColumn,
              handleFormatCells: fullScreen ? this.handleFormatCellsFS : formatComponentCells,
              searchInputValue: componentsSearchValue || '',
              TotalItems: componentsTotal,
              onRowClick: row => {
                onElementClick(null, row);
              },
              selectedItem: selected_item,
              searchPlaceholder: t('ASSIGN_COMPONENTS.SEARCH_COMPONENTS', { currentLevel: levelPath[levelPath.length - 1].Code }),
              isLoading: isLoadingComponents,
              noDataText: t('ASSIGN_COMPONENTS.COMPONENT_EMPTY_STATE'),
              onSortClick: this.onComponentsColumnSort,
              searchInputOnChange: this.handleInputComponentsChange,
              hasNext: componentsFilters[filterProps.hasNext],
              loadMoreOnClick: this.loadMoreComponents,
              onDropdownClick: this.handleHideElements,
              customTableClass: 'components-table',
            }}
          />
        </div>
      </div>
    );
  }
}

CHDisplay.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  SearchText: state.inspectionReducer.searchText,
  showCHSublevels: state.inspectionReducer.showCHSublevels,
  chLevels: state.inspectionReducer.chLevels,
  hideUnassignedComponents: state.inspectionReducer.hideUnassignedComponents,
  displayUnassignedComponentsLevel: state.inspectionReducer.displayUnassignedComponentsLevel,
});

const mapDispatchToProps = dispatch => ({
  fetchComponentHierarchyLevels: (data, callback, loadMore, componentLevels) => dispatch(fetchComponentHierarchyLevels(data, callback, loadMore, componentLevels)),
  setHierarchyID: hierarchyID => dispatch(setHierarchyID(hierarchyID)),
  setSearchText: val => dispatch(setSearchText(val)),
  setShowCHSubLevels: val => dispatch(setShowCHSubLevels(val)),
  setCHLevels: levels => dispatch(setCHLevels(levels)),
  toggleLevel: ID => dispatch(toggleLevel(ID)),
  setGenericNotification: data => dispatch(setGenericNotification(data)),
  setCHHideAll: chHideAll => dispatch(setCHHideAll(chHideAll)),
  setHideUnassignedComponents: data => dispatch(setHideUnassignedComponents(data)),
  setDisplayUnassignedComponentsLevel: data => dispatch(setDisplayUnassignedComponentsLevel(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CHDisplay));
