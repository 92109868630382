import { cloneDeep, filter, findIndex, some } from 'lodash';
import Helpers from '../../../common/helpers';
import ReducerHelpers from '../../../common/reducer-helpers';
import { participantTypes } from '../../../common/user-team/constants/constants';
import { assigneeFields, checklistFields } from '../components/work-order/constants/constants';
import { checklistTemplateFields } from '../components/work-order/constants/work-order-right-side-constants';
import actionTypes from '../constants/action-types';
import { defaultPagingObj, defaultQuickFilters, formConstants } from '../constants/work-order-constants';

const workOrderState = {
  workOrders: [],
  workOrdersLoading: false,
  delWorkorderModalData: {
    isOpen: false,
  },
  workOrderComponents: [],
  workOrderChecklists: [],
  workOrderChecklistsLoading: false,
  workOrderContributors: {
    Assignees: [],
    Collaborators: [],
  },
  workOrderContractors: [],
  workOrderAreas: [],
  workOrderFiles: [],
  workOrderCompletionFiles: [],
  workOrderComments: [],
  workOrderPermits: [],
  workOrderCPTemplates: [],
  workOrderDetailsLoading: false,
  createWOModalData: {
    isOpen: false,
  },
  filters: defaultQuickFilters,
  pagingObject: defaultPagingObj,
  workOrderProperties: [],
  customPropertySuggestions: [],
  workOrderCPSections: [],
  workOrderObservations: [],
};

export const workOrderReducer = (state = workOrderState, payload) => {
  switch (payload.type) {
    case actionTypes.SET_WORK_ORDERS:
      return {
        ...state,
        workOrders: payload.data,
      };
    case actionTypes.AMEND_WORK_ORDERS:
      return {
        ...state,
        workOrders: [...state.workOrders, ...payload.data],
      };
    case actionTypes.HANDLE_WORK_ORDERS_LOADING:
      return {
        ...state,
        workOrdersLoading: payload.data,
      };
    case actionTypes.HANDLE_DELETE_WORK_ORDER_MODAL:
      return { ...state, delWorkorderModalData: payload.data };
    case actionTypes.HANDLE_REMOVE_WORK_ORDER: {
      return {
        ...state,
        workOrders: filter(state.workOrders, item => {
          return item[formConstants.fields.id] !== payload.data;
        }),
      };
    }
    case actionTypes.REMOVE_WORKORDER_BY_PROP:
      return { ...state, workOrders: ReducerHelpers.removeItemByProp(state.workOrders, payload.data.item, payload.data.prop) };
    case actionTypes.UPDATE_WORKORDER_BY_PROP:
      return { ...state, workOrders: ReducerHelpers.updateItemInListByProp(state.workOrders, payload.data.item, payload.data.prop) };
    case actionTypes.SET_WORK_ORDER_COMPONENTS:
      return {
        ...state,
        workOrderComponents: payload.data,
      };
    case actionTypes.AMEND_WORK_ORDER_COMPONENT:
      return {
        ...state,
        workOrderComponents: [...state.workOrderComponents, payload.data],
      };
    case actionTypes.REMOVE_WORK_ORDER_COMPONENT:
      return {
        ...state,
        workOrderComponents: ReducerHelpers.removeItemByProp(state.workOrderComponents, payload.data.item, payload.data.prop),
      };
    case actionTypes.SET_WORK_ORDER_CHECKLISTS_LOADING:
      return {
        ...state,
        workOrderChecklistsLoading: payload.data,
      };
    case actionTypes.SET_WORK_ORDER_CHECKLISTS:
      return {
        ...state,
        workOrderChecklists: payload.data,
      };
    case actionTypes.AMEND_WORK_ORDER_CHECKLIST:
      return {
        ...state,
        workOrderChecklists: [...state.workOrderChecklists, payload.data],
      };

    case actionTypes.UPDATE_WORK_ORDER_CHECKLIST_IN_LIST:
      return {
        ...state,
        workOrderChecklists: ReducerHelpers.updateItemInListByProp(state.workOrderChecklists, payload.data.item, payload.data.prop),
      };
    case actionTypes.REMOVE_WORK_ORDER_CHECKLIST:
      return {
        ...state,
        workOrderChecklists: ReducerHelpers.removeItemByProp(state.workOrderChecklists, payload.data.item, payload.data.prop),
      };
    case actionTypes.SET_WORK_ORDER_CONTRIBUTORS:
      return {
        ...state,
        workOrderContributors: payload.data,
      };
    case actionTypes.AMEND_WORK_ORDER_CONTRIBUTOR:
      let woContributorsAdded = cloneDeep(state.workOrderContributors);

      if (payload.data[assigneeFields.participantType] === participantTypes.assignee) {
        woContributorsAdded.Assignees = [...state.workOrderContributors.Assignees, payload.data];
      } else if (payload.data[assigneeFields.participantType] === participantTypes.collaborator) {
        woContributorsAdded.Collaborators = [...state.workOrderContributors.Collaborators, payload.data];
      }

      return {
        ...state,
        workOrderContributors: woContributorsAdded,
      };
    case actionTypes.REMOVE_WORK_ORDER_CONTRIBUTOR:
      let woContributorsRemoved = cloneDeep(state.workOrderContributors);

      if (payload.data.item[assigneeFields.participantType] === participantTypes.assignee) {
        woContributorsRemoved.Assignees = ReducerHelpers.removeItemByProp(state.workOrderContributors.Assignees, payload.data.item, payload.data.prop);
      } else if (payload.data.item[assigneeFields.participantType] === participantTypes.collaborator) {
        woContributorsRemoved.Collaborators = ReducerHelpers.removeItemByProp(state.workOrderContributors.Collaborators, payload.data.item, payload.data.prop);
      }

      return {
        ...state,
        workOrderContributors: woContributorsRemoved,
      };
    case actionTypes.SET_WORK_ORDER_ASSIGNEES:
      return {
        ...state,
        workOrderContractors: payload.data,
      };
    case actionTypes.AMEND_WORK_ORDER_ASSIGNEES:
      return {
        ...state,
        workOrderContractors: [...state.workOrderContractors, payload.data],
      };
    case actionTypes.REMOVE_WORK_ORDER_ASSIGNEES:
      return {
        ...state,
        workOrderContractors: ReducerHelpers.removeItemByProp(state.workOrderContractors, payload.data.item, payload.data.prop),
      };
    case actionTypes.SET_WORK_ORDER_AREAS:
      return {
        ...state,
        workOrderAreas: payload.data,
      };
    case actionTypes.AMEND_WORK_ORDER_AREA:
      return {
        ...state,
        workOrderAreas: [...state.workOrderAreas, payload.data],
      };

    case actionTypes.UPDATE_WORK_ORDER_AREA:
      return {
        ...state,
        workOrderAreas: ReducerHelpers.updateItemInListByProp(state.workOrderAreas, payload.data.item, payload.data.prop),
      };
    case actionTypes.REMOVE_WORK_ORDER_AREA:
      return {
        ...state,
        workOrderAreas: ReducerHelpers.removeItemByProp(state.workOrderAreas, payload.data.item, payload.data.prop),
      };
    case actionTypes.SET_WORK_ORDER_FILES:
      return {
        ...state,
        workOrderFiles: payload.data,
      };
    case actionTypes.SET_WORK_ORDER_COMPLETION_FILES:
      return {
        ...state,
        workOrderCompletionFiles: payload.data,
      };
    case actionTypes.SET_WORK_ORDER_COMMENTS:
      return {
        ...state,
        workOrderComments: payload.data,
      };
    case actionTypes.REMOVE_FILE_BY_SECTION:
      return {
        ...state,
        workOrderFiles: {
          ...state.workOrderFiles,
          [payload.data.section]: ReducerHelpers.removeItemByProp(state.workOrderFiles[payload.data.section], payload.data.item, payload.data.prop),
        },
      };
    case actionTypes.REMOVE_WORK_ORDER_COMPLETION_FILE_BY_SECTION:
      return {
        ...state,
        workOrderCompletionFiles: {
          ...state.workOrderCompletionFiles,
          [payload.data.section]: ReducerHelpers.removeItemByProp(state.workOrderCompletionFiles[payload.data.section], payload.data.item, payload.data.prop),
        },
      };
    case actionTypes.SET_WORK_ORDER_DETAILS_LOADING:
      return {
        ...state,
        workOrderDetailsLoading: payload.data,
      };
    case actionTypes.HANDLE_CREATE_WORK_ORDER_MODAL:
      return { ...state, createWOModalData: payload.data };

    case actionTypes.HANDLE_WORK_ORDER_UPDATE_COUNT_FIELD:
      const { item, prop, field, amount } = payload.data;
      return {
        ...state,
        workOrders: ReducerHelpers.incrementItemPropInListByProp(state.workOrders, item, prop, field, amount),
      };
    case actionTypes.SET_WORK_ORDER_FILTERS:
      return {
        ...state,
        filters: payload.data,
      };
    case actionTypes.SET_WORK_ORDER_PAGING:
      return {
        ...state,
        pagingObject: payload.data,
      };
    case actionTypes.SET_WORK_ORDER_PERMITS:
      return {
        ...state,
        workOrderPermits: payload.data,
      };

    case actionTypes.AMEND_WORK_ORDER_PERMIT:
      return {
        ...state,
        workOrderPermits: [...state.workOrderPermits, payload.data],
      };
    case actionTypes.REMOVE_WORK_ORDER_PERMIT:
      return {
        ...state,
        workOrderPermits: ReducerHelpers.removeItemByProp(state.workOrderPermits, payload.data.item, payload.data.prop),
      };
    case actionTypes.SET_WORK_ORDER_CP_TEMPLATES:
      return {
        ...state,
        workOrderCPTemplates: Helpers.addStandaloneCPTemplates(payload.data).map(template => {
          const hasLinkedComponent = some(payload.data, i => i.ID === template.ID && i[checklistTemplateFields.componentID] !== undefined);
          return { ...template, [checklistFields.isLinkedToComponent]: hasLinkedComponent };
        }),
      };

    case actionTypes.AMEND_WORK_ORDER_CP_TEMPLATE:
      return {
        ...state,
        workOrderCPTemplates: [...state.workOrderCPTemplates, payload.data],
      };
    case actionTypes.REMOVE_WORK_ORDER_CP_TEMPLATE: {
      let newWorkorderChecklists = cloneDeep(state.workOrderCPTemplates);
      // remove all checklists by id
      newWorkorderChecklists = filter(newWorkorderChecklists, it => it[payload.data.prop] !== payload.data.item[payload.data.prop]);
      return {
        ...state,
        workOrderCPTemplates: newWorkorderChecklists,
      };
    }
    case actionTypes.LINK_WORK_ORDER_CP_TEMPLATE_TO_COMPONENTS:
      return {
        ...state,
        workOrderCPTemplates: ReducerHelpers.updateItemInListByProp(state.workOrderCPTemplates, { ...payload.data.item, [checklistFields.isLinkedToComponent]: true }, payload.data.prop),
      };
    case actionTypes.UNLINK_WORK_ORDER_CP_TEMPLATE_FROM_COMPONENTS: {
      let newWorkorderChecklists = cloneDeep(state.workOrderCPTemplates);
      // remove all components
      newWorkorderChecklists = filter(
        newWorkorderChecklists,
        it =>
          it[payload.data.prop] !== payload.data.item[payload.data.prop] || (it[payload.data.prop] === payload.data.item[payload.data.prop] && it[checklistTemplateFields.componentID] === undefined)
      );
      // update workorder checklist isLinkedToComponent prop
      newWorkorderChecklists = ReducerHelpers.updateItemInListByProp(
        newWorkorderChecklists || [],
        { [payload.data.prop]: payload.data.item[payload.data.prop], [checklistFields.isLinkedToComponent]: false },
        payload.data.prop
      );
      return {
        ...state,
        workOrderCPTemplates: newWorkorderChecklists,
      };
    }
    case actionTypes.REMOVE_WORK_ORDER_COMPONENT_FROM_CP_TEMPLATE: {
      const foundIndex = findIndex(
        state.workOrderCPTemplates,
        it => it[payload.data.prop] === payload.data.item[payload.data.prop] && it[checklistTemplateFields.componentID] === payload.data.component[payload.data.prop]
      );
      if (foundIndex === -1) {
        return;
      }

      const newWorkorderChecklists = [...state.workOrderCPTemplates.slice(0, foundIndex), ...state.workOrderCPTemplates.slice(foundIndex + 1)];
      return {
        ...state,
        workOrderCPTemplates: newWorkorderChecklists,
      };
    }
    case actionTypes.SET_WORK_ORDER_PROPERTIES:
      return { ...state, workOrderProperties: payload.data };
    case actionTypes.SET_WORKORDER_LABEL_SUGGESTIONS:
      return { ...state, customPropertySuggestions: payload.data };
    case actionTypes.CLEAR_WORKORDER_LABEL_SUGGESTIONS:
      return { ...state, customPropertySuggestions: [] };
    case actionTypes.SET_WORK_ORDER_CP_SECTIONS:
      return {
        ...state,
        workOrderCPSections: payload.data,
      };
    case actionTypes.SET_WORK_ORDER_OBSERVATIONS:
      return {
        ...state,
        workOrderObservations: payload.data,
      };
    default:
      return state;
  }
};
