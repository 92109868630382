import { cloneDeep, filter, find, findIndex, findLast, isEmpty, some } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { change, destroy } from 'redux-form';

import Modal from '../../../../common/modal/components/modal';
import { ComponentPicker3D, ComponentPickerCH, ComponentPickerPDF } from '../../../../common/question-components/components';
import Stepper from '../../../../common/stepper/components/stepper';
import ChecklistDetails from '../../../maintenance-regime/components/maintenance-regime/checklist-details';
import CreateWorkOrderForm from './create-work-order-form';
import TeamUsersModal from './team-users-modal';

import { FORMS } from '../../../../common/constants';
import Helpers from '../../../../common/helpers';
import { notificationModalCustom } from '../../../../common/modal/actions/modal-actions';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import ReducerHelpers from '../../../../common/reducer-helpers';
import { priorities } from '../../../inspections/constants/work-order-constants';
import { getIntervalList } from '../../../maintenance-regime/actions/regime-actions';
import { getTeamUsers } from '../../../members/actions/teams-data-actions';
import { setWorkorderFilesUploaded } from '../../../upload/actions/action-creators';
import {
  createWorkOrder,
  getAdditionalWorkorderTypes,
  getChecklistDetails,
  getChecklistTemplatesForCreateWO,
  getComponentsByNotificationIds,
  getFilesByNotifications,
  getNotificationsByType,
  getWorkOrderPermits,
  getWorkorderTypes,
  searchComponents,
  searchUsersAndTeams,
  singleItemUploadDone,
} from '../../actions/work-order-actions';
import '../../styles/component-files-modal.scss';
import '../../styles/create-work-order-modal.scss';
import { defaultFilter, filterFields } from '../permits/constants/permit-form-constants';
import {
  assigneeFields,
  checklistFields,
  componentFields,
  formConstants,
  imagesValidation,
  intervalTypes,
  notificationFields,
  notificationValidation,
  steps,
  validationTypes,
} from './constants/constants';
class CreateWorkOrderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreviewMode: props.isPreviewMode,
      activeStep: steps.firstStep,
      lastStep: steps.fifthStep,
      stepperData: [
        {
          name: 'CREATE_WORK_ORDER_POPUP.STEP_1',
          stepValue: steps.firstStep,
          description: 'CREATE_WORK_ORDER_POPUP.STEP_1_DESC',
        },
        {
          name: 'CREATE_WORK_ORDER_POPUP.STEP_2',
          stepValue: steps.secondStep,
          description: 'CREATE_WORK_ORDER_POPUP.STEP_2_DESC',
        },
        {
          name: 'CREATE_WORK_ORDER_POPUP.STEP_3',
          stepValue: steps.thirdStep,
          description: 'CREATE_WORK_ORDER_POPUP.STEP_3_DESC',
        },
        {
          name: 'CREATE_WORK_ORDER_POPUP.STEP_4',
          stepValue: steps.fourthStep,
          description: 'CREATE_WORK_ORDER_POPUP.STEP_4_DESC',
        },
        {
          name: 'CREATE_WORK_ORDER_POPUP.STEP_5',
          stepValue: steps.fifthStep,
          description: 'CREATE_WORK_ORDER_POPUP.STEP_5_DESC',
          access: {
            visibleFor: [PERMISSIONS[PERMISSION_TYPES.workOrders].workOrdersCPLink.name],
          },
        },
      ],
      workorderTypes: [],
      additionalWorkOrderTypes: [],
      notifications: [],
      workorderNotifications: [],
      components: [],
      workorderComponents: null,
      checklists: [],
      workorderChecklists: null,
      checklistModalData: {
        isOpen: false,
      },
      uploadModalData: {
        isOpen: false,
      },
      verificationModalData: {
        isOpen: false,
      },
      imagesSliderModal: {
        isOpen: false,
      },
      teamUsersModalData: {
        isOpen: false,
      },
      componentSelectionModal: {
        isOpen: false,
      },
      selectedChecklistItems: [],
      usersAndTeams: [],
      workorderAssignees: null,
      workOrderAlertList: null,
      intervalList: null,
      permits: [],
      workorderPermits: null,
    };
  }

  componentDidMount() {
    const { stepperData, lastStep } = this.state;
    const { getWorkorderTypes, getAdditionalWorkorderTypes, selectedNotification, user, setWorkorderFilesUploaded } = this.props;

    const mappedStepperData = stepperData.map(step => ({ ...step, isHidden: step.access ? !Helpers.hasAccess({ user, ...step.access }) : false }));
    const lastStepData = findLast(mappedStepperData, step => !step.isHidden);
    const newLastStep = !isEmpty(lastStepData) ? lastStepData.stepValue : lastStep;
    this.setState({ lastStep: newLastStep, stepperData: mappedStepperData.filter(step => !step.isHidden) });

    getWorkorderTypes(data => this.setState({ workorderTypes: data }));
    getAdditionalWorkorderTypes(data => this.setState({ additionalWorkOrderTypes: data }));
    if (selectedNotification) {
      this.setState(state => {
        return { workorderNotifications: [selectedNotification] };
      });
    }

    // reset workorderFiles on mount
    setWorkorderFilesUploaded(null);
  }

  componentWillUnmount() {
    // reset workorderFiles on unmount
    const { setWorkorderFilesUploaded } = this.props;
    setWorkorderFilesUploaded(null);
  }

  handleSubmit = values => {
    const { getComponentsByNotificationIds } = this.props;
    let { activeStep, workorderComponents, workorderNotifications, lastStep } = this.state;
    if (activeStep === steps.thirdStep && activeStep !== lastStep) {
      this.handleSearchComponents('');
      if (!workorderComponents) {
        getComponentsByNotificationIds(
          (workorderNotifications || []).map(el => el[notificationFields.id]),
          data => this.setState({ workorderComponents: data || [] })
        );
      }
    } else if (activeStep === steps.fourthStep && activeStep !== lastStep) {
      this.handleSearchChecklists('');
    } else if (activeStep === lastStep) {
      this.handleSearchUsersAndTeams('');
      this.createWorkOrder(values);
    }

    this.setStep(true);
  };

  openTeamUsersModal = id => {
    const { t } = this.context;
    const { getTeamUsers } = this.props;
    const closeAction = () =>
      this.setState({
        teamUsersModalData: {
          isOpen: false,
        },
      });

    getTeamUsers(id, users => {
      this.setState({
        teamUsersModalData: {
          isOpen: true,
          title: t('TEAM_USERS_MODAL.TITLE'),
          customClassName: 'modal-no-max-height modal-large',
          CustomContent: () => <TeamUsersModal users={users} />,
          closeAction,
          confirmAction: closeAction,
          type: 'ok',
        },
      });
    });
  };

  setStep = forward => {
    const { closeAction } = this.props;
    let { activeStep, lastStep } = this.state;
    const nextStep = forward ? ++activeStep : --activeStep;
    if (nextStep < steps.firstStep || nextStep > lastStep) {
      closeAction();
      return;
    }

    this.setState({ activeStep: nextStep });
  };

  createWorkOrder = values => {
    const { workorderNotifications, workorderComponents, workorderChecklists, workorderPermits } = this.state;
    const { inspectionId, createWorkOrder, createWOFromNotification, destroyForm, workorderFiles } = this.props;

    const StartDate = Helpers.getUnixDate(new Date(values[formConstants.fields.startDate]).getTime()),
      DueDate = Helpers.getUnixDate(new Date(values[formConstants.fields.endDate]).getTime()),
      ExpectedDueDate = Helpers.getUnixDate(new Date(values[formConstants.fields.dueDate]).getTime());

    const NotificationIDs = (workorderNotifications || []).map(el => el[notificationFields.id]),
      ComponentIDs = (workorderComponents || []).map(el => el[componentFields.id]),
      // Assignees = (workorderAssignees || []).map(el => el[assigneeFields.id]),
      ChecklistTemplateIDs = Helpers.removeStandaloneCPTemplatesIfLinked(workorderChecklists).map(el => ({ [componentFields.id]: el[componentFields.id], ComponentID: el.ComponentID })),
      PermitIDs = (workorderPermits || []).map(el => el[componentFields.id]);

    const onSuccess = workOrders => {
      if (workOrders && workOrders.length) {
        const { location } = this.props;
        const queryParams = { ...location.query };
        delete queryParams.selected_item;
        const params = [];

        for (let key in queryParams) {
          params.push({
            [key]: queryParams[key],
          });
        }

        params.push({ selected_item: workOrders[0][formConstants.fields.id] });

        Helpers.goTo(location.pathname, params);
      }
    };
    const Files =
      workorderFiles?.length > 0
        ? workorderFiles.map(f => {
            return { FileID: f.fileId, CategoryID: f.CategoryID };
          })
        : [];

    createWorkOrder(
      {
        ...values,
        StartDate,
        DueDate,
        ExpectedDueDate,
        InspectionID: inspectionId,
        NotificationIDs,
        ComponentIDs,
        /* Assignees set to null due to https://hybirdclarity.atlassian.net/browse/CLAR-2221 */
        Assignees: null,
        ChecklistTemplateIDs,
        Files: workorderFiles?.length > 0 ? Files : [],
        /* Alert set to null due to https://hybirdclarity.atlassian.net/browse/CLAR-2221 */
        Alert: null,
        PermitIDs,
        WorkOrderPerComponent: (ComponentIDs?.length > 0 && values.WorkOrderPerComponent) || false, // WorkOrderPerComponent can only be true if there are linked components
      },
      // introduced so it does not change the selected_item in URI, so the notification details fetch does not break
      createWOFromNotification ? null : onSuccess,
      destroyForm(FORMS.createWorkorderForm)
    );
  };

  openChecklistDetails = id => {
    const { getChecklistDetails } = this.props;

    const closeAction = () => this.setState({ checklistModalData: { isOpen: false } });

    getChecklistDetails(id, data => {
      const { t } = this.context;
      this.setState({
        checklistModalData: {
          isOpen: true,
          title: t('CHECKLIST_DETAILS_MODAL.TITLE'),
          customClassName: 'modal-no-max-height modal-large',
          CustomContent: dynamicProps => <ChecklistDetails {...dynamicProps} selectedChecklistItems={data} />,
          closeAction,
          confirmAction: closeAction,
          type: 'ok',
        },
      });
    });
  };

  handleSearchComponents = SearchText => {
    const { inspectionId, searchComponents } = this.props;
    searchComponents(inspectionId, SearchText, data => this.setState({ components: data }), { PerPage: Number.MAX_SAFE_INTEGER, LastSeen: 0 });
  };

  handleSearchChecklists = SearchText => {
    const { projectId, searchCPTemplates } = this.props;
    searchCPTemplates({ ...defaultFilter, [filterFields.projectID]: projectId, SearchText }, [], ({ checklists }) => this.setState({ checklists: checklists || [] }));
  };

  handleSearchUsersAndTeams = SearchText => {
    const { searchUsersAndTeams, projectId } = this.props;
    searchUsersAndTeams(
      {
        SearchText,
        SortByColumn: 'UserID',
        SortDirection: 'ASC',
        LastSeen: 0,
        PerPage: Number.MAX_SAFE_INTEGER,
        ProjectID: projectId,
      },
      data => this.setState({ usersAndTeams: data })
    );
  };

  handleSearchPermits = SearchText => {
    const { getWorkOrderPermits, projectId } = this.props;
    getWorkOrderPermits(
      {
        SearchText,
        SortByColumn: 'ID',
        SortDirection: 'ASC',
        LastSeen: 0,
        PerPage: Number.MAX_SAFE_INTEGER,
        ProjectID: projectId,
      },
      data => this.setState({ permits: data })
    );
  };

  moveChecklistItem = (index, direction = 0) => {
    const { workorderChecklists } = this.state;
    if (isEmpty(workorderChecklists)) {
      // No checklist items
      return;
    }
    if (index < 0 || index > workorderChecklists.length - 1) {
      // Out of bounds
      return;
    }
    if (direction === 0 && index < workorderChecklists.length - 1) {
      // DOWN
      const currentItem = workorderChecklists[index];
      this.setState({ workorderChecklists: [...workorderChecklists.slice(0, index), workorderChecklists[index + 1], currentItem, ...workorderChecklists.slice(index + 2)] });
    } else if (direction === 1 && index > 0) {
      // UP
      const currentItem = workorderChecklists[index];
      this.setState({ workorderChecklists: [...workorderChecklists.slice(0, index - 1), currentItem, workorderChecklists[index - 1], ...workorderChecklists.slice(index + 1)] });
    }
  };

  isValid = (type, items, files) => {
    const { notificationModalCustom } = this.props;
    if (type === validationTypes.notifications) {
      if (items.length >= notificationValidation.limit) {
        notificationModalCustom('WORK_ORDER_CREATE_VALIDATION.NOTIFICATION_LIMIT_DESC', 'WORK_ORDER_CREATE_VALIDATION.NOTIFICATION_LIMIT_TITLE');
        return false;
      }
      return true;
    }
    if (type === validationTypes.images) {
      const allFiles = items.concat(files);

      if (allFiles && allFiles.length > imagesValidation.maxFiles) {
        notificationModalCustom(
          {
            key: 'WORK_ORDER_CREATE_VALIDATION.VALIDATION_MAX_FILES',
            props: { maxFiles: imagesValidation.maxFiles, current: files && files.length ? files.length : 0 },
          },
          'WORK_ORDER_CREATE_VALIDATION.VALIDATION_FILE_SIZE_TITLE'
        );

        return false;
      }

      const hasLargeFiles = some(items, item => item.size > imagesValidation.bytes);

      if (hasLargeFiles) {
        //open modal with message
        notificationModalCustom(
          {
            key: 'WORK_ORDER_CREATE_VALIDATION.VALIDATION_FILE_SIZE',
            props: { fileSize: `${imagesValidation.value}${imagesValidation.unit}` },
          },
          'WORK_ORDER_CREATE_VALIDATION.VALIDATION_FILE_SIZE_TITLE'
        );
        return false;
      }
      return true;
    }
  };

  addComponentToWorkorder = items => {
    const { workorderComponents } = this.state;
    const component = items && items.length ? items[0] : {};
    const existingComponent = find(workorderComponents, { [componentFields.id]: component[componentFields.id] });

    // prevent adding existing component
    if (existingComponent && existingComponent[componentFields.id]) {
      return;
    }

    items = items.map(component => {
      return { ...component, Name: component.SystemType === componentFields.clusterType && !component[componentFields.name] ? component[componentFields.id] : component[componentFields.name] };
    });

    this.setState(state => ({ workorderComponents: [...(state.workorderComponents || []), ...items] }));
  };

  removeComponentFromWorkorder = item => {
    const { workorderComponents } = this.state;
    const { changeField } = this.props;
    const component = Number.isInteger(item) ? { [componentFields.id]: item } : item;
    this.setState(state => ({ workorderComponents: ReducerHelpers.removeItemByProp(state.workorderComponents || [], component, componentFields.id) }));
    if (workorderComponents?.length < 2) {
      // < 2 since we need to handle 0 and 1, since state update does not reflect immediately in the moment of this check
      // set WorkOrderPerComponent to false if there is no linked equipment
      changeField(FORMS.createWorkorderForm, formConstants.fields.perComponent, false);
    }
  };

  handleComponentPicker3D = () => {
    const { inspectionId } = this.props;
    const closeAction = () => {
      this.setState({ componentSelectionModal: { isOpen: false } });
    };

    this.setState({
      componentSelectionModal: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <ComponentPicker3D
            {...dynamicProps}
            handleComponentSelected={(_moduleId, components) => this.addComponentToWorkorder(components)}
            handleDeleteComponent={(_moduleId, componentId) => this.removeComponentFromWorkorder(componentId)}
            inspectionId={inspectionId}
            closeAction={closeAction}
          />
        ),
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        closeAction,
      },
    });
  };

  handleComponentPickerPDF = () => {
    const { inspectionId } = this.props;
    const closeAction = () => {
      this.setState({ componentSelectionModal: { isOpen: false } });
    };

    this.setState({
      componentSelectionModal: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <ComponentPickerPDF
            {...dynamicProps}
            handleComponentSelected={(_moduleId, components) => this.addComponentToWorkorder(components)}
            handleDeleteComponent={(_moduleId, componentId) => this.removeComponentFromWorkorder(componentId)}
            inspectionId={inspectionId}
            closeAction={closeAction}
          />
        ),
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        closeAction,
      },
    });
  };

  handleComponentPickerCH = () => {
    const { inspectionId } = this.props;
    const { t } = this.context;
    const closeAction = () => {
      this.setState({ componentSelectionModal: { isOpen: false } });
    };

    this.setState({
      componentSelectionModal: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <ComponentPickerCH
            {...dynamicProps}
            handleComponentSelected={(_moduleId, components) => this.addComponentToWorkorder(components)}
            handleDeleteComponent={(_moduleId, componentId) => this.removeComponentFromWorkorder(componentId)}
            inspectionId={inspectionId}
            closeAction={closeAction}
          />
        ),
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        title: t('QUESTION_COMPONENT_PICKER.CHOOSE_COMPONENTS'),
        closeAction,
        customCloseAction: closeAction,
      },
    });
  };

  closeWorkOrderModalHandler = () => {
    const { closeAction, destroyForm } = this.props;
    destroyForm(FORMS.createWorkorderForm);
    closeAction();
  };

  render() {
    const {
      stepperData,
      activeStep,
      lastStep,
      workorderTypes,
      additionalWorkOrderTypes,
      workorderNotifications,
      notifications,
      components,
      workorderComponents,
      checklists,
      workorderChecklists,
      checklistModalData,
      uploadModalData,
      usersAndTeams,
      workorderAssignees,
      intervalList,
      verificationModalData,
      imagesSliderModal,
      workOrderAlertList,
      teamUsersModalData,
      componentSelectionModal,
      permits,
      workorderPermits,
    } = this.state;
    const { className, notificationType, startDate, severityColors, projectDMSCategories, uploadBatches, createWorkOrderFormValues } = this.props;
    const activeStepObj = find(stepperData, item => item.stepValue === activeStep);

    return (
      <div className={`create-work-order-modal ${className || ''}`}>
        <Stepper {...{ stepperData, activeStep }} />
        <CreateWorkOrderForm
          onSubmit={this.handleSubmit}
          stepAction={this.setStep}
          initialValues={{
            [formConstants.fields.startDate]: startDate ? new Date(startDate * 1000) : new Date().setDate((startDate ? new Date(startDate * 1000) : new Date()).getDate() + 1),
            [formConstants.fields.dueDate]: (startDate ? new Date(startDate * 1000) : new Date()).setDate((startDate ? new Date(startDate * 1000) : new Date()).getDate() + 8),
            [formConstants.fields.endDate]: (startDate ? new Date(startDate * 1000) : new Date()).setDate((startDate ? new Date(startDate * 1000) : new Date()).getDate() + 7),
            [formConstants.fields.notificationType]: notificationType || null,
            [formConstants.fields.severity]: priorities.none.value,
            [formConstants.fields.intervalId]: intervalTypes[1],
          }}
          openTeamUsersModal={this.openTeamUsersModal}
          activeStep={activeStep}
          activeStepObj={activeStepObj}
          lastStep={lastStep}
          workorderTypes={workorderTypes}
          additionalWorkOrderTypes={additionalWorkOrderTypes}
          workorderComponents={workorderComponents}
          workorderNotifications={workorderNotifications}
          searchComponents={this.handleSearchComponents}
          searchChecklists={this.handleSearchChecklists}
          notifications={notifications}
          components={components}
          addNotification={item => {
            if (this.isValid(validationTypes.notifications, workorderNotifications)) {
              this.setState(state => ({ workorderNotifications: [...(state.workorderNotifications || []), item] }));
            }
          }}
          removeNotification={item => this.setState(state => ({ workorderNotifications: ReducerHelpers.removeItemByProp(state.workorderNotifications || [], item, notificationFields.id) }))}
          clearNotifications={() => this.setState({ workorderNotifications: [] })}
          addComponentToWorkorder={this.addComponentToWorkorder}
          removeComponentFromWorkorder={this.removeComponentFromWorkorder}
          checklistList={checklists}
          workorderChecklists={workorderChecklists}
          addChecklistToTheWorkOrder={item => this.setState(state => ({ workorderChecklists: [...(state.workorderChecklists || []), item] }))}
          deleteWorkorderChecklist={item =>
            this.setState(state => {
              let newWorkorderChecklists = cloneDeep(state.workorderChecklists);
              // remove all checklists by id
              newWorkorderChecklists = filter(newWorkorderChecklists, it => it[componentFields.id] !== item[componentFields.id]);

              return { workorderChecklists: newWorkorderChecklists };
            })
          }
          linkWorkorderChecklist={item =>
            this.setState(state => ({
              workorderChecklists: ReducerHelpers.updateItemInListByProp(state.workorderChecklists || [], { ...item, [checklistFields.isLinkedToComponent]: true }, componentFields.id),
            }))
          }
          unlinkWorkorderChecklist={item =>
            this.setState(state => {
              let newWorkorderChecklists = cloneDeep(state.workorderChecklists);
              // remove all components
              newWorkorderChecklists = filter(
                newWorkorderChecklists,
                it => it[componentFields.id] !== item[componentFields.id] || (it[componentFields.id] === item[componentFields.id] && it.ComponentID === undefined)
              );
              // update workorder checklist isLinkedToComponent prop
              newWorkorderChecklists = ReducerHelpers.updateItemInListByProp(newWorkorderChecklists || [], { ...item, [checklistFields.isLinkedToComponent]: false }, componentFields.id);
              return {
                workorderChecklists: newWorkorderChecklists,
              };
            })
          }
          removeComponentFromCPTemplate={(item, component) => {
            const foundIndex = findIndex(workorderChecklists, it => it[componentFields.id] === item[componentFields.id] && it.ComponentID === component[componentFields.id]);
            if (foundIndex === -1) {
              return;
            }

            const newWorkorderChecklists = [...workorderChecklists.slice(0, foundIndex), ...workorderChecklists.slice(foundIndex + 1)];
            this.setState({ workorderChecklists: newWorkorderChecklists });
          }}
          intervalList={intervalList}
          usersAndTeams={usersAndTeams}
          workorderAssignees={workorderAssignees}
          searchUsersAndTeams={this.handleSearchUsersAndTeams}
          addAssigneeToWorkorder={item =>
            this.setState(state => ({ workorderAssignees: [...(state.workorderAssignees || []), item], workOrderAlertList: [...(state.workOrderAlertList || []), item] }))
          }
          removeAssigneeFromWorkorder={item =>
            this.setState(state => ({
              workorderAssignees: ReducerHelpers.removeItemByProp(state.workorderAssignees || [], item, assigneeFields.id),
              workOrderAlertList: ReducerHelpers.removeItemByProp(state.workOrderAlertList || [], item, assigneeFields.id),
            }))
          }
          workOrderAlertList={workOrderAlertList}
          addUserToAlertList={item => this.setState(state => ({ workOrderAlertList: [...(state.workOrderAlertList || []), item] }))}
          removeUserFromAlertList={item => this.setState(state => ({ workOrderAlertList: ReducerHelpers.removeItemByProp(state.workOrderAlertList || [], item, assigneeFields.id) }))}
          openChecklistDetails={this.openChecklistDetails}
          moveChecklistItem={this.moveChecklistItem}
          severityColors={severityColors}
          handleComponentPicker3D={this.handleComponentPicker3D}
          handleComponentPickerPDF={this.handleComponentPickerPDF}
          handleComponentPickerCH={this.handleComponentPickerCH}
          permits={permits}
          workorderPermits={workorderPermits}
          handleSearchPermits={this.handleSearchPermits}
          addPermitToWorkorder={item => this.setState(state => ({ workorderPermits: [...(state.workorderPermits || []), item] }))}
          removePermitFromWorkorder={item => this.setState(state => ({ workorderPermits: ReducerHelpers.removeItemByProp(state.workorderPermits || [], item, componentFields.id) }))}
          closeCreateWorkOrderModal={this.closeWorkOrderModalHandler}
          projectDMSCategories={projectDMSCategories}
          allBatchesUploaded={!uploadBatches || uploadBatches.length === 0 || (uploadBatches?.length > 0 && uploadBatches.every(batch => batch.batchCompleted === true))}
          createWorkOrderFormValues={createWorkOrderFormValues}
        />
        <Modal {...checklistModalData} />
        <Modal {...uploadModalData} />
        <Modal {...verificationModalData} />
        <Modal {...imagesSliderModal} />
        <Modal {...teamUsersModalData} />
        <Modal {...componentSelectionModal} components={components} question={{ ID: 1, QuestionsComponents: workorderComponents }} />
      </div>
    );
  }
}

CreateWorkOrderModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

CreateWorkOrderModal.defaultProps = {
  createWOFromNotification: false,
};

const mapStateToProps = state => {
  return {
    severityColors: state.themeReducer.severityColors,
    user: state.userReducer,
    projectDMSCategories: state.projectDetailsReducer.projectDMSCategories,
    workorderFiles: state.uploadReducer.workorderFiles,
    uploadBatches: state.uploadBatchesReducer.uploadBatches,
    createWorkOrderFormValues: state.form[FORMS.createWorkorderForm]?.values || {},
  };
};

const mapDispatchToProps = dispatch => ({
  getWorkorderTypes: successCallback => dispatch(getWorkorderTypes(successCallback)),
  getAdditionalWorkorderTypes: successCallback => dispatch(getAdditionalWorkorderTypes(successCallback)),
  searchNotifications: (inspectionId, data, successCallback) => dispatch(getNotificationsByType(inspectionId, data, successCallback)),
  getComponentsByNotificationIds: (notificationIds, successCallback) => dispatch(getComponentsByNotificationIds(notificationIds, successCallback)),
  searchComponents: (inspection_id, searchText, successCallback, otherParams) => dispatch(searchComponents(inspection_id, searchText, successCallback, otherParams)),
  searchCPTemplates: (filters, checklists, callback, loadMore) => dispatch(getChecklistTemplatesForCreateWO(filters, checklists, callback, loadMore)),
  getChecklistDetails: (id, successCallback) => dispatch(getChecklistDetails(id, successCallback)),
  getFilesByNotifications: (ids, successCallback) => dispatch(getFilesByNotifications(ids, successCallback)),
  singleItemUploadDone: (url, callback) => dispatch(singleItemUploadDone(url, callback)),
  searchUsersAndTeams: (params, successCallback) => dispatch(searchUsersAndTeams(params, successCallback)),
  createWorkOrder: (data, successCallback, destroyFormCallback) => dispatch(createWorkOrder(data, successCallback, destroyFormCallback)),
  getIntervalList: sucessCallback => dispatch(getIntervalList(sucessCallback)),
  notificationModalCustom: (errorMessage, title) => dispatch(notificationModalCustom(true, errorMessage, title)),
  getTeamUsers: (teamId, callback) => dispatch(getTeamUsers(teamId, callback)),
  getWorkOrderPermits: (params, successCallback) => dispatch(getWorkOrderPermits(params, successCallback)),
  destroyForm: formName => dispatch(destroy(formName)),
  setWorkorderFilesUploaded: files => dispatch(setWorkorderFilesUploaded(files)),
  changeField: (formName, field, value, touch) => dispatch(change(formName, field, value, touch)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateWorkOrderModal));
