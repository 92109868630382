import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { isEmpty } from 'lodash';
import AddButton from '../../add-button/components/add-button';
import Loader from '../../global-loader/components/simple-loader';
import Icon from '../../icon/components/icon';
import SearchInput from '../../input/components/search-input';
import LoadMore from '../../load-more/components/load-more';
import RenderIf from '../../render-if/components/render-if';
import StatusesFilter from '../../statuses-filter/components/statuses-filter';
import TableWithPath from '../../table-with-path/components/table';
import '../styles/table-template.scss';

const TableTemplate = (
  {
    tableConfig,
    data,
    filters,
    sortDirection,
    sortByColumn,
    TotalItems,
    onSortClick,
    loadMoreOnClick,
    loadMoreDisabled,
    onRowClick,
    rowClickDisabled,
    onDropdownClick,
    selectedItem,
    handleFormatCells,
    resultsText,
    searchPlaceholder,
    searchInputValue,
    searchInputOnChange,
    customTableClass,
    isLoading,
    noDataText,
    hasNext,
    pointerDotID,
    showSearchBar,
    user,
    onToggleClick,
    onDeleteRow,
    statusData,
    handleChangeStatus,
    onLinkFileClick,
    hasLinkDMSFilePermissions,
    showInfo,
    showHeader,
    loadForDMS,
    openAdvancedFilters,
    activeAdvancedFilters,
    quickFilters,
    onQuickFilterClick,
    selectedQuickFilters,
    isSingleSelect,
    isQFAdjustedForSmallerScreen,
    showOptionFor3DToggle,
    allHidden = true,
    toggleAll,
    stickyHeader,
    addButtonText,
    addButtonAction,
    addButtonDisabled = false,
    showEmptyState = false,
    emptyStateComponent,
    title,
    toggleModalAction,
    onReorderClick,
    defaultUnassignedDMSFolderID,
    maxSearchCharacters = null,
  },
  { t }
) => {
  const [previousScroll, setPreviousScroll] = useState(0);
  const containerRef = useRef();

  const handleRowsScroll = e => setPreviousScroll(e.target.scrollTop);

  return (
    <div className="table-template">
      <RenderIf if={showSearchBar}>
        <div className={`table-template-search ${openAdvancedFilters ? 'adv-filter-yes' : 'adv-filter-no'} ${addButtonText && typeof addButtonAction === 'function' ? 'button-yes' : 'button-no'}`}>
          <SearchInput
            onChange={searchInputOnChange}
            stripped
            placeholder={searchPlaceholder}
            wrapperClass="table-template-search__wrapper"
            value={searchInputValue}
            maxCharacters={maxSearchCharacters}
          />
          <RenderIf if={openAdvancedFilters && typeof openAdvancedFilters === 'function'}>
            <div className="table-template-advanced-filters">
              <p className="f-secondary-green noselect pointer link table-template-search__advanced-filter" onClick={openAdvancedFilters}>
                {t('ADVANCED_FILTER', { active: activeAdvancedFilters })}
              </p>
            </div>
          </RenderIf>
          <RenderIf if={addButtonText && typeof addButtonAction === 'function'}>
            <AddButton disabled={addButtonDisabled} children={addButtonText} onClick={addButtonAction} />
          </RenderIf>
        </div>
      </RenderIf>
      <RenderIf if={quickFilters && quickFilters.length !== 0 && onQuickFilterClick}>
        <div className="table-template-quick-filters">
          <p className="table-template-quick-filters__text">{t('QUICK_FILTER')}</p>
          <StatusesFilter
            statuses={quickFilters}
            selected={selectedQuickFilters}
            isSingleSelect={isSingleSelect}
            onChange={onQuickFilterClick}
            isAdjustedForSmallerScreen={isQFAdjustedForSmallerScreen}
          />
        </div>
      </RenderIf>
      <RenderIf if={isLoading}>
        <Loader isLoading={isLoading} />
      </RenderIf>
      <RenderIf if={(!isLoading && isEmpty(data)) || showEmptyState}>
        <p className="table-template-no-data-text">{emptyStateComponent && typeof emptyStateComponent === 'function' ? emptyStateComponent() : noDataText}</p>
      </RenderIf>
      <RenderIf if={!isLoading && !isEmpty(data) && showOptionFor3DToggle}>
        <div className="table-template-show-3d-toggle">
          <h5>{t(title || 'ALL')}</h5>
          <Icon name={allHidden ? 'not-visible' : 'eye'} size="md" onClick={() => toggleAll()} />
        </div>
      </RenderIf>
      <RenderIf if={!isLoading && !isEmpty(data)}>
        <div ref={ref => (containerRef.current = ref)} className={`table-template-container ${customTableClass ? customTableClass : ''}`} onScroll={handleRowsScroll}>
          <TableWithPath
            tableConfig={tableConfig}
            formatCell={(value, type, row, cell, rowIndex) =>
              handleFormatCells(
                value,
                type,
                searchInputValue,
                row,
                onDropdownClick,
                t,
                pointerDotID,
                user,
                onToggleClick,
                onDeleteRow,
                statusData,
                handleChangeStatus,
                onLinkFileClick,
                selectedItem,
                cell,
                hasLinkDMSFilePermissions,
                onRowClick,
                toggleModalAction,
                onReorderClick,
                rowIndex,
                defaultUnassignedDMSFolderID
              )
            }
            translationModule={t}
            stickyHeader={stickyHeader}
            sortingObj={filters}
            sortDirectionProp={sortDirection}
            sortByColumnProp={sortByColumn}
            data={data}
            onSortClick={onSortClick}
            onRowClick={onRowClick}
            rowClickDisabled={rowClickDisabled}
            selectedItem={selectedItem}
            showHeader={showHeader}
            loadForDMS={loadForDMS}
            previousScroll={previousScroll}
            containerRef={containerRef}
          />
          <RenderIf if={showInfo}>
            <div className="table-template-container__info">
              <LoadMore
                loaded={data?.length}
                total={TotalItems}
                onlyText={!hasNext}
                totalPosition="center"
                label="LOAD_MORE"
                showButton
                showTotalItems
                disabled={loadMoreDisabled}
                buttonVariant="success-outline"
                resultsText={resultsText}
                onClick={loadMoreOnClick ? loadMoreOnClick : () => null}
                buttonWidth="sm"
              />
            </div>
          </RenderIf>
        </div>
      </RenderIf>
    </div>
  );
};

TableTemplate.contextTypes = {
  t: PropTypes.func.isRequired,
};

TableTemplate.defaultProps = {
  showSearchBar: true,
  onToggleClick: () => null,
  showInfo: true,
  showHeader: true,
  activeAdvancedFilters: 0,
  isQFAdjustedForSmallerScreen: false,
  showOptionFor3DToggle: false,
  stickyHeader: true,
};

TableTemplate.propTypes = {
  tableConfig: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.array.isRequired]),
  data: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  searchTerm: PropTypes.string,
  TotalItems: PropTypes.number,
  onSortClick: PropTypes.func,
  loadMoreOnClick: PropTypes.func,
  loadMoreDisabled: PropTypes.bool,
  onRowClick: PropTypes.func,
  rowClickDisabled: PropTypes.bool,
  selectedItem: PropTypes.number,
  pointerDotID: PropTypes.number,
  showSearchBar: PropTypes.bool,
  onToggleClick: PropTypes.func,
  showInfo: PropTypes.bool,
  showHeader: PropTypes.bool,
  openAdvancedFilters: PropTypes.func,
  activeAdvancedFilters: PropTypes.number,
  quickFilters: PropTypes.array,
  onQuickFilterClick: PropTypes.func,
  selectedQuickFilters: PropTypes.array,
  isQFAdjustedForSmallerScreen: PropTypes.bool,
  showOptionFor3DToggle: PropTypes.bool,
  stickyHeader: PropTypes.bool,
};

export default TableTemplate;
