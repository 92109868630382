import { debounce, find, get, isEmpty, some, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Modal from '../../../../common/modal/components/modal';
import WorkAreasModal from '../../../../common/question-areas/components/work-areas-modal';
import ChangeStatusDropdown from '../right-toolbar/common/change-status-dropdown';
import ChangeStatusModal from '../right-toolbar/common/change-status-modal';
import ModuleChangeStatusHistoryModal from '../right-toolbar/common/module-change-status-history-modal';
import ModuleSections from '../right-toolbar/common/module-sections';
import PermitComponentsModal from './components-section/components-modal';
import IsolationCertificateModal from './isolation-certificate-section/isolation-certificate-modal';
import IssuePermitModal from './issue-permit/issue-permit-modal';
import PermitKeyboxModal from './keybox-section/keybox-modal';
import ReturnKeysModal from './return-keys-modal';

import { notificationModalCustom } from '../../../../common/modal/actions/modal-actions';
import { setSingleUploadItem, setUploadItems, uploadAsset } from '../../../upload/actions/upload-actions';
import {
  addPermitWorkArea,
  changePermitStatus,
  changePermitStatusToHandback,
  changePermitStatusToIssued,
  changePermitStatusToReIssued,
  changePermitStatusToSurrendered,
  deletePermitWorkArea,
  getPermitDetails,
  getPermits,
  getStatusHistory,
  linkIsolationCertificate,
  linkKeybox,
  removeIsolationCertificate,
  removeKeybox,
  updatePermitDates,
  updatePermitDetails,
  updatePermitWorkArea,
} from '../../actions/permit-actions';
import { singleItemUploadDone } from '../../actions/work-order-actions';
import { permitFields, statuses, fields as tableFields } from './constants/permit-constants';

import Helpers from '../../../../common/helpers';
import StatusChangeNoteModal from '../../../../common/modal/components/status-change-note-modal';
import { ComponentPicker3D, ComponentPickerCH, ComponentPickerPDF } from '../../../../common/question-components/components';
import { fetchComponents } from '../../../checklists-procedures-management/actions/checklists-procedures-management-actions';
import { defaultContractorsFilter } from '../../../contractor-management/constants/constants';
import UploadAssetsModal from '../../../project/components/upload-assets-modal';
import { uploadType } from '../../../upload/constants/constants';
import { getPermitContractorsList } from '../../actions/permit-actions';
import { imagesValidation, measurementTypes, moduleSectionsFields } from '../../constants/constants';
import InspectionRenderer from '../left-toolbar/inspection-renderer';
import { customSectionDisabledStatuses } from '../permits/constants/section-constants';
import { fields as changeStatusConstants } from '../right-toolbar/isolation-certificate-details/constants/change-status-constants';
import WorkOrderFiles from '../work-order/work-order-files';
import { fields as componentConstants, componentFetchParams } from './constants/components-constants';
import { fields as isolationCertFields } from './constants/isolation-certificate-constants';
import { fields as keyboxFields } from './constants/keybox-constants';

// TODO: refactor to functional component
class PermitSections extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actionModalData: {
        isOpen: false,
      },
      modalData: {
        isOpen: false,
      },
      changeStatusModal: {
        isOpen: false,
      },
      changeStatusConfirmModal: {
        isOpen: false,
      },
      sectionComponentsModalData: {
        isOpen: false,
      },
      componentSelectionModal: {
        isOpen: false,
      },
      editFilesModalData: {
        isOpen: false,
      },
      uploadModalData: {
        isOpen: false,
      },
      workAreasModal: {
        isOpen: false,
      },
      statusChangeNoteModalData: {
        isOpen: false,
      },
      workAreas: [],
      components: [],
      changeStatusInProgress: false,
      isDownloadInProgress: false,
    };

    this.handleSectionsChangeDebounce = debounce(this.handleSectionsChange, 600);
  }

  handleSectionsChange = (data, moduleAction) => {
    const { queryItem } = this.props;
    const dispatchAction = this.props[moduleAction];

    if (dispatchAction) {
      if (data.values) {
        data.values[permitFields.permitId] = queryItem;
      }

      dispatchAction(data);
    }
  };

  toggleIsolationCertificateModal = module => {
    const { t } = this.context;
    const { projectId } = this.props;

    const closeAction = () => {
      const { queryItem, getPermitDetails, handleSuccessFetchPermitDetails } = this.props;
      getPermitDetails(queryItem, handleSuccessFetchPermitDetails);
      this.setState({
        modalData: {
          isOpen: false,
        },
      });
    };
    const modalData = {
      isOpen: true,
      ...{
        title: t('PERMIT.ISOLATION_CERTIFICATE_MODAL_TITLE'),
        CustomContent: dynamicProps => <IsolationCertificateModal {...dynamicProps} closeAction={closeAction} projectId={projectId} />,
        type: 'none',
        customClassName: 'permit-isolation-certificate-modal',
        closeAction,
      },
    };

    this.setState({ modalData });
  };

  toggleKeyboxModal = module => {
    const { t } = this.context;
    const { projectId } = this.props;

    const closeAction = () => {
      const { queryItem, getPermitDetails, handleSuccessFetchPermitDetails } = this.props;
      getPermitDetails(queryItem, handleSuccessFetchPermitDetails);
      this.setState({
        modalData: {
          isOpen: false,
        },
      });
    };
    const modalData = {
      isOpen: true,
      ...{
        title: t('PERMIT_KEYBOX.MODAL_TITLE'),
        CustomContent: dynamicProps => <PermitKeyboxModal {...dynamicProps} closeAction={closeAction} projectId={projectId} />,
        type: 'none',
        customClassName: 'permit-keybox-modal',
        closeAction,
      },
    };

    this.setState({ modalData });
  };

  toggleComponentsModal = (module, section) => {
    const { t } = this.context;
    const { fetchComponents, inspectionId } = this.props;
    const closeAction = () => {
      this.setState({ sectionComponentsModalData: { isOpen: false }, components: [] });
    };

    fetchComponents({ ...componentFetchParams, InspectionID: parseInt(inspectionId, 10) }, components => this.setState({ components }));

    const modalData = {
      isOpen: true,
      title: t('PERMIT.EDIT_COMPONENTS_MODAL_TITLE'),
      CustomContent: dynamicProps => <PermitComponentsModal module={module} section={section} closeAction={closeAction} {...dynamicProps} />,
      type: 'none',
      customClassName: 'components-picker-modal',
      closeAction,
    };

    this.setState({ sectionComponentsModalData: modalData });
  };

  handleSectionActionClicked = (action, module, section) => {
    if (this[action] && typeof this[action] === 'function') {
      this[action](module, section);
    }
  };

  addIsolationCertificate = (module, item) => {
    const { linkIsolationCertificate, handleAddIsolationCertificateSuccess } = this.props;

    const params = {
      PermitID: module['ID'],
      IsolationCertificateID: item[isolationCertFields.id.name],
    };

    linkIsolationCertificate(params, () => handleAddIsolationCertificateSuccess(module['ID'], item));
  };

  removeIsolationCertificate = (module, item) => {
    const { removeIsolationCertificate, handleRemoveIsolationCertificateSuccess } = this.props;

    const params = {
      PermitID: module['ID'],
      IsolationCertificateID: item[isolationCertFields.id.name],
    };

    removeIsolationCertificate(params, () => handleRemoveIsolationCertificateSuccess(module['ID'], item));
  };

  addKeybox = (module, item) => {
    const { linkKeybox, handleAddKeyboxSuccess } = this.props;

    const params = {
      PermitID: module['ID'],
      KeyboxID: item[keyboxFields.id.name],
    };

    linkKeybox(params, () => handleAddKeyboxSuccess(module['ID'], { ...item, [keyboxFields.editable.name]: true }));
  };

  removeKeybox = (module, item) => {
    const { removeKeybox, handleRemoveKeyboxSuccess } = this.props;

    const params = {
      PermitID: module['ID'],
      KeyboxID: item[keyboxFields.id.name],
    };

    removeKeybox(params, () => handleRemoveKeyboxSuccess(module['ID'], item));
  };

  openIssuePermitModal = (module, statusData) => {
    const { t } = this.context;
    const { projectId, getPermitContractorsList } = this.props;

    const closeAction = () => {
      this.setState({
        changeStatusModal: {
          isOpen: false,
        },
      });
    };

    const contractorsParams = {
      ...defaultContractorsFilter,
      projectId,
      PerPage: Number.MAX_SAFE_INTEGER,
      ActiveFilter: true,
      ExcludeExpired: true,
    };

    getPermitContractorsList(contractorsParams, contractors => {
      const modalData = {
        isOpen: true,
        ...{
          title: t('PERMIT.ISSUE_PERMIT.MODAL_TITLE'),
          CustomContent: dynamicProps => (
            <IssuePermitModal
              {...dynamicProps}
              signatureRequired={module.SignatureRequired}
              contractors={contractors}
              module={module}
              closeAction={closeAction}
              projectId={projectId}
              handleStatusChanged={data => this.handleChangeStatusWithData(statusData, data)}
            />
          ),
          type: 'none',
          closeAction,
        },
      };

      this.setState({ changeStatusModal: modalData });
    });
  };

  openReturnKeysPopup = (module, data, onFinish = () => null) => {
    const { t } = this.context;
    const { changePermitStatusToHandback, permits, filters } = this.props;

    const closeAction = () => {
      this.setState({
        modalData: {
          isOpen: false,
        },
        changeStatusInProgress: false,
      });
    };

    const modalData = {
      isOpen: true,
      ...{
        title: t('PERMIT.HANDBACK_RETURN_KEYS.MODAL_TITLE'),
        CustomContent: dynamicProps => (
          <ReturnKeysModal
            {...dynamicProps}
            ModuleID={module[moduleSectionsFields.id]}
            SectionID={data.SectionID}
            closeAction={closeAction}
            handleSubmit={Keys => {
              this.setState({ changeStatusInProgress: true });
              changePermitStatusToHandback({ ...data, Keys }, Object.assign([], permits), filters, isSuccess => {
                closeAction();
                onFinish(isSuccess);
              });
            }}
          />
        ),
        type: 'none',
        closeAction,
      },
    };

    this.setState({ modalData });
  };

  handleChangeStatusWithData = (statusData, data = {}) => {
    const { permitDetails } = this.props;
    const { queryItem, permits, changePermitStatus, changePermitStatusToIssued, changePermitStatusToSurrendered, changePermitStatusToReIssued, filters } = this.props;
    const sections = permitDetails[moduleSectionsFields.sections];
    const section = find(sections, { Name: statusData.sectionName });

    statusData[permitFields.permitId] = queryItem;
    data[permitFields.permitId] = queryItem;

    this.setState({ changeStatusInProgress: true });

    const onChangeStatusFinish = isSuccess => {
      if (isSuccess) {
        const { queryItem, getPermitDetails, handleSuccessFetchPermitDetails } = this.props;
        getPermitDetails(queryItem, handleSuccessFetchPermitDetails);

        this.setState({ changeStatusConfirmModal: { isOpen: false }, changeStatusModal: { isOpen: false }, statusChangeNoteModalData: { isOpen: false }, changeStatusInProgress: false });
      } else {
        this.setState({ changeStatusInProgress: false });
      }
    };

    switch (statusData[tableFields.status]) {
      case statuses.issued.value:
        changePermitStatusToIssued(data, Object.assign([], permits), filters, onChangeStatusFinish);
        break;
      case statuses.reissued.value:
        data.ReissuedSectionID = section?.ID;
        changePermitStatusToReIssued(data, Object.assign([], permits), filters, onChangeStatusFinish);
        break;
      case statuses.surrendered.value:
        data.SurrenderedSectionID = section?.ID;
        changePermitStatusToSurrendered(data, Object.assign([], permits), filters, onChangeStatusFinish);
        break;
      case statuses.handback.value:
        data.HandBackSectionID = section?.ID;
        this.openReturnKeysPopup(permitDetails, data, onChangeStatusFinish);
        break;
      default:
        changePermitStatus(statusData, Object.assign([], permits), filters, onChangeStatusFinish);
        break;
    }
  };

  handleChangeStatus = data => {
    const { t } = this.context;
    const { permitDetails } = this.props;
    const { queryItem } = this.props;
    data[permitFields.permitId] = queryItem;

    const sections = permitDetails[moduleSectionsFields.sections];
    const issuedSection = find(sections, { Name: statuses.issued.sectionName });
    const reissuedSection = find(sections, { Name: statuses.reissued.sectionName });
    let issuedToPerson = null;
    if (reissuedSection[changeStatusConstants.statusChangeAssigneeID] && reissuedSection[changeStatusConstants.statusChangeAssigneeName]) {
      issuedToPerson = { ID: reissuedSection[changeStatusConstants.statusChangeAssigneeID], Name: reissuedSection[changeStatusConstants.statusChangeAssigneeName] };
    } else if (issuedSection[changeStatusConstants.statusChangeAssigneeID] && issuedSection[changeStatusConstants.statusChangeAssigneeName]) {
      issuedToPerson = { ID: issuedSection[changeStatusConstants.statusChangeAssigneeID], Name: issuedSection[changeStatusConstants.statusChangeAssigneeName] };
    }

    const openStatusChangeNoteModal = values => {
      this.setState({
        statusChangeNoteModalData: {
          title: t('STATUS_CHANGE_NOTE_MODAL.REJECT_TITLE'),
          content: t('STATUS_CHANGE_NOTE_MODAL.PERMIT_REJECTED_CONTENT'),
          confirmButtonLabel: t('STATUS_CHANGE_NOTE_MODAL.MARK_AS_REJECTED'),
          isOpen: true,
          confirmAction: comment => this.handleChangeStatusWithData({ ...values, Comment: comment.Comment }),
          closeAction: () => this.setState({ statusChangeNoteModalData: { isOpen: false }, changeStatusConfirmModal: { isOpen: false } }),
        },
      });
    };

    if (data?.isRegularStatus) {
      const confirmChangeStatus = () => {
        this.handleChangeStatusWithData(data);
      };

      const modalData = {
        isOpen: true,
        title: t(data.modalTitle),
        content: t('PERMIT.STATUS.CONFIRM_MODAL_CONTENT', { status: t(data.title) }),
        type: 'yes-no',
        customClassName: 'change-status-confirm-modal',
        // if Permit is marked as rejected, display the Rejection Note modal
        confirmAction: () => (data[moduleSectionsFields.status] === statuses.rejected.value ? openStatusChangeNoteModal(data) : confirmChangeStatus()),
        closeAction: () => this.setState({ changeStatusConfirmModal: { isOpen: false } }),
      };

      this.setState({ changeStatusConfirmModal: modalData });
    } else {
      const { t } = this.context;
      const { projectId, getPermitContractorsList } = this.props;
      const closeAction = () => {
        this.setState({ changeStatusModal: { isOpen: false } });
      };

      const contractorsParams = {
        ...defaultContractorsFilter,
        projectId,
        PerPage: Number.MAX_SAFE_INTEGER,
        ActiveFilter: true,
        ExcludeExpired: true,
      };

      switch (data?.value) {
        case statuses.issued.value:
          this.openIssuePermitModal(permitDetails, data);
          break;
        default:
          getPermitContractorsList(contractorsParams, contractors => {
            const modalData = {
              isOpen: true,
              title: t(data.modalTitle),
              CustomContent: dynamicProps => (
                <ChangeStatusModal
                  closeAction={closeAction}
                  contractors={contractors}
                  selectedContractor={data?.value === statuses.handback.value || data?.value === statuses.surrendered.value ? issuedToPerson : null}
                  status={data}
                  signatureRequired={permitDetails.SignatureRequired}
                  handleStatusChanged={values => this.handleChangeStatusWithData(data, values)}
                  {...dynamicProps}
                />
              ),
              type: 'none',
              customClassName: 'modules-change-status-modal-default',
              closeAction,
            };

            this.setState({ changeStatusModal: modalData });
          });
          break;
      }
    }
  };

  handleStatusHistoryViewMore = section => {
    const { t } = this.context;
    const { getStatusHistory } = this.props;
    let statusHistoryParams = {
      ModuleSectionID: section[moduleSectionsFields.id],
    };

    const closeAction = () => {
      this.setState({ modalData: { isOpen: false } });
    };

    const status = values(statuses).find(status => section[moduleSectionsFields.sectionName] === status?.sectionName);

    if (status) {
      statusHistoryParams.Status = status.value;
      getStatusHistory(statusHistoryParams, (isSuccess, historyRecords) => {
        if (isSuccess && !isEmpty(historyRecords)) {
          this.setState({
            modalData: {
              isOpen: true,
              title: t(section[moduleSectionsFields.sectionSecondaryName]),
              CustomContent: dynamicProps => <ModuleChangeStatusHistoryModal data={historyRecords} {...dynamicProps} />,
              customClassName: 'modules-change-status-modal-default',
              type: 'none',
              closeAction,
            },
          });
        }
      });
    }
  };

  formatComponents = components => {
    if (components && components.length > 0) {
      return components.map(item => {
        return { ...item, [componentConstants.component.name]: item[componentConstants.componentId.name], ComponentName: item.Name };
      });
    }

    return [];
  };

  handleComponentPicker3D = () => {
    const { inspectionId, handleComponentSelected, handleDeleteComponent } = this.props;
    const closeAction = () => {
      this.setState({ componentSelectionModal: { isOpen: false } });
    };

    this.setState({
      componentSelectionModal: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <ComponentPicker3D {...dynamicProps} handleComponentSelected={handleComponentSelected} handleDeleteComponent={handleDeleteComponent} inspectionId={inspectionId} closeAction={closeAction} />
        ),
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        closeAction,
      },
    });
  };

  handleComponentPickerPDF = () => {
    const { inspectionId, handleComponentSelected, handleDeleteComponent } = this.props;
    const closeAction = () => {
      this.setState({ componentSelectionModal: { isOpen: false } });
    };

    this.setState({
      componentSelectionModal: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <ComponentPickerPDF {...dynamicProps} handleComponentSelected={handleComponentSelected} handleDeleteComponent={handleDeleteComponent} inspectionId={inspectionId} closeAction={closeAction} />
        ),
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        closeAction,
      },
    });
  };

  handleComponentPickerCH = () => {
    const { inspectionId, handleComponentSelected, handleDeleteComponent } = this.props;
    const { t } = this.context;
    const closeAction = () => {
      this.setState({ componentSelectionModal: { isOpen: false } });
    };

    this.setState({
      componentSelectionModal: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <ComponentPickerCH {...dynamicProps} handleComponentSelected={handleComponentSelected} handleDeleteComponent={handleDeleteComponent} inspectionId={inspectionId} closeAction={closeAction} />
        ),
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        title: t('QUESTION_COMPONENT_PICKER.CHOOSE_COMPONENTS'),
        closeAction,
        customCloseAction: closeAction,
      },
    });
  };

  toggleConfirmationModal = (isOpen, title, confirmAction) => {
    const { t } = this.context;

    if (isOpen) {
      const handleConfirmAction = () => {
        if (typeof confirmAction === 'function') {
          confirmAction();
        }
      };

      this.setState({
        actionModalData: {
          isOpen: true,
          title: t(title),
          type: 'yes-no',
          customClassName: 'permit-confirm-modal',
          confirmAction: handleConfirmAction,
          closeAction: () => this.setState({ actionModalData: { isOpen: false } }),
        },
      });
    } else {
      this.setState({ actionModalData: { isOpen: false } });
    }
  };

  isValid = (items, files) => {
    const { notificationModalCustom } = this.props;
    const allFiles = items.concat(files);

    if (allFiles && allFiles.length > imagesValidation.maxFiles) {
      notificationModalCustom(
        {
          key: 'WORK_ORDER_CREATE_VALIDATION.VALIDATION_MAX_FILES',
          props: { maxFiles: imagesValidation.maxFiles, current: files && files.length ? files.length : 0 },
        },
        'WORK_ORDER_CREATE_VALIDATION.VALIDATION_FILE_SIZE_TITLE'
      );

      return false;
    }

    const hasLargeFiles = some(items, item => item.size > imagesValidation.bytes);

    if (hasLargeFiles) {
      //open modal with message
      notificationModalCustom(
        {
          key: 'WORK_ORDER_CREATE_VALIDATION.VALIDATION_FILE_SIZE',
          props: { fileSize: `${imagesValidation.value}${imagesValidation.unit}` },
        },
        'WORK_ORDER_CREATE_VALIDATION.VALIDATION_FILE_SIZE_TITLE'
      );
      return false;
    }
    return true;
  };

  onDropAsset = (files, UploadGroup, onUpload) => {
    const { permitDetails } = this.props;
    const { queryItem, uploadAsset, setUploadItems, setSingleUploadItem, singleItemUploadDone, inspectionId, projectId } = this.props;
    const permitFiles = permitDetails?.[moduleSectionsFields.files];
    const permitUploadType = uploadType.permitFiles;

    if (!this.isValid(files, Object.assign([], permitFiles))) {
      return false;
    }

    const tmp_files = files.map((file, index) => {
      const tmp_file = {
        type: Helpers.getFileType(file.type),
        mimeType: file.type,
        title: '',
        description: '',
        real_name: Helpers.formatFileName(file.name),
      };
      file.timeStamp = Date.now();
      file.real_name = tmp_file.real_name;
      file.uploadType = permitUploadType;

      uploadAsset(file, { ProjectID: projectId, InspectionID: inspectionId, UploadGroup, PermitID: queryItem, UploadType: permitUploadType }, setSingleUploadItem, index, (i, url) => {
        singleItemUploadDone(url, file => {
          file.UploadGroup = UploadGroup;

          if (typeof onUpload === 'function') {
            onUpload(file);
          }
        });
      });

      return { ...tmp_file, ...file };
    });

    setUploadItems(tmp_files);
  };

  openFilesModalData = (uploadGroup, supportedMimeTypes, onUpload, skipValidation) => {
    const { t } = this.context;
    const { setUploadItems } = this.props;

    const confirmAction = () => {
      setUploadItems([]);

      const { queryItem, getPermitDetails, handleSuccessFetchPermitDetails } = this.props;
      getPermitDetails(queryItem, (_, permitDetails) => handleSuccessFetchPermitDetails(false, permitDetails));

      this.setState({
        uploadModalData: {
          isOpen: false,
        },
      });
    };

    this.setState({
      uploadModalData: {
        title: t('REGIME_FILES_UPLOAD_MODAL.TITLE'),
        customClassName: 'project-assets-modal modal-small',
        CustomContent: () => (
          <UploadAssetsModal
            onDrop={files => this.onDropAsset(files, uploadGroup, onUpload, skipValidation)}
            className="project-dropzone"
            labelWhite={t('REGIME_FILES_UPLOAD_MODAL.TITLE')}
            labelGreen={t('REGIME_FILES_UPLOAD_MODAL.BROWSE')}
            supportedMimeTypes={supportedMimeTypes}
          />
        ),
        isOpen: true,
        type: 'ok',
        confirmAction: confirmAction,
        closeAction: confirmAction,
      },
    });
  };

  openEditFilesModal = () => {
    const { t } = this.context;

    const confirmAction = () => {
      this.setState({
        editFilesModalData: {
          isOpen: false,
        },
      });
    };

    const deletePermitFileHandler = file => {
      const { queryItem, handleDeletePermitFile } = this.props;

      this.toggleConfirmationModal(true, 'WORK_ORDER.DELETE_FILE_TITLE', () => handleDeletePermitFile(queryItem, file.FileID, () => this.toggleConfirmationModal(false)));
    };

    this.setState(prevState => ({
      editFilesModalData: {
        ...prevState.editFilesModalData,
        title: t('WORK_ORDER.EDIT_FILES'),
        customClassName: 'work-order-default-modal create-work-order-modal',
        CustomContent: dynamicProps => (
          <div className="work-order-form">
            <WorkOrderFiles openFilesModalData={this.openFilesModalData} deleteWorkOrderFile={deletePermitFileHandler} hideRams={true} isFilesSectionSelected {...dynamicProps} />
          </div>
        ),
        isOpen: true,
        type: 'none',
        confirmAction: confirmAction,
        closeAction: confirmAction,
      },
    }));
  };

  addPermitWorkArea = async (permitId, workArea) => {
    const { addPermitWorkArea, getPermitDetails, handleSuccessFetchPermitDetails } = this.props;

    await addPermitWorkArea(permitId, workArea);
    getPermitDetails(permitId, (_, permitDetails) => handleSuccessFetchPermitDetails(false, permitDetails));
  };

  deletePermitWorkArea = async (permitId, workArea) => {
    const { queryItem, getPermitDetails, deletePermitWorkArea, handleSuccessFetchPermitDetails } = this.props;

    await deletePermitWorkArea(queryItem, workArea);
    getPermitDetails(queryItem, (_, permitDetails) => handleSuccessFetchPermitDetails(false, permitDetails));
  };

  updatePermitWorkArea = async (permitId, workArea, dataCallback) => {
    const { queryItem, getPermitDetails, updatePermitWorkArea, handleSuccessFetchPermitDetails } = this.props;

    await updatePermitWorkArea(permitId, workArea, dataCallback);
    getPermitDetails(queryItem, (_, permitDetails) => handleSuccessFetchPermitDetails(false, permitDetails));
  };

  handleOpenWorkAreaModal = () => {
    const { inspectionId } = this.props;

    const closeAction = () => {
      this.setState({ workAreasModal: { isOpen: false } });
    };

    this.setState({
      workAreasModal: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <WorkAreasModal
            {...dynamicProps}
            inspectionId={inspectionId}
            handleWorkAreaAdd={this.addPermitWorkArea}
            handleDeleteWorkArea={this.deletePermitWorkArea}
            handleWorkAreaUpdate={this.updatePermitWorkArea}
            closeAction={closeAction}
            showOnly2D={true}
          />
        ),
        customClassName: 'modal-no-max-height',
        customClassWrapperName: 'no-padding',
        type: 'none',
        closeAction,
      },
      currentQuestionId: 1,
    });
  };

  // handleScreenshotFrom3D = componentId => {
  //   const { inspectionId } = this.props;

  //   const closeAction = () => {
  //     this.setState({ screenShotPickerModal: { isOpen: false }, componentId: null });
  //   };

  //   const onDropAsset = (files, _certificateId, uploadGroup) => {
  //     const { screenShotPickerModal } = this.state;

  //     this.onDropAsset(files, screenShotPickerModal?.componentId, uploadGroup);
  //   };

  //   this.setState({
  //     screenShotPickerModal: {
  //       isOpen: true,
  //       componentId,
  //       CustomContent: dynamicProps => <ScreenshotPicker {...dynamicProps} inspectionId={inspectionId} onDropAsset={onDropAsset} closeAction={closeAction} />,
  //       customClassName: 'modal-no-max-height modal-large',
  //       type: 'none',
  //       closeAction,
  //     },
  //   });
  // };

  render() {
    const {
      queryItem,
      permits,
      projectId,
      user,
      location,
      permitDetails,
      handleChangeModule,
      handleAnswerQuestion,
      handleComponentSelected,
      handleDeleteComponent,
      handleCustomAction,
      isLoading,
      viewer,
      setPermitCameraPosition,
      debounceValues,
    } = this.props;
    const { addIsolationCertificate, removeIsolationCertificate, addKeybox, removeKeybox, toggleComponentsModal } = this;
    const {
      actionModalData,
      modalData,
      changeStatusConfirmModal,
      changeStatusInProgress,
      changeStatusModal,
      isDownloadInProgress,
      sectionComponentsModalData,
      components,
      componentSelectionModal,
      editFilesModalData,
      uploadModalData,
      workAreasModal,
      statusChangeNoteModalData,
    } = this.state;
    const { WorkAreas } = permitDetails;
    const modalProps = {
      addIsolationCertificate,
      removeIsolationCertificate,
      addKeybox,
      removeKeybox,
      module: permitDetails,
    };
    const descriptionType = location && get(location.query, 'type');
    const workareas = (permitDetails[moduleSectionsFields.workAreas] || []).map(wa => ({ ...wa, visible: true }));

    return (
      <div className="details-sections">
        <InspectionRenderer
          deselectAll={() => null}
          selectAll={() => null}
          toggleElement={() => null}
          deselectAllTemp={() => null}
          selectAllTemp={() => null}
          toggleElementTemp={() => null}
          selectElement={() => null}
          updateElementGeometry={() => null}
          selectedDefect={null}
          elements={(permitDetails[moduleSectionsFields.components] || [])
            .map(component => ({
              ...component,
              visible: true,
              Colour: component[componentConstants.colour.name] || '',
              SystemType: measurementTypes.component,
              // in case ComponentId is provided from API overwrite it to ID
              ID: component[componentConstants.component.name] || component.ID,
            }))
            .concat(workareas)}
          disableMove={true}
          viewer={viewer}
        />
        <ChangeStatusDropdown
          statuses={statuses}
          module={Object.assign({}, permitDetails)}
          createdById={permitDetails[tableFields.createdById]}
          user={user}
          handleChangeStatus={this.handleChangeStatus}
          finalStatus={statuses?.handback?.value}
          isDownloadDisabled={isDownloadInProgress}
          rejectedStatus={statuses?.rejected?.value}
          descriptionType={descriptionType}
        />
        <ModuleSections
          disabled={isLoading}
          viewer={viewer}
          sections={permitDetails?.Sections}
          module={{ ...Object.assign({}, permitDetails), workAreas: WorkAreas, setCameraPosition: setPermitCameraPosition }}
          handleSectionChange={this.handleSectionsChangeDebounce}
          handleAnswerQuestion={handleAnswerQuestion}
          finalStatus={statuses?.handback?.value}
          modules={Object.assign([], permits)}
          projectId={projectId}
          moduleId={permitDetails[permitFields.id]}
          handleChangeModule={handleChangeModule}
          handleSectionActionClicked={this.handleSectionActionClicked}
          statuses={statuses}
          createdById={permitDetails[tableFields.createdById]}
          user={user}
          handleCustomAction={(action, values, cb) =>
            this.props[action] && typeof this.props[action] === 'function' ? handleCustomAction(this.props[action], values, cb) : this.handleStatusHistoryViewMore(action)
          }
          customSectionDisabledStatuses={customSectionDisabledStatuses}
          toggleComponentsModal={toggleComponentsModal}
          debounceValues={debounceValues}
        />
        <Modal {...editFilesModalData} workorderFiles={{ OTHER_FILES: permitDetails?.[moduleSectionsFields.files] }} />
        <Modal {...uploadModalData} />
        <Modal
          {...sectionComponentsModalData}
          components={components}
          selectedComponents={permitDetails[moduleSectionsFields.components]}
          handleComponentSelected={handleComponentSelected}
          handleDeleteComponent={handleDeleteComponent}
          permitId={queryItem}
          handleComponentPicker3D={this.handleComponentPicker3D}
          handleComponentPickerPDF={this.handleComponentPickerPDF}
          handleComponentPickerCH={this.handleComponentPickerCH}
        />
        <Modal {...componentSelectionModal} components={components} question={{ QuestionsComponents: this.formatComponents(permitDetails[moduleSectionsFields.components]), ID: queryItem }} />
        <Modal {...changeStatusModal} {...modalProps} sections={Object.assign([], permitDetails?.[moduleSectionsFields.sections])} disabled={changeStatusInProgress} />
        <Modal {...modalData} {...modalProps} />
        <Modal {...changeStatusConfirmModal} modalDisabled={changeStatusInProgress} />
        <Modal {...actionModalData} />
        <Modal {...workAreasModal} question={{ ID: queryItem, QuestionAreas: WorkAreas }} />
        <StatusChangeNoteModal {...statusChangeNoteModalData} />
      </div>
    );
  }
}

PermitSections.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  permits: state.permitsReducer.permits,
  user: state.userReducer,
  filters: state.permitsReducer.filters,
});

const mapDispatchToProps = dispatch => ({
  getPermits: (filters, isolationCertificates, loadMore, callback) => dispatch(getPermits(filters, isolationCertificates, loadMore, callback)),
  getPermitDetails: (permitId, callback) => dispatch(getPermitDetails(permitId, callback)),
  updatePermitDetails: data => dispatch(updatePermitDetails(data)),
  updatePermitDates: data => dispatch(updatePermitDates(data)),
  linkIsolationCertificate: (params, callback) => dispatch(linkIsolationCertificate(params, callback)),
  removeIsolationCertificate: (params, callback) => dispatch(removeIsolationCertificate(params, callback)),
  linkKeybox: (params, callback) => dispatch(linkKeybox(params, callback)),
  removeKeybox: (params, callback) => dispatch(removeKeybox(params, callback)),
  getPermitContractorsList: (data, callback) => dispatch(getPermitContractorsList(data, callback)),
  changePermitStatus: (data, permits, filters, callback) => dispatch(changePermitStatus(data, permits, filters, callback)),
  changePermitStatusToIssued: (data, permits, filters, callback) => dispatch(changePermitStatusToIssued(data, permits, filters, callback)),
  changePermitStatusToSurrendered: (data, permits, filters, callback) => dispatch(changePermitStatusToSurrendered(data, permits, filters, callback)),
  changePermitStatusToReIssued: (data, permits, filters, callback) => dispatch(changePermitStatusToReIssued(data, permits, filters, callback)),
  changePermitStatusToHandback: (data, permits, filters, callback) => dispatch(changePermitStatusToHandback(data, permits, filters, callback)),
  getStatusHistory: (data, callback) => dispatch(getStatusHistory(data, callback)),
  fetchComponents: (data, callback) => dispatch(fetchComponents(data, callback)),
  notificationModalCustom: (errorMessage, title) => dispatch(notificationModalCustom(true, errorMessage, title)),
  uploadAsset: (file, IDs, callbackFunction, index, customDoneFunc) => dispatch(uploadAsset(file, IDs, callbackFunction, index, customDoneFunc)),
  setSingleUploadItem: (progress, index) => dispatch(setSingleUploadItem(progress, index)),
  setUploadItems: assets => dispatch(setUploadItems(assets)),
  singleItemUploadDone: (url, callback) => dispatch(singleItemUploadDone(url, callback)),
  addPermitWorkArea: (permitID, data) => dispatch(addPermitWorkArea(permitID, data)),
  updatePermitWorkArea: (permitID, data, dataCallback) => dispatch(updatePermitWorkArea(permitID, data, dataCallback)),
  deletePermitWorkArea: (permitID, data) => dispatch(deletePermitWorkArea(permitID, data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PermitSections));
