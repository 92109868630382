import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { modules } from '../../../../constants/constants';
import EditModuleItemFilesModal from '../../../right-toolbar/common/edit-module-item-files-modal';
import { fields, MAX_MEASUREMENT_READING_FILES } from '../../constants/constants';
import '../../styles/measurement-reading-form.scss';

const MeasurementReadingFormFiles = props => {
  const { measurementReadingData, defaultReadingsAndGaugesUnassignedDMSFolderID, fetchMeasurementReadingUploadedFiles, setMeasurementReadingFilesUploaded } = props;

  useEffect(() => {
    fetchMeasurementReadingUploadedFiles(measurementReadingData[fields.measurementReadingId]);
    return () => {
      setMeasurementReadingFilesUploaded([]); // resets the measurementReadingFilesUploaded state
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="create-measurement-reading-form__files">
      <EditModuleItemFilesModal
        projectDMSCategories={[
          { [fields.id]: defaultReadingsAndGaugesUnassignedDMSFolderID, [fields.name]: 'MEASUREMENT_READING.READINGS_FILES', [fields.description]: 'MEASUREMENT_READING.READINGS_FILES.MAX' },
        ]}
        selectedModuleItem={measurementReadingData}
        moduleType={modules.readingsAndGauges}
        maximumUploadLimit={MAX_MEASUREMENT_READING_FILES}
        overrideModuleTypeWithProp
      />
    </div>
  );
};

MeasurementReadingFormFiles.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MeasurementReadingFormFiles;
