import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import 'react-input-range/lib/css/index.css';
import { connect } from 'react-redux';
import { change, Field, getFormSyncErrors, reduxForm } from 'redux-form';
import { FORMS } from '../../../../../common/constants';
import Button from '../../../../../common/form/components/button';
import CustomInput from '../../../../../common/form/components/input';
import CustomSelect from '../../../../../common/form/components/select';
import Textarea from '../../../../../common/form/components/text-area';
import Helpers from '../../../../../common/helpers';
import Icon from '../../../../../common/icon/components/icon';
import { clearSubTypesSuggestions } from '../../../actions/action-creators';
import { fetchSubTypesSuggestions } from '../../../actions/inspection-actions';
import { measurementTypes } from '../../../constants/constants';
import { observationsTypesIconsMap, observationTypeFields, textAreaMaxChars } from '../../../constants/defect-constants';
import ComponentPickerWithList from '../../common/component-picker/component-picker-with-list';
import AutoComplete from '../../common/input/autocomplete';
import CustomInputRange from '../../common/input/input-range';
import { validate } from '../../right-toolbar/validators/defect-validator';
import { fields } from '../constants/constants';
import '../styles/new-observation-form.scss';

const NewObservationForm = (
  {
    onCancel,
    handleSubmit,
    observationTypes,
    subTypesSuggestions,
    fetchSubTypesSuggestions,
    clearSubTypesSuggestions,
    changeField,
    formValues,
    fetchComponents,
    selectedComponent,
    handleOnComponentSelect,
    inspectionId,
    formErrors,
    isPristine,
    isDirty,
  },
  { t }
) => {
  return (
    <form className="new-observation-form" noValidate onSubmit={handleSubmit}>
      <div className="row">
        <Field
          id={fields.name}
          disabled={false}
          name={fields.name}
          component={CustomInput}
          placeholder={t('NAME') + '*'}
          label={t('NAME')}
          labelClass="f-primary defect-form__label"
          type="text"
          size="lg"
          isRequired
        />
      </div>
      <div className="row">
        <Field
          id={fields.mainType}
          name={fields.mainType}
          disabled={false}
          disabledItems={[]}
          component={CustomSelect}
          isHidden={false}
          placeholder={t('DEFECT_DETAILS.FORM_MAIN_TYPE')}
          label={'DEFECT_DETAILS.FORM_MAIN_TYPE'}
          labelClass="f-primary defect-form__label"
          type="text"
          data={observationTypes}
          valueField={observationTypeFields.value}
          textField={observationTypeFields.name}
          onChange={newValue => {
            changeField(fields.mainType, newValue[observationTypeFields.value]);
          }}
          defaultValue={measurementTypes.defect}
          isEnhancedDesignCustomProp
          size="lg"
          valueComponent={selected => {
            return (
              <p className="observations-details-section__select-option">
                <Icon name={observationsTypesIconsMap[selected?.item?.Value]} size="sm" handleHover={false} />
                {selected.text}
              </p>
            );
          }}
          optionComponent={({ dataItem, children, onSelect, ...e }) => {
            return (
              <p onClick={e => onSelect(dataItem, e)} className="observations-details-section__select-options">
                <Icon name={observationsTypesIconsMap[dataItem?.Value]} size="sm" handleHover={false} />
                {children}
              </p>
            );
          }}
          CustomSelectIcon={<Icon name="chevron-down" className="dropdown-icon" handleHover={true} size="sm" />}
        />
        <Field
          id={fields.defectType}
          name={fields.defectType}
          disabled={false}
          component={AutoComplete}
          suggestions={Helpers.convertSuggestionArray(subTypesSuggestions)}
          fetchSuggestions={fetchSubTypesSuggestions}
          clearSuggestions={clearSubTypesSuggestions}
          placeholder={t('DEFECT_DETAILS.FORM_TYPE')}
          label={t('DEFECT_DETAILS.FORM_TYPE')}
          labelClass="f-primary defect-form__label"
          type="text"
          inputProps={{ isEnhancedDesignCustomProp: true }}
        />
      </div>
      <div className="row">
        <Field
          id={fields.severity}
          disabled={false}
          name={fields.severity}
          minValue={1}
          maxValue={10}
          isHidden={false}
          component={CustomInputRange}
          placeholder={t('DEFECT_DETAILS.FORM_SEVERITY')}
          label={t('DEFECT_DETAILS.FORM_SEVERITY')}
          labelClass="f-primary defect-form__label defect-form__label--range"
          hideBorder
          type="range"
        />
      </div>
      <div className="row comments-container">
        <Field
          id={fields.description}
          disabled={false}
          name={fields.description}
          component={Textarea}
          placeholder={'DEFECT_DETAILS.FORM_DESCRIPTION'}
          label={'DEFECT_DETAILS.FORM_DESCRIPTION'}
          labelClass="f-primary defect-form__label"
          maxChars={textAreaMaxChars}
          enableAutoResize={false}
          className="input-wrapper__input text-area"
        />

        <Field
          id={fields.recommendation}
          disabled={false}
          name={fields.recommendation}
          component={Textarea}
          placeholder={'DEFECT_DETAILS.FORM_RECOMMENDATION'}
          label={'DEFECT_DETAILS.FORM_RECOMMENDATION'}
          labelClass="f-primary defect-form__label"
          maxChars={textAreaMaxChars}
          enableAutoResize={false}
          className="input-wrapper__input text-area"
        />
      </div>
      <div className="row">
        <ComponentPickerWithList
          fetchComponents={fetchComponents}
          inspectionId={inspectionId}
          selectedComponents={selectedComponent}
          onComponentSelect={handleOnComponentSelect}
          disabled={false}
          searchPlaceholder={'INSPECTION_COMPONENTS'}
        />
      </div>
      <div className="row">
        <Button type="button" width="sm" height="md" text={t('CANCEL')} variant={'gray-outline'} onClick={onCancel} />
        <Button type="submit" width="md" height="md" text={t('ALARMS.FORM_ENABLE_DEFECT_CREATION.LABEL')} disabled={!isEmpty(formErrors) || isPristine} keepOriginalText />
      </div>
    </form>
  );
};

NewObservationForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { initialValues }) => {
  return {
    initialValues,
    formValues: state.form[FORMS.newObservationForm]?.values || {},
    formErrors: getFormSyncErrors(FORMS.newObservationForm)(state),
    subTypesSuggestions: state.inspectionReducer.observationSubTypesSuggestions,
  };
};

const mapDispatchToProps = dispatch => ({
  changeField: (fieldName, value) => dispatch(change(FORMS.newObservationForm, fieldName, value)),
  fetchSubTypesSuggestions: val => dispatch(fetchSubTypesSuggestions(val)),
  clearSubTypesSuggestions: () => dispatch(clearSubTypesSuggestions()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: FORMS.newObservationForm,
    enableReinitialize: true,
    validate,
  })(NewObservationForm)
);
