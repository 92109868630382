import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
//components
import InspectionRenderer from '../../../left-toolbar/inspection-renderer';
import SingleRnGItemTable from '../single-item-table/single-item-table';
//constants
import Helpers from '../../../../../../common/helpers';
import { modules } from '../../../../constants/constants';
import { deselectAllMeasurementGroups, selectAllMeasurementGroups, setMeasurementGroup, setMeasurementGroups, toggleMeasurementGroupVisibility } from '../../actions/action-creators';
import { fetchGroups } from '../../actions/measurement-group-actions';
import { defaultFilters, fields, MAX_SEARCH_GROUP_CHARACTERS, viewOptions } from '../../constants/constants';

const MeasurementGroupsInspectionView = (props, { t }) => {
  const {
    viewer,
    isFullScreen = false,
    projectID,
    handleBackClick,
    handlePathClick,
    path,
    navigateToPath,
    view,
    fetchGroups,
    selectMeasurementGroup,
    user,
    showPath,
    setMeasurementGroups,
    measurementGroups,
    deselectAll,
    selectAll,
    toggleElement,
    elementsClustered,
    areAllMeasurementGroupsHidden,
    visibleGroupID,
    showAdditionalOptions = true,
    searchPlaceholder,
    isNewMeasurementPointModal = false,
  } = props;
  const [groupFilters, setGroupFilters] = useState(defaultFilters);
  const [isGroupLoading, setIsGroupLoading] = useState(false);

  const fetchData = useCallback(
    (filters, loadMore = false) => {
      const setNewData = newData => {
        const dataToSet = loadMore ? [...measurementGroups, ...newData] : newData;
        setMeasurementGroups(dataToSet);
      };
      const setDataLoaded = isLoading => {
        setIsGroupLoading(isLoading);

        if (loadMore && !isLoading) {
          Helpers.scrollIntoView('measurement-groups-table', null, -250);
        }
      };
      fetchGroups({ ...filters, [fields.projectID]: projectID }, setNewData, setGroupFilters, null, setDataLoaded);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [measurementGroups]
  );

  const handleRowClick = row => {
    selectMeasurementGroup(row);
    const newPathItem = { [fields.name]: t(row[fields.name]), queryParams: { type: modules.readingsAndGauges, view: viewOptions.location_and_points } };
    navigateToPath(newPathItem);
  };

  const emptyStateProps = {
    emptyStateText: t('READINGS_AND_GAUGES.GROUP_EMPTY_STATE.TEXT'),
    showButton: false,
    transparent: true,
    buttonAction: () => null,
    buttonDisabled: true,
  };

  return (
    <>
      <InspectionRenderer
        deselectAll={deselectAll}
        selectAll={selectAll}
        toggleElement={toggleElement}
        deselectAllTemp={() => null}
        selectAllTemp={() => null}
        toggleElementTemp={() => null}
        updateElementGeometry={(...args) => {}}
        selectElement={(data, autoScroll) => {}}
        elements={areAllMeasurementGroupsHidden && !visibleGroupID ? [] : elementsClustered || []}
        queryItem={null}
        disableMove={true}
        viewer={viewer}
      >
        {({ elementShowHandler, elementHideHandler }) => (
          <>
            <SingleRnGItemTable
              customTableClass="measurement-groups-table"
              title={'READINGS_AND_GAUGES.ALL_GROUPS'}
              isFullScreen={isFullScreen}
              view={view}
              handleRowClick={handleRowClick}
              showPath={showPath}
              path={path}
              navigateToPath={navigateToPath}
              handleBackClick={handleBackClick}
              handlePathClick={handlePathClick}
              projectID={projectID}
              fetchData={fetchData}
              data={measurementGroups}
              filters={groupFilters}
              defaultFilters={defaultFilters}
              isDataLoading={isGroupLoading}
              emptyStateProps={emptyStateProps}
              user={user}
              toggleVisibilityHandler={(e, row) => {
                if (!areAllMeasurementGroupsHidden) {
                  deselectAll();
                  return elementShowHandler(e, row);
                }
                return row.visible ? elementHideHandler(e, row) : elementShowHandler(e, row);
              }}
              allHidden={areAllMeasurementGroupsHidden}
              toggleAll={() => (areAllMeasurementGroupsHidden ? selectAll() : deselectAll())}
              showAdditionalOptions={showAdditionalOptions}
              searchPlaceholder={searchPlaceholder}
              isNewMeasurementPointModal={isNewMeasurementPointModal}
              maxSearchCharacters={MAX_SEARCH_GROUP_CHARACTERS}
            />
          </>
        )}
      </InspectionRenderer>
    </>
  );
};

MeasurementGroupsInspectionView.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  measurementGroups: state.measurementGroupReducer.measurementGroups,
  areAllMeasurementGroupsHidden: state.measurementGroupReducer.areAllMeasurementGroupsHidden,
  visibleGroupID: state.measurementGroupReducer.visibleGroupID,
});

const mapDispatchToProps = dispatch => ({
  fetchGroups: (filters, callback, filtersCallback, errorCallback, loadingCallback) => dispatch(fetchGroups(filters, callback, filtersCallback, errorCallback, loadingCallback)),
  selectMeasurementGroup: data => dispatch(setMeasurementGroup(data)),
  setMeasurementGroups: data => dispatch(setMeasurementGroups(data)),
  deselectAll: () => dispatch(deselectAllMeasurementGroups()),
  selectAll: () => dispatch(selectAllMeasurementGroups()),
  toggleElement: id => dispatch(toggleMeasurementGroupVisibility(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MeasurementGroupsInspectionView);
