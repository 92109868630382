import dimensions from '../common/styles/dimensions/_dimensions.scss';
import darkThemeColors from '../common/styles/theme-files/_dark-theme.scss';
import lightThemeColors from '../common/styles/theme-files/_light-theme.scss';
import { advario } from './environments/advario';
import { aurubis } from './environments/aurubis';
import { collab } from './environments/collab';
import { crh } from './environments/crh';
import { dev } from './environments/dev';
import { dyckerhoff } from './environments/dyckerhoff';
import { greenergy } from './environments/greenergy';
import { houstonlbc } from './environments/houstonlbc';
import { jura } from './environments/jura';
import { holcim } from './environments/lafarge-holcim';
import { lbc } from './environments/lbc';
import { localhost } from './environments/localhost';
import { raico } from './environments/raico';
import { raicod } from './environments/raicod';
import { raicom } from './environments/raicom';
import { raicos } from './environments/raicos';
import { staging } from './environments/staging';
import { uat } from './environments/uat';

export const currentEnvironment = process.env.REACT_APP_ENV;
export const environments = {
  localhost: 'localhost',
  dev: 'development',
  greenEnergy: 'green',
  holcim: 'holcim',
  collab: 'collab',
  uat: 'uat',
  aurubis: 'aurubis',
  crh: 'crh',
  lbc: 'lbc',
  staging: 'staging',
  raico: 'raico',
  raicos: 'raicos',
  raicod: 'raicod',
  raicom: 'raicom',
  jura: 'jura',
  dyckerhoff: 'dyckerhoff',
  advario: 'advario',
  houstonlbc: 'houstonlbc',
};
const environmentsMap = {
  // in house envs
  [environments.localhost]: localhost,
  [environments.dev]: dev,
  [environments.uat]: uat,
  [environments.collab]: collab,
  [environments.staging]: staging,
  // production envs
  [environments.greenEnergy]: greenergy,
  [environments.holcim]: holcim,
  [environments.raico]: raico,
  [environments.raicos]: raicos,
  [environments.raicod]: raicod,
  [environments.raicom]: raicom,
  [environments.aurubis]: aurubis,
  [environments.crh]: crh,
  [environments.lbc]: lbc,
  [environments.jura]: jura,
  [environments.dyckerhoff]: dyckerhoff,
  [environments.advario]: advario,
  [environments.houstonlbc]: houstonlbc,
};

const envVars = environmentsMap[currentEnvironment];

export const isDevelopment = currentEnvironment === environments.localhost || currentEnvironment === environments.dev || !currentEnvironment;

let GOOGLE_API_KEY = envVars['GOOGLE_API_KEY'],
  API_BASE_URL = envVars['API_BASE_URL'],
  API_PROCESSING_BASE_URL = envVars['API_PROCESSING_BASE_URL'],
  PUBLIC_URL = envVars['PUBLIC_URL'],
  FEATURES = envVars['FEATURES'],
  HYBIRD_ID = envVars['HYBIRD_ID'],
  TERMS_LINK = envVars['TERMS_LINK'],
  PRIVACY_AND_POLICY_LINK = envVars['PRIVACY_AND_POLICY_LINK'],
  DROPBOX_CLIENT_ID = envVars['DROPBOX_CLIENT_ID'],
  DROPBOX_BASE_URL = envVars['DROPBOX_BASE_URL'],
  SYNC_API_BASE_URL = envVars['SYNC_API_BASE_URL'],
  LOGIN_SLIDER_ITEMS = envVars['LOGIN_SLIDER_ITEMS'],
  MAIN_LOGO = envVars['MAIN_LOGO'],
  FAVICON = envVars['FAVICON'],
  FOOTER_TEXT = envVars['FOOTER_TEXT'],
  FOOTER_LOGO = envVars['FOOTER_LOGO'],
  HUBSPOT_PORTAL_ID = envVars['HUBSPOT_PORTAL_ID'],
  HUBSPOT_BASE_URL = envVars['HUBSPOT_BASE_URL'],
  POTREE_STATS_VISIBLE = envVars['POTREE_STATS_VISIBLE'],
  LENGTH_UNIT = envVars['LENGTH_UNIT'],
  SHOW_GEOMETRY_MISSING_WARNING = envVars['SHOW_GEOMETRY_MISSING_WARNING'];

const FORMS = {
  createProject: 'createProject',
  login: 'login',
  defect: 'defect',
  measurementForm: 'measurement-form',
  createDefectForm: 'createDefectForm',
  dynamicDefect: 'dynamicDefect',
  componentForm: 'component-form',
  dynamicComponentForm: 'dynamic-component-form',
  inspectionSettings: 'inspection-settings',
  chartFilter: 'chart-filter',
  resetPassword: 'reset-password',
  createUser: 'create-user',
  requestResetPassword: 'request-reset-password',
  changePassword: 'change-password',
  editProject: 'edit-project',
  setPassword: 'set-password',
  externalUploadForm: 'external-upload-form',
  passwordVerification: 'password-verification',
  register: 'register',
  downloadReport: 'download-report',
  guestUserCheck: 'guest-user-check',
  componentSelection: 'component-selection',
  defectSelection: 'defect-selection',
  sapIntegrationForm: 'sap-integration-form',
  addWorkAreaForm: 'work-area-form',
  explosiveZoneForm: 'explosive-zone-form',
  emailVerification: 'email-verification',
  mfaSetup: 'mfa-setup',
  areasForm: 'areas-form',
  ndtMeasurementForm: 'ndt-measurement-form',
  ndtMeasurementPointForm: 'ndt-measurement-point-form',
  dynamicNDTMeasurementForm: 'dynamic-ndt-measurement-form',
  dynamicNDTMeasurementPointForm: 'dynamic-ndt-measurement-point-form',
  alarmsCreate: 'alarms-create',
  downloadReportXslx: 'download-report-xslx',
  emailCreate: 'email-create',
  editPdf: 'edit-pdf',
  ndtPredictiveSettings: 'ndt-predictive-settings',
  resolveForm: 'resolve-form',
  areaGroupForm: 'area-group-form',
  dowloadDefectReport: 'download-defect-report',
  checklistTemplateForm: 'checklist-template-form',
  createRegimeForm: 'create-regime-form',
  taskForm: 'task-form',
  addQuickNDTPointForm: 'add-quick-ndt-point-form',
  notificationForm: 'notification-form',
  createWorkorderForm: 'create-workorder-form',
  createTeam: 'create-team',
  createRole: 'create-role',
  addTeamMember: 'add-team-member',
  workorderForm: 'work-order-form',
  defectFilters: 'defectFilters',
  notificationFilters: 'notification-filters',
  addAreaForm: 'add-area-form',
  addAreaGroupForm: 'add-area-group-form',
  componentsFilter: 'componentsFilter',
  measurementsFilters: 'measurements-filters',
  documentsFilter: 'documents-filter',
  workordersFilter: 'workordersFilter',
  regimeWorkordersFilter: 'regimeWorkordersFilter',
  ndtMeasurementsFilter: 'ndt-measurements-filter',
  areasFilter: 'areas-filter',
  ndtNotificationsCreate: 'ndt-notifications-create',
  checklistsProceduresFirstStep: 'checklists-procedures-first-step',
  createContractorForm: 'create-contractor-form',
  sectionsForm: 'sections-form',
  createKeybox: 'create-keybox',
  addKeyboxKeys: 'add-keybox-keys',
  isolationTemplateForm: 'isolation-template-form',
  permitTemplateForm: 'permit-template-form',
  isolationCertificateForm: 'isolation-certificate-form',
  createPermitForm: 'create-permit-form',
  workOrderCreatePermitForm: 'work-order-create-permit-form',
  changeModuleStatusForm: 'change-module-status-form',
  changeModuleStatusQuestionsForm: 'change-module-status-questions-form',
  changeModuleStatusIsolationComponentsForm: 'change-module-status-isolation-components-form',
  issuePermitForm: 'issue-permit-form',
  workOrderBasicDetailsForm: 'work-order-basic-details-form',
  workOrderCompletedForm: 'work-order-completed-form',
  manageWorkOrderAlerts: 'manage-work-order-alerts',
  completionTab: 'completion-tab',
  flowTab: 'flow-tab',
  areaMeasurementForm: 'area-measurement-form',
  cubeShapeForm: 'cube-shape-form',
  cylinderShapeForm: 'cylinder-shape-form',
  sphereShapeForm: 'sphere-shape-form',
  ringShapeForm: 'ring-shape-form',
  isolationCertificateFilters: 'isolationCertificateFilters',
  permitFilters: 'permit-filters',
  componentHierarchyLevel: 'component-hierarchy-level',
  DMSFolderHierarchyLevel: 'dms-folder-hierarchy-level',
  DMSFileHierarchyLevel: 'dms-file-hierarchy-level',
  componentHierarchyLevelCustomProp: 'component-hierarchy-level-custom-prop',
  unassignedComponentDetailsUneditable: 'unassigned-component-details-uneditable',
  confirmModalFormCH: 'confirm-modal-form-ch',
  downloadPDFOptionsForm: 'download-pdf-options-form',
  changeHierarchyDetailsSection: 'change-hierarchy-details-section',
  componentPickerCHDetailsSection: 'component-picker-ch-details',
  maintenanceRegimeBasicDetailsForm: 'maintenance-regime-basic-details-form',
  permitDetails: 'permit-details',
  unassignedComponentDetailsUneditableProp: 'unassigned-component-details-uneditable-prop',
  createNewFolderForm: 'create-new-folder-form',
  dynamicNotificationForm: 'dynamic-notification-form',
  dmsFiltersFileTypeForm: 'dms-filters-file-type-form',
  dmsChangeLocationFileDetailsForm: 'dms-change-location-file-details-form',
  workOrderCustomPropertiesForm: 'work-order-custom-properties-form',
  commentUsersAndTeamsDropdownForm: 'comment-users-and-teams-dropdown-form',
  checklistsProceduresFilters: 'checklists-procedures-filters',
  checklistProceduresTemplateCustomProperties: 'checklist-procedures-template-custom-properties',
  downloadWorkOrderPdfOptions: 'download-work-order-pdf-options',
  DMSFileVersionModalForm: 'dms-file-version-modal-form',
  createNotificationFromObservationForm: 'create-notification-from-observation-form',
  groupForm: 'group-form',
  confirmPassword: 'confirmPassword',
  measurementLocationForm: 'readings-and-gauges-measurement-location-form',
  measurementPointForm: 'readings-and-gauges-measurement-point-form',
  createMeasurementPointForm: 'readings-and-gauges-create-measurement-point-form',
  measurementPointAlarmsForm: 'readings-and-gauges-measurement-point-alarms-form',
  measurementPointMeasurementReadingForm: 'readings-and-gauges-measurement-point-measurement-reading-form',
  deleteWithModalForm: 'delete-with-modal-form',
  changeCategoryForm: 'change-category-form',
  timeSeriesGraphSettingsForm: 'time-series-graph-settings-form',
  createGraphingGroupForm: 'create-graphing-group-form',
  dateRangePickerForm: 'date-range-picker-form',
  newObservationForm: 'new-observation-form',
};

const THEMES = {
  dark: {
    name: 'dark',
    colors: {
      ...darkThemeColors,
    },
  },
  light: {
    name: 'light',
    colors: {
      ...lightThemeColors,
    },
  },
};

const DEFAULT_LANGUAGE = 'en';

let DIMENSIONS = {};

Object.keys(dimensions).forEach(key => {
  DIMENSIONS[key] = parseFloat(dimensions[key].split('px')[0]);
});

const EMAIL_REGEX = /^[a-zA-Z0-9._%\-+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const PASSWORD_REGEX = /^\S{8,20}$/;
const SPECIAL_CHAR_REGEX = /[^a-zA-Z0-9 -_.]/;
const POSITIVE_INTEGER = /^[1-9]\d*$/;
const PHONE_REGEX = /[^0-9 -/()+]/;

const PERSISTOR_SECRET_KEY = 'eWJOlLODu5ONV0D04ZAnBgCi8fjvZLuU';

const GENERIC_QUERY_PARAMS = {
  forceLogin: 'forceLogin',
};

const SUPPORTED_IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif', 'tiff', 'jfif'];
const PDF_EXTENSIONS = ['pdf'];

const AMAZON_IMAGE_SIZES = {
  small: {
    name: 'small',
    prefix: 'thumbnail_',
  },
  medium: {
    name: 'medium',
    prefix: 'document_',
  },
  large: {
    name: 'large',
    prefix: '',
  },
};

const DISABLED_FEATURES = {
  workflow: 'Workflow',
  prdReport: 'PRD-Report',
};

const SLIDER_ITEM_ZOOM_SPEED = {
  fullscrren: 75,
  normal: 2,
};

const LANDING_TYPE = {
  dropbox: 'dropbox',
};

const MAX_DESCRIPTION_CHAR_SHOW = 81;

const MIN_SAFE_DECIMAL = -99999999.9999999999;
const MAX_SAFE_DECIMAL = 99999999.9999999999;
const MIN_SAFE_DECIMAL_STRING = '-99999999.9999999999';
const MAX_SAFE_DECIMAL_STRING = '99999999.9999999999';

const MAX_TRUNCATE_LENGTH_PLACEHOLDER = 45;

const POLLING_INTERVAL = 5000; // 5 seconds

export {
  AMAZON_IMAGE_SIZES,
  API_BASE_URL,
  API_PROCESSING_BASE_URL,
  DEFAULT_LANGUAGE,
  DIMENSIONS,
  DISABLED_FEATURES,
  DROPBOX_BASE_URL,
  DROPBOX_CLIENT_ID,
  EMAIL_REGEX,
  FAVICON,
  FEATURES,
  FOOTER_LOGO,
  FOOTER_TEXT,
  FORMS,
  GENERIC_QUERY_PARAMS,
  GOOGLE_API_KEY,
  HUBSPOT_BASE_URL,
  HUBSPOT_PORTAL_ID,
  HYBIRD_ID,
  LANDING_TYPE,
  LENGTH_UNIT,
  LOGIN_SLIDER_ITEMS,
  MAIN_LOGO,
  MAX_DESCRIPTION_CHAR_SHOW,
  MAX_SAFE_DECIMAL,
  MAX_SAFE_DECIMAL_STRING,
  MAX_TRUNCATE_LENGTH_PLACEHOLDER,
  MIN_SAFE_DECIMAL,
  MIN_SAFE_DECIMAL_STRING,
  PASSWORD_REGEX,
  PDF_EXTENSIONS,
  PERSISTOR_SECRET_KEY,
  PHONE_REGEX,
  POLLING_INTERVAL,
  POSITIVE_INTEGER,
  POTREE_STATS_VISIBLE,
  PRIVACY_AND_POLICY_LINK,
  PUBLIC_URL,
  SHOW_GEOMETRY_MISSING_WARNING,
  SLIDER_ITEM_ZOOM_SPEED,
  SPECIAL_CHAR_REGEX,
  SUPPORTED_IMAGE_EXTENSIONS,
  SYNC_API_BASE_URL,
  TERMS_LINK,
  THEMES,
};
