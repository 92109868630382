export const measurementReadingFormConstants = {
  fields: {
    values: 'Values',
    date: 'Date',
    measurementPointID: 'MeasurementPointID',
    measurementLocationID: 'MeasurementLocationID',
    measurementReadingID: 'MeasurementReadingID',
  },
};

export const previewConstants = [
  { id: 'max', label: 'MAX' },
  { id: 'min', label: 'MIN' },
  { id: 'avg', label: 'AVERAGE' },
  { id: 'median', label: 'MEDIAN' },
];

export const measurementReadingFormSteps = {
  firstStep: 1,
  secondStep: 2,
};

export const measurementReadingFormStepperData = [
  {
    name: 'MEASUREMENT_READING.ADD_MEASUREMENT_READINGS',
    stepValue: measurementReadingFormSteps.firstStep,
  },
  {
    name: 'MEASUREMENT_READING.ADD_FILES',
    stepValue: measurementReadingFormSteps.secondStep,
    description: 'FIELD.OPTIONAL',
  },
];
