import { find, get, values } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { DIMENSIONS } from '../../../../common/constants';
import Modal from '../../../../common/modal/components/modal';
import { toggleToolbar } from '../../../inspections/actions/inspection-actions';
import CollapsibleToolbar from '../../../inspections/components/collapsible-toolbar';
import InspectionWrapper from '../../../inspections/components/inspection-wrapper';
import LeftToolbar from '../../../inspections/components/left-toolbar/left-toolbar';
import RightToolbar from '../../../inspections/components/right-toolbar/right-toolbar';
import { detailsPages, modules, modulesFullScreen, placements } from '../../../inspections/constants/constants';
import '../../styles/stream-inspection-screen.scss';

const StreamInspectionScreen = (props, { t }) => {
  const {
    viewer,
    selectTool,
    currentInsertion,
    setCurrentInsertion,
    createNewDefect,
    getCameraPosition,
    handleObjectToolClick,
    getDefectType,
    showObjectToolbox,
    scrollToElement,
    router,
    route,
    location,
    inspectionReducer: { leftCollapsed, rightCollapsed, activeDetailsPage },
    uploadInProgress,
    toggleToolbar,
  } = props;

  const { query } = location;

  const selectedType = !query.type || !find(modules, item => item === query.type) ? modules.details : query.type;
  const view = query.view;

  return (
    <InspectionWrapper {...props}>
      {({
        handleActivePage,
        openDownloadReportModal,
        openDownloadReportModalForMeasurments,
        openDownloadReportModalSingleDefect,
        openAddQuickNDTPointModal,
        openInspectionAssetModal,
        openDocumentsUploadModal,
        switchToFullScreenMode,
        setModalState,
        handleInspectionClick,
        modalData,
      }) => (
        <div className="stream-inspection-screen">
          <CollapsibleToolbar collapsed={leftCollapsed} toggleToolbar={toggleToolbar} placement={placements.left}>
            <LeftToolbar
              // Routing
              inspectionId={parseInt(query.inspection_id)}
              projectId={parseInt(query.project_id)}
              queryItem={!isNaN(query.selected_item) ? parseInt(query.selected_item) : null}
              handleActivePage={handleActivePage}
              activeItem={selectedType}
              // End Routing
              handleObjectToolClick={handleObjectToolClick}
              openDownloadReportModal={openDownloadReportModal}
              openDownloadReportModalForMeasurments={openDownloadReportModalForMeasurments}
              openAddQuickNDTPointModal={openAddQuickNDTPointModal}
              getCameraPosition={getCameraPosition}
              viewer={viewer}
              openInspectionAssetModal={openInspectionAssetModal}
              openDocumentsUploadModal={openDocumentsUploadModal}
              setModalState={setModalState}
              scrollToElement={scrollToElement}
              handleInspectionClick={handleInspectionClick}
              sharedModuleActionsMenu={
                find(values(modulesFullScreen), item => item === selectedType)
                  ? [
                      {
                        title: 'INSPECTION.SWITCH_TO_FULLSCREEN',
                        action: switchToFullScreenMode,
                      },
                    ]
                  : []
              }
              view={view}
            />
          </CollapsibleToolbar>
          <CollapsibleToolbar
            collapsed={rightCollapsed || !activeDetailsPage}
            toggleToolbar={toggleToolbar}
            placement={placements.right}
            resizable={activeDetailsPage === detailsPages.inspect}
            defaultWidth={DIMENSIONS.inspectionRightToolbar}
          >
            <RightToolbar
              // Routing
              inspectionId={parseInt(query.inspection_id)}
              projectId={parseInt(query.project_id)}
              handleActivePage={handleActivePage}
              queryItem={!isNaN(query.selected_item) ? parseInt(query.selected_item) : null}
              router={router}
              route={route}
              // Remove reducer saving delay
              activeDetailsPage={!get(query, 'details') && selectedType !== modules.details ? query.type : get(query, 'details')}
              // End Routing
              getCameraPosition={getCameraPosition}
              viewer={viewer}
              openInspectionAssetModal={openInspectionAssetModal}
              selectTool={selectTool}
              currentInsertion={currentInsertion}
              setCurrentInsertion={setCurrentInsertion}
              createNewDefect={createNewDefect}
              handleObjectToolClick={handleObjectToolClick}
              getDefectType={getDefectType}
              showObjectToolbox={showObjectToolbox}
              openDownloadReportModalSingleDefect={openDownloadReportModalSingleDefect}
              view={view}
            />
          </CollapsibleToolbar>
          <Modal {...modalData} modalDisabled={uploadInProgress} />
        </div>
      )}
    </InspectionWrapper>
  );
};

const mapStateToProps = state => {
  return {
    inspectionReducer: state.inspectionReducer,
    uploadInProgress: state.uploadReducer.uploadInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleToolbar: (placement, implicitState) => dispatch(toggleToolbar(placement, implicitState)),
});

StreamInspectionScreen.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(StreamInspectionScreen);
