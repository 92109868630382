import axios from 'axios';
import { isEmpty, pick } from 'lodash';
import { updateSyncErrors } from 'redux-form';
import readingsAndGaugesAPI from '../../../../../api/readings-and-gauges/actions';
import { FORMS } from '../../../../../common/constants';
import Helpers from '../../../../../common/helpers';
import { dispatchErrorModalWired } from '../../../../../common/modal/actions/modal-actions';
import { setGenericNotification } from '../../../../../common/notification/actions/action-creators';
import { measurementTypes } from '../../../constants/constants';
import { formConstants as inspectionSettingsConstants } from '../../../constants/inspection-settings';
import { deleteWithModalFields } from '../constants/constants';
import { filterParams, formConstants } from '../constants/measurement-location-constants';
import { TIME_SERIES_AGGREGATION_ORDER_MAP } from '../constants/time-series-graph-constants';
import {
  amendMeasurementLocations,
  setMeasurementLocations,
  setMeasurementLocationsClustered,
  setMeasurementLocationsFilters,
  setMeasurementLocationsLoading,
  updateMeasurementLocationInArray,
} from './action-creators';

let cancelTokens = {
  measurementLocationsClustered: undefined,
};

export const fetchMeasurementLocations = (filters, loadMore, successCallback, errorCallback) => {
  return async dispatch => {
    try {
      dispatch(setMeasurementLocationsLoading(true));
      const res = await readingsAndGaugesAPI.fetchMeasurementLocations(filters);
      const { Items, ...newFilters } = res.data.Data;

      if (loadMore) {
        dispatch(amendMeasurementLocations(Items || []));
      } else {
        dispatch(setMeasurementLocations(Items || []));
      }
      dispatch(setMeasurementLocationsFilters({ ...filters, ...newFilters }));
      dispatch(setMeasurementLocationsLoading(false));
      successCallback && typeof successCallback === 'function' && successCallback({ data: Items, filters: { ...filters, ...newFilters } });
    } catch (e) {
      console.error(e);
      dispatch(setMeasurementLocationsLoading(false));
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const createMeasurementLocation = (Colour, Name, ProjectID, MeasurementGroupID, filters, successCallback) => {
  return async dispatch => {
    try {
      const res = await readingsAndGaugesAPI.createMeasurementLocation({ Colour, Name, ProjectID, MeasurementGroupID });
      const { Data } = res.data;

      dispatch(updateMeasurementLocationInArray(Data, 'add'));
      dispatch(setMeasurementLocationsFilters({ ...filters, [filterParams.totalItems]: filters[filterParams.totalItems] + 1 }));
      successCallback && typeof successCallback === 'function' && successCallback(Data);
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateMeasurementLocation = (data, callback) => {
  if (isEmpty(data)) return;
  return async dispatch => {
    try {
      await readingsAndGaugesAPI.updateMeasurementLocationDetails(data);

      dispatch(updateMeasurementLocationInArray(data, 'update'));

      callback && callback();

      return data;
    } catch (e) {
      const customError = Helpers.getErrorContent(e);
      if (customError) {
        dispatch(
          updateSyncErrors(FORMS.measurementLocationForm, {
            Code: { string: customError },
          })
        );
      } else {
        dispatchErrorModalWired(true, e);
      }
    }
  };
};

export const deleteMeasurementLocation = (data, ConfirmationCheck, context, successCallback, errorCallback) => {
  return async dispatch => {
    try {
      const { t } = context;
      await readingsAndGaugesAPI.deleteMeasurementLocation({ [formConstants.fields.id]: data[formConstants.fields.id], ConfirmationCheck });
      dispatch(updateMeasurementLocationInArray({ [formConstants.fields.id]: data[formConstants.fields.id] }, 'delete'));
      // set generic notification
      dispatch(
        setGenericNotification({
          isDisplayed: true,
          type: 'error',
          icon: 'trash',
          text: t('MEASUREMENT_LOCATION.NOTIFICATIONS.DELETE_SUCCESS', { mlName: data[formConstants.fields.name] }),
        })
      );

      successCallback && typeof successCallback === 'function' && successCallback();
    } catch (e) {
      const customError = Helpers.getErrorContent(e);
      if (customError) {
        dispatch(
          updateSyncErrors(FORMS.deleteWithModalForm, {
            [deleteWithModalFields.field.name]: customError,
          })
        );
      } else {
        console.error(e);
        dispatchErrorModalWired(true, e);
      }

      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const getMeasurementLocationsClustered = (inspection_id, activeLeftSidebar, searchText = '', optionalParams = {}, callback, MeasurementLocationID) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionSettings },
    } = getState();
    const clusterConfiguration = pick(inspectionSettings, [
      inspectionSettingsConstants.fields.numberOfRings,
      inspectionSettingsConstants.fields.numberOfSlices,
      inspectionSettingsConstants.fields.initialDistance,
      inspectionSettingsConstants.fields.multiplier,
    ]);
    try {
      // Check if there are any previous pending requests
      if (typeof cancelTokens.measurementLocationsClustered != typeof undefined) {
        cancelTokens.measurementLocationsClustered.cancel('Operation canceled due to new request.');
      }

      // Save the cancel token for the current request
      cancelTokens.measurementLocationsClustered = axios.CancelToken.source();

      let res = null;
      if (MeasurementLocationID) {
        if (optionalParams?.HiddenItems && optionalParams.HiddenItems.indexOf(MeasurementLocationID) > -1) {
          // Measurement Location is hidden so we don't need to call the endpoint
          res = { data: { Data: { Items: [] } } };
        } else {
          res = await readingsAndGaugesAPI.getMeasurementPointsClustered(
            {
              InspectionID: parseInt(inspection_id),
              SearchText: searchText,
              SystemType: measurementTypes.rgMeasurementPoint,
              MeasurementLocationID,
              ...clusterConfiguration,
              ...optionalParams,
            },
            { cancelToken: cancelTokens.measurementLocationsClustered.token }
          );
        }
      } else {
        res = await readingsAndGaugesAPI.getMeasurementLocationsClustered(
          {
            InspectionID: parseInt(inspection_id),
            SearchText: searchText,
            SystemType: measurementTypes.rgMeasurementLocation,
            ...clusterConfiguration,
            ...optionalParams,
          },
          { cancelToken: cancelTokens.measurementLocationsClustered.token }
        );
      }
      const { Data } = res.data;

      dispatch(
        setMeasurementLocationsClustered(
          (Data.Items || []).map((el, index) => ({
            ...el,
            ID: el.ID > 0 ? el.ID : `CLUSTER_${Date.now()}_${index}`,
          }))
        )
      );
      callback &&
        callback(
          (Data.Items || []).map((el, index) => ({
            ...el,
            ID: el.ID > 0 ? el.ID : `CLUSTER_${Date.now()}_${index}`,
          }))
        );
    } catch (e) {
      console.error(e);
    }
  };
};

export const getSelectedMeasurementLocationClustered = (inspection_id, activeLeftSidebar, searchText = '', optionalParams = {}, callback) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionSettings },
    } = getState();
    const clusterConfiguration = pick(inspectionSettings, [
      inspectionSettingsConstants.fields.numberOfRings,
      inspectionSettingsConstants.fields.numberOfSlices,
      inspectionSettingsConstants.fields.initialDistance,
      inspectionSettingsConstants.fields.multiplier,
    ]);
    try {
      if (!optionalParams.SelectedItemID || optionalParams.SelectedItemID <= 0) {
        return;
      }
      // Check if there are any previous pending requests
      if (typeof cancelTokens.measurementLocationsClustered != typeof undefined) {
        cancelTokens.measurementLocationsClustered.cancel('Operation canceled due to new request.');
      }

      // Save the cancel token for the current request
      cancelTokens.measurementLocationsClustered = axios.CancelToken.source();

      const res = await readingsAndGaugesAPI.getMeasurementLocationsClustered(
        {
          InspectionID: parseInt(inspection_id),
          SearchText: searchText,
          SystemType: measurementTypes.rgMeasurementLocation,
          ...clusterConfiguration,
          ...optionalParams,
        },
        { cancelToken: cancelTokens.measurementLocationsClustered.token }
      );
      const { Data } = res.data;
      const allClusters = (Data.Items || []).map((el, index) => ({
        ...el,
        ID: el.ID > 0 ? el.ID : `CLUSTER_${Date.now()}_${index}`,
      }));
      const filteredClusters = [allClusters.find(el => el.ID === optionalParams.SelectedItemID)];

      dispatch(setMeasurementLocationsClustered(filteredClusters));
      callback && callback(filteredClusters);
    } catch (e) {
      console.error(e);
    }
  };
};

export const linkMeasurementLocationComponent = (ComponentID, MeasurementLocationID, successCallback, errorCallback) => {
  return async () => {
    try {
      await readingsAndGaugesAPI.linkMeasurementLocationComponent({ ComponentID, MeasurementLocationID });

      successCallback && typeof successCallback === 'function' && successCallback();
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const changeMeasurementGroup = (MeasurementGroupID, MeasurementLocationID, additionalData, successCallback, errorCallback) => {
  return async () => {
    try {
      await readingsAndGaugesAPI.changeMeasurementGroup({ MeasurementGroupID, MeasurementLocationID, ...(additionalData || {}) });

      successCallback && typeof successCallback === 'function' && successCallback();
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const fetchMeasurementLocationGraphingGroups = async MeasurementLocationID => {
  try {
    const res = await readingsAndGaugesAPI.getGraphingGroupList({ MeasurementLocationID });
    const { Data } = res.data;

    return Data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getMeasurementLocationGraphingGroupsList = (MeasurementLocationID, successCallback, errorCallback) => {
  return async () => {
    try {
      const { DefaultGraphs: graphingGroups } = await fetchMeasurementLocationGraphingGroups(MeasurementLocationID);

      successCallback && typeof successCallback === 'function' && successCallback(graphingGroups);
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const getTimeSeriesAggregationTypes = () => {
  return async () => {
    try {
      const res = await readingsAndGaugesAPI.getAggregationTypes();
      const { Data } = res.data;
      if (!Data || isEmpty(Data)) return;
      const aggregationTypes = Data;

      // ordering done on the FE side, due to BE not returning the data in the correct order
      const aggregationList = (aggregationTypes || []).sort((a, b) => TIME_SERIES_AGGREGATION_ORDER_MAP[a.Key] - TIME_SERIES_AGGREGATION_ORDER_MAP[b.Key]);

      return aggregationList;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
};

export const getTimeSeriesChunks = () => {
  return async () => {
    try {
      const res = await readingsAndGaugesAPI.getChunks();
      const { Data } = res.data;

      return Data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
};

export const getDefaultTimeSeriesSettings = (measurementLocationId, successCallback, errorCallback) => {
  return async dispatch => {
    try {
      let graphingGroups = [];
      if (measurementLocationId) {
        const { DefaultGraphs } = await fetchMeasurementLocationGraphingGroups(measurementLocationId);
        graphingGroups = DefaultGraphs;
      }

      const aggregationList = await dispatch(getTimeSeriesAggregationTypes());
      const chunks = await dispatch(getTimeSeriesChunks());

      successCallback && typeof successCallback === 'function' && successCallback({ graphingGroups: graphingGroups || [], aggregationList: aggregationList || [], chunks: chunks || [] });
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const getGraphingGroupDetails = (measurementLocationId, graphingGroupId, successCallback, errorCallback) => {
  return async dispatch => {
    try {
      const res = await readingsAndGaugesAPI.getGraphingGroupDetails({ MeasurementLocationID: measurementLocationId, ID: graphingGroupId });
      const { Data } = res.data;

      successCallback && typeof successCallback === 'function' && successCallback(Data.DefaultGraphDetails);
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const getMeasurementLocationTimeSeriesGraph = (measurementLocationId, graphSettings, successCallback, errorCallback) => {
  return async dispatch => {
    try {
      const res = await readingsAndGaugesAPI.getMeasurementLocationTimeSeriesGraph({ MeasurementLocationID: measurementLocationId, ...graphSettings });
      const { Data } = res.data;

      successCallback && typeof successCallback === 'function' && successCallback(Data);
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const saveGraphingGroup = (data, successCallback, errorCallback) => {
  return async dispatch => {
    try {
      const res = await readingsAndGaugesAPI.saveGraphingGroup(data);
      const { Data } = res.data;

      successCallback && typeof successCallback === 'function' && successCallback(Data);
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const deleteGraphingGroup = (ID, MeasurementLocationID, successCallback, errorCallback) => {
  return async dispatch => {
    try {
      await readingsAndGaugesAPI.deleteGraphingGroup({ ID, MeasurementLocationID });

      successCallback && typeof successCallback === 'function' && successCallback();
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const saveGraphingGroupAsDefault = (GraphID, MeasurementLocationID, successCallback, errorCallback) => {
  return async dispatch => {
    try {
      await readingsAndGaugesAPI.saveAsDefaultGraphingGroup({ GraphID, MeasurementLocationID });
      successCallback && typeof successCallback === 'function' && successCallback();
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

// Checklist and Procedure template actions
export const fetchChecklistProceduresMeasurementLocations = (filters, dataCallback, loadingCallback, errorCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await readingsAndGaugesAPI.fetchChecklistProcedureMeasurementLocations(filters);
      const { Data } = res?.data;
      const { Items, ...rest } = Data;
      dataCallback && typeof dataCallback === 'function' && dataCallback(Items, { ...filters, ...rest });
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const linkMeasurementLocationToChecklistProcedure = (params, mpFilters, dataCallback, loadingCallback, errorCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await readingsAndGaugesAPI.linkMeasurementLocationToChecklistProcedure(params);
      const { Data } = res?.data;
      // if link was successful proceed to fetching the MPs from the selected ML
      if (Data === 'LINKED') {
        // TODO: this must be improved, cannot rely on the string literal i.e Data === 'LINKED' (maybe use if 200)
        const { MeasurementLocationID } = params;
        const res = await readingsAndGaugesAPI.fetchChecklistProcedureMeasurementPoints({ ...mpFilters, MeasurementLocationID: MeasurementLocationID });
        const { Data } = res?.data;
        const { Items, ...rest } = Data;
        // updates the right-hand side (e.g. the Added Measurement Points list)
        dataCallback && typeof dataCallback === 'function' && dataCallback(Items, { ...rest });
      }
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const unlinkMeasurementLocationFromChecklistProcedure = (params, mpFilters, dataCallback, loadingCallback, errorCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await readingsAndGaugesAPI.unlinkMeasurementLocationFromChecklistProcedure(params);
      const { Data } = res?.data;
      // if unlink was successful proceed to fetching the MPs from the selected ML
      if (Data.Status === 'DELETED') {
        // TODO: this must be improved, cannot rely on the string literal i.e Data === 'LINKED' (maybe use if 200)
        const { MeasurementLocationID } = params;
        const res = await readingsAndGaugesAPI.fetchChecklistProcedureMeasurementPoints({ ...mpFilters, MeasurementLocationID: MeasurementLocationID });
        const { Data } = res?.data;
        const { Items } = Data;
        // updates the right-hand side (or the Added Measurement Points list)
        dataCallback && typeof dataCallback === 'function' && dataCallback(Items);
      }
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};
