import { values } from 'lodash';
import React from 'react';

import ModuleSections from '../../../inspections/components/right-toolbar/common/module-sections';
import { sections } from '../../constants/maintenance-regime-sections-constants';
import { formConstants, statuses } from '../../constants/regime-constants';

const MaintenanceRegimeSections = ({ moduleDetails, handleSectionActionClicked, user, debounceValues }) => (
  <ModuleSections
    module={moduleDetails}
    sections={values(sections)}
    handleSectionActionClicked={handleSectionActionClicked}
    handleCustomAction={handleSectionActionClicked}
    statuses={statuses}
    user={user}
    createdById={moduleDetails[formConstants.fields.createdByUser]}
    skipOrdering
    debounceValues={debounceValues}
  />
);

export default MaintenanceRegimeSections;
