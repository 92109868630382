import PropTypes from 'prop-types';
import PageNavigation from '../../common/page-navigation/components/page-navigation';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Card from '../../common/card/components/card';

import { FEATURES } from '../../common/constants';
import Helpers from '../../common/helpers';
import routesConstants from '../../common/routes-constants';
import './styles/augmented-reality.scss';

const AugmentedReality = (props, { t }) => {
  const { location } = props;
  const { search } = location;

  const backButtonPath = `${routesConstants.routes.protectedRoutes.inspections.fullPath}${search}`;

  const augmentedRealityTools = [
    {
      title: 'AUGMENTED_REALITY.TOOLS.LIVE_STREAM.TITLE',
      description: 'AUGMENTED_REALITY.TOOLS.LIVE_STREAM.PARAGRAPH',
      iconName: 'live-streams',
      path: routesConstants.routes.protectedRoutes.liveStreams.fullPath,
      disabled: !FEATURES.augmentedReality.liveStreams?.visible,
      soon: !FEATURES.augmentedReality.liveStreams?.visible,
    },
    {
      title: 'AUGMENTED_REALITY.TOOLS.STREAM_HISTORY.TITLE',
      description: 'AUGMENTED_REALITY.TOOLS.STREAM_HISTORY.PARAGRAPH',
      iconName: 'stream-history',
      path: routesConstants.routes.protectedRoutes.streamHistory.fullPath,
      disabled: !FEATURES.augmentedReality.streamHistory.visible,
      soon: !FEATURES.augmentedReality.streamHistory.visible,
    },
    {
      title: 'AUGMENTED_REALITY.TOOLS.POST_VIDEO_RELOCALISATION.TITLE',
      description: 'AUGMENTED_REALITY.TOOLS.POST_VIDEO_RELOCALISATION.PARAGRAPH',
      iconName: 'post-video-relocalisation',
      path: routesConstants.routes.protectedRoutes.postVideoRelocalisation.fullPath,
      disabled: !FEATURES.augmentedReality.postVideoRelocalisation.visible,
      soon: !FEATURES.augmentedReality.postVideoRelocalisation.visible,
    },
  ];

  return (
    <div className="augmented-reality">
      <PageNavigation title="TOOL_TYPE.AUGMENTED_REALITY" icon="map" backButtonPath={backButtonPath} withBottomBorder />
      <div className="augmented-reality__tools-wrapper">
        <h5>{t('AUGMENTED_REALITY.TOOLS.TITLE')}</h5>
        <div className="augmented-reality__tools-wrapper__content">
          {augmentedRealityTools?.map(({ title, description, path, disabled, soon, iconName }) => (
            <Card title={title} onClick={() => Helpers.goTo(`${path}${search}`)} description={description} disabled={disabled} buttonText={'CHOOSE'} soon={soon} iconName={iconName} />
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.userReducer,
});

AugmentedReality.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, null)(AugmentedReality));
