import { cloneDeep, find, isBoolean, orderBy } from 'lodash';
import ReducerHelpers from '../../../common/reducer-helpers';
import { participantTypes } from '../../../common/user-team/constants/constants';
import { fields } from '../components/observations/constants/constants';
import actionTypes from '../constants/action-types';
import { formConstants as componentFormConstants, defaultFilter } from '../constants/component-constants';
import { detailsPages, imagesCameraPosition, modules } from '../constants/constants';
import { assigneeFields } from '../constants/defect-constants';
import { InitialInspectionSettings } from '../constants/inspection';
import { formConstants } from '../constants/inspection-settings';

const inspectionState = {
  inspectionDetails: null,
  inspections: [],
  selectedDefect: null,
  defectComments: [],
  defectProperties: [],
  componentProperties: [],
  activeObjectTool: null,
  leftCollapsed: false,
  topCollapsed: true,
  rightCollapsed: false,
  activeDetailsPage: null,
  activeLeftSidebar: modules.details,
  defects: [],
  defectsLoading: false,
  defectDetailsLoading: false,
  defectsTemp: [],
  allDefectsVisible: true,
  measurements: [],
  measurementsLoading: false,
  measurementsTemp: [],
  components: [],
  componentsLoading: false,
  componentsClustered: [],
  componentDetailsLoading: false,
  measurementsDetailsLoading: false,
  observationsClustered: [],
  measurementsClustered: [],
  componentFilter: null,
  componentDetails: null,
  componentsTemp: [],
  inspectionID: null,
  projectID: null,
  delDefectModalData: {
    isOpen: false,
  },
  inspectionModalData: {
    isOpen: false,
    isMinimized: false,
  },
  delShareLinkModalData: {
    isOpen: false,
  },
  shareLinks: [],
  inspectionSettings: InitialInspectionSettings,
  defectPropertySuggestions: [],
  componentPropertySuggestions: [],
  assetSuggestions: [],
  componentTypeSuggestions: [],
  componentSubTypeSuggestions: [],
  materialSuggestions: [],
  manufacturerSuggestions: [],
  locationSuggestions: [],
  defectTypeSuggestions: [],
  fetchingReportData: false,
  inspectPoint: null,
  isSavingComponent: false,
  savingComponentError: null,
  componentsVisible: false,
  componentRelatedDefects: [],
  componentRelatedDefectsLoading: false,
  searchText: '',
  realCameraType: imagesCameraPosition.uav,
  observationTypes: [],
  observationNotifications: [],
  observationNotificationsLoading: false,
  chShow: true,
  chHierarchyID: null,
  images360Ref: null,
  showCHSublevels: true,
  chLevels: [],
  chHideAll: false,
  componentsFilter: defaultFilter,
  panoramicImages: [],
  hideUnassignedComponents: false,
  displayUnassignedComponentsLevel: false,
  is3DViewModeActive: true,
  componentLocationEditing: false,
  componentLocationAdding: false,
  defectLocationEditing: false,
  defectLocationAdding: false,
  notificationLocationEditing: false,
  notificationLocationAdding: false,
  observationSubTypesSuggestions: [],
  observationContributors: {
    Assignees: [],
    Collaborators: [],
  },
  observationFormState: {
    hasUnsavedChanges: false,
    hasUnsavedCustomProps: false,
    unsavedCustomProps: [],
    requestInProgress: false,
  },
  createNotificationFormSubmitting: false,
  observationWorkOrders: [],
  componentFormState: {
    hasUnsavedChanges: false,
    hasUnsavedCustomProps: false,
    unsavedCustomProps: [],
    requestInProgress: false,
  },
  inspectionDetailsIsLoading: false,
};

export const inspectionReducer = (state = inspectionState, payload) => {
  switch (payload.type) {
    case actionTypes.SET_INSPECTION_DETAILS:
      const inspectionSettings = Object.keys(payload.data)
        .filter(key => Object.keys(formConstants.fields).findIndex(fieldKey => formConstants.fields[fieldKey] === key) > -1)
        .reduce((obj, key) => {
          obj[key] = payload.data[key];
          return obj;
        }, {});
      return {
        ...state,
        inspectionSettings: { ...state.inspectionSettings, ...inspectionSettings },
        inspectionDetails: payload.data,
      };
    case actionTypes.SET_INSPECTION_DETAILS_IS_LOADING:
      return {
        ...state,
        inspectionDetailsIsLoading: payload.data,
      };
    case actionTypes.UPDATE_INSPECTION_DETAILS:
      return {
        ...state,
        inspectionDetails: { ...state.inspectionDetails, ...payload.data },
      };
    case actionTypes.SET_SEARCH_TEXT_LEFT_TOOLBAR:
      return {
        ...state,
        searchText: payload.data,
      };
    case actionTypes.SELECT_ALL_COMPONENTS:
      return {
        ...state,
        components: state.components.map(component => {
          if (payload.data) {
            if (component[componentFormConstants.fields.componentType] === payload.data) {
              return { ...component, visible: true };
            }
            return component;
          }
          return { ...component, visible: true };
        }),
        chLevels: state.chLevels?.map(level => ({ ...level, visible: true })),
        chHideAll: false,
      };
    case actionTypes.DESELECT_ALL_COMPONENTS:
      return {
        ...state,
        components: state.components.map(component => {
          if (payload.data) {
            if (component[componentFormConstants.fields.componentType] === payload.data) {
              return { ...component, visible: false };
            }
            return component;
          }
          return { ...component, visible: false };
        }),
        chLevels: state.chLevels?.map(level => ({ ...level, visible: false })),
        chHideAll: true,
      };
    case actionTypes.TOGGLE_COMPONENT:
      const componentToToggle = find(state.components, { ID: payload.data });
      return {
        ...state,
        chHideAll: state.chHideAll && !componentToToggle.visible ? false : state.chHideAll,
        components: state.components.map(component => {
          return { ...component, visible: component.ID === payload.data ? !component.visible : component.visible };
        }),
      };
    case actionTypes.SELECT_ALL_COMPONENTS_TEMP:
      return {
        ...state,
        componentsTemp: state.componentsTemp.map(component => {
          if (payload.data) {
            if (component[componentFormConstants.fields.componentType] === payload.data) {
              return { ...component, visible: true };
            }
            return component;
          }
          return { ...component, visible: true };
        }),
      };
    case actionTypes.DESELECT_ALL_COMPONENTS_TEMP:
      return {
        ...state,
        componentsTemp: state.componentsTemp.map(component => {
          if (payload.data) {
            if (component[componentFormConstants.fields.componentType] === payload.data) {
              return { ...component, visible: false };
            }
            return component;
          }
          return { ...component, visible: false };
        }),
      };
    case actionTypes.TOGGLE_COMPONENT_TEMP:
      return {
        ...state,
        componentsTemp: state.componentsTemp.map(component => {
          return { ...component, visible: component.ID === payload.data ? !component.visible : component.visible };
        }),
      };
    case actionTypes.SET_INSPECTION_COMPONENTS:
      return {
        ...state,
        components: payload.data.map(component => ({ ...component, visible: true })),
      };
    case actionTypes.AMEND_INSPECTION_COMPONENTS:
      return {
        ...state,
        components: [...state.components, ...payload.data.map(component => ({ ...component, visible: true }))],
      };

    case actionTypes.SET_INSPECTION_COMPONENTS_LOADING:
      return {
        ...state,
        componentsLoading: payload.data,
      };
    case actionTypes.SET_INSPECTION_COMPONENTS_CLUSTERED:
      return {
        ...state,
        componentsClustered: payload.data.map(component => ({ ...component, visible: true })),
      };
    case actionTypes.SET_INSPECTION_OBSERVATION_CLUSTERED:
      return {
        ...state,
        observationsClustered: payload.data.map(observation => ({ ...observation, visible: true })),
      };
    case actionTypes.SET_INSPECTION_MEASUREMENTS_CLUSTERED:
      return {
        ...state,
        measurementsClustered: payload.data.map(measurement => ({ ...measurement, visible: true })),
      };
    case actionTypes.APPEND_COMPONENTS:
      const foundIndex = state.components.findIndex(el => el.ID === payload.data.ID);
      if (foundIndex === -1) {
        return {
          ...state,
          components: [...state.components, { ...payload.data, visible: true }],
        };
      } else {
        return {
          ...state,
          components: state.components.map((component, index) => (index === foundIndex ? { ...payload.data, visible: true } : component)),
        };
      }
    case actionTypes.APPEND_TEMP_COMPONENTS:
      return {
        ...state,
        componentsTemp: [...state.componentsTemp, { ...payload.data, visible: true }],
      };
    case actionTypes.UPDATE_COMPONENTS_SUCCESS:
      return {
        ...state,
        components: payload.data,
        componentsClustered: payload.clusters,
      };
    case actionTypes.UPDATE_TEMP_COMPONENTS_SUCCESS:
      return {
        ...state,
        componentsTemp: payload.data,
      };
    case actionTypes.APPEND_COMPONENT_TEMP_COMMENT:
      if (payload.data.defect.IsTemp) {
        return {
          ...state,
          componentsTemp: state.componentsTemp.map(component =>
            payload.data.defect.ID === component.ID ? { ...component, commentsTemp: [payload.data.commentPayload || {}, ...(component.commentsTemp || [])] } : component
          ),
        };
      } else {
        return {
          ...state,
          components: state.components.map(component =>
            payload.data.defect.ID === component.ID ? { ...component, commentsTemp: [payload.data.commentPayload || {}, ...(component.commentsTemp || [])] } : component
          ),
        };
      }
    case actionTypes.SET_INSPECTION_DEFECTS:
      return {
        ...state,
        defects: payload.data.map(defect => ({ ...defect, visible: true })),
      };
    case actionTypes.AMEND_INSPECTION_DEFECTS:
      return {
        ...state,
        defects: [...state.defects, ...payload.data.map(defect => ({ ...defect, visible: state.allDefectsVisible }))],
      };
    case actionTypes.SET_INSPECTION_DEFECTS_LOADING:
      return {
        ...state,
        defectsLoading: payload.data,
      };
    case actionTypes.SET_DEFECT_DETAILS_LOADING_LOADING:
      return {
        ...state,
        defectDetailsLoading: payload.data,
      };
    case actionTypes.SET_COMPONENT_DETAILS_LOADING:
      return {
        ...state,
        componentDetailsLoading: payload.data,
      };
    case actionTypes.SET_MEASUREMENT_DETAILS_LOADING_LOADING:
      return {
        ...state,
        measurementsDetailsLoading: payload.data,
      };

    case actionTypes.SET_INSPECTION_MEASUREMENTS:
      return {
        ...state,
        measurements: payload.data.map(defect => ({ ...defect, visible: true })),
      };
    case actionTypes.AMEND_INSPECTION_MEASUREMENTS:
      return {
        ...state,
        measurements: [...state.measurements, ...payload.data.map(defect => ({ ...defect, visible: true }))],
      };

    case actionTypes.SET_INSPECTION_MEASUREMENTS_LOADING:
      return {
        ...state,
        measurementsLoading: payload.data || false,
      };

    case actionTypes.APPEND_DEFECT:
      return {
        ...state,
        defects: [...state.defects, { ...payload.data, visible: state.allDefectsVisible }],
      };
    case actionTypes.APPEND_TEMP_DEFECT:
      return {
        ...state,
        defectsTemp: [...state.defectsTemp, { ...payload.data, visible: state.allDefectsVisible }],
      };

    case actionTypes.APPEND_MEASUREMENT:
      return {
        ...state,
        measurements: [...state.measurements, { ...payload.data, visible: true }],
      };
    case actionTypes.APPEND_TEMP_MEASUREMENT:
      return {
        ...state,
        measurementsTemp: [...state.measurementsTemp, { ...payload.data, visible: true }],
      };
    case actionTypes.APPEND_ELEMENT_COMMENT:
      return {
        ...state,
        defectComments: [payload.data, ...state.defectComments],
      };
    case actionTypes.APPEND_DEFECT_TEMP_COMMENT:
      if (payload.data.defect.IsTemp) {
        return {
          ...state,
          defectsTemp: state.defectsTemp.map(defect =>
            payload.data.defect.ID === defect.ID ? { ...defect, commentsTemp: [payload.data.commentPayload || {}, ...(defect.commentsTemp || [])] } : defect
          ),
        };
      } else {
        return {
          ...state,
          defects: state.defects.map(defect => (payload.data.defect.ID === defect.ID ? { ...defect, commentsTemp: [payload.data.commentPayload || {}, ...(defect.commentsTemp || [])] } : defect)),
        };
      }
    case actionTypes.APPEND_MEASUREMENT_TEMP_COMMENT:
      if (payload.data.defect.IsTemp) {
        return {
          ...state,
          measurementsTemp: state.measurementsTemp.map(defect =>
            payload.data.defect.ID === defect.ID ? { ...defect, commentsTemp: [payload.data.commentPayload || {}, ...(defect.commentsTemp || [])] } : defect
          ),
        };
      } else {
        return {
          ...state,
          measurements: state.measurements.map(defect =>
            payload.data.defect.ID === defect.ID ? { ...defect, commentsTemp: [payload.data.commentPayload || {}, ...(defect.commentsTemp || [])] } : defect
          ),
        };
      }
    case actionTypes.SET_PROJECT_INSPECTIONS:
      return {
        ...state,
        inspections: payload.data,
      };
    case actionTypes.SET_SELECTED_DEFECT:
      return {
        ...state,
        selectedDefect: payload.data.defect || state.selectedDefect,
        defectComments: payload.data.comments || state.defectComments,
      };
    case actionTypes.UPDATE_SELECTED_DEFECT:
      return {
        ...state,
        selectedDefect: { ...(state.selectedDefect || {}), ...payload.data },
      };
    case actionTypes.UPDATE_DEFECT_SUCCESS:
      return {
        ...state,
        defects: payload.data,
        observationsClustered: payload.clusters,
      };
    case actionTypes.UPDATE_MEASUREMENT_SUCCESS:
      return {
        ...state,
        measurements: payload.data,
        measurementsClustered: payload.clusters,
      };
    case actionTypes.UPDATE_TEMP_DEFECT_SUCCESS:
      return {
        ...state,
        defectsTemp: payload.data,
      };

    case actionTypes.UPDATE_TEMP_MEASUREMENT_SUCCESS:
      return {
        ...state,
        measurementsTemp: payload.data,
      };
    case actionTypes.SELECT_ALL_DEFECTS:
      return {
        ...state,
        defects: state.defects.map(defect => ({ ...defect, visible: true })),
        allDefectsVisible: true,
      };
    case actionTypes.SELECT_ALL_DEFECTS_TEMP:
      return {
        ...state,
        defectsTemp: state.defectsTemp.map(defect => ({ ...defect, visible: true })),
        allDefectsVisible: true,
      };
    case actionTypes.SELECT_ALL_MEASUREMENTS:
      return {
        ...state,
        measurements: state.measurements.map(defect => {
          if (payload.data.type) {
            return defect.Geometry.type === payload.data.type ? { ...defect, visible: payload.data.persistState ? defect.visible : true } : defect;
          }
          return { ...defect, visible: true };
        }),
      };
    case actionTypes.SELECT_ALL_MEASUREMENTS_TEMP:
      return {
        ...state,
        measurementsTemp: state.measurementsTemp.map(defect => (defect.Geometry.type === payload.data.type ? { ...defect, visible: payload.data.persistState ? defect.visible : true } : defect)),
      };
    case actionTypes.TOGGLE_DEFECT:
      const defectsToBeSet = state.defects.map(defect => {
        return { ...defect, visible: defect.ID === payload.data ? !defect.visible : defect.visible };
      });
      return {
        ...state,
        defects: defectsToBeSet,
        allDefectsVisible: defectsToBeSet.some(defect => defect.visible === true),
      };
    case actionTypes.TOGGLE_MEASUREMENT:
      return {
        ...state,
        measurements: state.measurements.map(defect => {
          return { ...defect, visible: defect.ID === payload.data ? !defect.visible : defect.visible };
        }),
      };
    case actionTypes.TOGGLE_DEFECT_TEMP:
      return {
        ...state,
        defectsTemp: state.defectsTemp.map(defect => {
          return { ...defect, visible: defect.ID === payload.data ? !defect.visible : defect.visible };
        }),
      };
    case actionTypes.TOGGLE_MEASUREMENT_TEMP:
      return {
        ...state,
        measurementsTemp: state.measurementsTemp.map(defect => {
          return { ...defect, visible: defect.ID === payload.data ? !defect.visible : defect.visible };
        }),
      };
    case actionTypes.DESELECT_ALL_DEFECTS:
      return {
        ...state,
        defects: state.defects.map(defect => ({ ...defect, visible: false })),
        allDefectsVisible: false,
      };
    case actionTypes.DESELECT_ALL_MEASUREMENTS:
      return {
        ...state,
        measurements: state.measurements.map(defect => {
          if (payload.data.type) {
            return defect.Geometry.type === payload.data.type ? { ...defect, visible: payload.data.persistState ? defect.visible : false } : defect;
          }
          return { ...defect, visible: false };
        }),
      };
    case actionTypes.DESELECT_ALL_DEFECTS_TEMP:
      return {
        ...state,
        defectsTemp: state.defectsTemp.map(defect => ({ ...defect, visible: false })),
      };
    case actionTypes.DESELECT_ALL_MEASUREMENTS_TEMP:
      return {
        ...state,
        measurementsTemp: state.measurementsTemp.map(defect => (defect.Geometry.type === payload.data.type ? { ...defect, visible: payload.data.persistState ? defect.visible : false } : defect)),
      };
    case actionTypes.SELECT_OBJECT_TOOL:
      return {
        ...state,
        activeObjectTool: payload.data,
      };
    case actionTypes.TOGGLE_LEFT_TOOLBAR:
      return {
        ...state,
        leftCollapsed: isBoolean(payload.data) ? payload.data : !state.leftCollapsed,
      };
    case actionTypes.TOGGLE_TOP_TOOLBAR:
      return {
        ...state,
        topCollapsed: isBoolean(payload.data) ? payload.data : !state.topCollapsed,
      };
    case actionTypes.TOGGLE_RIGHT_TOOLBAR:
      return {
        ...state,
        rightCollapsed: isBoolean(payload.data) ? payload.data : !state.rightCollapsed,
      };
    case actionTypes.SELECT_DETAILS_PAGE:
      return {
        ...state,
        activeDetailsPage: Object.keys(detailsPages).findIndex(key => detailsPages[key] === payload.data) !== -1 ? payload.data : null,
      };
    case actionTypes.SET_ACTIVE_LEFT_SIDEBAR:
      return {
        ...state,
        activeLeftSidebar: Object.keys(modules).findIndex(key => modules[key] === payload.data) !== -1 ? payload.data : modules.details,
      };
    case actionTypes.FETCH_SHARE_LINKS_SUCCESS:
      return {
        ...state,
        shareLinks: payload.data,
      };
    case actionTypes.APPEND_SHARE_LINK:
      return {
        ...state,
        shareLinks: [...state.shareLinks, payload.data],
      };
    case actionTypes.HANDLE_DELETE_DEFECT_MODAL:
      return { ...state, delDefectModalData: payload.data };
    case actionTypes.HANDLE_DELETE_SHARE_MODAL:
      return { ...state, delShareLinkModalData: payload.data };
    case actionTypes.SET_INSPECTION_ID:
      return { ...state, inspectionID: payload.data };
    case actionTypes.SET_PROJECT_ID:
      return { ...state, projectID: payload.data };
    case actionTypes.UPDATE_INSPECTION_SETTINGS:
      return { ...state, inspectionSettings: payload.data };
    case actionTypes.SET_IMAGES_360_REF:
      return { ...state, images360Ref: payload.data };
    case actionTypes.SET_DEFECT_LABEL_SUGGESTIONS:
      return { ...state, defectPropertySuggestions: payload.data };
    case actionTypes.CLEAR_DEFECT_LABEL_SUGGESTIONS:
      return { ...state, defectPropertySuggestions: [] };
    case actionTypes.SET_COMPONENT_LABEL_SUGGESTIONS:
      return { ...state, componentPropertySuggestions: payload.data };
    case actionTypes.CLEAR_COMPONENT_LABEL_SUGGESTIONS:
      return { ...state, componentPropertySuggestions: [] };
    case actionTypes.SET_PARENT_ASSET_SUGGESTIONS:
      return { ...state, assetSuggestions: payload.data };
    case actionTypes.CLEAR_PARENT_ASSET_SUGGESTIONS:
      return { ...state, assetSuggestions: [] };
    case actionTypes.SET_COMPONENT_TYPE_SUGGESTIONS:
      return { ...state, componentTypeSuggestions: payload.data };
    case actionTypes.CLEAR_COMPONENT_TYPE_SUGGESTIONS:
      return { ...state, componentTypeSuggestions: [] };
    case actionTypes.SET_COMPONENT_SUB_TYPE_SUGGESTIONS:
      return { ...state, componentSubTypeSuggestions: payload.data };
    case actionTypes.CLEAR_COMPONENT_SUB_TYPE_SUGGESTIONS:
      return { ...state, componentSubTypeSuggestions: [] };
    case actionTypes.SET_MATERIAL_SUGGESTIONS:
      return { ...state, materialSuggestions: payload.data };
    case actionTypes.CLEAR_MATERIAL_SUGGESTIONS:
      return { ...state, materialSuggestions: [] };
    case actionTypes.SET_MANUFACTURER_SUGGESTIONS:
      return { ...state, manufacturerSuggestions: payload.data };
    case actionTypes.CLEAR_MANUFACTURER_SUGGESTIONS:
      return { ...state, manufacturerSuggestions: [] };
    case actionTypes.SET_LOCATION_SUGGESTIONS:
      return { ...state, locationSuggestions: payload.data };
    case actionTypes.CLEAR_LOCATION_SUGGESTIONS:
      return { ...state, locationSuggestions: [] };
    case actionTypes.SET_DEFECT_TYPE_SUGGESTIONS:
      return { ...state, defectTypeSuggestions: payload.data };
    case actionTypes.FETCH_DEFECT_PROPERTIES_SUCCESS:
      return { ...state, defectProperties: payload.data };
    case actionTypes.FETCH_COMPONENT_PROPERTIES_SUCCESS:
      return { ...state, componentProperties: payload.data };
    case actionTypes.APPEND_DEFECT_PROPERTY:
      return {
        ...state,
        defectProperties: [
          ...(state.defectProperties || []).map(prop => {
            // find unsaved custom prop index
            const unsavedPropIndex = state.observationFormState.unsavedCustomProps?.findIndex(unsavedProp => unsavedProp.ID === prop.ID);
            // if there unsaved custom prop with the same ID update it with new value
            if (state.observationFormState.unsavedCustomProps[unsavedPropIndex]) {
              return {
                ...prop,
                Value: state.observationFormState.unsavedCustomProps[unsavedPropIndex].Value,
              };
            }
            return prop;
          }),
          payload.data,
        ],
      };
    case actionTypes.APPEND_COMPONENT_PROPERTY:
      return { ...state, componentProperties: [...state.componentProperties, payload.data] };
    case actionTypes.UPDATE_DEFECT_PROPERTY:
      const propIndex = state.defectProperties.findIndex(el => el.ID === payload.data.ID);
      if (propIndex > -1) {
        let newProperties = [...state.defectProperties];
        newProperties[propIndex] = payload.data;
        return { ...state, defectProperties: newProperties };
      }
      return { ...state };
    case actionTypes.UPDATE_COMPONENT_PROPERTY: {
      const propIndex = state.componentProperties.findIndex(el => el.ID === payload.data.ID);
      if (propIndex > -1) {
        let newProperties = [...state.componentProperties];
        newProperties[propIndex] = payload.data;
        return { ...state, componentProperties: newProperties };
      }
      return { ...state };
    }
    case actionTypes.REMOVE_DEFECT_PROPERTY:
      return { ...state, defectProperties: state.defectProperties.filter(el => el.ID !== payload.data.ID) };
    case actionTypes.REMOVE_COMPONENT_PROPERTY:
      if (typeof payload?.data?.ID === 'string') {
        const newFormComponentStateCustomPropsArray = (state.componentFormState.unsavedCustomProps || []).filter(el => el.ID !== payload.data.ID);
        return {
          ...state,
          componentProperties: (state.componentProperties || []).filter(el => el.ID !== payload.data.ID),
          componentFormState: { ...state.componentFormState, unsavedCustomProps: newFormComponentStateCustomPropsArray, hasUnsavedCustomProps: newFormComponentStateCustomPropsArray.length !== 0 },
        };
      }
      return { ...state, componentProperties: (state.componentProperties || []).filter(el => el.ID !== payload.data.ID) };
    case actionTypes.SET_COMPONENT_FILTER:
      return { ...state, componentFilter: payload.data };
    case actionTypes.SET_COMPONENT_DETAILS_DATA:
      return { ...state, componentDetails: payload.data };
    case actionTypes.SET_FETCHING_REPORT_DATA_ACTIVE:
      return { ...state, fetchingReportData: payload.data };
    case actionTypes.APPEND_TEMP_INSPECT_POINT:
      return {
        ...state,
        inspectPoint: { ...payload.data, visible: true },
      };
    case actionTypes.UPDATE_TEMP_INSPECT_POINT_SUCCESS:
      return {
        ...state,
        inspectPoint: payload.data,
      };
    case actionTypes.HANDLE_INSPECTION_MODAL:
      return { ...state, inspectionModalData: payload.data };
    case actionTypes.SAVE_COMPONENT_START:
      return { ...state, isSavingComponent: true, savingComponentError: null };
    case actionTypes.SAVE_COMPONENT_SUCCESS:
      return { ...state, isSavingComponent: false };
    case actionTypes.SAVE_COMPONENT_FAILURE:
      return { ...state, isSavingComponent: false, savingComponentError: payload.data };
    case actionTypes.HANDLE_DEFECT_RELATED_COMPONENTS_VISIBLE:
      const isVisible = isBoolean(payload.data) ? payload.data : !state.componentsVisible;
      return { ...state, componentsVisible: isVisible };
    case actionTypes.SET_COMPONENT_RELATED_DEFECTS:
      return { ...state, componentRelatedDefects: payload.data };
    case actionTypes.SET_COMPONENT_RELATED_DEFECTS_LOADING:
      return { ...state, componentRelatedDefectsLoading: payload.data };
    case actionTypes.HANDLE_REAL_CAMERA_TYPE_CHANGE:
      return { ...state, realCameraType: payload.data };
    case actionTypes.HANDLE_SET_OBSERVATION_TYPES:
      return { ...state, observationTypes: payload.data };
    case actionTypes.HANDLE_SET_OBSERVATION_NOTIFICATIONS:
      return { ...state, observationNotifications: payload.data };
    case actionTypes.SET_OBSERVATION_NOTIFICATIONS_LOADING:
      return { ...state, observationNotificationsLoading: payload.data };
    case actionTypes.SET_CH_SHOW:
      return { ...state, chShow: payload.data };
    case actionTypes.SET_HIERARCHY_ID:
      return { ...state, chHierarchyID: payload.data };
    case actionTypes.SET_SHOW_CH_SUB_LEVELS:
      return { ...state, showCHSublevels: payload.data, chLevels: state.chLevels?.map(level => ({ ...level, visible: payload.data })) };
    case actionTypes.SET_CH_LEVELS:
      return { ...state, chLevels: payload.data?.map(level => ({ ...level, visible: state.showCHSublevels })) };
    case actionTypes.TOGGLE_LEVEL:
      return {
        ...state,
        chLevels: state.chLevels.map(level => ({ ...level, visible: level.ID === payload.data ? !level.visible : level.visible })),
      };
    case actionTypes.SET_CH_HIDE_ALL:
      return { ...state, chHideAll: payload.data };
    case actionTypes.SET_COMPONENTS_FILTER:
      return { ...state, componentsFilter: payload.data };
    case actionTypes.SET_PANORAMIC_IMAGES:
      return { ...state, panoramicImages: payload.data };
    case actionTypes.SET_HIDE_UNASSIGNED_COMPONENTS:
      return { ...state, hideUnassignedComponents: payload.data };
    case actionTypes.SET_DISPLAY_UNASSIGNED_COMPONENTS_LEVEL:
      return { ...state, displayUnassignedComponentsLevel: payload.data };
    case actionTypes.SET_IS_3D_VIEW_MODE_ACTIVE:
      return { ...state, is3DViewModeActive: payload.data };
    case actionTypes.SET_COMPONENT_LOCATION_EDITING:
      return { ...state, componentLocationEditing: payload.data };
    case actionTypes.SET_COMPONENT_LOCATION_ADDING:
      return { ...state, componentLocationAdding: payload.data };
    case actionTypes.SET_DEFECT_LOCATION_EDITING:
      return { ...state, defectLocationEditing: payload.data };
    case actionTypes.SET_DEFECT_LOCATION_ADDING:
      return { ...state, defectLocationAdding: payload.data };
    case actionTypes.SET_NOTIFICATION_LOCATION_EDITING:
      return { ...state, notificationLocationEditing: payload.data };
    case actionTypes.SET_NOTIFICATION_LOCATION_ADDING:
      return { ...state, notificationLocationAdding: payload.data };
    case actionTypes.SET_OBSERVATION_SUBTYPES_SUGGESTIONS:
      return { ...state, observationSubTypesSuggestions: payload.data };
    case actionTypes.CLEAR_OBSERVATION_SUBTYPES_SUGGESTIONS:
      return { ...state, observationSubTypesSuggestions: [] };
    case actionTypes.SET_OBSERVATION_CONTRIBUTORS:
      return {
        ...state,
        observationContributors: payload.data,
      };
    case actionTypes.AMEND_OBSERVATION_CONTRIBUTOR:
      let obsContributorsAdded = cloneDeep(state.observationContributors);

      if (payload.data[assigneeFields.participantType] === participantTypes.assignee) {
        obsContributorsAdded.Assignees = [...state.observationContributors.Assignees, payload.data];
      } else if (payload.data[assigneeFields.participantType] === participantTypes.collaborator) {
        obsContributorsAdded.Collaborators = [...state.observationContributors.Collaborators, payload.data];
      }

      return {
        ...state,
        observationContributors: obsContributorsAdded,
      };
    case actionTypes.REMOVE_OBSERVATION_CONTRIBUTOR:
      let obsContributorsRemoved = cloneDeep(state.observationContributors);

      if (payload.data.item[assigneeFields.participantType] === participantTypes.assignee) {
        obsContributorsRemoved.Assignees = ReducerHelpers.removeItemByProp(state.observationContributors.Assignees, payload.data.item, payload.data.prop);
      } else if (payload.data.item[assigneeFields.participantType] === participantTypes.collaborator) {
        obsContributorsRemoved.Collaborators = ReducerHelpers.removeItemByProp(state.observationContributors.Collaborators, payload.data.item, payload.data.prop);
      }

      return {
        ...state,
        observationContributors: obsContributorsRemoved,
      };

    case actionTypes.SET_OBSERVATION_FORM_STATE:
      return {
        ...state,
        observationFormState: {
          ...state.observationFormState,
          ...payload.data,
        },
      };
    case actionTypes.SET_CREATE_NOTIFICATION_FORM_SUBMITTING:
      return {
        ...state,
        createNotificationFormSubmitting: payload.data,
      };
    case actionTypes.REPLACE_ADDED_OBSERVATION_CUSTOM_PROPERTY:
      const updatedProps = Object.assign([], payload.data || []);
      const newCustomProps = (state.defectProperties || []).map(prop => {
        // If the custom prop is updated it will have ID, so we look for the updated props that already
        // have ID to just update the prop
        const sameIDpropIndex = updatedProps.findIndex(updatedProp => updatedProp.ID === prop.ID);
        let propToReturn = prop;
        if (sameIDpropIndex > -1) {
          // We set the prop to updated prop value
          propToReturn = payload.data[sameIDpropIndex];
          // We remove the updated prop
          updatedProps.splice(sameIDpropIndex, 1);
        }

        return propToReturn;
      });
      // We order it so it is displayed as it will be on next api call
      orderBy(updatedProps, [fields.id]);
      return { ...state, defectProperties: [...newCustomProps, ...updatedProps], observationFormState: { ...state.observationFormState, unsavedCustomProps: [] } };
    case actionTypes.SET_OBSERVATION_WORK_ORDERS:
      return {
        ...state,
        observationWorkOrders: payload.data,
      };
    case actionTypes.SET_COMPONENT_FORM_STATE:
      return {
        ...state,
        componentFormState: {
          ...state.componentFormState,
          ...payload.data,
        },
      };
    case actionTypes.REPLACE_ADDED_COMPONENT_CUSTOM_PROPERTY:
      const updatedComponentProps = Object.assign([], payload.data || []);
      const newCustomPropsOfComponent = (state.componentProperties || []).map(prop => {
        // If the custom prop is updated it will have ID, so we look for the updated props that already
        // have ID to just update the prop
        const sameIDpropIndex = updatedComponentProps.findIndex(updatedProp => updatedProp.ID === prop.ID);
        let propToReturn = prop;
        if (sameIDpropIndex > -1) {
          // We set the prop to updated prop value
          propToReturn = payload.data[sameIDpropIndex];
          // We remove the updated prop
          updatedComponentProps.splice(sameIDpropIndex, 1);
        }

        return propToReturn;
      });
      // We order it so it is displayed as it will be on next api call
      orderBy(updatedComponentProps, [fields.id]);
      return { ...state, componentProperties: [...newCustomPropsOfComponent, ...updatedComponentProps], componentFormState: { ...state.componentFormState, unsavedCustomProps: [] } };

    default:
      return state;
  }
};
