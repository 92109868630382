import { findIndex, isEmpty, orderBy } from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { change, getFormSyncErrors } from 'redux-form';
import { FORMS } from '../../../../../../common/constants';
import { customPropertyFieldTypes } from '../../../../../../common/custom-property/constants/property-constants';
import { fetchCustomPropDependantValues } from '../../../../actions/notification-actions';
import { defectNotificationFields } from '../constants/constants';
import DefectNotificationCreationForm from './defect-notification-creation-form';

const DefectNotificationCreationModal = props => {
  const { notificationData, fetchCustomPropDependantValues, changeField, customCloseAction, formErrors, createNotificationFormSubmitting, handleSubmit } = props;
  const { Properties } = notificationData;
  const [propertiesForForm, setPropsForForm] = useState(orderBy(Properties, defectNotificationFields.order));

  const handleOnPropChange = ({ _changedField, newValue, property }) => {
    if (property[defectNotificationFields.fieldType] === customPropertyFieldTypes.select) {
      const propertyDependencies = property[defectNotificationFields.dependency];
      const newPropForForm = Object.assign([], propertiesForForm);
      const changedPropIndex = findIndex(newPropForForm, { [defectNotificationFields.order]: property[defectNotificationFields.order] });
      newPropForForm[changedPropIndex][defectNotificationFields.value] = newValue;
      if (!isEmpty(propertyDependencies)) {
        fetchCustomPropDependantValues(property[defectNotificationFields.name], propertyDependencies, newValue, data => {
          // set the new props, to be precise the new field values
          data.forEach(newProperty => {
            newPropForForm[newProperty[defectNotificationFields.order] - 1] = newProperty;
          });
          setPropsForForm(newPropForForm);
          // set the fields to new default values
          data.forEach(newProperty => {
            changeField(`${defectNotificationFields.properties}[${newProperty[defectNotificationFields.order] - 1}].${defectNotificationFields.value}`, newProperty[defectNotificationFields.value]);
          });
          return;
        });
      }
      // in case there were no dependencies just set the prop to have new value
      setPropsForForm(newPropForForm);
    }
  };

  return (
    <div>
      <DefectNotificationCreationForm
        initialValues={{ ...notificationData, [defectNotificationFields.properties]: orderBy(notificationData[defectNotificationFields.properties], defectNotificationFields.order) }}
        properties={propertiesForForm}
        onSubmit={handleSubmit}
        handleOnPropChange={handleOnPropChange}
        closeAction={customCloseAction}
        submitDisabled={(formErrors && !isEmpty(formErrors)) || createNotificationFormSubmitting}
        formErrors={formErrors}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  formErrors: getFormSyncErrors(FORMS.createNotificationFromObservationForm)(state),
  createNotificationFormSubmitting: state.inspectionReducer.createNotificationFormSubmitting,
});

const mapDispatchToProps = dispatch => ({
  fetchCustomPropDependantValues: (propName, dependencies, propValues, callback) => dispatch(fetchCustomPropDependantValues(propName, dependencies, propValues, callback)),
  changeField: (field, value) => dispatch(change(FORMS.createNotificationFromObservationForm, field, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DefectNotificationCreationModal);
