import { debounce, findIndex, remove } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import CommentsTab from '../../../../../../common/comments-tab/components/comments-tab';
import { commentFields } from '../../../../../../common/comments-tab/constants/constants';
import FlowStatusWrapper from '../../../../../../common/flow-status/components/flow-status-wrapper';
import SimpleLoader from '../../../../../../common/global-loader/components/simple-loader';
import Helpers from '../../../../../../common/helpers';
import Modal from '../../../../../../common/modal/components/modal';
import Notification from '../../../../../../common/notification/components/notification';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../../../common/permissions-constants';
import { questionTypes } from '../../../../../../common/question-components/constants/question-constants';
import RenderIf from '../../../../../../common/render-if/components/render-if';
import Tab from '../../../../../../common/tabs/component/tab';
import Tabs from '../../../../../../common/tabs/component/tabs';
import { setIsolationCertificates } from '../../../../actions/action-creators';
import { fetchCommentUsersAndTeams } from '../../../../actions/inspection-actions';
import { filterProps } from '../../../../components/isolation-certificates/constants/isolation-certificates-table';
import { moduleSectionsFields } from '../../../../constants/constants';
import '../../../../styles/isolation-certificate-details.scss';
import { fields as isolationCertificateTableFields, statuses } from '../../../isolation-certificates/constants/isolation-certificates-table';
import CriticalEquipmentInfo from '../../common/critical-equipment-info';
import DownloadPDFOptionsForm from '../../common/download-pdf-options-form';
import ModuleHeader from '../../common/module-header';
import {
  addIsolationCertificateComment,
  answerOnQuestion,
  archiveIsolationCertificate,
  deleteIsolationCertificate,
  deleteIsolationCertificateComment,
  deleteIsolationCertificateFile,
  deleteIsolationComponentFile,
  fetchIsolationCertificateComments,
  generateAndDownloadPDF,
  generateAndDownloadPDFPopulatedDrawings,
  getIsolationCertificateDetails,
  getIsolationCertificateStatusFlows,
  orderIsolationComponents,
} from '../actions/isolation-certificate-details-actions';
import { isolationComponentFields } from '../constants/change-status-constants';
import { equipmentKey, fields, pdfDownloadOptions, fields as sectionFields, tabNames, toolbarItems } from '../constants/constants';
import { fileFields, fields as isolationComponentsFields } from '../constants/isolation-components-constants';
import IsolationCertificateSections from './details/isolation-certificate-sections';

class IsolationCertificateDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      activeToolbarItem: toolbarItems[tabNames.details].name,
      isolationCertificateDetails: {},
      isDownloadInProgress: false,
      actionModalData: {
        isOpen: false,
      },
      notificationData: { isDisplayed: false },
      commentsList: [],
      commentsLoading: false,
      addCommentLoading: false,
      commentUsersList: [],
      commentTeamsList: [],
      commentUsersAndTeamsLoading: false,
    };

    this.handleAnswerQuestionDebounce = debounce(this.handleAnswerQuestion, 350);
  }

  componentDidMount = () => {
    const { queryItem, getIsolationCertificateDetails } = this.props;

    if (queryItem) {
      getIsolationCertificateDetails(queryItem, this.handleSuccessFetchIsolationCertificate);
    }
  };

  componentDidUpdate = prevProps => {
    const { queryItem, getIsolationCertificateDetails } = this.props;

    if (prevProps.queryItem !== queryItem && queryItem) {
      this.setState({
        isLoading: true,
        activeToolbarItem: toolbarItems[tabNames.details].name,
      });
      getIsolationCertificateDetails(queryItem, this.handleSuccessFetchIsolationCertificate);
    }
  };

  handleSuccessFetchIsolationCertificate = (isLoading, isolationCertificateDetails) => {
    const state = {
      isLoading,
    };

    if (isolationCertificateDetails) {
      state.isolationCertificateDetails = isolationCertificateDetails;
    }

    this.setState(state);
  };

  closeNotificationTimeout = () => {
    setTimeout(() => {
      this.setState({ notificationData: { isDisplayed: false } });
    }, 5000);
  };

  closeNotification = () => this.setState({ notificationData: { isDisplayed: false } });

  handleAnswerQuestion = values => {
    const question = Object.assign({}, values.question);
    delete values.question;
    const { answerOnQuestion } = this.props;

    const onSuccess = () => {
      const { isolationCertificateDetails } = this.state;
      const isolationCertificate = { ...isolationCertificateDetails };

      const sectionIndex = findIndex(isolationCertificate.Sections, { [fields.id]: question.SectionID });

      if (sectionIndex > -1) {
        const section = isolationCertificate.Sections[sectionIndex];
        const questionIndex = findIndex(section.SectionQuestions, { [fields.id]: question[fields.id] });

        if (questionIndex > -1) {
          const foundQuestion = section.SectionQuestions[questionIndex];
          const foundQuestionType = Object.values(questionTypes).find(qt => qt.valueId === foundQuestion.QuestionType);

          if (foundQuestionType.value !== 'MULTI_ANSWER') {
            /* Updating the value of the answer to the question. */
            if (!foundQuestion.Answers || foundQuestion?.Answers.length === 0) {
              /* in case the input has no answer value, add it */
              foundQuestion.Answers = [];
              foundQuestion.Answers.push({ [foundQuestionType.fieldName]: values[foundQuestionType.fieldName] });
            } else foundQuestion.Answers[0][foundQuestionType.fieldName] = values[foundQuestionType.fieldName];
            /* Replacing the question at the index of questionIndex with the foundQuestion. */
            section.SectionQuestions.splice(questionIndex, 1, foundQuestion);
          } else {
            // Ensure the question object is correctly structured for MULTI_ANSWER
            const updatedQuestion = { ...question, Answers: values.Answers || [] };
            section.SectionQuestions.splice(questionIndex, 1, updatedQuestion);
          }
          isolationCertificate.Sections.splice(sectionIndex, 1, section);
          /* Setting the state of the permitDetails property to the permit object. */
          this.setState({ isolationCertificateDetails: isolationCertificate });
        }
      }
    };

    answerOnQuestion(values, onSuccess);
  };

  handleSuccessManipulateIsolationComponent = (isolationCertificateId, isolationComponent, isUpdate) => {
    const { isolationCertificateDetails } = this.state;
    const { queryItem, getIsolationCertificateDetails, isolationCertificates, setIsolationCertificates } = this.props;
    const certificateDetails = Object.assign({}, isolationCertificateDetails);
    const components = Object.assign([], isolationCertificateDetails[moduleSectionsFields.components]);

    if (isUpdate) {
      const componentIndex = findIndex(components, { [isolationComponentsFields.component.name]: isolationComponent[isolationComponentsFields.component.name] });

      if (componentIndex > -1) {
        components.splice(componentIndex, 1, isolationComponent);
      }
    } else {
      components.push(isolationComponent);
    }

    certificateDetails[moduleSectionsFields.components] = components;

    const certificates = Object.assign([], isolationCertificates);
    const index = findIndex(certificates, { [isolationCertificateTableFields.id]: isolationCertificateId });

    if (index > -1) {
      const certificate = certificates[index];
      certificate[isolationCertificateTableFields.numberOfIsolations] = parseInt(certificate[isolationCertificateTableFields.numberOfIsolations], 10) + 1;

      if (isUpdate) {
        const componentIndex = findIndex(certificate[isolationCertificateTableFields.components], {
          [isolationComponentsFields.component.name]: isolationComponent[isolationComponentsFields.component.name],
        });

        if (componentIndex > -1) {
          certificate[isolationCertificateTableFields.components].splice(componentIndex, 1, isolationComponent);
        }
      } else {
        if (certificate[isolationCertificateTableFields.components]) {
          certificate[isolationCertificateTableFields.components].push(isolationComponent);
        } else {
          certificate[isolationCertificateTableFields.components] = [isolationComponent];
        }
      }

      certificates[index] = certificate;

      setIsolationCertificates(false, certificates);
    }

    if (!isUpdate) {
      this.setState({ isolationCertificateDetails: certificateDetails });
      // after the isolation is added, fetch the latest data from API
      getIsolationCertificateDetails(queryItem, (_, certificate) => this.handleSuccessFetchIsolationCertificate(false, certificate));
    }
  };

  handleSuccessDeleteIsolationComponent = (isolationCertificateId, componentId) => {
    const { isolationCertificateDetails } = this.state;
    const { isolationCertificates, setIsolationCertificates, getIsolationCertificateDetails, queryItem } = this.props;
    const certificateDetails = Object.assign({}, isolationCertificateDetails);
    remove(certificateDetails[moduleSectionsFields.components], { [isolationComponentsFields.component.name]: componentId });

    const certificates = Object.assign([], isolationCertificates);
    const index = findIndex(certificates, { [isolationCertificateTableFields.id]: isolationCertificateId });

    if (index > -1) {
      const certificate = certificates[index];
      certificate[isolationCertificateTableFields.numberOfIsolations] = parseInt(certificate[isolationCertificateTableFields.numberOfIsolations], 10) - 1;
      remove(certificate[isolationCertificateTableFields.components], { [isolationComponentsFields.component.name]: componentId });
      certificates[index] = certificate;

      setIsolationCertificates(false, certificates);
    }

    this.setState({ isolationCertificateDetails: certificateDetails });
    // after the isolation is deleted, fetch the latest data from API
    getIsolationCertificateDetails(queryItem, (_, certificate) => this.handleSuccessFetchIsolationCertificate(false, certificate));
  };

  handleDeleteFile = (componentId, fileId) => {
    const { queryItem, deleteIsolationComponentFile } = this.props;

    deleteIsolationComponentFile({ [isolationComponentsFields.isolationCertificate.name]: queryItem, [isolationComponentsFields.component.name]: componentId, [fileFields.id]: fileId }, () => {
      const { isolationCertificateDetails } = this.state;
      const certificateDetails = Object.assign({}, isolationCertificateDetails);
      const index = findIndex(certificateDetails[moduleSectionsFields.components], { [isolationComponentsFields.component.name]: componentId });

      if (index > -1) {
        const component = certificateDetails[moduleSectionsFields.components][index];
        remove(component[isolationComponentsFields.files.name], { [fileFields.id]: fileId });
        certificateDetails[moduleSectionsFields.components].splice(index, 1, component);

        this.setState({ isolationCertificateDetails: certificateDetails });
      }
    });
  };

  handleAddIsolationCertificateComment = (values, isolationCertificate, callback) => {
    const { addIsolationCertificateComment } = this.props;
    addIsolationCertificateComment(values, isolationCertificate, callback);
  };

  handleCustomAction = (action, values, onSuccess) => {
    if (action && typeof action === 'function') {
      const { isolationCertificateDetails } = this.state;

      action(values, Object.assign({}, isolationCertificateDetails), (isSuccess, certificateDetails) => {
        if (isSuccess && certificateDetails) {
          this.setState({ isolationCertificateDetails: certificateDetails });
        }

        if (onSuccess) {
          onSuccess(isSuccess);
        }
      });
    }
  };

  handleOrderComponents = values => {
    const { orderIsolationComponents, queryItem, getIsolationCertificateDetails } = this.props;

    orderIsolationComponents({ ...values, [isolationComponentsFields.isolationCertificate.name]: queryItem }, () =>
      getIsolationCertificateDetails(queryItem, (_, certificate) => this.handleSuccessFetchIsolationCertificate(false, certificate))
    );
  };

  handleDeleteIsolationCertificateFile = (isolationId, fileId, callback) => {
    const { deleteIsolationCertificateFile } = this.props;

    deleteIsolationCertificateFile(isolationId, fileId, () => {
      const { isolationCertificateDetails } = this.state;
      const newDetails = Object.assign({}, isolationCertificateDetails);
      remove(newDetails[moduleSectionsFields.files], { FileID: fileId });

      this.setState({ isolationCertificateDetails: newDetails });
      if (callback) {
        callback();
      }
    });
  };

  handleMaximize = () => {};

  handleDownload = () => {
    const { t } = this.context;

    const { isDownloadInProgress } = this.state;

    if (isDownloadInProgress) {
      return;
    }

    // first show the new modal with options (w & w/o drawings/files)
    // if Generate & Download clicked show the notification for downloading
    // if Cancel clicked, close the new modal
    this.setState({ isDownloadInProgress: true });
    const { queryItem, generateAndDownloadPDF } = this.props;

    const actionModalData = {
      isOpen: true,
      title: t('ISOLATION_CERTIFICATE.DOWNLOAD_ISOLATION_PDF'),
      content: (
        <DownloadPDFOptionsForm
          submitForm={values => {
            const populatedDrawingsVal = values[pdfDownloadOptions.populatedDrawings.name] || false;
            // const filesAndImagesVal = values[pdfDownloadOptions.filesAndImages.name] || false;

            // close action modal
            this.setState({ actionModalData: { isOpen: false } });
            generateAndDownloadPDF(queryItem, populatedDrawingsVal, () => this.setState({ isDownloadInProgress: false, actionModalData: { isOpen: false } }));
            // now display the notification
            this.setState({
              notificationData: {
                isDisplayed: true,
                type: 'info',
                icon: 'check-toast',
                text: t('ISOLATION_CERTIFICATE.DOWNLOAD_NOTIFICATION'),
                onClickClose: this.closeNotification,
              },
            });
            this.closeNotificationTimeout();
          }}
          closeAction={() => this.setState({ actionModalData: { isOpen: false } })}
        />
      ),

      type: 'cancel-download',
      customClassName: 'download-isolation-pdf-modal',
      closeAction: () => this.setState({ actionModalData: { isOpen: false } }),
    };

    this.setState({ actionModalData });
    this.setState({ isDownloadInProgress: false });
  };

  handleShare = () => {};

  toggleHeaderActionsModal = (values, isArchiveAction) => {
    const { t } = this.context;
    const { isolationCertificateDetails } = this.state;
    const { archiveIsolationCertificate, deleteIsolationCertificate, isolationCertificates, filters, projectId } = this.props;
    const newFilters = Object.assign({}, filters);
    newFilters[filterProps.projectId] = projectId;
    newFilters[filterProps.lastSeen] = 0;

    const handleSuccessHeaderAction = (isolationCertificate, isDeleteAction) => {
      this.setState({ isolationCertificateDetails: isolationCertificate, actionModalData: { isOpen: false } });

      if (isDeleteAction) {
        const { location } = this.props;
        const queryParams = { ...location.query };
        delete queryParams.selected_item;
        const params = [];

        for (let key in queryParams) {
          params.push({
            [key]: queryParams[key],
          });
        }

        Helpers.goTo(location.pathname, params);
      }
    };

    const getActionContent = () => {
      if (!isArchiveAction) {
        return 'ISOLATION_CERTIFICATE.DELETE_CERTIFICATE_CONTENT';
      }

      return values[sectionFields.archive] ? 'ISOLATION_CERTIFICATE.ARCHIVE_CERTIFICATE_CONTENT' : 'ISOLATION_CERTIFICATE.UNARCHIVE_CERTIFICATE_CONTENT';
    };

    const action = isArchiveAction
      ? () =>
          archiveIsolationCertificate(values, Object.assign({}, isolationCertificateDetails), Object.assign([], isolationCertificates), newFilters, isolationCertificate =>
            handleSuccessHeaderAction(isolationCertificate)
          )
      : () =>
          deleteIsolationCertificate(values, Object.assign([], isolationCertificates), Object.assign({}, filters), (isolationCertificate, isDeleteAction) =>
            handleSuccessHeaderAction(isolationCertificate, isDeleteAction)
          );

    const actionModalData = {
      isOpen: true,
      content: t(getActionContent(), { name: isolationCertificateDetails[fields.name] }),
      type: 'yes-no',
      customClassName: 'delete-confirm-modal',
      confirmAction: action,
      closeAction: () => this.setState({ actionModalData: { isOpen: false } }),
    };

    this.setState({ actionModalData });
  };

  fetchIsolationCertificateCommentsHandler = () => {
    const { fetchIsolationCertificateComments, queryItem } = this.props;

    const onSuccessFetch = newState => {
      this.setState({ commentsList: newState.commentsList, commentsLoading: newState.commentsLoading });

      if (newState.commentsList) {
        Helpers.scrollIntoView('comments-list-wrapper', `comment-${newState.commentsList.length - 1}`, 0);
      }
    };

    fetchIsolationCertificateComments({ [commentFields.isolationCertificateId]: queryItem }, onSuccessFetch);
  };

  addIsolationCertificateCommentHandler = (commentValue, commentTags, resetCommentInput, scrollIntoView) => {
    const { addIsolationCertificateComment, inspectionId, projectId } = this.props;
    const { isolationCertificateDetails } = this.state;

    // TODO: replace with CommentSectionID once API tweaks the response for GET isolationCertificateDetails
    const isolationCertificateCommentSection = isolationCertificateDetails.Sections.find(s => s[commentFields.name] === 'Comments');

    const commentParams = {
      InspectionID: inspectionId,
      ProjectID: projectId,
      [commentFields.id]: isolationCertificateDetails[fields.id],
      [commentFields.tags]: commentTags,
      [commentFields.moduleSectionID]: isolationCertificateCommentSection[fields.id],
      Comment: commentValue,
    };
    addIsolationCertificateComment(
      commentParams,
      () => this.fetchIsolationCertificateCommentsHandler(),
      loading => this.setState({ addCommentLoading: loading })
    );
    resetCommentInput();
    scrollIntoView();
  };

  deleteIsolationCertificateCommentHandler = comment => {
    const { deleteIsolationCertificateComment } = this.props;
    const commentParams = { [commentFields.moduleCommentID]: comment[commentFields.id], CommentID: null };

    deleteIsolationCertificateComment(
      commentParams,
      () => this.fetchIsolationCertificateCommentsHandler(false),
      loading => this.setState({ addCommentLoading: loading })
    );
  };

  searchUserAndTeamsHandler = searchTerm => {
    const { fetchCommentUsersAndTeams } = this.props;
    fetchCommentUsersAndTeams(
      searchTerm,
      (usersList, teamsList) => this.setState({ commentUsersList: usersList, commentTeamsList: teamsList }),
      loading =>
        this.setState({
          commentUsersAndTeamsLoading: loading,
        })
    );
  };

  render() {
    const {
      activeToolbarItem,
      isolationCertificateDetails,
      isDownloadInProgress,
      actionModalData,
      notificationData,
      isLoading,
      commentsList,
      commentsLoading,
      addCommentLoading,
      commentUsersList,
      commentTeamsList,
      commentUsersAndTeamsLoading,
    } = this.state;
    const { queryItem, projectId, inspectionId, getIsolationCertificateStatusFlows, generateAndDownloadPDFPopulatedDrawings, user } = this.props;

    const menuOptions = [
      {
        title: isolationCertificateDetails[fields.archived] ? 'ISOLATION_CERTIFICATE.UNARCHIVE' : 'ISOLATION_CERTIFICATE.ARCHIVE',
        action: () => this.toggleHeaderActionsModal({ [fields.isolationCertificateId]: queryItem, [fields.archive]: !isolationCertificateDetails[fields.archived] }, true),
        access: {
          visibleFor: PERMISSIONS[PERMISSION_TYPES.isolationCertificates].archive.name,
        },
        isDisabled:
          [statuses.authorised.value, statuses.underTest.value, statuses.live.value, statuses.longTerm.value, statuses.rejected.value].indexOf(
            isolationCertificateDetails[moduleSectionsFields.status]
          ) > -1,
      },
      {
        title: 'ISOLATION_CERTIFICATE.DELETE_ISOLATION',
        action: () => this.toggleHeaderActionsModal({ [fields.isolationCertificateId]: queryItem }, false),
        access: {
          visibleFor: PERMISSIONS[PERMISSION_TYPES.isolationCertificates].delete.name,
        },
        separator: true,
        isHighlighted: true,
        isDisabled:
          [statuses.approved.value, statuses.authorised.value, statuses.underTest.value, statuses.live.value, statuses.longTerm.value].indexOf(
            isolationCertificateDetails[moduleSectionsFields.status]
          ) > -1,
      },
    ];

    const isolationEquipment = isolationCertificateDetails[equipmentKey];
    const containsCriticalEquipment = isolationEquipment && isolationEquipment.some(component => component[isolationComponentFields.critical]);
    const debounceValues = {
      MULTI_ANSWER: 100,
      CHECKBOX: 100,
      DEFAULT: 300,
    };

    return (
      <div className={`isolation-certificate-details ${isLoading ? 'loading' : ''}`}>
        <RenderIf if={isLoading}>
          <SimpleLoader isLoading={true} className="loader" />
        </RenderIf>
        <RenderIf if={!isLoading}>
          <Tabs
            defaultTabKey={activeToolbarItem}
            navigationClassName="tabs"
            tabsHeader={
              activeToolbarItem === tabNames.details ? (
                <ModuleHeader
                  id={queryItem}
                  handleMaximize={this.handleMaximize}
                  handleDownload={this.handleDownload}
                  handleShare={this.handleShare}
                  menuOptions={menuOptions}
                  isDownloadDisabled={isDownloadInProgress}
                  hideMaximize
                  hideShare
                />
              ) : null
            }
            onChange={tab => this.setState({ activeToolbarItem: tab })}
          >
            <Tab title={toolbarItems[tabNames.details].label} tabKey={tabNames.details}>
              <RenderIf if={containsCriticalEquipment}>
                <CriticalEquipmentInfo className="isolation-certificates-critical-eq" title={'CRITICAL_EQUIPMENT.TITLE'} paragraph={'CRITICAL_EQUIPMENT_ISOLATIONS.PARAGRAPH'} />
              </RenderIf>
              <IsolationCertificateSections
                queryItem={queryItem}
                projectId={projectId}
                inspectionId={inspectionId}
                isolationCertificateDetails={isolationCertificateDetails}
                handleSuccessFetchIsolationCertificate={this.handleSuccessFetchIsolationCertificate}
                handleAnswerQuestion={this.handleAnswerQuestionDebounce}
                handleSuccessManipulateIsolationComponent={this.handleSuccessManipulateIsolationComponent}
                handleSuccessDeleteIsolationComponent={this.handleSuccessDeleteIsolationComponent}
                handleDeleteFile={this.handleDeleteFile}
                handleCustomAction={this.handleCustomAction}
                handleOrderComponents={this.handleOrderComponents}
                handleDeleteIsolationCertificateFile={this.handleDeleteIsolationCertificateFile}
                handleAddIsolationCertificateComment={this.handleAddIsolationCertificateComment}
                generateAndDownloadPDFPopulatedDrawings={generateAndDownloadPDFPopulatedDrawings}
                isLoading={isLoading}
                debounceValues={debounceValues}
              />
            </Tab>
            <Tab title={toolbarItems[tabNames.comments].label} tabKey={tabNames.comments}>
              <CommentsTab
                commentsList={commentsList}
                commentsLoading={commentsLoading}
                addCommentLoading={addCommentLoading}
                fetchCommentsList={this.fetchIsolationCertificateCommentsHandler}
                onAddCommentClick={this.addIsolationCertificateCommentHandler}
                onDeleteCommentClick={this.deleteIsolationCertificateCommentHandler}
                fetchCommentUsersAndTeams={this.searchUserAndTeamsHandler}
                commentUsersList={commentUsersList}
                commentTeamsList={commentTeamsList}
                commentUsersAndTeamsLoading={commentUsersAndTeamsLoading}
                user={user}
                addCommentPermission={PERMISSIONS[PERMISSION_TYPES.isolationCertificates].addComment.name}
              />
            </Tab>
            <Tab title={toolbarItems[tabNames.flow].label} tabKey={tabNames.flow}>
              <FlowStatusWrapper flowStatusModuleId={queryItem} parentProps={this.props} getFlowStatusData={getIsolationCertificateStatusFlows} statuses={statuses} />
            </Tab>
          </Tabs>
        </RenderIf>
        <Modal {...actionModalData} />
        <Notification {...notificationData} />
      </div>
    );
  }
}

IsolationCertificateDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isolationCertificates: state.isolationCertificatesReducer.isolationCertificates,
  user: state.userReducer,
  filters: state.isolationCertificatesReducer.filters,
});

const mapDispatchToProps = dispatch => ({
  getIsolationCertificateDetails: (isolationCertificateId, callback) => dispatch(getIsolationCertificateDetails(isolationCertificateId, callback)),
  archiveIsolationCertificate: (data, isolationCertificate, certificates, filters, callback) => dispatch(archiveIsolationCertificate(data, isolationCertificate, certificates, filters, callback)),
  deleteIsolationCertificate: (data, certificates, filters, callback) => dispatch(deleteIsolationCertificate(data, certificates, filters, callback)),
  getIsolationCertificateStatusFlows: (certificateId, callback) => dispatch(getIsolationCertificateStatusFlows(certificateId, callback)),
  generateAndDownloadPDF: (isolationCertificateId, additionalProps, callback) => dispatch(generateAndDownloadPDF(isolationCertificateId, additionalProps, callback)),
  generateAndDownloadPDFPopulatedDrawings: data => dispatch(generateAndDownloadPDFPopulatedDrawings(data)),
  answerOnQuestion: (data, callback) => dispatch(answerOnQuestion(data, callback)),
  setIsolationCertificates: (isLoading, certificates) => dispatch(setIsolationCertificates(isLoading, certificates)),
  deleteIsolationComponentFile: (data, callback) => dispatch(deleteIsolationComponentFile(data, callback)),
  orderIsolationComponents: (data, callback) => dispatch(orderIsolationComponents(data, callback)),
  deleteIsolationCertificateFile: (isolationCertificateId, fileId, section, callback) => dispatch(deleteIsolationCertificateFile(isolationCertificateId, fileId, section, callback)),
  fetchIsolationCertificateComments: (params, callback) => dispatch(fetchIsolationCertificateComments(params, callback)),
  addIsolationCertificateComment: (params, dataCallback, loadingCallback) => dispatch(addIsolationCertificateComment(params, dataCallback, loadingCallback)),
  deleteIsolationCertificateComment: (params, dataCallback, loadingCallback) => dispatch(deleteIsolationCertificateComment(params, dataCallback, loadingCallback)),
  fetchCommentUsersAndTeams: (searchTerm, dataCallback, loadingCallback) => dispatch(fetchCommentUsersAndTeams(searchTerm, dataCallback, loadingCallback)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IsolationCertificateDetails));
