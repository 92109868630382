import { map, some } from 'lodash';
import ReducerHelpers from '../../../common/reducer-helpers';
import { fields } from '../../inspections/components/work-orders/constants/constants';
import actionTypes from '../constants/action-types';

const uploadFilesState = {
  uploadTmpFiles: [],
  uploadInProgress: false,
  externalUploadedFiles: null,
  defectFiles: null,
  componentFiles: null,
  notificationFiles: null,
  workorderFiles: null,
  measurementFiles: null,
  ndtMeasurementFiles: null,
  ndtMeasurementPointFiles: null,
  checklistQuestionFiles: {},
  defectFilesLoading: false,
  measurementFilesLoading: false,
  cancelArr: [],
  measurementReadingFiles: null,
};

export const uploadReducer = (state = uploadFilesState, payload) => {
  switch (payload.type) {
    case actionTypes.SET_UPLOAD_TMP_FILES:
      return { ...state, uploadTmpFiles: payload.data };
    case actionTypes.SET_SINGLE_UPLOADITEM_PROGRESS:
      const mappedData = state.uploadTmpFiles.map((item, index) => {
        if (index === payload.data.index) {
          item.progress = payload.data.progress;
          item.awsKey = payload.data.awsKey;
        }
        return item;
      });
      return {
        ...state,
        uploadTmpFiles: mappedData,
        uploadInProgress: some(mappedData, item => {
          return item.progress < 100 && item.progress > 0;
        }),
      };
    case actionTypes.REMOVE_SINGLE_UPLOADITEM:
      return {
        ...state,
        uploadTmpFiles: state.uploadTmpFiles.splice(payload.index, 1),
      };
    case actionTypes.SET_EXTERNAL_UPLOADED_FILES:
      return { ...state, externalUploadedFiles: payload.data };
    case actionTypes.SET_COMPONENT_FILES_UPLOADED:
      return { ...state, componentFiles: payload.data };
    case actionTypes.SET_DEFECT_FILES_UPLOADED:
      return { ...state, defectFiles: payload.data };
    case actionTypes.SET_NOTIFICATION_FILES_UPLOADED:
      return { ...state, notificationFiles: payload.data };
    case actionTypes.SET_WORKORDER_FILES_UPLOADED:
      return { ...state, workorderFiles: payload.data };
    case actionTypes.SET_MEASUREMENT_READING_FILES_UPLOADED:
      return { ...state, measurementReadingFiles: payload.data };
    case actionTypes.AMEND_WORKORDER_FILES_UPLOADED:
      return { ...state, workorderFiles: [...(state.workorderFiles || []), ...payload.data] };
    case actionTypes.REMOVE_WORKORDER_FILE_BY_ID:
      return { ...state, workorderFiles: ReducerHelpers.removeItemByProp(state.workorderFiles || [], payload.data, fields.id) };
    case actionTypes.SET_CHECKLIST_QUESTION_FILES_UPLOADED:
      return { ...state, checklistQuestionFiles: { ...state.checklistQuestionFiles, [payload.prop]: payload.data } };
    case actionTypes.AMEND_CHECKLIST_QUESTION_FILES_UPLOADED:
      return { ...state, checklistQuestionFiles: { ...state.checklistQuestionFiles, [payload.prop]: [...(state.checklistQuestionFiles[payload.prop] || []), ...payload.data] } };
    case actionTypes.REMOVE_CHECKLIST_QUESTION_FILE_BY_PROP:
      return {
        ...state,
        checklistQuestionFiles: { ...state.checklistQuestionFiles, [payload.prop]: ReducerHelpers.removeItemByProp(state.checklistQuestionFiles[payload.prop] || [], payload.data, payload.data.ID) },
      };
    case actionTypes.SET_MEASUREMENT_FILES_UPLOADED:
      return { ...state, measurementFiles: payload.data };
    case actionTypes.SET_NDT_MEASUREMENT_FILES_UPLOADED:
      return { ...state, ndtMeasurementFiles: payload.data };
    case actionTypes.SET_NDT_MEASUREMENT_POINT_FILES_UPLOADED:
      return { ...state, ndtMeasurementPointFiles: payload.data };
    case actionTypes.SET_DEFECT_FILES_UPLOADED_LOADING:
      return { ...state, defectFilesLoading: payload.data };
    case actionTypes.SET_MEASUREMENT_FILES_UPLOADED_LOADING:
      return { ...state, measurementFilesLoading: payload.data };
    case actionTypes.SET_ITEM_TO_CANCEL: {
      uploadFilesState.cancelArr.push(payload.data);
      return { ...state, cancelArr: uploadFilesState.cancelArr };
    }
    case actionTypes.REMOVE_ITEM_TO_CANCEL:
      return {
        ...state,
        cancelArr: map(uploadFilesState.cancelArr, item => {
          if (item.timestamp !== payload.data) {
            return item;
          }
        }),
      };
    default:
      return state;
  }
};
