import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field, getFormSyncErrors, reduxForm } from 'redux-form';

import ExpandableSection from '../../../../common/expandable-section/components/expandable-section';
import Button from '../../../../common/form/components/button';
import CustomInputRange from '../common/input/input-range';
import { validate } from './validators/notification-validator';

import { cloneDeep, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import AccessRenderer from '../../../../common/access-renderer/components/access-renderer';
import { AMAZON_IMAGE_SIZES, FORMS } from '../../../../common/constants';
import CustomProperties from '../../../../common/custom-property/components/custom-properties';
import EmptyState from '../../../../common/empty-state-v2/components/empty-state';
import CustomInput from '../../../../common/form/components/input';
import UneditableInfo from '../../../../common/form/components/uneditable-info';
import Helpers from '../../../../common/helpers';
import LocationExpandableSection from '../../../../common/location-expandable-section/components/location-expandable-section';
import Modal from '../../../../common/modal/components/modal';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import RenderIf from '../../../../common/render-if/components/render-if';
import { clearUnsavedChangesDirty, setNotificationFormState, setNotificationLocationAdding, setNotificationLocationEditing } from '../../actions/action-creators';
import { updateElementGeometry } from '../../actions/inspection-actions';
import { preventedFieldsPriorComponentIsCreated } from '../../constants/component-constants';
import { measurementTypes, modules, objectTools } from '../../constants/constants';
import { expandableSectionInfoTypes, formConstants } from '../../constants/notification-constants';
import { statuses } from '../notifications/constants/constants';
import { fields } from '../observations/constants/constants';
import ChangeStatusDropdown from './common/change-status-dropdown';
import EditModuleItemFilesModal from './common/edit-module-item-files-modal';
import FieldUpload from './component-assets';
import LocationSection from './location-section';
import ExpandableSectionInfo from './notification-details/expandable-section-info';

const NotificationForm = (
  {
    generalStatus,
    user,
    handleActivePage,
    handleSourceClick,
    relatedComponents,
    notificationId,
    getProperties,
    updateProperty,
    addProperty,
    deleteProperty,
    getSuggestions,
    properties,
    initialValues,
    handleSubmit,
    onChangeStatus,
    formHasUnsavedChanges,
    showGeometryWarning,
    viewer,
    notificationLocationAdding,
    notificationLocationEditing,
    images360Ref,
    setNotificationLocationEditing,
    setNotificationLocationAdding,
    objectToolClick,
    updateGeometry,
    toggleDeleteModal,
    handleChangeCameraUpdate,
    selectedNotification,
    setNotificationFormState,
    openFile,
    handleInputDisabled,
    projectDMSCategories,
    loggedByUserID,
    notificationFiles,
    openDeleteFileModal,
    clearUnsavedChangesDirty,
    defaultComponent,
    toggleElement,
    locationObject,
    customPropertyFormErrors,
  },
  { t }
) => {
  const [editFilesModal, setEditFilesModal] = useState({ isOpen: false });
  const [imageType] = useState(AMAZON_IMAGE_SIZES.small.name);

  useEffect(() => {
    setNotificationLocationEditing(false);
    setNotificationLocationAdding(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formIsDisabled = !Helpers.hasAccess({
    user,
    visibleFor: PERMISSIONS[PERMISSION_TYPES.notifications].edit.name,
    id: initialValues[formConstants.fields.createdByID],
    ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.notifications].create.name,
  });

  const toggleConfirmationModal = (isOpen, title, content, confirmAction) => {
    if (isOpen) {
      const handleConfirmAction = () => typeof confirmAction === 'function' && confirmAction();

      toggleDeleteModal({
        isOpen: true,
        content: t(content),
        title: t(title),
        type: 'cancel-confirm',
        customClassName: 'component-confirmation-modal',
        confirmAction: handleConfirmAction,
        closeAction: () => toggleDeleteModal({ isOpen: false }),
      });
    } else {
      toggleDeleteModal({ isOpen: false });
    }
  };

  const handleDeleteLocation = newCoordinates => {
    const element = cloneDeep({ ...selectedNotification, SystemType: measurementTypes.notification });
    element.Geometry.coordinates = newCoordinates;

    handleChangeCameraUpdate(element);
    updateGeometry(element);
    toggleDeleteModal(false);
  };

  const openEditFilesModal = () => {
    setEditFilesModal({
      isOpen: true,
      type: '',
      title: t('EDIT_FILES'),
      CustomContent: dynamicProps => <EditModuleItemFilesModal {...dynamicProps} projectDMSCategories={projectDMSCategories} selectedModuleItem={selectedNotification} />,
      customClassName: 'modal-no-max-height modal-large',
      closeAction: () => setEditFilesModal({ isOpen: false }),
      customCloseAction: () => setEditFilesModal({ isOpen: false }),
    });
  };

  const renderFilesSection = (ownerID, readonly, notificationFiles) => {
    const filesByCategory = {};

    if (isEmpty(notificationFiles)) {
      return <EmptyState emptyStateText={t('NO_FILES_ADDED')} showButton transparent buttonText={t('CREATE_REGIME_FORM.ADD_FILES')} buttonAction={openEditFilesModal} buttonDisabled={readonly} />;
    }

    if (!isEmpty(notificationFiles)) {
      projectDMSCategories.forEach(c => {
        const category = c[fields.id];

        if (!category) return null;

        const categoryFiles = notificationFiles.filter(cf => cf.CategoryID === category) || [];
        filesByCategory[c.Name] = categoryFiles;
      });
    }
    return projectDMSCategories.map(category => {
      return (
        <FieldUpload
          disabled={readonly}
          label={t(category.Name)}
          labelClass="f-secondary-dark defect-form__label"
          noFileText={t('NO_FILES_IN_CATEGORY')}
          files={filesByCategory[category.Name]}
          imageType={imageType}
          showUploadSvg={false}
          actionsMenu={[
            {
              title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_1',
              action: data => {
                openFile(data);
              },
            },
            {
              title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_2',
              action: data => {
                Helpers.getFileExtensionAndDownload(data);
              },
            },
            {
              title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_5',
              action: data => Helpers.downloadImageWithDrawing(data),
              isDisabled: item => {
                return !item.isImage || isEmpty(item.Drawings);
              },
            },
            {
              title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.UNLINK_FILE',
              action: ({ SourceID, FileName }) => openDeleteFileModal(SourceID, Helpers.decodeIfStringEncoded(FileName), category[fields.id]),
              access: {
                id: ownerID,
                ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.notifications].create.name,
                visibleFor: [PERMISSIONS[PERMISSION_TYPES.notifications].edit.name],
              },
            },
          ]}
        />
      );
    });
  };

  const isDisabled = !selectedNotification || !selectedNotification[fields.id] || selectedNotification[fields.id] < 0;
  const formattedSourceDetailsData =
    initialValues[formConstants.fields.sourceID] && initialValues[formConstants.fields.sourceID] > 0
      ? { ...initialValues[formConstants.fields.sourceDetails], [formConstants.fields.id]: initialValues[formConstants.fields.sourceID] }
      : null;

  return (
    <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.notifications].edit.name} id={loggedByUserID} ownerRequiredPermission={PERMISSIONS[PERMISSION_TYPES.notifications].create.name}>
      {({ hasAccess }) => {
        const readonly = !hasAccess;
        return (
          <form className="defect-form overflow-inherit" onSubmit={handleSubmit} noValidate>
            <RenderIf if={showGeometryWarning}>
              <LocationExpandableSection
                customExpanded={locationObject?.visible}
                title={t('SECTION_TITLE.COMPONENT_LOCATION')}
                onToggleLocationSection={() => toggleElement(selectedNotification[fields.id])}
              >
                <LocationSection
                  viewer={viewer}
                  coordinates={locationObject?.Geometry?.coordinates || []}
                  invalid={false}
                  readonlyCamPos={formIsDisabled}
                  locationIsAdding={notificationLocationAdding}
                  locationIsEditing={notificationLocationEditing}
                  setLocationIsAdding={setNotificationLocationAdding}
                  setLocationIsEditing={isEditing => {
                    setNotificationLocationEditing(isEditing);
                    if (images360Ref) {
                      // Show/Hide all footprints
                      images360Ref.footprintsVisible = isEditing ? false : true;
                    }
                  }}
                  handleAddLocation={() => {
                    objectToolClick(objectTools.notification, '', false, e => {
                      const newSelectedNotification = { ...selectedNotification, [fields.geometry]: e[fields.geometry], [fields.camPosition]: e[fields.camPosition] };
                      handleChangeCameraUpdate(newSelectedNotification);
                      !formHasUnsavedChanges && setNotificationFormState({ hasUnsavedChanges: false });
                      setNotificationLocationAdding(false);
                      clearUnsavedChangesDirty();
                    });
                  }}
                  handleCameraChange={newValue => {
                    const newSelectedNotification = { ...selectedNotification, CameraPosition: { coordinates: newValue } };
                    handleChangeCameraUpdate(newSelectedNotification);
                    updateGeometry(newSelectedNotification);
                  }}
                  handleDeleteLocation={newCoordinates => {
                    toggleConfirmationModal(true, 'NOTIFICATION_DETAILS.DELETE_LOCATION_MODAL.TITLE', 'NOTIFICATION_DETAILS.DELETE_LOCATION_MODAL.DESC', () => handleDeleteLocation(newCoordinates));
                  }}
                  addingLocationDescription="NOTIFICATION_DETAILS.MARK_ON_3D.IN_PROGRESS.TEXT"
                  missingLocationDescription="NOTIFICATION_DETAILS.WARNING.MISSING_PIN.DESCRIPTION"
                  missingLocationErrorMessage="NOTIFICATION_DETAILS.MISSING_PIN.FORM_INVALID.ERROR_MESSAGE"
                  editLocationDescription="NOTIFICATION_DETAILS.MARK_ON_3D.EDIT.TEXT"
                />
              </LocationExpandableSection>
            </RenderIf>
            <ExpandableSection title={t('DETAILS')} expanded={true}>
              <ChangeStatusDropdown statuses={statuses} module={Object.assign({}, initialValues)} user={user} handleChangeStatus={onChangeStatus} disabled={formIsDisabled} />
              <div className="defect-form__fields">
                <Field
                  id={formConstants.fields.name}
                  name={formConstants.fields.name}
                  component={CustomInput}
                  placeholder={'NOTIFICATION_DETAILS.FORM_NAME'}
                  label={'NOTIFICATION_DETAILS.FORM_NAME'}
                  labelClass="defect-form__label text-transform-none "
                  type="text"
                  disabled={formIsDisabled}
                  size="lg"
                />
                <Field id={formConstants.fields.type} name={formConstants.fields.type} component={UneditableInfo} label="NOTIFICATION_DETAILS.FORM_TYPE" />
                <Field
                  id={formConstants.fields.severity}
                  name={formConstants.fields.severity}
                  minValue={1}
                  maxValue={10}
                  component={CustomInputRange}
                  placeholder={t('NOTIFICATION_DETAILS.FORM_SEVERITY')}
                  label={t('NOTIFICATION_DETAILS.FORM_SEVERITY')}
                  labelClass="f-secondary-dark defect-form__label defect-form__label--range"
                  hideBorder
                  type="range"
                  disabled={formIsDisabled}
                />
                <CustomProperties
                  isDisabled={formIsDisabled}
                  formName={FORMS.dynamicNotificationForm}
                  properties={properties}
                  getProperties={() => getProperties(notificationId)}
                  updateProperty={property => updateProperty(property)}
                  addProperty={data => addProperty(data)}
                  restProps={{ NotificationID: notificationId }}
                  deleteProperty={(data, callback) => deleteProperty(data, callback)}
                  getSuggestions={getSuggestions}
                  isEnhancedDesignCustomProp
                  noDebounceOnForm
                  triggerDeleteCallback={false}
                />
                <Field id={formConstants.fields.createdAt} name={formConstants.fields.createdAt} component={UneditableInfo} label="NOTIFICATION_DETAILS.FORM_CREATED_AT" />
                <Field id={formConstants.fields.createdBy} name={formConstants.fields.createdBy} component={UneditableInfo} label="NOTIFICATION_DETAILS.FORM_CREATED_BY" />
                <RenderIf if={generalStatus === statuses.actioned.value || generalStatus === statuses.closed.value}>
                  <Field id={formConstants.fields.actionedAt} name={formConstants.fields.actionedAt} component={UneditableInfo} label="NOTIFICATION_DETAILS.ACTIONED_AT" />
                  <Field id={formConstants.fields.actionedBy} name={formConstants.fields.actionedBy} component={UneditableInfo} label="NOTIFICATION_DETAILS.ACTIONED_BY" />
                </RenderIf>
                <RenderIf if={generalStatus === statuses.closed.value}>
                  <Field id={formConstants.fields.closedAt} name={formConstants.fields.closedAt} component={UneditableInfo} label="NOTIFICATION_DETAILS.CLOSED_AT" />
                  <Field id={formConstants.fields.closedBy} name={formConstants.fields.closedBy} component={UneditableInfo} label="NOTIFICATION_DETAILS.CLOSED_BY" />
                </RenderIf>
              </div>
            </ExpandableSection>
            <ExpandableSection title={t('COMPONENT')}>
              <ExpandableSectionInfo
                data={relatedComponents[0]}
                type={expandableSectionInfoTypes.components}
                onClick={data => handleActivePage(modules.components, data[fields.id])}
                defaultComponent={defaultComponent}
                emptyStateProps={{ emptyStateText: t('NOTIFICATIONS_FORM.COMPONENT_EMPTY_STATE') }}
              />
            </ExpandableSection>
            <ExpandableSection title={t('NOTIFICATIONS.SOURCE')}>
              <ExpandableSectionInfo
                data={formattedSourceDetailsData}
                type={expandableSectionInfoTypes.source}
                onClick={data => handleSourceClick(data)}
                emptyStateProps={{
                  emptyStateText: t('NOTIFICATIONS_FORM.SOURCE_EMPTY_STATE'),
                }}
              />
            </ExpandableSection>
            <div className="notification-details__files-section">
              <ExpandableSection
                title={t('SECTION_TITLE.COMPONENT_FILES')}
                handlePreventClick={isDisabled ? () => handleInputDisabled(preventedFieldsPriorComponentIsCreated.upload) : null}
                onEditClick={e => {
                  e.stopPropagation();
                  openEditFilesModal();
                }}
                hideEditAction={false}
              >
                {renderFilesSection(loggedByUserID, readonly, notificationFiles)}
              </ExpandableSection>
            </div>

            <div className="defect-form__save-cta-wrapper default-background">
              <Button type="submit" disabled={formIsDisabled || !formHasUnsavedChanges || !isEmpty(customPropertyFormErrors)} width="sm" height="md" text={t('SAVE')} />
            </div>
            <Modal {...editFilesModal} />
          </form>
        );
      }}
    </AccessRenderer>
  );
};

NotificationForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

const selector = formValueSelector(FORMS.notificationForm);
const mapStateToProps = (state, props) => {
  const loggedByUserID = selector(state, formConstants.fields.createdByID);

  return {
    viewer: state.potreeReducer.viewerInstance,
    notificationLocationAdding: state.inspectionReducer.notificationLocationAdding,
    notificationLocationEditing: state.inspectionReducer.notificationLocationEditing,
    images360Ref: state.inspectionReducer.images360Ref,
    selectedNotification: state.inspectionReducer.selectedDefect,
    projectDMSCategories: state.projectDetailsReducer.projectDMSCategories,
    loggedByUserID,
    customPropertyFormErrors: getFormSyncErrors(FORMS.dynamicNotificationForm)(state),
  };
};

const mapDispatchToProps = dispatch => ({
  setNotificationLocationAdding: val => dispatch(setNotificationLocationAdding(val)),
  setNotificationLocationEditing: val => dispatch(setNotificationLocationEditing(val)),
  updateGeometry: data => dispatch(updateElementGeometry(data)),
  setNotificationFormState: state => dispatch(setNotificationFormState(state)),
  clearUnsavedChangesDirty: () => dispatch(clearUnsavedChangesDirty()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: FORMS.notificationForm,
    validate,
    enableReinitialize: true,
  })(NotificationForm)
);
