import { cloneDeep, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import AccessRenderer from '../../../../../../common/access-renderer/components/access-renderer';
import { FORMS } from '../../../../../../common/constants';
import ExpandableSection from '../../../../../../common/expandable-section/components/expandable-section';
import Button from '../../../../../../common/form/components/button';
import ColorPalette from '../../../../../../common/form/components/color-pallete';
import CustomInput from '../../../../../../common/form/components/input';
import Textarea from '../../../../../../common/form/components/text-area';
import UneditableInfo from '../../../../../../common/form/components/uneditable-info';
import Helpers from '../../../../../../common/helpers';
import LocationExpandableSection from '../../../../../../common/location-expandable-section/components/location-expandable-section';
import Modal from '../../../../../../common/modal/components/modal';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../../../common/permissions-constants';
import RenderIf from '../../../../../../common/render-if/components/render-if';
import ActionModal from '../../../../../document-management/components/modals/action-modal/action-modal';
import { formConstants as componentConstants } from '../../../../constants/component-constants';
import { modules, objectTools } from '../../../../constants/constants';
import { expandableSectionInfoTypes } from '../../../../constants/notification-constants';
import '../../../../styles/measurement-location-form.scss';
import CreateGraphingGroupModal from '../../../readings-and-gauges/components/graphing-groups/create-graphing-group-modal';
import GraphingGroupsList from '../../../readings-and-gauges/components/graphing-groups/graphing-groups-list';
import TimeSeriesGraphModal from '../../../readings-and-gauges/components/time-series-graph-modal/time-series-graph-modal';
import { settingsFormConstants } from '../../../readings-and-gauges/constants/time-series-graph-constants';
import CriticalEquipmentInfo from '../../common/critical-equipment-info';
import DefectComponentPicker from '../../defect-details/components/defect-component-picker';
import LocationSection from '../../location-section';
import ExpandableSectionInfo from '../../notification-details/expandable-section-info';
import { formConstants, tabNames, textAreaMaxChars } from '../constants/measurement-location-constants';
import { validate } from '../validators/measurement-location-validator';
import MeasurementPointsSection from './measurement-points-section';
import TimeSeriesGraphSmallPreview from './time-series-graph-small-preview/time-series-graph-small-preview';

const MeasurementLocationForm = (props, { t }) => {
  const {
    handleSubmit,
    selectedMeasurementLocation,
    showGeometryWarning,
    formHasUnsavedChanges,
    viewer,
    locationObject,
    updateGeometry,
    measurementLocationObjectAdding,
    measurementLocationObjectEditing,
    setMeasurementLocationObjectAdding,
    setMeasurementLocationObjectEditing,
    images360Ref,
    objectToolClick,
    toggleConfirmationModal,
    defaultComponent,
    componentDetails,
    handleActivePage,
    handleComponentChange,
    setGenericNotification,
    openChangeMeasurementGroupModal,
    queryItem,
    projectID,
    inspectionDetails,
    user,
    saveGraphingGroup,
    fetchData,
    onChange,
    toggleMeasurementLocation,
    isGraphingGroupFormDirty,
    graphingGroupDetails,
    graphDetailsIsLoading,
    graphLoading,
    graphData,
    timePeriodsList,
    refetchGraphDetails,
    errorModalData,
    setMeasurementLocationDetailsErrorModal,
  } = props;
  const [confirmModal, setConfirmModal] = useState({ isOpen: false });
  const [modalData, setModalData] = useState({ isOpen: false });
  const [createGraphingGroupModal, setCreateGraphingGroupModal] = useState({ isOpen: false });
  const ownerID = selectedMeasurementLocation?.[formConstants.fields.createdBy];
  const canCreateMeasurementPoint = !Helpers.hasAccess({ user, visibleFor: [PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].pointCreate.name] });

  const handleDeleteLocation = newCoordinates => {
    const element = cloneDeep({ ...selectedMeasurementLocation, ...locationObject });
    element.Geometry.coordinates = newCoordinates;

    updateGeometry(element);
    toggleConfirmationModal(false);
  };

  const formatDate = input => {
    if (!input) return;

    return Helpers.getDateFromUnix(input);
  };

  const formatGroup = input => {
    // TODO: input === 'NO_GROUP' needs to be tweaked, we cannot rely solely on the string literal condition
    return input === 'NO_GROUP' ? t('NO_GROUP') : input;
  };

  const handleComponentClick = component => {
    if (component[componentConstants.fields.id] === defaultComponent[componentConstants.fields.id]) return;
    handleActivePage(modules.components, component[componentConstants.fields.id]);
  };

  const closeModal = () => {
    refetchGraphDetails();
    setModalData({ isOpen: false });
  };

  const handleChangedComponent = components => {
    const idToBeSent = components?.[0] ? components?.[0]?.[componentConstants.fields.id] : null;
    handleComponentChange(idToBeSent);
    closeModal();
  };

  const openComponentPickerModal = e => {
    e?.stopPropagation();
    setModalData({
      isOpen: true,
      type: '',
      customClassName: 'pick-and-link-components-modal modal-large',
      customClassWrapperName: 'pick-and-link-components-modal__picker',
      closeAction: closeModal,
      customCloseAction: closeModal,
      CustomContent: dynamicProps => <DefectComponentPicker {...dynamicProps} />,
      title: t('MEASUREMENT_LOCATION_DETAILS.EDIT_COMPONENTS_MODAL.TITLE'),
      setGenericNotification: setGenericNotification,
      component: {
        ...componentDetails,
        [formConstants.fields.componentName]: componentDetails?.[componentConstants.fields.name],
        [formConstants.fields.componentCode]: componentDetails?.[componentConstants.fields.code],
        [formConstants.fields.componentId]: componentDetails?.[componentConstants.fields.id],
      },
      customConfirmAction: handleChangedComponent,
    });
  };

  const openTimeSeriesGraphPreviewModal = e => {
    e?.stopPropagation();
    setModalData({
      isOpen: true,
      customClassName: 'alarms-edit-modal modal-large ',
      customClassWrapperName: 'alarms-edit-modal__picker',
      type: '',
      closeAction: closeModal,
      customCloseAction: closeModal,
      CustomContent: dynamicProps => <TimeSeriesGraphModal {...dynamicProps} />,
      title: t('MEASUREMENT_LOCATION_DETAILS.TIME_SERIES_GRAPH_MODAL.TITLE'),
      measurementLocationID: queryItem,
      createdBy: ownerID,
    });
  };

  const openCreateGraphingGroupModal = (e, handleFormSubmit = () => null, selectedGroup = null, shouldRefreshGraph = false) => {
    e?.stopPropagation();

    setCreateGraphingGroupModal({
      isOpen: true,
      customClassName: 'alarms-edit-modal modal-large graphing-modal',
      customClassWrapperName: 'alarms-edit-modal__picker',
      type: '',
      CustomContent: dynamicProps => <CreateGraphingGroupModal {...dynamicProps} />,
      title: t('MEASUREMENT_LOCATION_DETAILS.CREATE_GRAPHING_GROUP_MODAL.TITLE'),
      measurementLocation: selectedMeasurementLocation,
      graphingGroup: selectedGroup,
      projectID: projectID,
      handleFormSubmit,
    });
  };

  const openConfirmModal = isDirty => {
    const closeModal = () => {
      setCreateGraphingGroupModal({ isOpen: false });
      setConfirmModal({ isOpen: false });
    };
    if (isDirty) {
      setConfirmModal({
        isOpen: true,
        customClassName: 'modal-medium confirmation-modal confirm-graphing-group-modal',
        CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
        closeAction: () => setConfirmModal({ isOpen: false }),
        type: '',
        title: t('UNSAVED_CHANGES.GRAPHING_GROUP_CREATE.TITLE'),
        firstParagraph: t('UNSAVED_CHANGES.GRAPHING_GROUP_CREATE.DESC'),
        closeButtonText: t('CANCEL'),
        confirmButtonText: t('LEAVE'),
        customCloseAction: () => setConfirmModal({ isOpen: false }),
        customConfirmAction: closeModal,
      });
    } else {
      setCreateGraphingGroupModal({ isOpen: false });
    }
  };

  const openErrorModal = (err, modalProps = {}) => {
    const defaultTitle = t(Helpers.getErrorContent(err));
    const {
      title = defaultTitle,
      firstParagraph = defaultTitle,
      firstParagraphProps = {},
      closeButtonText = 'CANCEL',
      secondParagraph,
      confirmButtonText = 'MEASUREMENT_LOCATION_DETAILS.DUPLICATE_GRAPH_ERROR.CONFIRM_BUTTON_TEXT_CREATE',
    } = modalProps;
    const closeModal = () => {
      setMeasurementLocationDetailsErrorModal({ isOpen: false });
    };
    setMeasurementLocationDetailsErrorModal({
      isOpen: true,
      customClassName: 'modal-medium confirmation-modal',
      CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
      closeAction: closeModal,
      type: '',
      title,
      firstParagraph,
      firstParagraphProps,
      secondParagraph,
      closeButtonText,
      confirmButtonText,
      customCloseAction: closeModal,
      customConfirmAction: () => {
        closeModal();
        setCreateGraphingGroupModal({ isOpen: false });
      },
    });
  };

  const openGraphingGroupsModal = e => {
    e?.stopPropagation();
    setModalData({
      isOpen: true,
      customClassName: 'alarms-edit-modal modal-large',
      customClassWrapperName: 'alarms-edit-modal__picker',
      type: '',
      closeAction: closeModal,
      customCloseAction: closeModal,
      CustomContent: dynamicProps => (
        <div className="measurement-location-form__graphing-groups-modal">
          <div className="measurement-location-form__graphing-groups-modal__content-container">
            <GraphingGroupsList {...dynamicProps} />
          </div>
          <div className="measurement-location-form__graphing-groups-modal__actions-container">
            <Button type="button" variant="gray-outline" text={t('CLOSE')} onClick={closeModal} height="md" width="sm" />
          </div>
        </div>
      ),
      title: t('MEASUREMENT_LOCATION_DETAILS.GRAPHING_GROUPS_MODAL.TITLE'),
      measurementLocation: selectedMeasurementLocation,
      handleSaveGraphingGroupError: (err, isEdit) => {
        const errData = Helpers.getErrorData(err);
        const errCode = Helpers.getErrorCode(err);

        // 6543 duplicate graph custom error
        const modalProps =
          errCode === 6543 && !isEmpty(errData?.Name)
            ? {
                firstParagraph: 'MEASUREMENT_LOCATION_DETAILS.DUPLICATE_GRAPH_ERROR.FIRST_PARAGRAPH',
                firstParagraphProps: { name: errData.Name },
              }
            : {};
        openErrorModal(err, {
          ...modalProps,
          confirmButtonText: isEdit ? 'MEASUREMENT_LOCATION_DETAILS.DUPLICATE_GRAPH_ERROR.CONFIRM_BUTTON_TEXT_EDIT' : 'MEASUREMENT_LOCATION_DETAILS.DUPLICATE_GRAPH_ERROR.CONFIRM_BUTTON_TEXT_CREATE',
        });
      },
      handleSaveGraphingGroupSuccess: () => {
        openConfirmModal(false);
        refetchGraphDetails();
      },
      handleEditGraphingGroup: (e, onSubmit, group) => openCreateGraphingGroupModal(e, onSubmit, group),
    });
  };

  const handleSaveGraphingGroup = values => {
    const handleSaveSuccess = () => {
      fetchData(tabNames.details);
      openConfirmModal(false);
      refetchGraphDetails();
    };

    const handleSaveError = err => {
      const errData = Helpers.getErrorData(err);
      const errCode = Helpers.getErrorCode(err);

      // 6543 duplicate graph custom error
      const modalProps =
        errCode === 6543 && !isEmpty(errData?.Name)
          ? {
              firstParagraph: 'MEASUREMENT_LOCATION_DETAILS.DUPLICATE_GRAPH_ERROR.FIRST_PARAGRAPH',
              firstParagraphProps: { name: errData.Name },
            }
          : {};
      openErrorModal(err, modalProps);
    };

    saveGraphingGroup(
      {
        ...values,
        MeasurementLocationID: selectedMeasurementLocation[formConstants.fields.id],
        GraphID: values[formConstants.fields.id] || undefined,
        Aggregations: values[settingsFormConstants.aggregation.name],
      },
      handleSaveSuccess,
      handleSaveError
    );
  };

  const containsCriticalEquipment = componentDetails && componentDetails[formConstants.fields.critical];

  return (
    <AccessRenderer
      visibleFor={[PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].locationEdit.name]}
      id={ownerID}
      ownerRequiredPermission={PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].locationCreate.name}
    >
      {({ hasAccess }) => {
        const readonly = !hasAccess;

        return (
          <>
            <form className="defect-form measurement-location-form overflow-inherit" onSubmit={handleSubmit} noValidate onChange={onChange}>
              <div className="measurement-location-form__fields-container">
                <div className="measurement-location-form__fields-container--wrapper">
                  <RenderIf if={containsCriticalEquipment}>
                    <CriticalEquipmentInfo title={'CRITICAL_EQUIPMENT.TITLE'} paragraph={'CRITICAL_EQUIPMENT_MEASUREMENT_LOCATION.PARAGRAPH'} />
                  </RenderIf>
                  <ExpandableSection hideEditAction={readonly} onEditClick={openGraphingGroupsModal} expanded={true} title={t('SECTION_TITLE.TIME_SERIES_GRAPH')}>
                    <TimeSeriesGraphSmallPreview
                      readonly={readonly}
                      handleViewMore={openTimeSeriesGraphPreviewModal}
                      handleCreateGraphingGroup={e => openCreateGraphingGroupModal(e, handleSaveGraphingGroup, null, true)}
                      graphDetailsIsLoading={graphDetailsIsLoading}
                      graphLoading={graphLoading}
                      graphData={graphData}
                      graphDetails={graphingGroupDetails}
                      timePeriodsList={timePeriodsList}
                    />
                  </ExpandableSection>
                  <RenderIf if={showGeometryWarning}>
                    <LocationExpandableSection
                      customExpanded={locationObject?.visible}
                      title={t('SECTION_TITLE.COMPONENT_LOCATION')}
                      onToggleLocationSection={() => toggleMeasurementLocation(selectedMeasurementLocation[formConstants.fields.id])}
                    >
                      <LocationSection
                        viewer={viewer}
                        coordinates={locationObject?.Geometry?.coordinates || []}
                        invalid={readonly}
                        readonlyCamPos={readonly}
                        locationIsAdding={measurementLocationObjectAdding}
                        locationIsEditing={measurementLocationObjectEditing}
                        setLocationIsAdding={setMeasurementLocationObjectAdding}
                        setLocationIsEditing={isEditing => {
                          setMeasurementLocationObjectEditing(isEditing);
                          if (images360Ref) {
                            // Show/Hide all footprints
                            images360Ref.footprintsVisible = isEditing ? false : true;
                          }
                        }}
                        handleAddLocation={() => {
                          setMeasurementLocationObjectAdding(true);
                          objectToolClick(objectTools.rgMeasurementLocation, null, false, () => setMeasurementLocationObjectAdding(false));
                        }}
                        handleCameraChange={newValue => {
                          const element = cloneDeep({ ...selectedMeasurementLocation, ...locationObject });
                          element.CameraPosition.coordinates = newValue;

                          updateGeometry(element);
                        }}
                        handleDeleteLocation={newCoordinates => {
                          toggleConfirmationModal(true, t('MEASUREMENT_LOCATION_DETAILS.DELETE_LOCATION_MODAL.TITLE'), t('MEASUREMENT_LOCATION_DETAILS.DELETE_LOCATION_MODAL.DESC'), () =>
                            handleDeleteLocation(newCoordinates)
                          );
                        }}
                        addingLocationDescription="MEASUREMENT_LOCATION_DETAILS.MARK_ON_3D.IN_PROGRESS.TEXT"
                        missingLocationDescription="MEASUREMENT_LOCATION_DETAILS.WARNING.MISSING_PIN.DESCRIPTION"
                        missingLocationErrorMessage="MEASUREMENT_LOCATION_DETAILS.MISSING_PIN.FORM_INVALID.ERROR_MESSAGE"
                        editLocationDescription="MEASUREMENT_LOCATION_DETAILS.MARK_ON_3D.EDIT.TEXT"
                      />
                    </LocationExpandableSection>
                  </RenderIf>
                  <ExpandableSection expanded={true} title={t('DETAILS')}>
                    <Field
                      id={formConstants.fields.measurementGroup}
                      name={formConstants.fields.measurementGroup}
                      component={UneditableInfo}
                      labelClass="inspection-input__label f-secondary-dark defect-form__label"
                      label="MEASUREMENT_LOCATION_DETAILS.FORM_GROUP"
                      withTopRightButton
                      topRightButtonAction={openChangeMeasurementGroupModal}
                      topRightButtonText="COMPONENT_DETAILS.FORM_HIERARCHY_CHANGE"
                      textClassName="break-text"
                      disabled={readonly}
                      format={formatGroup}
                    />
                    <Field
                      id={formConstants.fields.name}
                      name={formConstants.fields.name}
                      disabled={readonly}
                      component={CustomInput}
                      placeholder={t('MEASUREMENT_LOCATION_DETAILS.FORM_NAME')}
                      label={t('MEASUREMENT_LOCATION_DETAILS.FORM_NAME')}
                      labelClass="defect-form__label"
                      type="text"
                      size="lg"
                      isRequired
                    />
                    <Field
                      id={formConstants.fields.description}
                      name={formConstants.fields.description}
                      disabled={readonly}
                      component={Textarea}
                      placeholder={'MEASUREMENT_LOCATION_DETAILS.FORM_DESCRIPTION'}
                      label={'MEASUREMENT_LOCATION_DETAILS.FORM_DESCRIPTION'}
                      labelClass="defect-form__label"
                      maxChars={textAreaMaxChars}
                      enableAutoResize={true}
                      wrapperClassName={''}
                    />
                    <Field id={formConstants.fields.color} name={formConstants.fields.color} disabled={readonly} component={ColorPalette} label={t('MEASUREMENT_LOCATION_DETAILS.COLOR_TAG')} />
                    <Field
                      id={formConstants.fields.createdDate}
                      disabled={true}
                      name={formConstants.fields.createdDate}
                      component={UneditableInfo}
                      format={formatDate}
                      label={t('MEASUREMENT_LOCATION_DETAILS.FORM_DATE')}
                      labelClass=" defect-form__label"
                      type="text"
                    />
                    <Field
                      id={formConstants.fields.createdByUser}
                      disabled={true}
                      name={formConstants.fields.createdByUser}
                      component={UneditableInfo}
                      placeholder={t('MEASUREMENT_LOCATION_DETAILS.FORM_CREATED_BY')}
                      label={t('MEASUREMENT_LOCATION_DETAILS.FORM_CREATED_BY')}
                      labelClass="defect-form__label"
                      type="text"
                    />
                  </ExpandableSection>
                  <ExpandableSection title={t('INSPECTION_COMPONENTS')} className="source-section" hideEditAction={readonly} onEditClick={openComponentPickerModal} expanded={true}>
                    <ExpandableSectionInfo
                      data={componentDetails}
                      type={expandableSectionInfoTypes.components}
                      onClick={handleComponentClick}
                      defaultComponent={defaultComponent}
                      emptyStateProps={{
                        emptyStateText: t('NO_EQUIPMENT_EMPTY_STATE'),
                        buttonAction: openComponentPickerModal,
                        buttonText: t('WORK_ORDER.ADD_COMPONENTS'),
                        showButton: !readonly,
                      }}
                    />
                  </ExpandableSection>
                  <ExpandableSection title={t('MEASUREMENT_LOCATION_DETAILS.MEASUREMENT_POINTS')} className="source-section" hideEditAction={true} expanded={true}>
                    <MeasurementPointsSection
                      queryItem={queryItem}
                      projectID={projectID}
                      handleActivePage={handleActivePage}
                      inspectionDetails={inspectionDetails}
                      selectedMeasurementLocation={selectedMeasurementLocation}
                      readonly={canCreateMeasurementPoint}
                      user={user}
                      selectedMeasurementLocationComponent={componentDetails}
                    />
                  </ExpandableSection>
                </div>
              </div>
              <div className="defect-form__save-cta-wrapper default-background">
                <Button type="submit" width="sm" height="md" text={t('SAVE')} disabled={!formHasUnsavedChanges} />
              </div>
            </form>
            <Modal {...modalData} />
            <Modal {...createGraphingGroupModal} closeAction={() => openConfirmModal(isGraphingGroupFormDirty)} customCloseAction={() => openConfirmModal(isGraphingGroupFormDirty)} />
            <Modal {...confirmModal} />
            <Modal {...errorModalData} />
          </>
        );
      }}
    </AccessRenderer>
  );
};

MeasurementLocationForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: FORMS.measurementLocationForm,
  validate,
  enableReinitialize: true,
})(MeasurementLocationForm);
