import { debounce, find, get, some } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Button from '../../../../../../../common/form/components/button';
import Helpers from '../../../../../../../common/helpers';
import { notificationModalCustom } from '../../../../../../../common/modal/actions/modal-actions';
import Modal from '../../../../../../../common/modal/components/modal';
import StatusChangeNoteModal from '../../../../../../../common/modal/components/status-change-note-modal';
import { ComponentPicker3D, ComponentPickerCH, ComponentPickerPDF } from '../../../../../../../common/question-components/components';
import ScreenshotPicker from '../../../../../../../common/question-screenshots/components/screenshot-picker';
import { fetchComponents } from '../../../../../../checklists-procedures-management/actions/checklists-procedures-management-actions';
import { defaultContractorsFilter } from '../../../../../../contractor-management/constants/constants';
import UploadAssetsModal from '../../../../../../project/components/upload-assets-modal';
import { setSingleUploadItem, setUploadItems, uploadAsset } from '../../../../../../upload/actions/upload-actions';
import uploadConstants from '../../../../../../upload/constants/constants';
import { fields as isolationCertificateTableFields, statuses } from '../../../../../components/isolation-certificates/constants/isolation-certificates-table';
import { measurementTypes, moduleSectionsFields } from '../../../../../constants/constants';
import InspectionRenderer from '../../../../left-toolbar/inspection-renderer';
import WorkOrderFiles from '../../../../work-order/work-order-files';
import ChangeStatusDropdown from '../../../common/change-status-dropdown';
import ChangeStatusModal from '../../../common/change-status-modal';
import ModuleSections from '../../../common/module-sections';
import {
  addIsolationComponent,
  assignKeyBox,
  changeIsolationCertificateStatus,
  changeIsolationCertificateStatusWithData,
  deleteIsolationComponent,
  generateAndDownloadPDFPopulatedDrawings,
  getContractors,
  getIsolationCertificateDetails,
  updateIsolationCertificateDetails,
  updateIsolationComponent,
} from '../../actions/isolation-certificate-details-actions';
import { imagesValidation, fields as sectionFields } from '../../constants/constants';
import { componentFetchParams, defaultValues, fileFields, fields as isolationComponentsFields } from '../../constants/isolation-components-constants';
import { customSectionDisabledStatuses, sections } from '../../constants/section-constants';
import IsolationComponentsModal from './isolation-components-modal';
import IsolationComponentsOrderModal from './isolation-components-order-modal';

class IsolationCertificateSections extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actionModalData: {
        isOpen: false,
      },
      sectionComponentsModalData: {
        isOpen: false,
      },
      uploadModalData: {
        isOpen: false,
      },
      componentSelectionModal: {
        isOpen: false,
      },
      screenShotPickerModal: {
        isOpen: false,
      },
      componentsOrderModal: {
        isOpen: false,
      },
      changeStatusModal: {
        isOpen: false,
      },
      changeStatusConfirmModal: {
        isOpen: false,
      },
      statusChangeNoteModalData: {
        isOpen: false,
      },
      components: [],
      isDownloadInProgress: false,
      changeStatusInProgress: false,
      isEditFilesSectionClicked: false,
    };

    this.handleSectionsChangeDebounce = debounce(this.handleSectionsChange, 600);
    this.handleUpdateIsolationComponentDebounce = debounce(this.handleUpdateIsolationComponent, 600);
  }

  handleSectionsChange = (data, moduleAction) => {
    const { queryItem } = this.props;
    const dispatchAction = this.props[moduleAction];

    if (dispatchAction) {
      if (data.values) {
        data.values[sectionFields.isolationCertificateId] = queryItem;
      }

      dispatchAction(data);
    }
  };

  handleComponentSelected = (isolationCertificateId, components) => {
    if (!components || !components.length) {
      return;
    }

    const { addIsolationComponent, handleSuccessManipulateIsolationComponent } = this.props;
    const component = components[0];
    const values = {
      ...defaultValues,
      [isolationComponentsFields.isolationCertificate.name]: isolationCertificateId,
      [isolationComponentsFields.component.name]: component[isolationComponentsFields.componentId.name],
    };

    addIsolationComponent(values, isolationComponent =>
      handleSuccessManipulateIsolationComponent(isolationCertificateId, {
        ...isolationComponent,
        [isolationComponentsFields.componentName.name]: component[isolationComponentsFields.componentName.name],
      })
    );
  };

  handleUpdateIsolationComponent = values => {
    const { updateIsolationComponent, handleSuccessManipulateIsolationComponent } = this.props;

    updateIsolationComponent(values, () => handleSuccessManipulateIsolationComponent(values[isolationComponentsFields.isolationCertificate.name], values, true));
  };

  handleDeleteIsolationComponent = (isolationCertificateId, componentId) => {
    const { deleteIsolationComponent, handleSuccessDeleteIsolationComponent } = this.props;
    const values = {
      [isolationComponentsFields.isolationCertificate.name]: isolationCertificateId,
      [isolationComponentsFields.component.name]: componentId,
    };

    deleteIsolationComponent(values, () => handleSuccessDeleteIsolationComponent(isolationCertificateId, componentId));
  };

  formatIsolationComponents = components => {
    if (components && components.length > 0) {
      return components.map(item => {
        return { ...item, ID: item.ComponentID, ComponentName: item.Name };
      });
    }

    return [];
  };

  toggleIsolationComponentsModal = (module, section) => {
    const { t } = this.context;
    const { queryItem, fetchComponents, inspectionId, getIsolationCertificateDetails, handleSuccessFetchIsolationCertificate } = this.props;
    const closeAction = () => {
      // refresh isolation certificate details
      getIsolationCertificateDetails(queryItem, (_, certificate) => handleSuccessFetchIsolationCertificate(false, certificate));
      this.setState({ sectionComponentsModalData: { isOpen: false }, components: [] });
    };

    // fetches the latest certificate details including updated isolations
    getIsolationCertificateDetails(queryItem, (_, certificate) => handleSuccessFetchIsolationCertificate(false, certificate));

    fetchComponents({ ...componentFetchParams, InspectionID: parseInt(inspectionId, 10) }, components => this.setState({ components }));

    const modalData = {
      isOpen: true,
      title: t('ISOLATION_CERTIFICATE.EDIT_ISOLATIONS'),
      CustomContent: dynamicProps => <IsolationComponentsModal module={module} section={section} closeAction={closeAction} {...dynamicProps} />,
      type: 'none',
      customClassName: 'isolation-certificate-default-modal',
      closeAction,
    };

    this.setState({ sectionComponentsModalData: modalData });
  };

  handleComponentPicker3D = () => {
    const { inspectionId } = this.props;
    const closeAction = () => {
      this.setState({ componentSelectionModal: { isOpen: false } });
    };

    this.setState({
      componentSelectionModal: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <ComponentPicker3D
            {...dynamicProps}
            handleComponentSelected={this.handleComponentSelected}
            handleDeleteComponent={this.handleDeleteIsolationComponent}
            inspectionId={inspectionId}
            closeAction={closeAction}
          />
        ),
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        closeAction,
      },
    });
  };

  handleComponentPickerPDF = () => {
    const { inspectionId } = this.props;
    const closeAction = () => {
      this.setState({ componentSelectionModal: { isOpen: false } });
    };

    this.setState({
      componentSelectionModal: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <ComponentPickerPDF
            {...dynamicProps}
            handleComponentSelected={this.handleComponentSelected}
            handleDeleteComponent={this.handleDeleteIsolationComponent}
            inspectionId={inspectionId}
            closeAction={closeAction}
          />
        ),
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        closeAction,
      },
    });
  };

  handleComponentPickerCH = () => {
    const { inspectionId } = this.props;
    const { t } = this.context;
    const closeAction = () => {
      this.setState({ componentSelectionModal: { isOpen: false } });
    };

    this.setState({
      componentSelectionModal: {
        isOpen: true,
        CustomContent: dynamicProps => (
          <ComponentPickerCH
            {...dynamicProps}
            handleComponentSelected={this.handleComponentSelected}
            handleDeleteComponent={this.handleDeleteIsolationComponent}
            inspectionId={inspectionId}
            closeAction={closeAction}
          />
        ),
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        title: t('QUESTION_COMPONENT_PICKER.CHOOSE_COMPONENTS'),
        closeAction,
        customCloseAction: closeAction,
      },
    });
  };

  handleSectionActionClicked = (action, module, section) => {
    if (this[action] && typeof this[action] === 'function') {
      this[action](module, section);
    }
  };

  handleScreenshotFrom3D = componentId => {
    const { inspectionId } = this.props;

    const closeAction = () => {
      this.setState({ screenShotPickerModal: { isOpen: false }, componentId: null });
    };

    const onDropAsset = (files, _certificateId, uploadGroup) => {
      const { screenShotPickerModal } = this.state;

      this.onDropAsset(files, screenShotPickerModal?.componentId, uploadGroup);
    };

    this.setState({
      screenShotPickerModal: {
        isOpen: true,
        componentId,
        CustomContent: dynamicProps => <ScreenshotPicker {...dynamicProps} inspectionId={inspectionId} onDropAsset={onDropAsset} closeAction={closeAction} />,
        customClassName: 'modal-no-max-height modal-large',
        type: 'none',
        closeAction,
      },
    });
  };

  isValid = (items, files) => {
    const { notificationModalCustom } = this.props;

    const allFiles = items.concat(files);

    if (allFiles && allFiles.length > imagesValidation.maxFiles) {
      notificationModalCustom(
        {
          key: 'WORK_ORDER_CREATE_VALIDATION.VALIDATION_MAX_FILES',
          props: { maxFiles: imagesValidation.maxFiles, current: files && files.length ? files.length : 0 },
        },
        'WORK_ORDER_CREATE_VALIDATION.VALIDATION_FILE_SIZE_TITLE'
      );

      return false;
    }

    const hasLargeFiles = some(items, item => item.size > imagesValidation.bytes);

    if (hasLargeFiles) {
      //open modal with message
      notificationModalCustom(
        {
          key: 'WORK_ORDER_CREATE_VALIDATION.VALIDATION_FILE_SIZE',
          props: { fileSize: `${imagesValidation.value}${imagesValidation.unit}` },
        },
        'WORK_ORDER_CREATE_VALIDATION.VALIDATION_FILE_SIZE_TITLE'
      );
      return false;
    }

    return true;
  };

  onDropAsset = (files, componentId, uploadGroup) => {
    const { isolationCertificateDetails } = this.props;
    let addedFiles = [];
    if (componentId) {
      const component = find(isolationCertificateDetails?.[isolationCertificateTableFields.components], { [isolationComponentsFields.component.name]: componentId });
      if (component) {
        addedFiles = component[isolationComponentsFields.files.name];
      }
    } else {
      addedFiles = isolationCertificateDetails?.[moduleSectionsFields.files];
    }

    if (!this.isValid(files, Object.assign([], addedFiles))) {
      return;
    }

    this.setState({ isLoading: true });
    const { uploadAsset, setUploadItems, setSingleUploadItem, location, queryItem, getIsolationCertificateDetails, handleSuccessFetchIsolationCertificate } = this.props;
    const { query } = location;
    const projectId = get(query, 'project_id');

    const tmp_files = files.map((file, index) => {
      const tmp_file = {
        type: Helpers.getFileType(file.type),
        mimeType: file.type,
        title: '',
        description: '',
        real_name: Helpers.formatFileName(file.name),
      };
      file.timeStamp = Date.now();
      file.real_name = tmp_file.real_name;
      file.uploadType = uploadConstants.uploadType.isolationComponentFiles;

      const uploadPayload = { IsolationCertificateID: queryItem, ProjectID: parseInt(projectId, 10), UploadGroup: uploadGroup };
      if (componentId) {
        uploadPayload.ComponentID = componentId;
      }
      uploadAsset(file, uploadPayload, setSingleUploadItem, index, () => {
        getIsolationCertificateDetails(queryItem, (_, certificate) => handleSuccessFetchIsolationCertificate(false, certificate));
      });
      return { ...tmp_file, ...file };
    });

    setUploadItems(tmp_files);
  };

  getFiles = componentId => {
    if (!componentId) {
      return [];
    }

    const { isolationCertificateDetails } = this.props;

    if (isolationCertificateDetails && isolationCertificateDetails[moduleSectionsFields.components]) {
      const component = find(isolationCertificateDetails[moduleSectionsFields.components], { [isolationComponentsFields.component.name]: componentId });

      if (component) {
        return component[isolationComponentsFields.files.name] || [];
      }
    }

    return [];
  };

  toggleUploadModal = (componentId, uploadGroup = uploadConstants.uploadGroup.otherFiles) => {
    const { t } = this.context;
    const { setUploadItems } = this.props;

    const confirmAction = () => {
      setUploadItems([]);

      this.setState({
        uploadModalData: {
          isOpen: false,
        },
      });
    };

    setUploadItems([]);

    this.setState(prevState => ({
      uploadModalData: {
        ...prevState.uploadModalData,
        title: t('CONTRACTOR_MODAL.FILES_UPLOAD.TITLE'),
        customClassName: 'project-assets-modal modal-small',
        CustomContent: () => (
          <UploadAssetsModal
            onDrop={files => this.onDropAsset(files, componentId, uploadGroup)}
            className="project-dropzone"
            labelWhite={t('CONTRACTOR_MODAL.FILES_UPLOAD.TITLE')}
            labelGreen={t('CONTRACTOR_MODAL.FILES_UPLOAD.BROWSE')}
          />
        ),
        isOpen: true,
        type: 'ok',
        confirmAction: confirmAction,
        closeAction: confirmAction,
      },
    }));
  };

  toggleComponentsOrderModal = isOpen => {
    const { t } = this.context;
    let componentsOrderModal = {
      isOpen,
    };

    if (isOpen) {
      componentsOrderModal = {
        ...componentsOrderModal,
        ...{
          title: t('ISOLATION_CERTIFICATE.ORDER_MODAL_TITLE'),
          CustomContent: dynamicProps => <IsolationComponentsOrderModal {...dynamicProps} closeAction={() => this.toggleComponentsOrderModal(false)} />,
          type: 'none',
          customClassName: 'isolation-components-order-modal',
          closeAction: () => this.toggleComponentsOrderModal(false),
        },
      };
    }

    this.setState({ componentsOrderModal });
  };

  handleChangeStatusWithData = values => {
    this.setState({ changeStatusInProgress: true });
    const { queryItem, isolationCertificates, changeIsolationCertificateStatusWithData, filters } = this.props;
    values[isolationComponentsFields.isolationCertificate.name] = queryItem;

    changeIsolationCertificateStatusWithData(values, Object.assign([], isolationCertificates), filters, isSuccess => {
      if (isSuccess) {
        const { queryItem, getIsolationCertificateDetails, handleSuccessFetchIsolationCertificate } = this.props;
        getIsolationCertificateDetails(queryItem, handleSuccessFetchIsolationCertificate);

        this.setState({ changeStatusModal: { isOpen: false }, changeStatusConfirmModal: { isOpen: false }, changeStatusInProgress: false });
      } else {
        this.setState({ changeStatusInProgress: false });
      }
    });
  };

  handleChangeStatus = data => {
    const { t } = this.context;
    const { isolationCertificateDetails } = this.props;
    const { queryItem, isolationCertificates, changeIsolationCertificateStatus, filters } = this.props;
    data[isolationComponentsFields.isolationCertificate.name] = queryItem;

    if (data?.isRegularStatus) {
      const openStatusChangeNoteModal = values => {
        this.setState({
          statusChangeNoteModalData: {
            title: t('STATUS_CHANGE_NOTE_MODAL.REJECT_TITLE'),
            content: t('STATUS_CHANGE_NOTE_MODAL.ISOLATION_CERTIFICATE_REJECTED_CONTENT'),
            confirmButtonLabel: t('STATUS_CHANGE_NOTE_MODAL.MARK_AS_REJECTED'),
            isOpen: true,
            confirmAction: comment => {
              data = { ...data, Comment: comment.Comment };
              confirmChangeStatus();
            },
            closeAction: () => this.setState({ statusChangeNoteModalData: { isOpen: false }, changeStatusConfirmModal: { isOpen: false } }),
          },
        });
      };

      const confirmChangeStatus = () => {
        this.setState({ changeStatusInProgress: true });
        changeIsolationCertificateStatus(data, Object.assign([], isolationCertificates), filters, isSuccess => {
          if (isSuccess) {
            const { queryItem, getIsolationCertificateDetails, handleSuccessFetchIsolationCertificate } = this.props;
            getIsolationCertificateDetails(queryItem, handleSuccessFetchIsolationCertificate);

            this.setState({ changeStatusConfirmModal: { isOpen: false }, statusChangeNoteModalData: { isOpen: false }, changeStatusInProgress: false });
          } else {
            this.setState({ changeStatusInProgress: false });
          }
        });
      };

      const modalData = {
        isOpen: true,
        title: t(data.modalTitle),
        content: t('ISOLATION_CERTIFICATE_STATUS.CONFIRM_MODAL_CONTENT', { status: t(data.title) }),
        type: 'yes-no',
        customClassName: 'change-status-confirm-modal',
        // if IC is marked as rejected, display the Rejection Note modal
        confirmAction: () => (data[moduleSectionsFields.status] === statuses.rejected.value ? openStatusChangeNoteModal(data) : confirmChangeStatus()),
        closeAction: () => this.setState({ changeStatusConfirmModal: { isOpen: false } }),
      };

      this.setState({ changeStatusConfirmModal: modalData });
    } else {
      const { t } = this.context;
      const { projectId, getContractors } = this.props;
      const closeAction = () => {
        this.setState({ changeStatusModal: { isOpen: false } });
      };

      const contractorsParams = {
        ...defaultContractorsFilter,
        projectId,
        PerPage: Number.MAX_SAFE_INTEGER,
        ActiveFilter: true,
        ExcludeExpired: false,
      };

      getContractors(contractorsParams, contractors => {
        const modalData = {
          isOpen: true,
          title: t(data.modalTitle),
          CustomContent: dynamicProps => (
            <ChangeStatusModal
              closeAction={closeAction}
              contractors={contractors}
              isolationComponents={isolationCertificateDetails[moduleSectionsFields.components]}
              status={data}
              signatureRequired={isolationCertificateDetails.SignatureRequired}
              handleStatusChanged={this.handleChangeStatusWithData}
              changeStatusDisclaimer={''}
              {...dynamicProps}
            />
          ),
          type: 'none',
          customClassName: 'modules-change-status-modal-default',
          closeAction,
        };

        this.setState({ changeStatusModal: modalData });
      });
    }
  };

  toggleConfirmationModal = (isOpen, title, confirmAction) => {
    const { t } = this.context;

    if (isOpen) {
      const handleConfirmAction = () => {
        if (typeof confirmAction === 'function') {
          confirmAction();
        }
      };

      this.setState({
        actionModalData: {
          isOpen: true,
          title: t(title),
          type: 'cancel-delete',
          customClassName: 'isolation-confirm-modal',
          confirmAction: handleConfirmAction,
          closeAction: () => this.setState({ actionModalData: { isOpen: false } }),
        },
      });
    } else {
      this.setState({ actionModalData: { isOpen: false } });
    }
  };

  openFilesModalData = (uploadGroup, supportedMimeTypes, onUpload) => {
    const { t } = this.context;
    const { setUploadItems } = this.props;

    const confirmAction = () => {
      setUploadItems([]);

      this.setState({
        uploadModalData: {
          isOpen: false,
        },
      });
    };

    this.setState({
      uploadModalData: {
        title: t('REGIME_FILES_UPLOAD_MODAL.TITLE'),
        customClassName: 'project-assets-modal modal-small',
        CustomContent: () => (
          <UploadAssetsModal
            onDrop={files => this.onDropAsset(files, null, uploadGroup, onUpload)}
            className="project-dropzone"
            labelWhite={t('REGIME_FILES_UPLOAD_MODAL.TITLE')}
            labelGreen={t('REGIME_FILES_UPLOAD_MODAL.BROWSE')}
            supportedMimeTypes={supportedMimeTypes}
          />
        ),
        isOpen: true,
        type: 'ok',
        confirmAction: confirmAction,
        closeAction: confirmAction,
      },
    });
  };

  openEditFilesModal = (module, section) => {
    const { t } = this.context;

    const isFilesSectionClicked = section.title === sections.Files.title;
    this.setState({
      isEditFilesSectionClicked: isFilesSectionClicked,
    });

    const confirmAction = () => {
      this.setState({
        editFilesModalData: {
          isOpen: false,
        },
      });
    };

    const deleteIsolationCertificateFileHandler = file => {
      const { queryItem, handleDeleteIsolationCertificateFile } = this.props;

      // TODO: in the future we will have to position the action modal below the bin icon (confirmed with design team)
      this.setState({
        actionModalData: {
          isOpen: true,
          title: t('DELETE_FILE'),
          type: 'cancel-delete',
          customClassName: 'isolation-confirm-modal',
          CustomContent: () => <div className="delete-confirmation-wrapper">{t('ISOLATION_CERTIFICATE.DELETE_FILE_TITLE', { fileName: file[fileFields.name] })}</div>,
          confirmAction: () => handleDeleteIsolationCertificateFile(queryItem, file.FileID, () => this.toggleConfirmationModal(false)),
          closeAction: () => this.setState({ actionModalData: { isOpen: false } }),
        },
      });
    };

    this.setState(prevState => ({
      editFilesModalData: {
        ...prevState.editFilesModalData,
        title: isFilesSectionClicked ? t('WORK_ORDER.EDIT_FILES') : t('ISOLATION_CERTIFICATE.POPULATED_DRAWINGS_SECTION'),
        customClassName: 'work-order-default-modal create-work-order-modal',
        CustomContent: dynamicProps => (
          <div className="work-order-form">
            <WorkOrderFiles
              openFilesModalData={this.openFilesModalData}
              deleteWorkOrderFile={deleteIsolationCertificateFileHandler}
              hideRams={true}
              // covers only disabling the removal of the Populated Drawings section which uses the same component for readability purposes
              hideDelete={isFilesSectionClicked ? false : true}
              {...dynamicProps}
            />
          </div>
        ),
        isOpen: true,
        type: 'none',
        confirmAction: confirmAction,
        closeAction: confirmAction,
        ModalFooter: () => <Button variant="success-outline" className="ml-auto" height="md" width="sm" text={t('CLOSE')} onClick={confirmAction} />,
      },
    }));
  };

  render() {
    const {
      actionModalData,
      sectionComponentsModalData,
      components,
      uploadModalData,
      componentSelectionModal,
      screenShotPickerModal,
      componentsOrderModal,
      changeStatusModal,
      changeStatusConfirmModal,
      changeStatusInProgress,
      editFilesModalData,
      statusChangeNoteModalData,
      isEditFilesSectionClicked,
    } = this.state;
    const {
      isLoading,
      getIsolationCertificateDetails,
      handleSuccessFetchIsolationCertificate,
      isolationCertificateDetails,
      queryItem,
      isolationCertificates,
      location,
      user,
      handleAnswerQuestion,
      handleDeleteFile,
      handleCustomAction,
      handleOrderComponents,
      viewer,
      debounceValues,
    } = this.props;
    const projectId = get(location.query, 'project_id');
    const descriptionType = get(location.query, 'type');

    return (
      <div className="details-sections">
        <InspectionRenderer
          deselectAll={() => null}
          selectAll={() => null}
          toggleElement={() => null}
          deselectAllTemp={() => null}
          selectAllTemp={() => null}
          toggleElementTemp={() => null}
          selectElement={() => null}
          updateElementGeometry={() => null}
          selectedDefect={null}
          elements={(isolationCertificateDetails[moduleSectionsFields.components] || []).map(component => ({
            ...component,
            visible: true,
            Colour: component[isolationComponentsFields.colour.name] || '',
            SystemType: measurementTypes.component,
            ID: component[isolationComponentsFields.component.name],
          }))}
          disableMove={true}
          viewer={viewer}
        />
        <ChangeStatusDropdown
          statuses={statuses}
          module={Object.assign({}, isolationCertificateDetails)}
          user={user}
          handleChangeStatus={this.handleChangeStatus}
          createdById={isolationCertificateDetails[isolationCertificateTableFields.createdBy]}
          finalStatus={statuses.deIsolated.value}
          rejectedStatus={statuses.rejected.value}
          descriptionType={descriptionType}
        />
        <ModuleSections
          disabled={isLoading}
          sections={isolationCertificateDetails?.Sections}
          module={Object.assign({}, isolationCertificateDetails)}
          handleSectionChange={this.handleSectionsChangeDebounce}
          handleAnswerQuestion={handleAnswerQuestion}
          finalStatus={statuses?.deIsolated?.value}
          modules={Object.assign([], isolationCertificates)}
          projectId={projectId}
          moduleId={isolationCertificateDetails[sectionFields.id]}
          handleChangeModule={() => getIsolationCertificateDetails(queryItem, (_, certificate) => handleSuccessFetchIsolationCertificate(false, certificate))}
          handleSectionActionClicked={this.handleSectionActionClicked}
          handleCustomAction={(action, values, cb) => handleCustomAction(this.props[action] || this[action], values, cb)}
          statuses={statuses}
          createdById={isolationCertificateDetails[isolationCertificateTableFields.createdBy]}
          user={user}
          customSectionDisabledStatuses={customSectionDisabledStatuses}
          debounceValues={debounceValues}
        />
        <Modal
          {...editFilesModalData}
          workorderFiles={{
            OTHER_FILES: isEditFilesSectionClicked ? isolationCertificateDetails?.[moduleSectionsFields.files] : isolationCertificateDetails?.[moduleSectionsFields.populatedDrawings],
          }}
          isFilesSectionSelected={isEditFilesSectionClicked}
        />
        <Modal
          {...sectionComponentsModalData}
          components={components}
          selectedComponents={isolationCertificateDetails[moduleSectionsFields.components]}
          handleComponentSelected={this.handleComponentSelected}
          handleUpdateIsolationComponent={this.handleUpdateIsolationComponentDebounce}
          handleDeleteIsolationComponent={this.handleDeleteIsolationComponent}
          toggleUploadModal={this.toggleUploadModal}
          handleDeleteFile={handleDeleteFile}
          isolationCertificateId={queryItem}
          handleComponentPicker3D={this.handleComponentPicker3D}
          handleComponentPickerPDF={this.handleComponentPickerPDF}
          handleComponentPickerCH={this.handleComponentPickerCH}
          handleScreenshotFrom3D={this.handleScreenshotFrom3D}
          toggleComponentsOrderModal={this.toggleComponentsOrderModal}
        />
        <Modal {...uploadModalData} modalDisabled={isLoading} />
        <Modal
          {...componentSelectionModal}
          components={components}
          question={{ QuestionsComponents: this.formatIsolationComponents(isolationCertificateDetails[moduleSectionsFields.components]), ID: queryItem }}
        />
        <Modal {...screenShotPickerModal} question={{ ID: queryItem, QuestionsScreenshots: this.getFiles(screenShotPickerModal?.componentId) }} />
        <Modal {...componentsOrderModal} components={isolationCertificateDetails[moduleSectionsFields.components]} handleOrderComponents={handleOrderComponents} modalDisabled={isLoading} />
        <Modal {...changeStatusModal} sections={Object.assign([], isolationCertificateDetails?.[moduleSectionsFields.sections])} disabled={changeStatusInProgress} />
        <Modal {...changeStatusConfirmModal} modalDisabled={changeStatusInProgress} />
        <Modal {...actionModalData} />
        <StatusChangeNoteModal {...statusChangeNoteModalData} />
      </div>
    );
  }
}

IsolationCertificateSections.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  filters: state.isolationCertificatesReducer.filters,
  isolationCertificates: state.isolationCertificatesReducer.isolationCertificates,
  user: state.userReducer,
  viewer: state.potreeReducer.viewerInstance,
});

const mapDispatchToProps = dispatch => ({
  getIsolationCertificateDetails: (isolationCertificateId, callback) => dispatch(getIsolationCertificateDetails(isolationCertificateId, callback)),
  updateIsolationCertificateDetails: data => dispatch(updateIsolationCertificateDetails(data)),
  assignKeyBox: data => dispatch(assignKeyBox(data)),
  fetchComponents: (data, callback) => dispatch(fetchComponents(data, callback)),
  addIsolationComponent: (data, callback) => dispatch(addIsolationComponent(data, callback)),
  updateIsolationComponent: (data, callback) => dispatch(updateIsolationComponent(data, callback)),
  deleteIsolationComponent: (data, callback) => dispatch(deleteIsolationComponent(data, callback)),
  uploadAsset: (file, IDs, callbackFunction, index, customDoneFunc) => dispatch(uploadAsset(file, IDs, callbackFunction, index, customDoneFunc)),
  setSingleUploadItem: (progress, index) => dispatch(setSingleUploadItem(progress, index)),
  setUploadItems: assets => dispatch(setUploadItems(assets)),
  changeIsolationCertificateStatus: (data, certificates, filters, callback) => dispatch(changeIsolationCertificateStatus(data, certificates, filters, callback)),
  getContractors: (data, callback) => dispatch(getContractors(data, callback)),
  changeIsolationCertificateStatusWithData: (data, certificates, filters, callback) => dispatch(changeIsolationCertificateStatusWithData(data, certificates, filters, callback)),
  notificationModalCustom: (errorMessage, title) => dispatch(notificationModalCustom(true, errorMessage, title)),
  generateAndDownloadPDFPopulatedDrawings: (isolationCertificateId, fileId) => dispatch(generateAndDownloadPDFPopulatedDrawings(isolationCertificateId, fileId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IsolationCertificateSections));
