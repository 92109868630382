import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { isEmpty } from 'lodash';
import EmptyState from '../../../../../../../common/empty-state-v2/components/empty-state';
import Button from '../../../../../../../common/form/components/button';
import Loader from '../../../../../../../common/global-loader/components/simple-loader';
import TimeSeriesGraph from '../../../../../../../common/graphs/components/time-series-graph';
import Helpers from '../../../../../../../common/helpers';
import Icon from '../../../../../../../common/icon/components/icon';
import RenderIf from '../../../../../../../common/render-if/components/render-if';
import InfoTooltip from '../../../../../../../common/tooltip/components/info-tooltip';
import { customTimePeriodIndex, formConstants, settingsFormConstants, TIME_SERIES_AGGREGATION_ORDER_MAP } from '../../../../readings-and-gauges/constants/time-series-graph-constants';
import '../../styles/time-series-graph-small-preview.scss';

const TimeSeriesGraphSmallPreview = (
  { handleViewMore, readonly, handleCreateGraphingGroup, showGraphingGroup = true, graphDetailsIsLoading, graphLoading, graphData, graphDetails, timePeriodsList },
  context
) => {
  const { t } = context;

  const customAggregationValue = useMemo(() => {
    const selectedAggregations = graphDetails?.[settingsFormConstants.aggregation.name];

    // ordering done on the FE side, due to BE not returning the data in the correct order
    const aggregationList = (selectedAggregations || []).sort((a, b) => TIME_SERIES_AGGREGATION_ORDER_MAP[a.Key] - TIME_SERIES_AGGREGATION_ORDER_MAP[b.Key]);

    if (!isEmpty(selectedAggregations)) {
      if (selectedAggregations.length > 1) {
        return (
          <div className="value-with-tooltip__container">
            <p className="f-primary">{t('MULTIPLE', { numberOfItems: selectedAggregations.length })}</p>
            <InfoTooltip
              customComponent={
                <>
                  <label>{t(settingsFormConstants.aggregation.placeholder)}</label>
                  {aggregationList.map(aggregation => (
                    <p className="f-primary">{t(aggregation.Key)}</p>
                  ))}
                </>
              }
              Component={() => <Icon name="info" size="sm" />}
              containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
            />
          </div>
        );
      } else {
        return <p className="f-primary">{selectedAggregations[0]?.Value}</p>;
      }
    }
    return null;
  }, [graphDetails, t]);

  const customTimePeriodValue = useMemo(() => {
    const selectedTimePeriod = graphDetails?.[settingsFormConstants.timePeriod.name];
    if (selectedTimePeriod?.ID === timePeriodsList[customTimePeriodIndex].ID) {
      return (
        <div className="value-with-tooltip__container">
          <p className="f-primary">{selectedTimePeriod?.Name}</p>
          <InfoTooltip
            actionsMenu={{
              'MEASUREMENTS_FILTERS.DATE_FROM': Helpers.formatCell(graphDetails?.[settingsFormConstants.dateFrom.name], 'date'),
              'MEASUREMENTS_FILTERS.DATE_TO': Helpers.formatCell(graphDetails?.[settingsFormConstants.dateTo.name], 'date'),
            }}
            Component={() => <Icon name="info" size="sm" />}
            containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
          />
        </div>
      );
    }
    return <p className="f-primary">{selectedTimePeriod?.Name}</p>;
  }, [graphDetails, timePeriodsList]);

  if (graphDetailsIsLoading) {
    return <Loader isLoading={true} />;
  }

  if (isEmpty(graphDetails)) {
    return (
      <EmptyState
        emptyStateText={t('SECTION_TITLE.TIME_SERIES_GRAPH.EMPTY_STATE.TITLE')}
        showButton={true}
        buttonText={t('SECTION_TITLE.TIME_SERIES_GRAPH.EMPTY_STATE.ACTION_TEXT')}
        buttonAction={handleCreateGraphingGroup}
        buttonDisabled={readonly || !handleCreateGraphingGroup}
        transparent
      />
    );
  }

  return (
    <div className="time-series-graph-small-preview">
      <RenderIf if={showGraphingGroup}>
        <h6 className="f-primary mb-20">{graphDetails?.[settingsFormConstants.graphingGroup.name]?.[formConstants.name] || 'Testing graphing group name'}</h6>
      </RenderIf>
      <div className="time-series-graph-small-preview__settings-grid mb-20">
        <div className="time-series-graph-small-preview__settings-grid-item">
          <label>{t(settingsFormConstants.aggregation.placeholder)}</label>
          {customAggregationValue}
        </div>
        <div className="time-series-graph-small-preview__settings-grid-item">
          <label>{t(settingsFormConstants.timePeriod.placeholder)}</label>
          {customTimePeriodValue}
        </div>
        <div className="time-series-graph-small-preview__settings-grid-item">
          <label>{t(settingsFormConstants.chunks.placeholder)}</label>
          <p className="f-primary">{graphDetails?.[settingsFormConstants.chunks.name]?.Value}</p>
        </div>
      </div>
      <div className="time-series-graph-small-preview__description-info mb-20">
        <Icon name="info" handleHover={false} size="sm" />
        <p className="f-primary">{t('TIME_SERIES_GRAPH.SMALL_PREVIEW.SECTION_DESCRIPTION_INFO')}</p>
      </div>
      <div className="time-series-graph-small-preview__graph">
        <TimeSeriesGraph data={graphData} isLoading={graphLoading} visibleAggregations={graphDetails?.[settingsFormConstants.aggregation.name]} showLegend={false} />
      </div>
      <Button type="button" width="lg" height="md" variant="success-outline" text={t('VIEW_MORE')} onClick={handleViewMore} />
    </div>
  );
};

TimeSeriesGraphSmallPreview.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default TimeSeriesGraphSmallPreview;
