import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import Lottie from 'react-lottie';
import RenderIf from '../../render-if/components/render-if';
import { computerVision } from '../constants/computer-vision';
import { animation } from '../constants/demo';
import loaderConstants from '../constants/loader-constants';

import '../styles/global-loader.scss';

class Loader extends Component {
  handleClick = e => {
    e.preventDefault();
  };
  render() {
    const { isLoading, type } = this.props;
    return (
      <Fragment>
        <RenderIf if={isLoading > 0}>
          {type === loaderConstants.loaderTypes.computerVision.name && (
            <div className="global-loader computer-vision">
              <Lottie
                options={{
                  animationData: computerVision,
                  loop: true,
                  autoplay: true,
                }}
                height={'80%'}
                width={'80%'}
              />
            </div>
          )}
          {!type && (
            <div className="global-loader ">
              <Lottie
                options={{
                  animationData: animation,
                  loop: true,
                  autoplay: true,
                }}
                height={200}
                width={200}
              />
            </div>
          )}
        </RenderIf>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.loaderReducer.isLoading,
  type: state.loaderReducer.type,
});

export default connect(mapStateToProps)(Loader);
