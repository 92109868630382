import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';
import { formatChecklistTemplateTableCells, formatComponentTableCells, formatNotificationTableCells, formatObservationTableCells, formatWorkorderTableCells } from '../actions/table-formatting';
import dmsFileLabels from '../components/dms-right-side/dms-file-labels';
import DMSFileLinkedModules from '../components/dms-right-side/dms-linked-modules';
import DMSFileTypes from '../components/filters/components/file-types';
import DMSLabelsFilter from '../components/filters/components/labels';
import DMSStatusesFilter from '../components/filters/components/statuses';

export const fields = {
  id: 'ID',
  fileId: 'FileID',
  icon: 'Icon',
  name: 'Name',
  fileName: 'FileName',
  status: 'Status',
  description: 'Description',
  location: 'Location',
  hierarchy: 'Hierarchy',
  smartDrawing: 'IsSmartDrawing',
  fileSizeKB: 'FileSizeKB',
  createdByUser: 'CreatedByUser',
  createdByName: 'CreatedByName',
  createdAt: 'CreatedAt',
  createdByID: 'CreatedBy',
  createdByUserID: 'CreatedByID',
  actionMenu: 'ActionMenu',
  fileType: 'FileType',
  fileSize: 'FileSize',
  type: 'type',
  realName: 'real_name',
  deleteFileRowAction: 'DeleteFileRowAction',
  numberOfPages: 'NumberOfPages',
  userId: 'UserID',
  isUnassigned: 'IsUnassigned',
  toggleLinkDocumentAction: 'ToggleLinkDocumentAction',
  colour: 'Colour',
  sourceID: 'SourceID',
  documentHierarchyID: 'DocumentHierarchyID',
  // Component fields
  componentName: 'ComponentName',
  componentCode: 'ComponentCode',
  componentCodeShort: 'Code',
  componentType: 'ComponentType',
  componentTypeShort: 'Type',
  componentID: 'ComponentID',
  // upload related filed
  progress: 'progress',
  // defect fields
  defectName: 'DefectName',
  // notification fields
  notificationName: 'NotificationName',
  // workorder fields
  workorderName: 'WokrorderName',
  // version related fields
  versionNumber: 'VersionNumber',
  versionDate: 'VersionDate',
  versionDescription: 'VersionDescription',
  isActive: 'IsActive',
  isPdf: 'isPDF',
  isImage: 'isImage',
  fileVersion: 'FileVersion',
  versionCreatedAt: 'VersionCreatedAt',
  versionCreatedBy: 'VersionCreatedByUser',
  versionCreatedByID: 'VersionCreatedBy',
  versionID: 'VersionID',
  numberOfFileVersions: 'NumberOfFileVersions',
  // URL related fields
  url: 'URL',
  src: 'src',
  thumbnailUrl: 'ThumbnailURL',
  documentSizeUrl: 'documentSizeUrl',
  documentUrl: 'DocumentURL',
  thumbnailUrlLower: 'thumbnailUrl',
  categoryId: 'CategoryID',
  critical: 'Critical',
  componentSelectedCategories: 'ComponentSelectedCategories', // only used on the FE for mapping
  categoriesID: 'CategoriesID',
  measurementReadingId: 'MeasurementReadingID',
};

export const folderTableConfig = [
  {
    title: '',
    key: fields.icon,
    type: fields.icon,
    class: 'icon-column',
  },
  {
    title: 'DOCUMENT_MANAGEMENT_TABLE.NAME',
    key: fields.name,
    type: fields.name,
    isSort: true,
    class: 'name-column',
  },
  {
    title: 'DOCUMENT_MANAGEMENT_TABLE.CREATED_AT',
    key: fields.createdAt,
    type: fields.createdAt,
    isSort: true,
    class: 'at-column',
  },
  {
    title: 'DOCUMENT_MANAGEMENT_TABLE.CREATED_BY',
    key: fields.createdByUser,
    type: fields.createdByUser,
    isSort: true,
    class: 'by-column',
  },
  {
    title: '',
    type: fields.actionMenu,
    key: fields.actionMenu,
    isSort: false,
    class: 'action-menu-column',
    className: 'action-menu',
  },
];

export const fileTableConfig = [
  {
    title: '',
    key: fields.fileType,
    type: fields.icon,
    class: 'icon-column',
  },
  {
    title: 'DOCUMENT_MANAGEMENT_TABLE.NAME',
    key: fields.fileName,
    type: fields.fileName,
    isSort: true,
    class: 'file-name-column',
  },
  {
    title: 'DOCUMENT_MANAGEMENT_TABLE.UPLOADED_AT',
    key: fields.createdAt,
    type: fields.createdAt,
    isSort: true,
    class: 'up-at-column',
  },
  {
    title: 'DOCUMENT_MANAGEMENT_TABLE.UPLOADED_BY',
    key: fields.createdByUser,
    type: fields.createdByUser,
    isSort: true,
    class: 'up-by-column',
  },
  {
    title: 'DOCUMENT_MANAGEMENT_TABLE.FILE_SIZE',
    key: fields.fileSize,
    type: fields.fileSize,
    isSort: true,
    class: 'size-column',
  },
  {
    title: 'DOCUMENT_MANAGEMENT_TABLE.STATUS',
    key: fields.status,
    type: fields.status,
    isSort: true,
    class: 'status-column',
  },
  {
    title: 'DOCUMENT_MANAGEMENT_TABLE.FILE_TYPE',
    key: fields.fileType,
    type: fields.fileType,
    isSort: true,
    class: 'type-column',
  },
  {
    title: '',
    type: fields.actionMenu,
    key: fields.actionMenu,
    isSort: false,
    class: 'action-menu-column',
    className: 'action-menu',
  },
];

export const chooseDMSFromComponentFileTable = [
  {
    title: '',
    key: fields.fileType,
    type: fields.icon,
    class: 'icon-column',
  },
  {
    title: 'DOCUMENT_MANAGEMENT_TABLE.NAME',
    key: fields.fileName,
    type: fields.fileName,
    isSort: true,
    class: 'file-name-column',
  },
  {
    title: 'DOCUMENT_MANAGEMENT_TABLE.UPLOADED_AT',
    key: fields.createdAt,
    type: fields.createdAt,
    isSort: true,
    class: 'up-at-column',
  },
  {
    title: 'DOCUMENT_MANAGEMENT_TABLE.UPLOADED_BY',
    key: fields.createdByUser,
    type: fields.createdByUser,
    isSort: true,
    class: 'up-by-column',
  },
  {
    title: 'DOCUMENT_MANAGEMENT_TABLE.FILE_SIZE',
    key: fields.fileSize,
    type: fields.fileSize,
    isSort: true,
    class: 'size-column',
  },
  {
    title: 'DOCUMENT_MANAGEMENT_TABLE.STATUS',
    key: fields.status,
    type: fields.status,
    isSort: true,
    class: 'status-column',
  },
  {
    title: 'DOCUMENT_MANAGEMENT_TABLE.FILE_TYPE',
    key: fields.fileType,
    type: fields.fileType,
    isSort: true,
    class: 'type-column',
  },
  {
    title: '',
    key: fields.toggleLinkDocumentAction,
    type: fields.toggleLinkDocumentAction,
    isSort: false,
    class: 'action-menu-column',
  },
];

export const uploadedFileTableConfig = [
  {
    title: '',
    key: fields.fileType,
    type: fields.icon,
    class: 'icon-column',
  },
  {
    title: 'DOCUMENT_MANAGEMENT_TABLE.NAME',
    key: fields.realName,
    type: fields.realName,
    isSort: false,
    class: 'name-column',
  },
  {
    title: 'DOCUMENT_HIERARCHY_FORM.FILE_SIZE',
    key: fields.fileSizeKB,
    type: fields.fileSizeKB,
    isSort: false,
    class: 'file-size-column',
  },
  {
    title: 'DOCUMENT_HIERARCHY_FORM.SMART_DRAWING',
    key: fields.smartDrawing,
    type: fields.smartDrawing,
    isSort: false,
    class: 'smart-drawing-column',
    isInfoTooltip: true,
    infoTooltipLabel: 'DOCUMENT_MANAGEMENT.SMART_DRAWING_INFOTOOLTIP',
  },
  {
    title: 'DOCUMENT_HIERARCHY_FORM.STATUS',
    key: fields.status,
    type: fields.status,
    isSort: false,
    class: 'status-column',
  },
  {
    title: '',
    type: fields.deleteFileRowAction,
    key: fields.deleteFileRowAction,
    isSort: false,
    class: 'action-menu-column',
    className: 'action-menu',
  },
];

export const filterProps = {
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  searchText: 'SearchText',
  hasNext: 'HasNext',
  totalItems: 'TotalItems',
  parentID: 'ParentID',
  statusesFilter: 'StatusFilter',
  fileTypesFilter: 'FileTypesFilter',
  labelsFilter: 'LabelsFilter',
  rootID: 'RootID',
  sourceID: 'SourceID',
  documentHierarchyID: 'DocumentHierarchyID',
  ID: 'ID',
};

export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const defaultFolderFilters = {
  [filterProps.sortByColumn]: fields.name,
  [filterProps.sortDirection]: sortDirection.asc,
  [filterProps.perPage]: 20,
  [filterProps.lastSeen]: 0,
  [filterProps.searchText]: '',
  [filterProps.hasNext]: false,
};

export const defaultFilesFilters = {
  [filterProps.sortByColumn]: fields.fileName,
  [filterProps.sortDirection]: sortDirection.asc,
  [filterProps.perPage]: 20,
  [filterProps.lastSeen]: 0,
  [filterProps.searchText]: '',
  [filterProps.hasNext]: false,
  [filterProps.parentID]: null,
};

// used in dms-component-picker for handling pagination when loading all components from lite-list
// also used for search functionality
export const defaultFileComponentsFilters = {
  [filterProps.sortByColumn]: fields.id,
  [filterProps.sortDirection]: sortDirection.desc,
  [filterProps.perPage]: 20,
  [filterProps.lastSeen]: 0,
  [filterProps.searchText]: '',
  [filterProps.hasNext]: false,
};

export const defaultNavigationButtonIconProps = { handleHover: false, className: 'action-buttons-icon' };

export const actionButtonProps = { icon: 'dms-folder', iconProps: defaultNavigationButtonIconProps, visibleFor: [PERMISSIONS[PERMISSION_TYPES.documentManagement].create.name] };

export const secondActionButtonProps = { icon: 'dms-documents', iconProps: defaultNavigationButtonIconProps, visibleFor: [PERMISSIONS[PERMISSION_TYPES.documentManagement].create.name] };

export const createFolderModalFormFields = {
  name: {
    name: fields.name,
    label: 'DOCUMENT_MANAGEMENT_MODAL.FOLDER_NAME',
    id: 'create-folder-form-name',
  },
};

export const fileModalFormFields = {
  name: {
    name: fields.name,
    label: 'DOCUMENT_HIERARCHY_FORM.FILE_NAME',
    id: 'file-form-name',
  },
  description: {
    name: fields.description,
    label: 'DOCUMENT_HIERARCHY_FORM.FILE_DESCRIPTION',
    id: 'file-form-description',
  },
};

export const itemActionTypes = {
  folder: {
    edit: 'editFolder',
    delete: 'deleteFolder',
  },
  file: {
    edit: 'editFile',
    delete: 'deleteFile',
    unlink: 'unlinkFile',
  },
  confirm: {
    delete: 'confirmDelete',
  },
  fileVersion: {
    delete: 'deleteFileVersion',
  },
};

export const actionModalContent = {
  [itemActionTypes.folder.delete]: {
    firstParagraph: 'DOCUMENT_MANAGEMENT_MODAL.DELETE_FOLDER_CONFIRMATION',
    secondParagraph: 'DOCUMENT_MANAGEMENT_MODAL.DELETE_FOLDER_WARNING',
    confirmButtonText: 'DOCUMENT_MANAGEMENT_MODAL.DELETE_FOLDER',
    title: 'DOCUMENT_MANAGEMENT_MODAL.DELETE_FOLDER',
  },
  [itemActionTypes.file.delete]: {
    firstParagraph: 'DOCUMENT_MANAGEMENT_MODAL.DELETE_FILE_CONFIRMATION',
    secondParagraph: 'DOCUMENT_MANAGEMENT_MODAL.DELETE_FILE_WARNING',
    confirmButtonText: 'DOCUMENT_MANAGEMENT_MODAL.DELETE_FILE',
    title: 'DOCUMENT_MANAGEMENT_MODAL.DELETE_FILE',
  },
  [itemActionTypes.confirm.delete]: {
    firstParagraph: 'DOCUMENT_MANAGEMENT_MODAL.CONFIRM_DELETE_WARNING',
    confirmButtonText: 'CONFIRM',
    title: 'DOCUMENT_MANAGEMENT_MODAL.CONFIRM_DELETE_TITLE',
  },
  [itemActionTypes.fileVersion.delete]: {
    firstParagraph: 'DOCUMENT_MANAGEMENT_MODAL.DELETE_FILE_CONFIRMATION',
    secondParagraph: null,
    confirmButtonText: 'DOCUMENT_MANAGEMENT_MODAL.DELETE_FILE_VERSION',
    title: 'DOCUMENT_MANAGEMENT_MODAL.DELETE_FILE_VERSION',
  },
};

// TODO: add following props to statuses if needed later
/**
 * 
    skipPermissionCheck: true,
    goToStatuses: ['RAISED'],
    isRegularStatus: true,
    modalTitle: 'ISOLATION_CERTIFICATE_STATUS.DRAFT_TITLE',
    editPermission: PERMISSIONS[PERMISSION_TYPES.isolationCertificates].edit.name,
    ownershipPermission: PERMISSIONS[PERMISSION_TYPES.isolationCertificates].create.name,
 */

export const statusNames = {
  draft: 'DRAFT',
  under_review: 'RAISED',
  approved: 'APPROVED',
  rejected: 'REJECTED',
  live: 'LIVE',
};

export const statuses = {
  [statusNames.draft]: {
    value: 'DRAFT',
    title: 'WORK_ORDERS.DRAFT',
    icon: 'clock',
    isRegularStatus: true,
    skipPermissionCheck: true,
  },
  [statusNames.under_review]: {
    value: 'RAISED',
    title: 'DOCUMENT_MANAGEMENT_STATUS.UNDER_REVIEW',
    icon: 'clock-filled',
    isRegularStatus: true,
    skipPermissionCheck: true,
  },
  [statusNames.approved]: {
    value: 'APPROVED',
    title: 'DOCUMENT_MANAGEMENT_STATUS.APPROVED',
    icon: 'checkmark-rounded',
    iconProps: {
      active: true,
    },
    iconFilledWhenActive: true,
    isRegularStatus: true,
    skipPermissionCheck: true,
  },
  [statusNames.rejected]: {
    value: 'REJECTED',
    title: 'DOCUMENT_MANAGEMENT_STATUS.REJECTED',
    icon: 'alert-octagon',
    iconProps: {
      handleHover: false,
    },
    isRegularStatus: true,
    skipPermissionCheck: true,
  },
  [statusNames.live]: {
    value: 'LIVE',
    title: 'DOCUMENT_MANAGEMENT_STATUS.LIVE',
    icon: 'checkmark',
    iconProps: {
      active: true,
    },
    isFinalStatus: false,
    skipPermissionCheck: true,
  },
};

export const fileTypesOptions = {
  images: 'images',
  audio: 'audio',
  video: 'video',
  doc: 'doc',
  dwg: 'dwg',
  pdf: 'pdf',
  xls: 'xls',
};

export const fileTypesOptionsValues = {
  [fileTypesOptions.images]: {
    name: fileTypesOptions.images,
    checkboxText: 'Images',
    value: ['png', 'PNG', 'gif', 'jpeg', 'jpg', 'JPG', 'tiff', 'heic', 'heif', 'DNG', 'svg'],
  },
  [fileTypesOptions.audio]: {
    name: fileTypesOptions.audio,
    checkboxText: 'Audio',
    value: ['mpeg', 'wav', 'mp3', 'ogg', 'wma'],
  },
  [fileTypesOptions.video]: {
    name: fileTypesOptions.video,
    checkboxText: 'Video',
    value: ['mp4', 'MP4', 'qt', 'mov', 'avi'],
  },
  [fileTypesOptions.doc]: {
    name: fileTypesOptions.doc,
    checkboxText: 'DOC',
    value: ['doc', 'docx', 'txt', 'csv', 'tsv', 'ods'],
  },
  [fileTypesOptions.dwg]: {
    name: fileTypesOptions.dwg,
    checkboxText: 'DWG',
    value: ['dwg'],
  },
  [fileTypesOptions.pdf]: {
    name: fileTypesOptions.pdf,
    checkboxText: 'PDF',
    value: ['pdf'],
  },
  [fileTypesOptions.xls]: {
    name: fileTypesOptions.xls,
    checkboxText: 'XLS',
    value: ['xls', 'xlsx'],
  },
};

export const filterSections = {
  statuses: {
    title: 'DOCUMENT_MANAGEMENT_FILTERS.STATUSES',
    component: DMSStatusesFilter,
    valueKey: filterProps.statusesFilter,
    componentProps: {
      isAdjustedForSmallerScreen: true,
    },
  },
  fileTypes: {
    title: 'DOCUMENT_MANAGEMENT_FILTERS.FILE_TYPE',
    component: DMSFileTypes,
    valueKey: filterProps.fileTypesFilter,
    componentProps: {},
  },
  labels: {
    title: 'DOCUMENT_MANAGEMENT_FILTERS.LABELS',
    component: DMSLabelsFilter,
    valueKey: filterProps.labelsFilter,
    componentProps: {
      isAdjustedForSmallerScreen: true,
    },
  },
};
export const componentTableConfig = [
  {
    title: '',
    key: fields.icon,
    type: fields.icon,
    isSort: false,
    class: 'icon-col',
  },
  {
    title: 'COMPONENTS.CODE',
    key: fields.componentCode,
    type: fields.componentCode,
    isSort: true,
    class: 'code-col',
  },
  {
    title: 'COMPONENTS.NAME_SHORT',
    key: fields.componentName,
    type: fields.componentName,
    isSort: true,
    class: 'name-col',
  },
  {
    title: 'COMPONENTS.TYPE',
    key: fields.componentType,
    type: fields.componentType,
    isSort: true,
    class: 'type-col',
  },
  {
    title: '',
    key: fields.critical,
    type: fields.critical,
    isSort: false,
    class: 'critical-col',
  },
];

export const observationTableConfig = [
  {
    title: '',
    key: fields.icon,
    type: fields.icon,
    isSort: false,
    class: 'icon-col',
  },
  {
    title: 'COMPONENTS.ID',
    key: fields.id,
    type: fields.id,
    isSort: false,
    class: 'code-col',
  },
  {
    title: 'COMPONENTS.NAME_SHORT',
    key: fields.defectName,
    type: fields.defectName,
    isSort: false,
    class: 'name-col',
  },
  {
    title: 'OBSERVATIONS.STATUS',
    key: fields.status,
    type: fields.status,
    isSort: false,
    class: 'type-col',
  },
];

export const notificationTableConfig = [
  {
    title: '',
    key: fields.icon,
    type: fields.icon,
    isSort: false,
    class: 'icon-col',
  },
  {
    title: 'COMPONENTS.ID',
    key: fields.id,
    type: fields.id,
    isSort: false,
    class: 'code-col',
  },
  {
    title: 'COMPONENTS.NAME_SHORT',
    key: fields.notificationName,
    type: fields.notificationName,
    isSort: false,
    class: 'name-col',
  },
  {
    title: 'OBSERVATIONS.STATUS',
    key: fields.status,
    type: fields.status,
    isSort: false,
    class: 'type-col',
  },
];

export const workorderTableConfig = [
  {
    title: '',
    key: fields.icon,
    type: fields.icon,
    isSort: false,
    class: 'icon-col',
  },
  {
    title: 'COMPONENTS.ID',
    key: fields.id,
    type: fields.id,
    isSort: false,
    class: 'code-col',
  },
  {
    title: 'COMPONENTS.NAME_SHORT',
    key: fields.workorderName,
    type: fields.workorderName,
    isSort: false,
    class: 'name-col',
  },
  {
    title: 'OBSERVATIONS.STATUS',
    key: fields.status,
    type: fields.status,
    isSort: false,
    class: 'type-col',
  },
];

export const checklistTemplateTableConfig = [
  {
    title: '',
    key: fields.icon,
    type: fields.icon,
    isSort: false,
    class: 'icon-col',
  },
  {
    title: 'COMPONENTS.ID',
    key: fields.id,
    type: fields.id,
    isSort: false,
    class: 'code-col',
  },
  {
    title: 'COMPONENTS.NAME_SHORT',
    key: fields.name,
    type: fields.name,
    isSort: false,
    class: 'name-col',
  },
  {
    title: 'OBSERVATIONS.STATUS',
    key: fields.status,
    type: fields.status,
    isSort: false,
    class: 'type-col',
  },
];

export const fileDetailsSections = {
  components: {
    title: 'DOCUMENT_MANAGEMENT.LINKED_EQUIPMENT',
    component: DMSFileLinkedModules,
    componentProps: {
      isAdjustedForSmallerScreen: false,
    },
    sectionProps: {
      hideEditAction: false,
    },
    valueKey: 'Components',
    className: 'linked-components',
    tableConfig: componentTableConfig,
    handleFormatCells: () => formatComponentTableCells,
    noDataText: 'NO_EQUIPMENT_EMPTY_STATE',
    emptyStateComponent: true,
    emptyStateButtonText: 'WORK_ORDER.ADD_COMPONENTS',
  },
  defects: {
    title: 'DOCUMENT_MANAGEMENT.LINKED_OBSERVATION',
    component: DMSFileLinkedModules,
    componentProps: {
      isAdjustedForSmallerScreen: false,
    },
    sectionProps: {
      hideEditAction: true,
    },
    hideEditAction: true,
    valueKey: 'Defects',
    className: 'linked-components',
    tableConfig: observationTableConfig,
    handleFormatCells: () => formatObservationTableCells,
    noDataText: 'DOCUMENT_MANAGEMENT.DEFECT_EMPTY_STATE',
    moduleItemTitleIcon: 'observations',
    moduleItemTitle: 'INSPECTION_DEFECTS',
  },
  notifications: {
    title: 'DOCUMENT_MANAGEMENT.LINKED_NOTIFICATION',
    component: DMSFileLinkedModules,
    componentProps: {
      isAdjustedForSmallerScreen: false,
    },
    sectionProps: {
      hideEditAction: true,
    },
    hideEditAction: true,
    valueKey: 'Notifications',
    className: 'linked-components',
    tableConfig: notificationTableConfig,
    handleFormatCells: () => formatNotificationTableCells,
    noDataText: 'DOCUMENT_MANAGEMENT.NOTIFICATION_EMPTY_STATE',
    moduleItemTitleIcon: 'notifications',
    moduleItemTitle: 'INSPECTION_NOTIFICATIONS',
  },
  workorders: {
    title: 'DOCUMENT_MANAGEMENT.LINKED_WORKORDER',
    component: DMSFileLinkedModules,
    componentProps: {
      isAdjustedForSmallerScreen: false,
    },
    sectionProps: {
      hideEditAction: true,
    },
    hideEditAction: true,
    valueKey: 'Workorders',
    className: 'linked-components',
    tableConfig: workorderTableConfig,
    handleFormatCells: () => formatWorkorderTableCells,
    noDataText: 'DOCUMENT_MANAGEMENT.WORKORDER_EMPTY_STATE',
    moduleItemTitleIcon: 'workorders',
    moduleItemTitle: 'INSPECTION_WORKORDERS',
  },
  checklistQuestions: {
    title: 'DOCUMENT_MANAGEMENT.LINKED_CHECKLIST_TEMPLATES',
    component: DMSFileLinkedModules,
    componentProps: {
      isAdjustedForSmallerScreen: false,
    },
    sectionProps: {
      hideEditAction: true,
    },
    hideEditAction: true,
    valueKey: 'ChecklistTemplates',
    className: 'linked-components',
    tableConfig: checklistTemplateTableConfig,
    handleFormatCells: () => formatChecklistTemplateTableCells,
    noDataText: 'DOCUMENT_MANAGEMENT.CHECKLIST_TEMPLATES_EMPTY_STATE',
    moduleItemTitleIcon: 'list',
    moduleItemTitle: 'TOOL_TYPE.CHECKLISTS_AND_PROCEDURES',
  },
  labels: {
    title: 'DOCUMENT_MANAGEMENT_FILTERS.LABELS',
    component: dmsFileLabels,
    componentProps: {
      isAdjustedForSmallerScreen: false,
    },
    sectionProps: {
      hideEditAction: false,
    },
    valueKey: 'Labels',
  },
};

export const quickFiltersDefault = {
  [filterSections.statuses.valueKey]: [],
  [filterSections.fileTypes.valueKey]: {},
  [filterSections.labels.valueKey]: [],
};

export const folderFormFields = {
  name: {
    name: fields.name,
    label: 'DOCUMENT_HIERARCHY_FORM.NAME',
    id: 'document-hierarchy-form-folder-name',
  },
  createdByUser: {
    name: fields.createdByUser,
    label: 'DOCUMENT_HIERARCHY_FORM.CREATED_BY',
    id: 'document-hierarchy-form-folder-created-by',
  },
  createdAt: {
    name: fields.createdAt,
    label: 'DOCUMENT_HIERARCHY_FORM.CREATED_AT',
    id: 'document-hierarchy-form-folder-created-at',
  },
};

export const fileFormFields = {
  fileId: {
    name: fields.fileId,
    label: 'DOCUMENT_HIERARCHY_FORM.FILE_ID',
    id: 'document-hierarchy-form-file-id',
  },
  status: {
    name: fields.status,
    label: 'DOCUMENT_HIERARCHY_FORM.STATUS',
    id: 'document-hierarchy-form-file-status',
  },
  fileName: {
    name: fields.fileName,
    label: 'DOCUMENT_HIERARCHY_FORM.FILE_NAME',
    id: 'document-hierarchy-form-file-name',
    minChars: 3,
    maxChars: 225,
  },
  description: {
    name: fields.description,
    label: 'DOCUMENT_HIERARCHY_FORM.DESCRIPTION',
    id: 'document-hierarchy-form-file-description',
    maxChars: 500,
  },
  location: {
    name: fields.hierarchy,
    label: 'DOCUMENT_HIERARCHY_FORM.LOCATION',
    id: 'document-hierarchy-form-file-location',
  },
  smartDrawing: {
    name: fields.smartDrawing,
    label: 'DOCUMENT_HIERARCHY_FORM.SMART_DRAWING_QUESTION_MARK',
    id: 'document-hierarchy-form-file-smart-drawing',
  },
  fileSize: {
    name: fields.fileSizeKB,
    label: 'DOCUMENT_HIERARCHY_FORM.FILE_SIZE',
    id: 'document-hierarchy-form-file-size',
  },
  uploadedAt: {
    name: fields.createdAt,
    label: 'DOCUMENT_HIERARCHY_FORM.UPLOADED_AT',
    id: 'document-hierarchy-form-file-uploaded-at',
  },
  uploadedBy: {
    name: fields.createdByName,
    label: 'DOCUMENT_HIERARCHY_FORM.UPLOADED_BY',
    id: 'document-hierarchy-form-file-uploaded-by',
  },
  systemVersion: {
    name: fields.fileVersion,
    label: 'DMS_FILE.SYSTEM_VERSION',
    id: 'document-hierarchy-form-file-system-version',
  },
  versionNumber: {
    name: fields.versionNumber,
    label: 'DMS_FILE.VERSION_NUMBER',
    id: 'document-hierarchy-form-file-version-number',
  },
  versionDescription: {
    name: fields.versionDescription,
    label: 'DMS_FILE.VERSION_DESCRIPTION',
    id: 'document-hierarchy-form-file-version-description',
  },
  versionDate: {
    name: fields.versionDate,
    label: 'DMS_FILE.VERSION_DATE',
    id: 'document-hierarchy-form-file-version-date',
  },
  versionUploadedAt: {
    name: fields.versionCreatedAt,
    label: 'DMS_FILE.VERSION_UPLOADED_DATE',
    id: 'document-hierarchy-form-file-version-uploaded-at',
  },
  versionUploadedBy: {
    name: fields.versionCreatedBy,
    label: 'DMS_FILE.VERSION_UPLOADED_BY',
    id: 'document-hierarchy-form-file-version-uploaded-by',
  },
};

export const changeDMSLocationFolderTable = Object.assign([], folderTableConfig).slice(0, -1);
export const changeDMSLocationFileTable = Object.assign([], fileTableConfig).slice(0, -1);

export const uploadedFileValidation = {
  value: 50,
  unit: 'mb',
  bytes: 50000000,
};

export const supportedUploadFileMimeTypes = {
  'application/xml': ['.xml'],
  'application/pdf': ['.pdf'],
  'application/zip': ['.zip'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/vnd.ms-excel': ['.xls', '.xlt', '.xla'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
  'application/acad': ['.dwg'],
  'audio/mpeg': ['.mpeg', '.wav', '.mp3', '.ogg'],
  'audio/x-ms-wma': ['.wma'],
  'text/csv': ['.csv', '.tsv'],
  'text/plain': ['.txt'],
  'video/mp4': ['.mp4', '.MP4', '.qt', '.mov', '.avi'],
  'image/svg': ['.svg'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': [],
  'image/tiff': ['.tiff'],
  'image/heic': ['.heic', '.heif'],
  'image/dng': ['.DNG'],
  'image/gif': ['.gif'],
};

// use the code below in the future to display the User message that these are the not allowed file extensions/types
/**
const disallowedFileExtensions = [
  '.js',
  '.jar',
  '.sh',
  '.py',
  '.vbs',
  '.exe',
  '.bat',
  '.msi',
  '.dll',
  '.sys',
  '.dll',
  '.ini',
  '.config',
  '.bat',
  '.cmd',
  '.ps1',
  '.scr',
  '.reg',
  '.docm',
  '.xlsm',
  '.pptm',
];

disallowedFileExtensions.forEach(extension => {
  const mimeType = `application/x-${extension.substring(1)}`;
  if (supportedUploadFileMimeTypes[mimeType]) {
    supportedUploadFileMimeTypes[mimeType] = [];
  }
});
 */

export const componentDetailsDisplayProps = [
  { name: 'ID' },
  {
    name: 'Name',
  },
  {
    name: 'Code',
  },
  {
    name: 'Type',
  },
];

export const uploadFileTableConfig = [
  {
    title: '',
    key: fields.fileType,
    type: fields.icon,
    isSort: false,
    class: 'upload-icon-col',
  },
  {
    title: '',
    key: fields.realName,
    type: fields.name,
    isSort: false,
    class: 'upload-name-col',
  },
  {
    title: '',
    key: fields.progress,
    type: fields.progress,
    isSort: false,
    class: 'upload-progress-col',
  },
];

export const DMSCategoriesModules = {
  components: 'COMPONENTS',
  observations: 'DEFECTS',
  notifications: 'NOTIFICATIONS',
  workOrders: 'WORKORDERS',
  checklistQuestions: 'CHECKLIST-QUESTIONS',
};
