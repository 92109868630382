import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';

import { concat, debounce, isEqual, keys, values } from 'lodash';

import Button from '../../../../../common/form/components/button';
import DatePicker from '../../../../../common/form/components/date-picker';
import MultiSelect from '../../../../../common/form/components/multiselect';
import ToggleRadioInput from '../../../../../common/form/components/toggle-radio-input-field';
import RenderIf from '../../../../../common/render-if/components/render-if';
import CustomInputRange from '../../../components/common/input/input-range';
// import SelectUsersTeamsMultiple from '../../../../../common/user-team/components/select-users-teams-multiple';
import SearchInput from '../../../../../common/input/components/search-input';

import { getUsers } from '../../../../members/actions/users-data-actions';
import { getComponentTypeSuggestions, getDefectTypeSuggestions } from '../../../actions/inspection-actions';
import { searchContributors } from '../../observations/actions/observations-api-calls';

import { assigneeFields, observationsTypes } from '../../../constants/defect-constants';

import Helpers from '../../../../../common/helpers';

import { FORMS } from '../../../../../common/constants';

import { participantTypes } from '../../../../../common/user-team/constants/constants';
import { filterParams, severityDefault } from '../../../constants/defect-constants';

import '../../../styles/defects-filter.scss';

const excludeSpecificProp = (object, prop = filterParams.severityFilter, specificValue = severityDefault.value) => {
  return {
    ...object,
    [prop]: !object[prop] || isEqual(object[prop], specificValue) ? null : object[prop],
  };
};

class DefectsFilter extends Component {
  constructor(props) {
    super(props);
    const initialObservationTypes = values(observationsTypes);

    this.getComponentTypeSuggestionsDebounced = debounce(props.getComponentTypeSuggestions, 300);
    this.getDefectTypeSuggestionsDebounced = debounce(props.getDefectTypeSuggestions, 300);
    this.getUsersDebounced = debounce(props.getUsers, 300);
    this.searchContributorsDebounced = debounce(this.handleSearchContributors, 300);
    this.handleSearchObservationTypesDebounced = debounce(this.handleSearchObservationTypes, 300);
    this.state = {
      collaborators: [],
      assignees: [],
      observationsTypesList: initialObservationTypes,
      initialObservationTypes, // Store the initial workOrderTypes
    };
  }
  componentDidMount = () => {
    const { getComponentTypeSuggestions, getDefectTypeSuggestions, getUsers, hideComponentFilters = false } = this.props;
    !hideComponentFilters && getComponentTypeSuggestions();
    getDefectTypeSuggestions();
    getUsers();
    this.handleSearchContributors('', participantTypes.assignee);
    this.handleSearchContributors('', participantTypes.collaborator);
  };

  handleSearchContributors = (searchText, participantType) => {
    const { searchContributors, inspectionId } = this.props;
    searchContributors(
      {
        SearchText: searchText || '',
        ParticipantType: participantType,
        InspectionID: inspectionId,
      },
      users => {
        users = concat(users.Users || [], users.Teams || []);
        if (participantType === participantTypes.assignee) {
          this.setState({ assignees: users });
        } else {
          this.setState({ collaborators: users });
        }
      }
    );
  };

  handleAddContributor = user => {
    const { array } = this.props;
    if (user[assigneeFields.participantType] === participantTypes.assignee) {
      array.push(filterParams.assignees, user);
    } else {
      array.push(filterParams.collaborators, user);
    }
  };

  handleRemoveContributor = user => {
    const { formValues, array } = this.props;
    if (user[assigneeFields.participantType] === participantTypes.assignee) {
      const foundIndex = (formValues[filterParams.assignees] || []).findIndex(contributor => contributor[assigneeFields.id] === user[assigneeFields.id]);
      if (foundIndex > -1) {
        array.remove(filterParams.assignees, foundIndex);
      }
    } else {
      const foundIndex = (formValues[filterParams.collaborators] || []).findIndex(contributor => contributor[assigneeFields.id] === user[assigneeFields.id]);
      if (foundIndex > -1) {
        array.remove(filterParams.collaborators, foundIndex);
      }
    }
  };

  handleComponentSearchInput = e => {
    const { change } = this.props;
    const text = e.target.value;
    this.setState({ componentSearchText: text });
    change(filterParams.componentsSearchFilter, text);
  };

  handleSearchObservationTypes = searchText => {
    const { initialObservationTypes } = this.state;
    if (searchText.trim() === '') {
      // Reset to initial observationsTypesList if searchText is empty
      this.setState({ observationsTypesList: initialObservationTypes });
    } else {
      const filteredObservationTypes = initialObservationTypes.filter(type => type.toLowerCase().includes(searchText.toLowerCase()));
      this.setState({ observationsTypesList: filteredObservationTypes });
    }
  };

  render() {
    const { t } = this.context;

    const { componentTypeSuggestions, defectTypeSuggestions, handleSubmit, resetFilter, users, formValues, submitForm, hideComponentFilters = false } = this.props;
    const { collaborators, assignees, componentSearchText, observationsTypesList } = this.state;

    return (
      <form
        className="filter-form"
        onSubmit={handleSubmit(vals => {
          submitForm(Helpers.removeUnusedKeys(excludeSpecificProp(vals)));
        })}
      >
        <div className="scrollable">
          <div className="grid">
            <Field
              label={'DEFECT_FILTERS.OBSERVATION_TYPE'}
              placeholder={'DEFECT_FILTERS.OBSERVATION_TYPE'}
              id={filterParams.defectMainTypeFilter}
              name={filterParams.defectMainTypeFilter}
              component={MultiSelect}
              data={observationsTypesList || []}
              size={'lg'}
              onSearch={text => {
                this.handleSearchObservationTypesDebounced(text);
              }}
              messages={{ emptyList: <p>{t('READINGS_AND_GAUGES.GROUP_EMPTY_STATE.TEXT')}</p> }}
            />

            <Field
              label={'DEFECT_FILTERS.OBSERVATION_SUB_TYPE'}
              placeholder={'DEFECT_FILTERS.OBSERVATION_SUB_TYPE'}
              id={filterParams.defectTypeFilter}
              name={filterParams.defectTypeFilter}
              component={MultiSelect}
              data={values(defectTypeSuggestions) || []}
              onSearch={text => {
                this.getDefectTypeSuggestionsDebounced(text);
              }}
              onToggle={isOpen => {
                if (isOpen && defectTypeSuggestions?.length === 0) {
                  this.getDefectTypeSuggestionsDebounced('');
                }
              }}
              size={'lg'}
              messages={{ emptyList: <p>{t('READINGS_AND_GAUGES.GROUP_EMPTY_STATE.TEXT')}</p> }}
            />
          </div>
          <RenderIf if={!hideComponentFilters}>
            <div className="grid">
              <Field
                label={'DEFECT_FILTERS.COMPONENT_TYPE'}
                placeholder={'DEFECT_FILTERS.COMPONENT_TYPE'}
                id={filterParams.componentTypeFilter}
                name={filterParams.componentTypeFilter}
                component={MultiSelect}
                data={componentTypeSuggestions || []}
                onSearch={text => {
                  this.getComponentTypeSuggestionsDebounced(text);
                }}
                onToggle={isOpen => {
                  if (isOpen && componentTypeSuggestions?.length === 0) {
                    this.getComponentTypeSuggestionsDebounced('');
                  }
                }}
                size={'lg'}
                messages={{ emptyList: <p>{t('READINGS_AND_GAUGES.GROUP_EMPTY_STATE.TEXT')}</p> }}
              />
              <div className="search-field-wrapper">
                <SearchInput
                  label={'DEFECT_FILTERS.COMPONENT'}
                  onChange={this.handleComponentSearchInput}
                  stripped={false}
                  placeholder={t('KEYWORD')}
                  wrapperClass="filter-form-search__wrapper"
                  value={formValues[filterParams.componentsSearchFilter] ? formValues[filterParams.componentsSearchFilter] : componentSearchText}
                />
              </div>
            </div>
          </RenderIf>
          <div className="grid">
            <Field
              id={filterParams.severityFilter}
              name={filterParams.severityFilter}
              minValue={severityDefault.filter.min}
              maxValue={severityDefault.filter.max}
              isHidden={false}
              component={CustomInputRange}
              label={t('DEFECT_FILTERS.SEVERITY')}
              labelClass="f-secondary-dark defect-form__label defect-form__label--range"
              hideBorder
              type="range"
            />
          </div>
          <div className="grid">
            <Field
              label={'DEFECT_FILTERS.ASSIGNEES'}
              placeholder={'DEFECT_FILTERS.ASSIGNEES'}
              id={filterParams.assignees}
              name={filterParams.assignees}
              component={MultiSelect}
              data={assignees || []}
              valueField={'ID'}
              textField={'Name'}
              onSearch={text => {
                this.searchContributorsDebounced(text, participantTypes.assignee);
              }}
              onToggle={isOpen => {
                if (isOpen && (!assignees || assignees.length === 0)) {
                  this.searchContributorsDebounced('', participantTypes.assignee);
                }
              }}
              size={'lg'}
              messages={{ emptyList: <p>{t('READINGS_AND_GAUGES.GROUP_EMPTY_STATE.TEXT')}</p> }}
            />
            <Field
              label={'DEFECT_FILTERS.COLLABORATORS'}
              placeholder={'DEFECT_FILTERS.COLLABORATORS'}
              id={filterParams.collaborators}
              name={filterParams.collaborators}
              component={MultiSelect}
              data={collaborators || []}
              valueField={'ID'}
              textField={'Name'}
              onSearch={text => {
                this.searchContributorsDebounced(text, participantTypes.collaborator);
              }}
              onToggle={isOpen => {
                if (isOpen && (!collaborators || collaborators.length === 0)) {
                  this.searchContributorsDebounced('', participantTypes.collaborator);
                }
              }}
              size={'lg'}
              messages={{ emptyList: <p>{t('READINGS_AND_GAUGES.GROUP_EMPTY_STATE.TEXT')}</p> }}
            />
          </div>
          <div className="grid">
            <Field
              label={'DEFECT_FILTERS.CREATED_BY'}
              placeholder={'DEFECT_FILTERS.CREATED_BY'}
              id={filterParams.createdByFilter}
              name={filterParams.createdByFilter}
              component={MultiSelect}
              data={users || []}
              valueField={'UserID'}
              textField={'UserName'}
              onSearch={text => {
                this.getUsersDebounced(text);
              }}
              onToggle={isOpen => {
                if (isOpen && (!users || users.length === 0)) {
                  this.getUsersDebounced('');
                }
              }}
              size={'lg'}
              messages={{ emptyList: <p>{t('READINGS_AND_GAUGES.GROUP_EMPTY_STATE.TEXT')}</p> }}
            />
          </div>

          <div className="grid">
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterParams.dateFrom}
              name={filterParams.dateFrom}
              component={DatePicker}
              maxDate={formValues?.[filterParams.dateTo] || null}
              popperPlacement="bottom-end"
              placeholder={'DEFECT_FILTERS.DATE_FROM'}
              label={'DEFECT_FILTERS.DATE_FROM'}
              isClearable={true}
              size={'lg'}
            />
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterParams.dateTo}
              name={filterParams.dateTo}
              component={DatePicker}
              minDate={formValues?.[filterParams.dateFrom] || null}
              popperPlacement="bottom-end"
              placeholder={'DEFECT_FILTERS.DATE_TO'}
              label={'DEFECT_FILTERS.DATE_TO'}
              isClearable={true}
              size={'lg'}
            />
          </div>
          <div className="grid">
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterParams.actionedFrom}
              name={filterParams.actionedFrom}
              component={DatePicker}
              maxDate={formValues?.[filterParams.actionedTo] || null}
              popperPlacement="bottom-end"
              placeholder={'DEFECT_FILTERS.ACTIONED_DATE_FROM'}
              label={'DEFECT_FILTERS.ACTIONED_DATE_FROM'}
              isClearable={true}
              size={'lg'}
            />
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterParams.actionedTo}
              name={filterParams.actionedTo}
              component={DatePicker}
              minDate={formValues?.[filterParams.actionedFrom] || null}
              popperPlacement="bottom-end"
              placeholder={'DEFECT_FILTERS.ACTIONED_DATE_TO'}
              label={'DEFECT_FILTERS.ACTIONED_DATE_TO'}
              isClearable={true}
              size={'lg'}
            />
          </div>
          <div className="grid">
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterParams.closedFrom}
              name={filterParams.closedFrom}
              component={DatePicker}
              maxDate={formValues?.[filterParams.closedTo] || null}
              popperPlacement="bottom-end"
              placeholder={'DEFECT_FILTERS.CLOSED_DATE_FROM'}
              label={'DEFECT_FILTERS.CLOSED_DATE_FROM'}
              isClearable={true}
              size={'lg'}
            />
            <Field
              disabledKeyboardNavigation={true}
              dateFormat="MMMM d, yyyy"
              id={filterParams.closedTo}
              name={filterParams.closedTo}
              component={DatePicker}
              minDate={formValues?.[filterParams.closedFrom] || null}
              popperPlacement="bottom-end"
              placeholder={'DEFECT_FILTERS.CLOSED_DATE_TO'}
              label={'DEFECT_FILTERS.CLOSED_DATE_TO'}
              isClearable={true}
              size={'lg'}
            />
          </div>

          <div className="grid">
            <Field
              id={filterParams.hasNotificationFilter}
              name={filterParams.hasNotificationFilter}
              component={ToggleRadioInput}
              label={t('DEFECT_FILTERS.HAS_NOTIFICATION')}
              className={'multiple-labels-toggle'}
            />
            <Field id={filterParams.hasWorkOrder} name={filterParams.hasWorkOrder} component={ToggleRadioInput} label={t('DEFECT_FILTERS.HAS_WORK_ORDER')} className={'multiple-labels-toggle'} />
          </div>
          <div className="grid">
            <Field id={filterParams.hasFiles} name={filterParams.hasFiles} component={ToggleRadioInput} label={t('DEFECT_FILTERS.HAS_FILES')} className={'multiple-labels-toggle'} />
            <Field
              id={filterParams.has3DLocationFilter}
              name={filterParams.has3DLocationFilter}
              component={ToggleRadioInput}
              label={t('DEFECT_FILTERS.HAS_3D_LOCATION')}
              className={'multiple-labels-toggle'}
            />
          </div>
        </div>
        <div className="buttons">
          <Button type="button" variant="gray-outline" height={'md'} width={'sm'} text={t('RESET_FILTERS')} onClick={resetFilter} />
          <Button height={'md'} width={'sm'} text={t('APPLY_FILTERS', { count: keys(Helpers.removeUnusedKeys(formValues)).length || 0 })} />
        </div>
      </form>
    );
  }
}
DefectsFilter.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  getComponentTypeSuggestions: text => dispatch(getComponentTypeSuggestions(text)),
  getDefectTypeSuggestions: search => dispatch(getDefectTypeSuggestions(search)),
  getUsers: SearchText => dispatch(getUsers({ SearchText })),
  searchContributors: (params, successCallback) => dispatch(searchContributors(params, successCallback)),
  // getDefectPropertyNames: (searchText, callback) => dispatch(getDefectPropertyNames(searchText, callback)),
});

const mapStateToProps = (state, { initialValues }) => {
  const {
    inspectionReducer: { componentTypeSuggestions, defectTypeSuggestions },
    usersTableReducer: { usersTableData },
  } = state;

  return {
    componentTypeSuggestions: componentTypeSuggestions,
    defectTypeSuggestions: defectTypeSuggestions,
    users: usersTableData,
    formValues: excludeSpecificProp(state.form[FORMS.defectFilters]?.values || {}),
    initialValues: { ...initialValues, [filterParams.severityFilter]: initialValues[filterParams.severityFilter] || severityDefault.value },
  };
};

DefectsFilter = reduxForm({
  form: FORMS.defectFilters,
  enableReinitialize: true,
})(DefectsFilter);

export default connect(mapStateToProps, mapDispatchToProps)(DefectsFilter);
