import { isNil } from 'lodash';
import { colorPalette } from '../../../../../common/form/constants/constants';
import Helpers from '../../../../../common/helpers';
import Icon from '../../../../../common/icon/components/icon';
import { PERMISSION_TYPES, PERMISSIONS } from '../../../../../common/permissions-constants';
import PointerDot from '../../../../../common/pointer-dot/pointer-dot';
import SmallDropdown from '../../../../../common/small-dropdown/components/small-dropdown';
import InfoTooltip from '../../../../../common/tooltip/components/info-tooltip';
import { alarmColorPalette, ALARMS, formConstants } from '../constants/alarm-constants';
import { fields, groupActionTypes } from '../constants/constants';
import { DEFAULT_COLOR } from '../constants/measurement-location-constants';
import { formConstants as readingFormConstants } from '../constants/time-series-graph-modal-constants';

export const formatGroupTableCells = (
  value,
  type,
  searchTerm,
  row,
  onDropDownClick,
  t,
  _pointerDotID,
  user,
  onToggleClick,
  _onDeleteRow,
  _statusData,
  _handleChangeStatus,
  _onLinkFileClick,
  _selectedItem,
  _cell,
  _hasLinkDMSFilePermissions,
  onRowClick
) => {
  if (type === fields.toggle) {
    return <Icon onClick={e => onToggleClick(e, row)} name={row.visible ? 'eye' : 'not-visible'} size="sm" />;
  } else if (type === fields.name) {
    // if the name of group is NO_GROUP we translate it, else we leave it as is
    const name = value === 'NO_GROUP' ? t('NO_GROUP') : value;
    return (
      <div className="flex-center">
        <Icon name="folder" size="sm" active />
        <p className="f-primary" title={name}>
          {Helpers.getSearchTermText(name, searchTerm)}
        </p>
      </div>
    );
  }
  if (type === fields.createdAt) {
    return <p className="f-primary">{value || value !== 0 ? Helpers.getDateFromUnix(value) : '-'}</p>;
  }
  if ([fields.editAction, fields.deleteAction].indexOf(type) > -1) {
    // checking if the group is no group so it can't be edited or deleted
    if (row[fields.name] === 'NO_GROUP') return null;
    const canDelete = Helpers.hasAccess({ user, visibleFor: PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].groupDelete.name });
    const canEdit = Helpers.hasAccess({
      user,
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].groupEdit.name],
      id: row && row[fields.createdBy],
      ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].groupCreate.name,
    });

    const isDelete = type === fields.deleteAction;
    const hasPermission = isDelete ? canDelete : canEdit;
    return (
      <Icon
        name={isDelete ? 'trash' : 'pen'}
        size="sm"
        disabled={!hasPermission}
        onClick={e => {
          e.stopPropagation();
          if (hasPermission) {
            onDropDownClick(e, isDelete ? groupActionTypes.delete : groupActionTypes.edit, row);
          }
        }}
      />
    );
  }
  if (fields.changeGroupAction === type) {
    const isActive = row[fields.isActive];

    return isActive ? (
      <PointerDot type="success" text={t('READINGS_AND_GAUGES.CHANGE_MEASUREMENT_GROUP.CURRENT_GROUP')} ignoreTheme popoverClassName={'current-group-popover'} />
    ) : (
      <Icon name={'move-to'} active size="sm" onClick={e => (!isActive ? onDropDownClick(e, row) : null)} />
    );
  }

  if (fields.navigateToGroupAction === type) {
    return (
      <Icon
        name={'arrow-right'}
        size="sm"
        onClick={e => {
          e.stopPropagation();
          onRowClick(row); // onRowClick used, since it does the same as it is expected from the arrow click which is navigateToPath
        }}
      />
    );
  } else {
    return <p className="f-primary">{value ? value : '-'}</p>;
  }
};

export const formatLocationTableCells = (
  value,
  type,
  searchTerm,
  row,
  _onDropdownClick,
  t,
  _pointerDotID,
  _user,
  onToggleClick,
  _onDeleteRow,
  _statusData,
  _handleChangeStatus,
  _onLinkFileClick,
  _selectedItem,
  _cell,
  _hasLinkDMSFilePermissions,
  _onRowClick,
  toggleModalAction
) => {
  if (type === fields.toggle) {
    return <Icon name={row.visible ? 'eye' : 'not-visible'} size="sm" onClick={e => onToggleClick(e, row)} />;
  } else if (type === fields.name) {
    const color = colorPalette.hasOwnProperty(row[fields.color]) ? colorPalette[row[fields.color]] : colorPalette[DEFAULT_COLOR];
    return (
      <div className="measurement-name">
        <div
          style={{
            backgroundColor: color,
          }}
          className="circle"
        />
        <p className="upload-name-col__title f-primary" title={value}>
          {Helpers.getSearchTermText(value, searchTerm)}
        </p>
      </div>
    );
  } else if (type === fields.createdAt) {
    return <p className="f-primary">{value || value !== 0 ? Helpers.getDateFromUnix(value) : '-'}</p>;
  } else if (type === fields.alarmStatusColor) {
    return (
      <>
        {value ? (
          <InfoTooltip
            customComponent={
              <div className="reading-popover-content capitalized">
                <span>{t('ALARM_TRIGGERED', { alarmName: row[fields.alarmStatusName] })}</span>
              </div>
            }
            offsetY={10}
            offsetX={-200}
            Component={() => (
              <div className={`alarm-icon ${alarmColorPalette[value].name}`}>
                <Icon size="sm" name="alarm-bell-icon" handleHover={false} disabled={true} />
              </div>
            )}
            componentProps={{ title: '' }}
            containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
          />
        ) : null}
      </>
    );
  } else if (fields.toggleLinkAllMPsFromMLToCPAction === type) {
    // show the add MPs from ML only if that ML has MPs
    if (row[fields.totalNumberOfMeasurementPoints] > 0) {
      const areAllMPsFromMLLinked = row[fields.totalNumberOfMeasurementPoints] === row[fields.numberOfLinkedMeasurementPoints];
      return (
        <div className={`circle ${areAllMPsFromMLLinked ? 'danger' : 'success'} `}>
          <Icon
            name={areAllMPsFromMLLinked ? 'close' : 'plus'} // TODO: toggle plus and minus icons
            size="sm"
            onClick={e => {
              e.stopPropagation();
              toggleModalAction(row, !areAllMPsFromMLLinked); // areAllMPsFromMLLinked will determine if link or unlink will be triggered
            }}
          />
        </div>
      );
    } else return null;
  } else {
    return <p className="f-primary">{value ? value : '-'}</p>;
  }
};

// add the quick add and alarm field once we confirm with BE the response
export const formatPointTableCells = (
  value,
  type,
  searchTerm,
  row,
  _onDropdownClick,
  t,
  _pointerDotID,
  user,
  onToggleClick,
  _onDeleteRow,
  _statusData,
  _handleChangeStatus,
  _onLinkFileClick,
  _selectedItem,
  _cell,
  _hasLinkDMSFilePermissions,
  _onRowClick,
  toggleModalAction
) => {
  if (type === fields.toggle) {
    return <Icon name={row.visible ? 'eye' : 'not-visible'} size="sm" onClick={e => onToggleClick(e, row)} />;
  } else if (type === fields.name) {
    const color = colorPalette.hasOwnProperty(row[fields.color]) ? colorPalette[row[fields.color]] : colorPalette[DEFAULT_COLOR];
    return (
      <div className="measurement-name">
        <div
          style={{
            backgroundColor: color,
          }}
          className="circle"
        />
        <p className="upload-name-col__title f-primary" title={value}>
          {Helpers.getSearchTermText(value, searchTerm)}
        </p>
      </div>
    );
  } else if (type === fields.createdAt) {
    return <p className="f-primary">{value || value !== 0 ? Helpers.getDateFromUnix(value) : '-'}</p>;
  } else if (type === fields.addReading) {
    const canCreateReading = Helpers.hasAccess({
      user,
      visibleFor: PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].readingCreate.name,
    });
    return (
      <div className={`circle ${!canCreateReading ? 'disabled' : ''}`}>
        <Icon
          name="plus"
          size="sm"
          onClick={e => {
            e.stopPropagation();
            if (canCreateReading) {
              toggleModalAction(row);
            }
          }}
        />
      </div>
    );
  } else if (type === fields.alarmStatusColor) {
    return (
      <>
        {value ? (
          <InfoTooltip
            customComponent={
              <div className="reading-popover-content capitalized">
                <span>{t('ALARM_TRIGGERED', { alarmName: row[fields.alarmStatusName] })}</span>
              </div>
            }
            offsetY={10}
            offsetX={-200}
            Component={() => (
              <div className={`alarm-icon ${alarmColorPalette[value].name}`}>
                <Icon size="sm" name="alarm-bell-icon" handleHover={false} disabled={true} />
              </div>
            )}
            componentProps={{ title: '' }}
            containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
          />
        ) : null}
      </>
    );
  } else if (type === fields.toggleLinkMPToCPAction) {
    return (
      <div className="circle">
        <Icon
          name="plus" // TODO: toggle plus and minus icons
          size="sm"
          onClick={e => {
            e.stopPropagation();
            toggleModalAction(row);
          }}
        />
      </div>
    );
  } else {
    return <p className="f-primary">{value ? value : '-'}</p>;
  }
};

export const formatAlarmSectionList = (value, type, t) => {
  if (type === formConstants.fields.enableDefectCreation.name) {
    return value ? t('YES_CAPITALIZED') : t('NO_CAPITALIZED');
  } else if (type === formConstants.fields.enableNotificationCreation.name) {
    return value ? t('YES_CAPITALIZED') : t('NO_CAPITALIZED');
  } else if (type === formConstants.fields.colour.name) {
    return (
      <div className={`alarm-icon ${alarmColorPalette[value].name}`}>
        <Icon size="sm" name="alarm-bell-icon" handleHover={false} disabled={true} />
      </div>
    );
  } else if (type === formConstants.fields.alarmLevel.name) {
    return <p className="f-primary">{!isNil(value) ? value : '-'}</p>;
  }
  return value ? value : '-';
};

export const calculateReadingStats = readings => {
  const numReadings = readings && readings.map(reading => parseFloat(reading)).filter(val => !isNaN(val));
  if (numReadings.length === 0) {
    return { max: '-', min: '-', avg: '-', median: '-' };
  }

  const max = Math.max(...numReadings);
  const min = Math.min(...numReadings);
  const avg = numReadings.reduce((acc, val) => acc + val, 0) / numReadings.length;

  const sorted = [...numReadings].sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);
  const median = sorted.length % 2 !== 0 ? sorted[middle] : (sorted[middle - 1] + sorted[middle]) / 2;

  return { max, min, avg, median };
};

export const formatMeasurementReadingsList = (
  value,
  type,
  _searchTerm,
  row,
  _onDropdownClick,
  t,
  _pointerDotID,
  _user,
  _onToggleClick,
  _onDeleteRow,
  _statusData,
  _handleChangeStatus,
  _onLinkFileClick,
  _selectedItem,
  _cell,
  _hasLinkDMSFilePermissions,
  _onRowClick,
  toggleModalAction,
  menuOptions
) => {
  if (type === readingFormConstants.fields.values) {
    return value;
  } else if (type === readingFormConstants.fields.measurementDate) {
    return <p className="f-primary">{value || value !== 0 ? Helpers.getDateFromUnix(value) : '-'}</p>;
  } else if (type === readingFormConstants.fields.averageValue) {
    let alarmTitle = null;
    const isChecklistAndProcedureOrigin = row[readingFormConstants.fields.isChecklistAndProcedureOrigin];
    const alarm = row[readingFormConstants.fields.alarm];
    if (alarm) {
      alarmTitle = ALARMS.find(a => a.id === alarm[fields.name]).displayName;
    }
    return (
      <>
        <InfoTooltip
          customComponent={
            <div className="reading-popover-content">
              <span>
                {t('MP.TIME_SERIES_GRAPH_MODAL_TAB.MEASUREMENT_READINGS.MEASUREMENT_READING.TOOLTIP')} {row[readingFormConstants.fields.averageValue]} {row[readingFormConstants.fields.unit] || ''}
              </span>
              {row[readingFormConstants.fields.values].map((readingValue, index) => {
                return (
                  <div key={readingValue} className="reading-popover-content__item">
                    <label>{t('READINGS_AND_GAUGES.MEASUREMENT_READINGS_FORM.READING', { number: index + 1 })}</label>
                    <p>
                      {readingValue}{' '}
                      <span>
                        {''}
                        {row[readingFormConstants.fields.unit] || ''}
                      </span>
                    </p>
                  </div>
                );
              })}
            </div>
          }
          offsetY={10}
          offsetX={-20}
          Component={() => (
            <div className="reading-popover">
              <p className="f-primary">
                {isNil(value) ? 0 : value}
                &nbsp;
                <span>{row[readingFormConstants.fields.unit] || ''}</span>
              </p>

              <Icon name="info" size="sm" />
            </div>
          )}
          containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
        />
        {isChecklistAndProcedureOrigin && (
          // on purpose only C&P is handled, since the Client confirmed a reading will never be possible to create from any other module than C&P
          <InfoTooltip
            className="d-flex"
            customComponent={
              <div className="reading-popover-content">
                <span>{t('MEASUREMENT_READING.CAPTURED_SOURCE', { module: t('CHECKLIST_TEMPLATE.STEP_2') })}</span>
              </div>
            }
            offsetY={10}
            offsetX={-20}
            Component={() => {
              return <Icon name="list" size="sm" />;
            }}
            containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
          />
        )}
        {alarm && alarmTitle && (
          <InfoTooltip
            className="d-flex"
            customComponent={
              <div className="reading-popover-content">
                <span>{t('ALARM_TRIGGERED', { alarmName: t(alarmTitle) })}</span>
              </div>
            }
            offsetY={10}
            offsetX={-20}
            Component={() => {
              return (
                <div key={`${alarm[fields.name]}`} className={`alarm-icon ${alarmColorPalette[alarm[fields.color]].name}`}>
                  <Icon size="sm" name="alarm-bell-icon" handleHover={false} disabled={true} />
                </div>
              );
            }}
            containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
          />
        )}
      </>
    );
  } else if (type === readingFormConstants.fields.editReading) {
    const actionsMenu = menuOptions(row) || [];
    return (
      <SmallDropdown
        className="page-navigation__wrapper__title-container__menu__dropdown"
        actionsMenu={actionsMenu}
        getClickAction={(action, b, selfClose, e) => {
          e?.preventDefault && e.preventDefault();
          selfClose();
          if (action) {
            if (typeof action === 'function') {
              action(row);
            }
          }
        }}
        offsetX={10}
      />
    );
  } else if (type === readingFormConstants.fields.files) {
    const hasFiles = value?.length > 0;
    return (
      <div className="reading-files-wrapper" onClick={() => toggleModalAction(row)}>
        <span className="f-primary">{hasFiles ? value.length : '-'}</span>
        {hasFiles && <Icon size="sm" name="paperclip" handleHover={false} />}
      </div>
    );
  }
  return value ? value : '-';
};

export const formatSelectedMeasurementPoints = (value, type, searchTerm, row, onDropdownClick) => {
  if (type === fields.name) {
    const color = colorPalette.hasOwnProperty(row[fields.color]) ? colorPalette[row[fields.color]] : colorPalette[DEFAULT_COLOR];
    return (
      <div className="linked-measurement-point-name-wrapper">
        <div className="linked-measurement-point-name-wrapper__name">
          <div
            style={{
              backgroundColor: color,
            }}
            className="circle"
          />
          <p className="upload-name-col__title f-primary" title={value}>
            {Helpers.getSearchTermText(value, searchTerm)}
          </p>
        </div>
        <div className="linked-measurement-point-name-wrapper__breadcrumb">
          <p className="f-secondary-dark" title={`${row?.[fields.readingsAndGaugesGroupName]}/${row?.[fields.measurementLocationName]}`}>{`${Helpers.truncateText(
            row?.[fields.readingsAndGaugesGroupName],
            20
          )}/${Helpers.truncateText(row?.[fields.measurementLocationName], 20)}`}</p>
        </div>
      </div>
    );
  } else if (type === 'actions') {
    return <Icon name="close" size="sm" onClick={() => onDropdownClick(row)} />;
  }
  return value ? value : '-';
};
