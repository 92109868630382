import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import TableTemplate from '../../../../common/double-table/components/table-template';
import EmptyState from '../../../../common/empty-state-v2/components/empty-state';
import TruncatedText from '../../../../common/form/components/truncated-text';
import Loader from '../../../../common/global-loader/components/simple-loader';
import Helpers from '../../../../common/helpers';
import Icon from '../../../../common/icon/components/icon';
import RenderIf from '../../../../common/render-if/components/render-if';
import InfoTooltip from '../../../../common/tooltip/components/info-tooltip';
import WarningIndicator from '../../../../common/warning-indicator/components/warning-indicator';
import { statuses } from '../../constants/work-order-constants';
import { checklistTemplateFields as fields, fields as WOFields } from './constants/work-order-right-side-constants';

const tableConfig = [
  {
    key: fields.isSubmitted,
    type: fields.isSubmitted,
    class: 'is-submitted-icon',
  },
  {
    title: 'NAME',
    key: fields.name,
    type: fields.name,
    class: 'cp-name',
  },
  {
    title: 'WORK_ORDER.CHECKLISTS.PROGRESS',
    key: fields.questionsTotal,
    type: fields.questionsTotal,
    class: 'checklist-progress',
  },
];

const WorkOrderSectionChecklists = ({ module }, { t }) => {
  const { workOrderFormValues, openFillInChecklistModalData, workOrderChecklistsLoading } = module; // we extract workOrderFormValues since the latest status changes are tied to it

  if (workOrderChecklistsLoading) {
    return <Loader isLoading={true} customText={t('LOADING')} className="work-order-section-checklist-loader" width={100} height={100} divStyle={{ textAlign: 'center ' }} />;
  }
  const textForEmptyState =
    workOrderFormValues[WOFields.status] === statuses.draft.value || workOrderFormValues[WOFields.status] === statuses.approved.value || workOrderFormValues[WOFields.status] === statuses.raised.value
      ? 'WORK_ORDERS.NO_CP_DRAFT_RAISED'
      : 'WORK_ORDERS.NO_CP';

  return (
    <div className="work-order-section-checklist-procedures">
      <RenderIf if={module && module.workOrderChecklists?.length > 0}>
        <RenderIf if={[statuses.completed.value, statuses.closed.value, statuses.inProgress.value].indexOf(workOrderFormValues[WOFields.status]) === -1}>
          <WarningIndicator title={t('WORK_ORDER.WARNING.CHECKLIST_AND_PROCEDURES_NOT_FILLABLE.TITLE')} description={t(`WORK_ORDER.WARNING.CHECKLIST_AND_PROCEDURES_NOT_FILLABLE.DESCRIPTION`)} />
        </RenderIf>
        <div className="notification-WO-tab__table">
          <TableTemplate
            data={module.workOrderChecklists}
            handleFormatCells={(value, type, index, item) => {
              if (type === fields.name) {
                return (
                  <div className="checklist-name-container">
                    <TruncatedText text={value} key={item[fields.id]} />
                    <InfoTooltip
                      actionsMenu={{
                        [t('WORK_ORDER.CHECKLISTS.SUBMITTED_DATE')]: item[fields.submittedAt] ? Helpers.getDateFromUnix(item[fields.submittedAt], 'DD MMM, YYYY hh:mm A') : null,
                        [t('WORK_ORDER.CHECKLISTS.SUBMITTED_BY')]: item[fields.submittedBy],
                      }}
                      offsetY={8}
                      offsetX={8}
                      Component={() => <Icon name="info" size="sm" />}
                      title={value}
                      containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
                    />
                  </div>
                );
              } else if (type === fields.isSubmitted) {
                return (
                  <div className="checklist-is-submitted-container">{value && <Icon name="checkmark" className="checklist-is-submitted-container__icon" active handleHover={false} size="sm" />}</div>
                );
              } else if (type === fields.questionsTotal) {
                return <p className={`${item[fields.isSubmitted] ? 'f-secondary-green' : 'f-secondary-red'} light-bold`}>{`${item[fields.questionsAnswered]}/${item[fields.questionsTotal]}`}</p>;
              }

              return Helpers.formatCell(value, type, index, item);
            }}
            showSearchBar={false}
            tableConfig={tableConfig}
            showInfo={false}
            onRowClick={row => openFillInChecklistModalData(row, false)}
            noDataText={t('WORK_ORDERS.NO_CP_TEMPLATES')}
          />
        </div>
      </RenderIf>
      <RenderIf if={module && isEmpty(module.workOrderChecklists)}>
        <EmptyState transparent showButton={false} emptyStateText={t(textForEmptyState)} />
      </RenderIf>
    </div>
  );
};

WorkOrderSectionChecklists.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default WorkOrderSectionChecklists;
