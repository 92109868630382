import { debounce, isEqual, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm } from 'redux-form';
import AdvancedFilterCustomPropertyField from '../../../../../common/advanced-filter-custom-property/components/advanced-filter-custom-property';
import { FORMS } from '../../../../../common/constants';
import Button from '../../../../../common/form/components/button';
import DatePicker from '../../../../../common/form/components/date-picker';
import CustomInput from '../../../../../common/form/components/input';
import MultiSelect from '../../../../../common/form/components/multiselect';
import ToggleRadioInput from '../../../../../common/form/components/toggle-radio-input-field';
import Helpers from '../../../../../common/helpers';
import SearchInput from '../../../../../common/input/components/search-input';
import RenderIf from '../../../../../common/render-if/components/render-if';
import { getUsers } from '../../../../members/actions/users-data-actions';
import { getNotificationPropertyNames } from '../../../actions/inspection-actions';
import { filterFields, getSourceObjectForDropdown, notificationTypes } from '../../../constants/notification-constants';
import '../../../styles/defects-filter.scss';
import CustomInputRange from '../../common/input/input-range';
import { validate } from '../validators/notification-filters-validator';

const severityDefaultValue = { min: 1, max: 10 };

const excludeSpecificProp = (object, prop = filterFields.severityFilter, specificValue = severityDefaultValue) => {
  return {
    ...object,
    [prop]: !object[prop] || isEqual(object[prop], specificValue) ? null : object[prop],
  };
};

let NotificationFilter = (
  { handleSubmit, resetFilter, closeModal, getUsers, users, formValues, submitForm, change, getNotificationPropertyNames, hideComponentFilters, hideCustomPropsFilter, ...rest },
  { t }
) => {
  const initialNotificationTypes = values(notificationTypes);
  const initialSourceTypes = getSourceObjectForDropdown(t);

  const [componentSearchText, setComponentSearchText] = useState('');
  const [notificationTypesList, setNotificationTypesList] = useState(initialNotificationTypes);
  const [notificationSourceList, setNotificationSourceList] = useState(initialSourceTypes);
  useEffect(() => {
    getUsers();
  }, [getUsers]);
  const getUsersDebounced = debounce(getUsers, 300);

  const handleComponentSearchInput = e => {
    const text = e.target.value;
    setComponentSearchText(text);
    change(filterFields.componentsSearchFilter, text);
  };

  const handleSearchNotificationTypes = searchText => {
    if (searchText.trim() === '') {
      // Reset to initial notificationTypes if searchText is empty
      setNotificationTypesList(initialNotificationTypes);
    } else {
      const filteredNotificationTypes = initialNotificationTypes.filter(type => type.toLowerCase().includes(searchText.toLowerCase()));
      setNotificationTypesList(filteredNotificationTypes);
    }
  };

  const handleSearchSourceTypes = searchText => {
    if (searchText.trim() === '') {
      // Reset to initial notificationSourceList if searchText is empty
      setNotificationSourceList(initialSourceTypes);
    } else {
      const filteredSourceTypes = initialSourceTypes.filter(type => type.label?.toLowerCase().includes(searchText.toLowerCase()));
      // TODO: improvement could be to also search by the type.value (the module names)
      setNotificationSourceList(filteredSourceTypes);
    }
  };

  return (
    <form className="filter-form" onSubmit={handleSubmit(vals => submitForm(Helpers.removeUnusedKeys(excludeSpecificProp(vals))))}>
      <div className="scrollable">
        <div className="grid">
          <Field
            label={'NOTIFICATION_FILTERS.NOTIFICATION_TYPE'}
            placeholder={'NONE'}
            id={filterFields.typeFilter}
            name={filterFields.typeFilter}
            component={MultiSelect}
            data={notificationTypesList || []}
            size={'lg'}
            onSearch={text => {
              handleSearchNotificationTypes(text);
            }}
            messages={{ emptyList: <p>{t('READINGS_AND_GAUGES.GROUP_EMPTY_STATE.TEXT')}</p> }}
          />

          <Field
            label={'NOTIFICATION_FILTERS.TRIGGERED_BY'}
            placeholder={'NONE'}
            id={filterFields.createdByFilter}
            name={filterFields.createdByFilter}
            component={MultiSelect}
            data={users || []}
            valueField={'UserID'}
            textField={'UserName'}
            onSearch={text => getUsersDebounced(text)}
            onToggle={isOpen => {
              if (isOpen && (!users || users.length === 0)) {
                getUsersDebounced('');
              }
            }}
            size={'lg'}
            messages={{ emptyList: <p>{t('READINGS_AND_GAUGES.GROUP_EMPTY_STATE.TEXT')}</p> }}
          />
        </div>

        <div className="grid">
          <RenderIf if={!hideComponentFilters}>
            <div className="search-field-wrapper">
              <SearchInput
                label={'DEFECT_FILTERS.COMPONENT'}
                onChange={handleComponentSearchInput}
                stripped={false}
                placeholder={t('KEYWORD')}
                wrapperClass="filter-form-search__wrapper"
                value={formValues[filterFields.componentsSearchFilter] ? formValues[filterFields.componentsSearchFilter] : componentSearchText}
              />
            </div>
          </RenderIf>

          <Field
            label={'NOTIFICATIONS.SOURCE'}
            placeholder={'NONE'}
            id={filterFields.sourceFilter}
            name={filterFields.sourceFilter}
            component={MultiSelect}
            data={notificationSourceList || []}
            size={'lg'}
            onSearch={text => {
              handleSearchSourceTypes(text);
            }}
            messages={{ emptyList: <p>{t('READINGS_AND_GAUGES.GROUP_EMPTY_STATE.TEXT')}</p> }}
          />
        </div>

        <div className="grid">
          <Field
            id={filterFields.severityFilter}
            name={filterFields.severityFilter}
            minValue={severityDefaultValue.min}
            maxValue={severityDefaultValue.max}
            isHidden={false}
            component={CustomInputRange}
            label={t('DEFECT_FILTERS.SEVERITY')}
            labelClass="defect-form__label defect-form__label--range"
            hideBorder
            type="range"
            size={'lg'}
          />

          <Field
            label={'NOTIFICATIONS.CREATED_IN_LAST_DAYS'}
            placeholder={'NUMBER_OF_DAYS'}
            id={filterFields.createdInLastXDaysFilter}
            name={filterFields.createdInLastXDaysFilter}
            component={CustomInput}
            type="number"
            size={'lg'}
          />
        </div>

        <div className="grid">
          <Field
            disabledKeyboardNavigation={true}
            dateFormat="MMMM d, yyyy"
            id={filterFields.dateFrom}
            name={filterFields.dateFrom}
            component={DatePicker}
            maxDate={formValues?.[filterFields.dateTo] || null}
            popperPlacement="bottom-end"
            placeholder={'DEFECT_FILTERS.DATE_FROM'}
            label={'DEFECT_FILTERS.DATE_FROM'}
            isClearable={true}
            size={'lg'}
          />
          <Field
            disabledKeyboardNavigation={true}
            dateFormat="MMMM d, yyyy"
            id={filterFields.dateTo}
            name={filterFields.dateTo}
            component={DatePicker}
            minDate={formValues?.[filterFields.dateFrom] || null}
            popperPlacement="bottom-end"
            placeholder={'DEFECT_FILTERS.DATE_TO'}
            label={'DEFECT_FILTERS.DATE_TO'}
            isClearable={true}
            size={'lg'}
          />
        </div>
        <div className="grid">
          <Field
            id={filterFields.hasWorkOrdersFilter}
            name={filterFields.hasWorkOrdersFilter}
            component={ToggleRadioInput}
            label={t('NOTIFICATION_FILTERS.HAS_WORK_ORDER')}
            className={'multiple-labels-toggle'}
          />
          <RenderIf if={!hideComponentFilters}>
            <Field
              id={filterFields.hasLinkedEquipment}
              name={filterFields.hasLinkedEquipment}
              component={ToggleRadioInput}
              label={t('NOTIFICATION_FILTERS.HAS_LINKED_EQUIPMENT')}
              className={'multiple-labels-toggle'}
            />
          </RenderIf>
        </div>
        <div className="grid">
          <Field id={filterFields.hasFiles} name={filterFields.hasFiles} component={ToggleRadioInput} label={t('NOTIFICATION_FILTERS.HAS_FILES')} className={'multiple-labels-toggle'} />
          <Field
            id={filterFields.has3DLocationFilter}
            name={filterFields.has3DLocationFilter}
            component={ToggleRadioInput}
            label={t('NOTIFICATION_FILTERS.HAS_3D_LOCATION')}
            className={'multiple-labels-toggle'}
          />
        </div>
        <RenderIf if={!hideCustomPropsFilter}>
          <FieldArray
            name={filterFields.properties}
            component={AdvancedFilterCustomPropertyField}
            fetchCustomProps={getNotificationPropertyNames}
            form={FORMS.notificationFilters}
            formValues={formValues}
          />
        </RenderIf>
      </div>
      <div className="buttons">
        <Button type="button" variant="gray-outline" height={'md'} width={'sm'} text={t('CANCEL')} onClick={closeModal} />
        <Button type="button" variant="gray-outline" height={'md'} width={'sm'} text={t('RESET_FILTERS')} onClick={resetFilter} />
        <Button height={'md'} width={'sm'} text={t('APPLY_FILTERS', { count: Object.keys(Helpers.removeUnusedKeys(formValues)).length || 0 })} />
      </div>
    </form>
  );
};

NotificationFilter.defaultProps = {
  hideComponentFilters: false,
  hideCustomPropsFilter: false,
};

NotificationFilter.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  users: PropTypes.array,
  formValues: PropTypes.object,
  submitForm: PropTypes.func.isRequired,
};

NotificationFilter.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  getUsers: searchText => dispatch(getUsers({ SearchText: searchText })),
  getNotificationPropertyNames: (searchText, callback) => dispatch(getNotificationPropertyNames(searchText, callback)),
});

const mapStateToProps = (state, { initialValues }) => {
  const {
    usersTableReducer: { usersTableData },
  } = state;

  return {
    users: usersTableData,
    formValues: excludeSpecificProp(state.form[FORMS.notificationFilters]?.values || {}),
    initialValues: { ...initialValues, [filterFields.severityFilter]: initialValues[filterFields.severityFilter] || severityDefaultValue },
  };
};

NotificationFilter = reduxForm({
  form: FORMS.notificationFilters,
  validate: validate,
  enableReinitialize: true,
})(NotificationFilter);

export default connect(mapStateToProps, mapDispatchToProps)(NotificationFilter);
