import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { change, isDirty, isPristine } from 'redux-form';
import { FORMS } from '../../../../../common/constants';
import Icon from '../../../../../common/icon/components/icon';
import { fetchComponentsToLink } from '../../../../document-management/actions/dm-api-calls';
import { getObservationTypes } from '../../../actions/inspection-actions';
import { fields } from '../constants/constants';
import '../styles/new-observation-form-wrapper.scss';
import NewObservationForm from './new-observation-form';

const NewObservationFormWrapper = ({ inspectionId, onCancelAction, onCreateAction, fetchComponents, changeField, observationTypes, getObservationTypes, isDirty, isPristine }, { t }) => {
  const [selectedComponent, setSelectedComponent] = useState([]);

  const fetchInitialData = useCallback(() => {
    if (isEmpty(observationTypes)) {
      getObservationTypes();
    }
  }, [observationTypes, getObservationTypes]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  const linkComponentToObservation = items => {
    if (isEmpty(items)) {
      if (selectedComponent) {
        setSelectedComponent([]);
        changeField(fields.componentId, null);
      } else {
        return;
      }
    } else {
      setSelectedComponent([items[0]]);
      changeField(fields.componentId, items[0]?.[fields.id]);
    }
  };

  return (
    <div className="new-observation-form-wrapper">
      <div className="f-primary mb-32 new-observation-form-wrapper__label">
        <Icon name="info" handleHover={false} size="sm" />
        {t('WORK_ORDER.CREATE_OBSERVATION_MODAL_LABEL')}
      </div>
      <NewObservationForm
        onCancel={() => onCancelAction(isDirty)}
        onSubmit={onCreateAction}
        fetchComponents={fetchComponents}
        handleOnComponentSelect={linkComponentToObservation}
        inspectionId={inspectionId}
        observationTypes={observationTypes}
        selectedComponent={selectedComponent}
        isDirty={isDirty}
        isPristine={isPristine}
      />
    </div>
  );
};

NewObservationFormWrapper.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  observationTypes: state.inspectionReducer.observationTypes,
  isDirty: isDirty(FORMS.newObservationForm)(state),
  isPristine: isPristine(FORMS.newObservationForm)(state),
});

const mapDispatchToProps = dispatch => ({
  fetchComponents: (filters, callback, loadingCallback) => dispatch(fetchComponentsToLink(filters, callback, loadingCallback)),
  changeField: (fieldName, value) => dispatch(change(FORMS.newObservationForm, fieldName, value)),
  getObservationTypes: () => dispatch(getObservationTypes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewObservationFormWrapper);
