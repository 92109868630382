import { PERMISSIONS, PERMISSION_TYPES } from '../../../../../common/permissions-constants';

export const commentsField = 'Comments';

export const commentFields = {
  comment: {
    name: 'Comment',
    label: 'ISOLATION_CERTIFICATE.COMMENT_LABEL',
    maxChars: 500,
  },
  sectionId: {
    name: 'ID',
  },
  moduleSectionId: {
    name: 'ModuleSectionId',
  },
  createdBy: {
    name: 'CreatedByUser',
  },
  createdAt: {
    name: 'CreatedAt',
  },
  commentText: {
    name: 'Value',
  },
  id: {
    name: 'ID',
  },
};

export const fields = {
  id: 'ID',
  name: 'Name',
  permitTemplate: 'PermitTemplate',
  templateName: 'TemplateName',
  isolationCertificates: 'IsolationCertificates',
  status: 'Status',
  createDate: 'CreatedAt',
  createdBy: 'CreatedBy',
  createdById: 'CreatedByID',
  createdByName: 'CreatedByName',
  issuedTo: 'IssuedTo',
  issuedAt: 'IssuedAt',
  statusCustom: 'statusCustom',
  permitType: 'Type',
  keybox: 'Keybox',
  keyboxes: 'Keyboxes',
  keys: 'Keys',
  key: 'Key',
  startDate: 'StartDate',
  reissuedAt: 'ReissuedAt',
  colour: 'Colour',
  cameraPosition: 'CameraPosition',
  sectionId: 'SectionID',
};

export const columnTypes = {
  status: 'status',
  date: 'date',
  statusCustom: 'statusCustom',
  keyboxes: 'keyboxes',
  keys: 'keys',
  isolationCertificates: 'isolationCertificates',
  permitType: 'permitType',
};

export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const filterProps = {
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  searchText: 'SearchText',
  status: 'StatusFilter',
  archived: 'ArchivedFilter',
  onHold: 'OnHoldFilter',
  singleSelected: 'SingleSelected',
  hasNext: 'HasNext',
  totalItems: 'TotalItems',
  projectId: 'ProjectID',
  statusFilter: 'StatusFilter',
  permitType: 'PermitType',
  issuedDateFrom: 'IssuedDateFrom',
  issuedDateTo: 'IssuedDateTo',
  issuedTo: 'IssuedToFilter',
  dateFrom: 'DateFrom',
  dateTo: 'DateTo',
  createdBy: 'CreatedByFilter',
  handbackedDateFrom: 'HandBackedDateFrom',
  handbackedDateTo: 'HandBackedDateTo',
  handbackedTo: 'HandBackedToFilter',
};

export const defaultFilters = {
  [filterProps.sortByColumn]: fields.startDate,
  [filterProps.sortDirection]: sortDirection.asc,
  [filterProps.perPage]: 20,
  [filterProps.lastSeen]: 0,
  [filterProps.searchText]: '',
  [filterProps.hasNext]: false,
  [filterProps.status]: [],
};

export const tableConfigLite = [
  {
    title: 'PERMITS_TABLE.ID',
    key: fields.id,
    enableSort: true,
  },
  {
    title: 'PERMITS_TABLE.NAME',
    key: fields.name,
    enableSort: true,
  },
  {
    title: 'PERMITS_TABLE.START_DATE',
    key: fields.startDate,
    enableSort: true,
    type: columnTypes.date,
  },
  {
    key: fields.statusCustom,
    type: columnTypes.statusCustom,
    className: 'inline-right',
  },
];

export const tableConfig = [
  {
    title: 'PERMITS_TABLE.ID',
    key: fields.id,
    enableSort: true,
  },
  {
    title: 'PERMITS_TABLE.NAME',
    key: fields.name,
    enableSort: true,
  },
  {
    title: 'PERMITS_TABLE.PERMIT_TYPE',
    key: fields.permitTemplate,
    type: columnTypes.permitType,
    className: 'inline-left',
  },
  {
    title: 'PERMITS_TABLE.STATUS',
    key: fields.status,
    type: columnTypes.status,
    enableSort: true,
  },
  {
    title: 'PERMITS_TABLE.ISSUED_DATE',
    key: fields.issuedAt,
    enableSort: true,
    type: columnTypes.date,
  },
  {
    title: 'PERMITS_TABLE.ISSUED_TO',
    key: fields.issuedTo,
    enableSort: true,
  },
  {
    title: 'PERMITS_TABLE.LINKED_ISOLATION_CERTIFICATES',
    key: fields.isolationCertificates,
    type: columnTypes.isolationCertificates,
  },
  {
    title: 'PERMITS_TABLE.KEYBOXES',
    key: fields.keyboxes,
    type: columnTypes.keyboxes,
  },
  {
    title: 'PERMITS_TABLE.KEYS',
    key: fields.keys,
    type: columnTypes.keys,
  },
  {
    title: 'PERMITS_TABLE.CREATE_DATE',
    key: fields.createDate,
    enableSort: true,
    type: columnTypes.date,
  },
  {
    title: 'PERMITS_TABLE.REISSUED_DATE',
    key: fields.reissuedAt,
    enableSort: true,
    type: columnTypes.date,
  },
  {
    title: 'PERMITS_TABLE.CREATED_BY',
    key: fields.createdBy,
    enableSort: true,
  },
];

export const statuses = {
  draft: {
    value: 'DRAFT',
    title: 'PERMITS.DRAFT',
    icon: 'clock',
    skipPermissionCheck: true,
    editPermission: PERMISSIONS[PERMISSION_TYPES.permits].edit.name,
    ownershipPermission: PERMISSIONS[PERMISSION_TYPES.permits].create.name,
    isRegularStatus: true,
    goToStatuses: ['RAISED'],
    modalTitle: 'PERMIT_STATUS.DRAFT_TITLE',
  },
  raised: {
    value: 'RAISED',
    title: 'PERMITS.RAISED',
    icon: 'clock-filled',
    editPermission: PERMISSIONS[PERMISSION_TYPES.permits].edit.name,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.permits].edit.name,
    ownershipPermission: PERMISSIONS[PERMISSION_TYPES.permits].create.name,
    goToStatuses: ['APPROVED'],
    isRegularStatus: true,
    modalTitle: 'PERMIT_STATUS.RAISED_TITLE',
  },
  approved: {
    value: 'APPROVED',
    title: 'PERMITS.APPROVED',
    icon: 'checkmark-rounded',
    iconProps: {
      active: true,
    },
    iconFilledWhenActive: true,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.permits].toApprove.name,
    editPermission: PERMISSIONS[PERMISSION_TYPES.permits].approveEdit.name,
    goToStatuses: ['AUTHORISED'],
    isRegularStatus: true,
    sectionName: 'Approved',
    modalTitle: 'PERMIT_STATUS.APPROVED_TITLE',
  },
  rejected: {
    value: 'REJECTED',
    title: 'PERMITS.REJECTED',
    icon: 'alert-octagon',
    editPermission: PERMISSIONS[PERMISSION_TYPES.permits].edit.name,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.permits].edit.name,
    ownershipPermission: PERMISSIONS[PERMISSION_TYPES.permits].create.name,
    fromStatuses: ['RAISED', 'APPROVED', 'AUTHORISED'],
    isRegularStatus: true,
    goToStatuses: ['DRAFT'],
    modalTitle: 'PERMIT_STATUS.REJECTED_TITLE',
  },
  authorised: {
    value: 'AUTHORISED',
    title: 'PERMITS.AUTHORISED',
    icon: 'check-rounded',
    iconProps: {
      active: true,
    },
    isRegularStatus: true,
    editPermission: PERMISSIONS[PERMISSION_TYPES.permits].authoriseEdit.name,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.permits].toAuthorise.name,
    goToStatuses: ['ISSUED'],
    sectionName: 'Authrised',
    modalTitle: 'PERMIT_STATUS.AUTHORISED_TITLE',
    actionButtonText: 'PERMIT_STATUS.AUTHORISED_ACTION_BUTTON_TEXT',
  },
  issued: {
    value: 'ISSUED',
    title: 'PERMITS.ISSUED',
    icon: 'in-progress',
    sectionName: 'Issued',
    iconFilledWhenActive: true,
    editPermission: PERMISSIONS[PERMISSION_TYPES.permits].issueEdit.name,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.permits].toIssue.name,
    goToStatuses: ['SURRENDERED', 'HAND_BACK'],
    modalTitle: 'PERMIT_STATUS.ISSUED_TITLE',
    actionButtonText: 'PERMIT_STATUS.ISSUED_ACTION_BUTTON_TEXT',
  },
  reissued: {
    value: 'REISSUED',
    title: 'PERMITS.REISSUED',
    icon: 'reissued',
    sectionName: 'Re-issued',
    editPermission: PERMISSIONS[PERMISSION_TYPES.permits].reissueEdit.name,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.permits].toReissue.name,
    goToStatuses: ['SURRENDERED', 'HAND_BACK'],
    modalTitle: 'PERMIT_STATUS.REISSUED_TITLE',
    actionButtonText: 'PERMIT_STATUS.REISSUED_ACTION_BUTTON_TEXT',
  },
  surrendered: {
    value: 'SURRENDERED',
    title: 'PERMITS.SURRENDERED',
    icon: 'flag',
    iconProps: {
      active: true,
    },
    iconFilledWhenActive: false,
    sectionName: 'Surrendered',
    editPermission: PERMISSIONS[PERMISSION_TYPES.permits].surrenderEdit.name,
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.permits].toSurrender.name,
    goToStatuses: ['REISSUED', 'HAND_BACK'],
    modalTitle: 'PERMIT_STATUS.SURRENDERED_TITLE',
    actionButtonText: 'PERMIT_STATUS.SURRENDERED_ACTION_BUTTON_TEXT',
  },
  handback: {
    value: 'HAND_BACK',
    title: 'PERMITS.HAND_BACK',
    icon: 'checkmark',
    iconProps: {
      active: true,
    },
    iconFilledWhenActive: false,
    sectionName: 'Hand Back',
    transitionPermission: PERMISSIONS[PERMISSION_TYPES.permits].toHandback.name,
    modalTitle: 'PERMIT_STATUS.HANDBACK_TITLE',
    actionButtonText: 'PERMIT_STATUS.HANDBACK_ACTION_BUTTON_TEXT',
  },
  archived: {
    value: 'ARCHIVED',
    title: 'PERMITS.ARCHIVED',
    isSingleSelect: true,
  },
};

export const tabNames = {
  details: 'DETAILS',
  comments: 'COMMENTS',
  flow: 'FLOW',
};

export const toolbarItems = {
  [tabNames.details]: {
    name: tabNames.details,
    label: 'PERMIT.TAB_DETAILS',
  },
  [tabNames.comments]: {
    name: tabNames.comments,
    label: 'COMPONENET_FORM_TAB.COMMENTS',
  },
  [tabNames.flow]: {
    name: tabNames.flow,
    label: 'PERMIT.TAB_FLOW',
  },
};

export const permitFields = {
  id: 'ID',
  archived: 'Archived',
  archive: 'Archive',
  permitId: 'PermitID',
  status: 'Status',
  isolationCertificates: 'IsolationCertificates',
  keyboxes: 'Keyboxes',
  keyboxId: 'KeyboxID',
  keyboxName: 'KeyboxName',
  cameraPosition: 'CameraPosition',
};

export const assigneeFields = {
  id: 'ID',
  name: 'Name',
  type: 'Type',
  email: 'Email',
  status: 'Status',
};

export const permitsWorkAreaDetailsDisplayProps = [
  { name: 'ID' },
  {
    name: 'Name',
  },
  {
    name: 'Colour',
  },
];

export const downloadTypesForPermits = {
  fullPermit: 'fullPermit',
  additionalPageIncluded: 'additionalPageIncluded',
};
