import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import Button from '../../form/components/button';
import Icon from '../../icon/components/icon';
import RenderIf from '../../render-if/components/render-if';
import { ReactComponent as BackSvg } from '../assets/back.svg';

import Helpers from '../../helpers';
import { modalOpenedClass } from '../constants/modal-constants';

import '../styles/modal.scss';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      modalHash: null,
    };

    this.node = React.createRef();
  }

  escFunction = event => {
    const { isOpen, closeAction, isActiveModalFormDirty } = this.props;
    if (event.keyCode === 27 && !isActiveModalFormDirty) {
      // restricts close of modal if the isActiveModalFormDirty
      //Do whatever when esc is pressed
      if (isOpen && closeAction) closeAction();
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
    const parent = this.node && this.node.current && this.node.current.parentNode;

    const additionalClass =
      parent && parent.classList
        ? parent.classList.value.indexOf('navigation') > -1 || parent.classList.value.indexOf('readings-and-gauges') > -1 || parent.classList.value.indexOf('uplift-modal') > -1
          ? 'from-navi'
          : ''
        : '';

    const modalHash = `${modalOpenedClass}_${additionalClass}_${Helpers.uuid4()}`;

    this.setState({ modalHash });
    document.body.classList.add(modalHash);
  }

  componentWillUnmount = () => {
    const { modalHash } = this.state;
    document.removeEventListener('keydown', this.escFunction, false);
    document.body.classList.remove(modalHash);
  };

  renderButtons = () => {
    const { t } = this.context;
    const { type, confirmAction, closeAction, modalDisabled, confirmButtonDisabled, okModalButtonText } = this.props;
    switch (type) {
      case 'ok':
        return <Button onClick={confirmAction} disabled={modalDisabled} type="button" text={t(okModalButtonText || 'OK')} className="modal__button" />;
      case 'upload':
        return <Button onClick={confirmAction} disabled={modalDisabled || confirmButtonDisabled} type="button" text={t('UPLOAD')} className="modal__button" />;
      case 'yes-no':
        return (
          <Fragment>
            <Button onClick={closeAction} type="button" disabled={modalDisabled} text={t('NO')} variant="gray-outline" />
            <Button onClick={confirmAction} disabled={modalDisabled} type="button" text={t('YES')} className="modal__button" />
          </Fragment>
        );
      case 'no-yes':
        return (
          <Fragment>
            <Button onClick={confirmAction} type="button" disabled={modalDisabled} text={t('YES')} variant="gray-outline" />
            <Button onClick={closeAction} type="button" disabled={modalDisabled} text={t('NO')} className="modal__button" />
          </Fragment>
        );
      case 'cancel-delete':
        return (
          <Fragment>
            <Button onClick={confirmAction} type="button" disabled={modalDisabled} text={t('DELETE')} className="modal__button modal__button_red" />
            <Button onClick={closeAction} type="button" disabled={modalDisabled} text={t('CANCEL')} variant="gray-outline" />
          </Fragment>
        );
      case 'cancel-confirm':
        return (
          <Fragment>
            <Button onClick={confirmAction} disabled={modalDisabled} type="button" text={t('CONFIRM')} variant="danger" className="modal__button" />
            <Button onClick={closeAction} disabled={modalDisabled} type="button" text={t('CANCEL')} variant="gray-outline" />
          </Fragment>
        );

      default:
        return '';
    }
  };

  renderActionItems = () => {
    const { actionItems, closeAction, modalDisabled } = this.props;

    return (
      <div className="modal-action-items">
        {!isEmpty(actionItems) &&
          actionItems.map(actionItem => (
            <Icon
              name={actionItem.icon}
              className={`modal-action-items__action-item ${actionItem.iconClass}`}
              {...actionItem.iconProps}
              title={actionItem.title}
              onClick={modalDisabled ? null : actionItem.onClick}
              data-cy={`${actionItem.icon}-modal-action`}
            />
          ))}
        {closeAction && <Icon name="close" className="close-modal modal-action-items__action-item" onClick={modalDisabled ? null : closeAction} data-cy="close-modal-action" />}
      </div>
    );
  };
  render() {
    const { title, content, CustomContent, closeAction, type, modalDisabled, customClassName, customClassWrapperName, backAction, ModalFooter, ...restProps } = this.props;
    const buttons = this.renderButtons();
    const modalHeaderActions = this.renderActionItems();

    return (
      <div className={`modal-container ${customClassName || ''}`} ref={this.node}>
        <div className={`modal-wrapper ${customClassWrapperName || ''}`} data-cy="modal">
          {backAction && <BackSvg className="back svg-white" onClick={modalDisabled ? null : backAction} />}
          <RenderIf if={title && (!type || type === 'none')}>
            <h3 className="modal-wrapper__title noselect">{title}</h3>
          </RenderIf>
          {modalHeaderActions}
          <div className={`modal${title && (!type || type === 'none') ? ' modal-with-absolute-title' : ''}`}>
            <RenderIf if={title && type && type !== 'none'}>
              <h3 className="modal__title noselect">{title}</h3>
            </RenderIf>
            {content && <div className="modal__content light-bold">{content}</div>}
            {CustomContent && <CustomContent {...restProps} />}
            <div className={`modal__button-container ${type}`}>{buttons}</div>
          </div>
          {ModalFooter && (
            <div className="modal__footer">
              <ModalFooter />
            </div>
          )}
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeAction: PropTypes.func,
  CustomContent: PropTypes.func,
  ModalFooter: PropTypes.func,
  customClassWrapperName: PropTypes.string,
  actionItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      iconClass: PropTypes.string,
      title: PropTypes.string,
      iconProps: PropTypes.object,
      onClick: PropTypes.func,
    })
  ),
};
Modal.defaultProps = {
  type: 'ok',
  customClassWrapperName: '',
  isActiveModalFormDirty: false,
};

Modal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default props => {
  return props.isOpen ? <Modal {...props} /> : null;
};
