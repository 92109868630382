import { isEmpty } from 'lodash';
import { SHORT_DESCRIPTION_CUSTOM_PROPERTY_MAX_SIZE } from '../../../app/inspections/components/right-toolbar/defect-details/validators/validators';
import { formConstants } from '../constants/property-constants';

export const validate = values => {
  const errors = {};
  if (!isEmpty(values[formConstants.fields.properties])) {
    const propertyArrayErrors = [];
    values[formConstants.fields.properties].forEach((el, index) => {
      const foundShortDescriptionProperty = el.Name === 'Short Description';

      const propertyErrors = {};
      if (!el || !el[formConstants.fields.name]) {
        propertyErrors[formConstants.fields.name] = {
          string: 'DEFECT_PROPERTY.ERROR.NAME.REQUIRED_FIELD',
          params: {},
        };
        propertyArrayErrors[index] = propertyErrors;
      }
      if (!el || !el[formConstants.fields.value]) {
        propertyErrors[formConstants.fields.value] = {
          string: 'DEFECT_PROPERTY.ERROR.VALUE.REQUIRED_FIELD',
          params: {},
        };
        propertyArrayErrors[index] = propertyErrors;
      }
      if (foundShortDescriptionProperty && el && el[formConstants.fields.value].length > SHORT_DESCRIPTION_CUSTOM_PROPERTY_MAX_SIZE) {
        propertyErrors[formConstants.fields.value] = {
          string: 'NOTIFICATION.SHORT_DESCRIPTION_MAX_SIZE',
          params: {},
        };
        propertyArrayErrors[index] = propertyErrors;
      }
    });
    if (!isEmpty(propertyArrayErrors)) {
      errors[formConstants.fields.properties] = propertyArrayErrors;
    }
  }

  return errors;
};
