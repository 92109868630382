import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import TableTemplate from '../../../../common/double-table/components/table-template';

import BackButton from '../../../../common/back-button/components/back-button';
import EmptyState from '../../../../common/empty-state-v2/components/empty-state';
import PageNavigation from '../../../../common/page-navigation/components/page-navigation';
import routesConstants from '../../../../common/routes-constants';
import { params } from '../../../profile/constants/profile-constants';
import { filterProps } from '../../constants/robotic-live-streams-constant';

import { isEmpty } from 'lodash';
import { formatStreamHistoryTable } from '../../actions/table-formatting';
import '../../styles/stream-history.scss';

import { fetchRoboticLiveStreams } from '../../actions/augmented-reality-actions';
import { streamHistoryTableConfig } from '../../constants/stream-history-constant';

const StreamHistory = (props, { t }) => {
  const { user, location } = props;
  const { search } = location;
  const filters = {};

  const emptyStateProps = {
    emptyStateText: t('STREAM_HISTORY.TABLE.EMPTY_STATE'),
    transparent: true,
  };

  const backButtonPath = `${user[params.fullScreenEnabled] ? routesConstants.routes.protectedRoutes.fullScreen.fullPath : routesConstants.routes.protectedRoutes.inspections.fullPath}${search}`;
  const navigationBackButtonPath = `${routesConstants.routes.protectedRoutes.augmentedReality.fullPath}${search}`;

  // TODO: replace with endpoint - not implemented on BE
  const data = [
    { ID: 12, UniqueLivestreamID: 'kfsdfd', Name: 'Mission d10', CreatedAt: 1233212323, Duration: '14:14' },
    { ID: 12, UniqueLivestreamID: 'asd', Name: 'Mission d11', CreatedAt: 1233212323, Duration: '14:14' },
    // TODO: replace with endpoint - not implemented on BE
  ];

  return (
    <div className="stream-history">
      <PageNavigation title="TOOL_TYPE.AUGMENTED_REALITY" icon="map" backButtonPath={backButtonPath} withBottomBorder />
      <div className="live-streams__wrapper">
        <div className="live-streams__wrapper__navigation">
          <BackButton path={navigationBackButtonPath} />
          <h3 className="f-primary title">{t('AUGMENTED_REALITY.TOOLS.STREAM_HISTORY.TITLE')}</h3>
          <p className="f-primary description ">{t('AUGMENTED_REALITY.TOOLS.LIVE_STREAM.PARAGRAPH')}</p>
        </div>
        <TableTemplate
          title={''}
          showSearchBar={false}
          showInfo={false}
          tableConfig={streamHistoryTableConfig}
          handleFormatCells={formatStreamHistoryTable}
          showOptionFor3DToggle={false}
          customTableClass={'stream-history-table'}
          data={data}
          onRowClick={() => null}
          filters={{}}
          sortDirection={filterProps.sortDirection}
          sortByColumn={filterProps.sortByColumn}
          hasNext={null}
          TotalItems={filters[filterProps.totalNumber]}
          isLoading={false}
          onToggleClick={() => null}
          loadMoreOnClick={() => null}
          onSortClick={() => null}
          selectedItem={null}
          showEmptyState={isEmpty(data)}
          emptyStateComponent={() => <EmptyState {...emptyStateProps} />}
          user={user}
        />
        {/* TODO: Implement pagination found on C&P */}
      </div>
    </div>
  );
};

StreamHistory.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.userReducer,
});

const mapDispatchToProps = dispatch => ({
  fetchRoboticLiveStreams: (filters, dataCallback, loadingCallback) => dispatch(fetchRoboticLiveStreams(filters, dataCallback, loadingCallback)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StreamHistory));
