import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import CustomMultiSelectV2 from '../../../../../../common/form/components/multiselect-v2';
import CustomSelect from '../../../../../../common/form/components/select';
import Toggle from '../../../../../../common/form/components/toggle-field';
import {
  aggregationFields,
  aggregationsDropdownItemsInfo,
  chunkFields,
  settingsFormConstants as formConstants,
  formConstants as graphingGroupFields,
  timePeriodFields,
} from '../../constants/time-series-graph-constants';
import '../../styles/graphing-group-details-form.scss';
import TimePeriodField from './time-period-field';

const GraphingGroupSettingsFormFields = ({ readonly, aggregationList, chunks, timePeriodsList, formValues, changeField }, { t }) => {
  return (
    <div className="graphing-group-details-form" id="graphing-group-details-form">
      <div className="double">
        <div className="split-input">
          <Field
            id={formConstants.aggregation.id}
            name={formConstants.aggregation.name}
            label={formConstants.aggregation.label}
            placeholder={t(formConstants.aggregation.placeholder)}
            disabled={readonly}
            component={CustomMultiSelectV2}
            data={aggregationList}
            textField={aggregationFields.value}
            valueField={aggregationFields.key}
            size="lg"
            isEnhancedDesignCustomProp
            isRequired
            groupDropdownItems
            showDropdownItemInfoIcon
            dropdownItemsInfo={aggregationsDropdownItemsInfo}
          />
        </div>
        <div className="split-input">
          <TimePeriodField
            id={formConstants.timePeriod.id}
            name={formConstants.timePeriod.name}
            label={formConstants.timePeriod.label}
            placeholder={t(formConstants.timePeriod.placeholder)}
            disabled={readonly}
            data={timePeriodsList}
            textField={timePeriodFields.key}
            valueField={timePeriodFields.value}
            formValues={formValues}
            changeField={changeField}
          />
        </div>
      </div>
      <div className="double">
        <div className="split-input">
          <Field
            id={formConstants.chunks.id}
            name={formConstants.chunks.name}
            label={formConstants.chunks.label}
            placeholder={t(formConstants.chunks.placeholder)}
            disabled={readonly}
            component={CustomSelect}
            data={chunks}
            textField={chunkFields.value}
            valueField={chunkFields.key}
            size="lg"
            isEnhancedDesignCustomProp
            isRequired
          />
        </div>
        <div className="split-input">
          <Field
            id={graphingGroupFields.isDefault}
            disabled={readonly}
            name={graphingGroupFields.isDefault}
            component={Toggle}
            className={'is-default-field'}
            label={t('GRAPHING_GROUP_DETAILS.FORM_SET_AS_DEFAULT')}
          />
        </div>
      </div>
    </div>
  );
};

GraphingGroupSettingsFormFields.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default GraphingGroupSettingsFormFields;
