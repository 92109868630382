import { defectNotificationFields } from '../constants/constants';
export const SHORT_DESCRIPTION_CUSTOM_PROPERTY_MAX_SIZE = 40;

export const validate = values => {
  const errors = {};
  const { Properties } = values;
  const foundShortDescriptionProperty = (Properties || []).find(p => p.Name === 'Short Description');
  const foundShortDescriptionPropertyIndex = (Properties || []).findIndex(p => p.Name === 'Short Description');
  const foundShortDescriptionPropertyValue = foundShortDescriptionProperty ? foundShortDescriptionProperty.Value : '';
  if (!values[defectNotificationFields.name]) {
    errors[defectNotificationFields.name] = 'NOTIFICATION.NAME_REQUIRED';
  }

  if (values[defectNotificationFields.name] && values[defectNotificationFields.name].length < 3) {
    errors[defectNotificationFields.name] = 'NOTIFICATION.NAME_MIN_SIZE';
  }

  if (values[defectNotificationFields.name] && values[defectNotificationFields.name].length > 100) {
    errors[defectNotificationFields.name] = 'NOTIFICATION.NAME_MAX_SIZE';
  }

  if (foundShortDescriptionPropertyValue && foundShortDescriptionPropertyValue.length > SHORT_DESCRIPTION_CUSTOM_PROPERTY_MAX_SIZE) {
    errors[`Properties[${foundShortDescriptionPropertyIndex}].Value`] = 'NOTIFICATION.SHORT_DESCRIPTION_MAX_SIZE';
  }

  return errors;
};
