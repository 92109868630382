import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Field } from 'redux-form';

import Icon from '../../../../../common/icon/components/icon';

import Helpers from '../../../../../common/helpers';
import '../styles/basic-input.scss';

class FieldGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labelHovered: false,
      labelIsEditing: false,
    };
  }
  componentDidMount() {
    const isNew = get(this.props, 'labelFieldProps.isNew');
    if (isNew) {
      this.setLabelEdit();
    }
  }
  handleChange = e => {
    const value = e.target.value;
    const {
      input: { onChange },
      inputProps,
    } = this.props;
    onChange(value);
    if (inputProps && inputProps.onChange) inputProps.onChange(value);
  };
  onMouseEnter = () => {
    this.setState({ labelHovered: true });
  };
  onMouseLeave = () => {
    this.setState({ labelHovered: false });
  };
  setLabelEdit = () => {
    this.setState({ labelIsEditing: true });

    setTimeout(() => {
      if (!this.input) return;
      const inputDOM = ReactDOM.findDOMNode(this.input).getElementsByTagName('input')[0];
      if (inputDOM && inputDOM.focus) {
        inputDOM.focus();
      }
    }, 0);
  };
  handleLabelFieldBlur = () => {
    const { labelFieldProps } = this.props;
    const { inputProps } = labelFieldProps;
    const { handleOnBlur } = inputProps;
    this.setState({ labelIsEditing: false });
    handleOnBlur && typeof handleOnBlur === 'function' && handleOnBlur();
  };
  renderLabel = () => {
    const { id, labelClass, label, labelFieldProps, labelActions, disabled, isEnhancedDesignCustomProp, showActions } = this.props;
    const { labelIsEditing } = this.state;
    return labelIsEditing ? (
      <Field {...labelFieldProps} inputRef={ref => (this.input = ref)} inputProps={{ onBlur: this.handleLabelFieldBlur, ...labelFieldProps.inputProps }} />
    ) : (
      <div onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} className="label-container">
        <label title={label} className={`inspection-input__label ${labelClass || 'f-secondary-dark'} ${isEnhancedDesignCustomProp ? 'enhanced-design-custom-prop' : ''}`} htmlFor={id}>
          {Helpers.getShortenedText(label, 35)}
        </label>
        <div>
          {!disabled && showActions && (
            <Icon name="pen" size={isEnhancedDesignCustomProp ? 'xs' : 'xxs'} onClick={this.setLabelEdit} className={`label-container__icon label-container__icon--active `} />
          )}
          {!isEmpty(labelActions) &&
            labelActions.map((el, index) => {
              return <el.IconComponent key={index} onClick={el.onClick} className={`${el.iconClass || ''} label-container__icon ${el.type || ''} label-container__icon--active `} />;
            })}
        </div>
      </div>
    );
  };
  render() {
    // isEnhancedDesignCustomProp - is a temp prop that will be present until all of the design is switched to new version
    const { input, type, placeholder, meta = {}, className, id, label, disabled, hideBorder, isHidden, inputProps, isEnhancedDesignCustomProp, maxLength } = this.props;
    const { t } = this.context;
    const numberOfLineBreaks = (input.value.match(/\n/g) || []).length + 1;
    const isMoreThan40Chars = input.value.length > 40;
    const isLongTextArea = isMoreThan40Chars || input.value.match(/\n/g);
    const textAreaRows = numberOfLineBreaks >= 6 || isMoreThan40Chars ? 6 : numberOfLineBreaks;

    return (
      <div className={`inspection-input ${isHidden ? 'hidden-field' : ''} `}>
        {label && this.renderLabel()}
        <div className={`inspection-input__input-wrapper${hideBorder ? '' : ' border'}` + (meta.error ? ' invalid ' : '')}>
          <textarea
            id={id}
            {...inputProps}
            {...input}
            onChange={this.handleChange}
            readOnly={disabled}
            disabled={disabled}
            type={type || 'text'}
            placeholder={placeholder || ''}
            className={(meta.error ? 'invalid ' : '') + `${className || 'input f-primary'} ${isEnhancedDesignCustomProp ? 'enhanced-design-input prop' : ''}`}
            autoComplete="off"
            rows={isLongTextArea ? textAreaRows : 1}
            style={{ resize: 'none' }}
            maxLength={maxLength || 5000}
          />
        </div>
        <div className={`error-message${meta.error ? '--visible' : ''}`}>{meta.error && t(meta.error.string, meta.error.params)}</div>
      </div>
    );
  }
}

FieldGenerator.contextTypes = {
  t: PropTypes.func.isRequired,
};

FieldGenerator.propTypes = {
  labelActions: PropTypes.arrayOf(
    PropTypes.shape({
      IconComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
      onClick: PropTypes.func.isRequired,
      iconClass: PropTypes.string,
      hoveredClass: PropTypes.string,
      type: PropTypes.oneOf(['danger']),
    })
  ),
};

FieldGenerator.defaultProps = {
  showActions: true,
};

export default FieldGenerator;
