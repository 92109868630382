import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import MultiSelect from 'react-widgets/Multiselect';

import Helpers from '../../helpers';

import 'react-widgets/scss/styles.scss';
import Icon from '../../icon/components/icon';
import '../styles/input.scss';

class CustomMultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      identificator: `${props.id}_${Helpers.uuid4()}`,
    };
  }
  render() {
    const { t } = this.context;
    const { identificator } = this.state;
    const {
      input,
      className,
      data,
      meta,
      label,
      labelClass,
      defaultValue,
      disabled,
      labelActions,
      placeholder,
      valueField,
      textField,
      onSearch,
      onToggle,
      size,
      isRequired,
      showError,
      showDropdownCaretIcon,
      isEnhancedDesignCustomProp,
      messages,
    } = this.props;

    return (
      <div className={`field-wrapper multiselect ${className || ''}`} id={identificator}>
        {label && (
          <div className="inline-wrapper">
            <label className={`field-wrapper__label ${labelClass}`}>{`${t(label)}${isRequired ? '*' : ''}`}</label>
            {!isEmpty(labelActions) &&
              labelActions.map((el, index) => {
                return <el.IconComponent key={index} onClick={el.onClick} className={`${el.iconClass || ''} label-container__icon ${el.type || ''} label-container__icon--active svg-gray-g`} />;
              })}
          </div>
        )}
        <div className={`input-wrapper ${isEnhancedDesignCustomProp ? 'enhanced-design-custom-prop' : ''}`}>
          <div className={`select-container ${disabled ? 'disabled' : ''} ${size}`}>
            <MultiSelect
              {...input}
              {...{
                data,
                onChange: (a, b, c) => {
                  const inp = document.body.querySelector(`#${identificator} [id$="_taglist"]`);
                  //override focus not triggering
                  setTimeout(() => {
                    inp && inp.click();
                  }, 300);
                  input.onChange && input.onChange(a, b, c);
                },
                dataKey: valueField,
                onBlur: () => input.onBlur(),
                value: input?.value || defaultValue,
                placeholder: (placeholder && t(placeholder)) || '',
                onSearch,
                onToggle,
                textField,
                disabled,
                filter: false,
                messages,
              }}
              // adds chevron down icon for default state of multiselect across the app
              busy={showDropdownCaretIcon}
              busySpinner={<Icon name="chevron-down" size="sm" />}
            />
            {showError && <div className={`error-message${meta?.error ? '--visible' : ''}`}>{meta?.error && t(meta.error)}</div>}
          </div>
        </div>
      </div>
    );
  }
}

CustomMultiSelect.contextTypes = {
  t: PropTypes.func.isRequired,
};

CustomMultiSelect.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  labelClass: PropTypes.string,
  label: PropTypes.string,
  labelActions: PropTypes.array,
  disabled: PropTypes.bool,
  valueField: PropTypes.string,
  textField: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg']),
  onSearch: PropTypes.func,
  onToggle: PropTypes.func,
  showError: PropTypes.bool,
  showDropdownCaretIcon: PropTypes.bool,
};

CustomMultiSelect.defaultProps = {
  valueField: 'value',
  textField: 'label',
  defaultValue: [],
  data: [],
  placeholder: '',
  size: 'sm',
  disabled: false,
  labelClass: '',
  showError: true,
  showDropdownCaretIcon: true,
};

export default CustomMultiSelect;
