import { find, isEmpty, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import ExpandableSection from '../../../../common/expandable-section/components/expandable-section';
import Button from '../../../../common/form/components/button';
import Loader from '../../../../common/global-loader/components/simple-loader';
import Icon from '../../../../common/icon/components/icon';
import Modal from '../../../../common/modal/components/modal';
import ProgressBar from '../../../../common/progress-bar/components/progress-bar';
import QuestionTagLocation from '../../../../common/question-tag-location/components/question-tag-location';
import SectionEmptyState from '../right-toolbar/common/section-empty-state';
import SectionTitle from '../right-toolbar/common/section-title';
import WorkOrderSectionChecklistQuestionsForm from './work-order-section-checklist-questions-form';

import Helpers from '../../../../common/helpers';
import { PERMISSION_TYPES, PERMISSIONS } from '../../../../common/permissions-constants';
import { questionTypes } from '../../../../common/question-components/constants/question-constants';
import { measurementTypes, moduleSectionQuestionFields, moduleSectionsFields } from '../../constants/constants';
import { fields, fields as workOrderField } from './constants/work-order-right-side-constants';

import { connect } from 'react-redux';
import { isDirty, touch } from 'redux-form';
import { FORMS } from '../../../../common/constants';
import PaginationNavigator from '../../../../common/load-more/components/pagination-navigator';
import { setGenericNotification } from '../../../../common/notification/actions/action-creators';
import ActionModal from '../../../document-management/components/modals/action-modal/action-modal';
import { setWorkOrderCPSections } from '../../actions/action-creators';
import { defectStatus } from '../../constants/defect-constants';
import { statuses } from '../../constants/work-order-constants';
import '../../styles/module-sections.scss';
import '../../styles/work-order-section-checklist-details.scss';
import NewObservationFormWrapper from '../observations/components/new-observation-form-wrapper';
import { defaultPagingObj, filterProps } from './constants/work-order-checklist-constants';
import { validate } from './validators/work-order-questions-form-validator';

const WorkOrderSectionChecklistDetails = (
  {
    isSectionExpanded,
    sectionTitle = moduleSectionsFields.sectionSecondaryName,
    module,
    handleAnswerQuestion,
    finalStatus = '',
    user,
    skipOrdering,
    severityColors,
    // showEmptyVersion prop handles for C&P template to display EMPTY version of the template which is non-fillable
    showEmptyVersion,
    showProgressBar,
    checklistId,
    disabledForStatuses,
    inspectionId,
    handleSubmitChecklist,
    fetchChecklistSections,
    fetchChecklistQuestions,
    handleCancelChecklist,
    workOrderCPSections,
    setWorkOrderCPSections,
    handleFileOpen,
    categoryPropName,
    filesPropName,
    touchField,
    getWorkOrderChecklistSectionQuestionDetails,
    workorderId,
    createWorkOrderObservationCP,
    cameraPos,
    defaultWorkorderDMSCategoryID,
    isNewObservationFormDirty,
    setGenericNotification,
    getWorkOrderObservations,
    queryItem,
  },
  { t }
) => {
  const [checklistDetails, setChecklistDetails] = useState({ Sections: [], Properties: [] });
  const [sectionFilters, setSectionFilters] = useState(defaultPagingObj);
  const [questionsAnswered, setQuestionAnswered] = useState(null);
  const [questionsTotal, setQuestionTotal] = useState(null);
  const [mandatoryQuestionsAnswered, setMandatoryQuestionAnswered] = useState(null);
  const [mandatoryQuestionsTotal, setMandatoryQuestionTotal] = useState(null);
  const [isPropertiesExpanded, setIsPropertiesExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [modalData, setModalData] = useState({ isOpen: false });
  const [confirmationModalData, setConfirmationModalData] = useState({ isOpen: false });

  useEffect(() => {
    // reset WO CP sections
    setWorkOrderCPSections([]);
    getChecklistProcedureSections(sectionFilters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistId]);

  const getChecklistProcedureSections = filters => {
    setIsLoading(true);
    fetchChecklistSections(
      { ...filters },
      checklist => {
        if (!isEmpty(checklist.Sections)) {
          const { Items, ...newFilters } = checklist.Sections;
          setSectionFilters({ ...filters, ...newFilters });
          checklist.Sections = (Items || []).map(section => ({ ...section, [moduleSectionsFields.questions]: section[moduleSectionsFields.questions], [moduleSectionsFields.expanded]: false }));
        }
        setChecklistDetails(checklist);
        setIsSubmitted(checklist[workOrderField.isSubmitted]);
        setQuestionAnswered(checklist?.[workOrderField.questionsAnswered] || 0);
        setMandatoryQuestionAnswered(checklist?.[workOrderField.mandatoryQuestionsAnswered] || 0);
        setQuestionTotal(checklist?.[workOrderField.questionsTotal] || 0);
        setMandatoryQuestionTotal(checklist?.[workOrderField.mandatoryQuestionsTotal] || 0);
        setIsLoading(false);
      },
      () => setIsLoading(false)
    );
  };

  const setSectionQuestionsState = (section, isLoading, questions, newFilters) => {
    const newSections = checklistDetails?.Sections.map(s => {
      if (s[moduleSectionsFields.id] === section[moduleSectionsFields.id]) {
        const newQuestionsState = {
          isLoading,
        };

        if (newFilters) {
          newQuestionsState.QuestionFilters = { ...(s.QuestionFilters || {}), ...newFilters };
        }

        if (questions) {
          newQuestionsState[moduleSectionsFields.questions] = questions;
        }

        return { ...s, ...newQuestionsState };
      }
      return s;
    });
    setChecklistDetails({ ...checklistDetails, Sections: newSections });
  };

  const getChecklistProcedureSectionsQuestions = (section, filters) => {
    setSectionQuestionsState(section, true);
    fetchChecklistQuestions(
      section[moduleSectionsFields.id],
      { ...filters },
      data => {
        const { Items, ...newFilters } = data;
        setSectionQuestionsState(section, false, Items, { ...filters, ...newFilters });
      },
      () => {
        setSectionQuestionsState(section, false);
      }
    );
  };

  const loadPreviousSections = () => {
    let prevLastSeen = Helpers.calculatePreviousLastSeen(sectionFilters[filterProps.lastSeen], defaultPagingObj[filterProps.perPage]);

    const newFilters = {
      ...sectionFilters,
      [filterProps.lastSeen]: prevLastSeen - defaultPagingObj[filterProps.perPage],
    };

    getChecklistProcedureSections(newFilters);
  };

  const loadNextSections = () => {
    getChecklistProcedureSections(sectionFilters);
  };

  const loadPreviousSectionQuestions = section => {
    let prevLastSeen = Helpers.calculatePreviousLastSeen(section.QuestionFilters?.[filterProps.lastSeen], defaultPagingObj[filterProps.perPage]);

    const newFilters = {
      ...(section.QuestionFilters || {}),
      [filterProps.lastSeen]: prevLastSeen - defaultPagingObj[filterProps.perPage],
    };

    getChecklistProcedureSectionsQuestions(section, newFilters);
  };

  const loadNextSectionQuestions = section => {
    getChecklistProcedureSectionsQuestions(section, section.QuestionFilters);
  };

  const renderPaginationNavigator = () => {
    if (sectionFilters[filterProps.totalNumber] <= defaultPagingObj[filterProps.perPage]) {
      return null;
    }

    const pageCurrentStart = sectionFilters[filterProps.lastSeen] ? Math.max(sectionFilters[filterProps.lastSeen] - defaultPagingObj[filterProps.perPage], 0) : 0; // clamp to 0 if negative value
    return (
      <PaginationNavigator
        isLoading={isLoading}
        className="sections-container__section-pagination-navigator"
        currentStart={pageCurrentStart}
        currentEnd={pageCurrentStart + checklistDetails?.Sections?.length}
        totalItems={sectionFilters[filterProps.totalNumber]}
        label="CHECKLISTS_PROCEDURES_MANAGEMENT.SECTIONS.PAGINATION_INFO"
        prevButtonProps={{
          disabled: sectionFilters[filterProps.lastSeen] - defaultPagingObj[filterProps.perPage] <= 0,
          onClick: loadPreviousSections,
          text: 'CHECKLISTS_PROCEDURES_MANAGEMENT.SECTIONS.PREV_BUTTON_TEXT',
        }}
        nextButtonProps={{
          disabled: !sectionFilters[filterProps.hasNext],
          onClick: loadNextSections,
          text: 'CHECKLISTS_PROCEDURES_MANAGEMENT.SECTIONS.NEXT_BUTTON_TEXT',
        }}
      />
    );
  };

  const customProperties = useMemo(() => {
    if (isEmpty(checklistDetails?.Properties)) {
      return [];
    }
    if (isPropertiesExpanded) {
      return checklistDetails?.Properties;
    } else {
      // Show only first 4 properties
      return checklistDetails?.Properties.slice(0, 4);
    }
  }, [checklistDetails, isPropertiesExpanded]);

  const showSubmitBtn = useMemo(() => {
    return !showEmptyVersion && !isSubmitted;
  }, [showEmptyVersion, isSubmitted]);

  const hasInvalidSection = useMemo(() => {
    return (workOrderCPSections || []).some(section => section.SectionInvalid);
  }, [workOrderCPSections]);

  const hasUnansweredMandatoryQuestions = mandatoryQuestionsAnswered < mandatoryQuestionsTotal;

  if (isLoading) {
    return (
      <div className="sections-container">
        <div className="sections-container__loader-wrapper">
          <Loader isLoading={true} />
        </div>
      </div>
    );
  }

  if (!checklistDetails.Sections || !checklistDetails.Sections.length) {
    return null;
  }

  const getCustomSectionFormInitialValues = section => {
    const types = values(questionTypes);
    const initialValues = {};

    // we return initialValues as {} if showEmptyVersion is true (all answers should be blank)
    if (showEmptyVersion) return initialValues;
    const sectionQuestions = Object.assign([], section[moduleSectionsFields.questions]);

    for (let i = 0; i < sectionQuestions.length; i++) {
      const type = find(types, { valueId: sectionQuestions[i][moduleSectionQuestionFields.type] });

      if (!isEmpty(type)) {
        const name = `${type.fieldName}-${sectionQuestions[i][moduleSectionQuestionFields.id]}`;
        const value =
          sectionQuestions[i][moduleSectionQuestionFields.answers] && sectionQuestions[i][moduleSectionQuestionFields.answers].length > 0
            ? sectionQuestions[i][moduleSectionQuestionFields.answers][0][type.fieldName]
            : null;

        // if the question type is "MULTI_ANSWER", we need to loop through available questions options
        // to determine is the question answered to pre-populate the field in the form
        if (type.valueId === questionTypes.MULTI_ANSWER.valueId) {
          const questionOptions = Object.assign([], sectionQuestions[i][moduleSectionQuestionFields.questionOptions]);

          for (let j = 0; j < questionOptions.length; j++) {
            if (questionOptions[j][moduleSectionQuestionFields.isAnswer]) {
              initialValues[`${name}-${questionOptions[j][moduleSectionQuestionFields.id]}`] = questionOptions[j][moduleSectionQuestionFields.id];
            }
          }
        } else if (type.valueId === questionTypes.CHECKBOX.valueId) {
          initialValues[`${name}-yes`] = value && typeof value === 'boolean' ? 'true' : null;
          initialValues[`${name}-no`] = !value && typeof value === 'boolean' ? 'false' : null;
        } else if (type.valueId === questionTypes.DATE.valueId) {
          initialValues[name] = value ? Helpers.getDateFromUnix(value) : null;
        } else {
          initialValues[name] = value;
        }
      }
      const evidenceFieldName = `${moduleSectionQuestionFields.evidenceFiles}-${sectionQuestions[i][moduleSectionQuestionFields.id]}`;

      initialValues[evidenceFieldName] = sectionQuestions[i][moduleSectionQuestionFields.evidenceFiles];
    }

    return initialValues;
  };

  // determine is section disabled for editing
  const isSectionDisabled = disabled => {
    // showEmptyVersion prop handles for C&P template to display EMPTY version of the template which is non-fillable/editable
    let isDisabled = module[moduleSectionsFields.archived] || disabled || showEmptyVersion || false;

    // if the module is in final status, for example isolation certificate is in
    // status "DE_ISOLATED", section is disabled for editing
    if (module[moduleSectionsFields.status] === finalStatus) {
      isDisabled = true;
    }

    isDisabled =
      isDisabled ||
      !Helpers.hasAccess({
        user,
        visibleFor: [PERMISSIONS[PERMISSION_TYPES.workOrders].fillInCP.name],
      });

    if (module[moduleSectionsFields.status] && disabledForStatuses && disabledForStatuses.length) {
      isDisabled = isDisabled || disabledForStatuses.indexOf(module[moduleSectionsFields.status]) > -1;
    }

    return isDisabled;
  };

  const hasEditPermission = () => {
    return Helpers.hasAccess({
      user,
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.workOrders].fillInCP.name],
    });
  };

  const onAnswerQuestionSuccess = data => {
    setQuestionAnswered(data?.[workOrderField.questionsAnswered] || 0);
    setQuestionTotal(data?.[workOrderField.questionsTotal] || 0);
    setMandatoryQuestionAnswered(data?.[workOrderField.mandatoryQuestionsAnswered] || 0);
    setMandatoryQuestionTotal(data?.[workOrderField.mandatoryQuestionsTotal] || 0);
  };

  // isFormDirty is passed for a specific case of cancel action on the NewObservationFormWrapper, due to the popup on popup flow
  const closeModalAction = (isFormDirty = false) => {
    const closeModalAndConfirmModal = () => {
      setConfirmationModalData({ isOpen: false });
      setModalData({ isOpen: false });
    };
    if (isNewObservationFormDirty || (typeof isFormDirty === 'boolean' && Boolean(isFormDirty))) {
      const title = 'WORK_ORDER.CREATE_OBSERVATION_MODAL.LEAVE_CONFIRMATION_MODAL.TITLE';
      const firstParagraph = 'WORK_ORDER.CREATE_OBSERVATION_MODAL.LEAVE_CONFIRMATION_MODAL.FIRST_PARAGRAPH';
      setConfirmationModalData({
        isOpen: true,
        CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
        closeAction: () => setConfirmationModalData({ isOpen: false }),
        type: '',
        title: t(title),
        confirmButtonText: 'LEAVE',
        closeButtonText: 'CANCEL',
        firstParagraph: firstParagraph,
        secondParagraph: 'WORK_ORDER.CREATE_OBSERVATION_MODAL.LEAVE_CONFIRMATION_MODAL.SECOND_PARAGRAPH',
        customCloseAction: () => setConfirmationModalData({ isOpen: false }),
        customConfirmAction: closeModalAndConfirmModal,
        customClassName: 'confirm-leave-modal modal-large',
        customClassWrapperName: 'confirm-leave-modal__picker',
      });
    } else {
      setModalData({ isOpen: false });
    }
  };

  const open3DTagLocationModal = question => {
    setModalData({
      isOpen: true,
      CustomContent: dynamicProps => (
        <QuestionTagLocation {...dynamicProps} question={question} inspectionId={inspectionId} handleUpdateLocation={() => null} closeAction={closeModalAction} viewOnly={true} />
      ),
      customClassName: 'modal-no-max-height modal-large location-modal',
      type: 'none',
      closeAction: closeModalAction,
    });
  };

  const renderSectionContent = section => {
    if (!section) {
      return null;
    }
    if (section?.isLoading) {
      return <Loader isLoading={true} />;
    }
    if (section[moduleSectionsFields.questions]) {
      return (
        <WorkOrderSectionChecklistQuestionsForm
          form={`module-sections-form-${section[moduleSectionsFields.id]}`}
          handleFieldChange={vals => handleAnswerQuestion(vals, onAnswerQuestionSuccess)}
          questions={section[moduleSectionsFields.questions]}
          moduleId={module[moduleSectionsFields.id]}
          initialValues={getCustomSectionFormInitialValues(section)}
          disabled={isSectionDisabled(section[moduleSectionsFields.disabled]) || isSubmitted || submitting}
          handle3DTagLocationClick={open3DTagLocationModal}
          handleFileOpen={handleFileOpen}
          categoryPropName={categoryPropName}
          filesPropName={filesPropName}
          showEmptyVersion={showEmptyVersion}
          touchField={fieldName => touchField(`module-sections-form-${section[moduleSectionsFields.id]}`, fieldName)}
          loadPreviousQuestions={() => loadPreviousSectionQuestions(section)}
          loadNextQuestions={() => loadNextSectionQuestions(section)}
          questionsFilters={section?.QuestionFilters}
          questionsLoading={section?.isLoading}
          getWorkOrderChecklistSectionQuestionDetails={getWorkOrderChecklistSectionQuestionDetails}
          inspectionId={inspectionId}
          module={module}
          defaultWorkorderDMSCategoryID={defaultWorkorderDMSCategoryID}
          {...section}
        />
      );
    }

    return <SectionEmptyState />;
  };

  const handleCreateObservation = () => {
    setModalData({
      isOpen: true,
      CustomContent: dynamicProps => (
        <NewObservationFormWrapper {...dynamicProps} onCancelAction={isDirty => closeModalAction(isDirty)} onCreateAction={createWorkOrderObservationCPHandler} inspectionId={inspectionId} />
      ),
      customClassName: 'modal-large new-observation-modal',
      type: 'none',
      closeAction: closeModalAction,
      title: t('WORK_ORDER.CREATE_OBSERVATION_SECTION.CTA'),
    });

    const createWorkOrderObservationCPHandler = formData => {
      if (!formData || isEmpty(formData)) return;

      const newObservation = {
        [fields.workOrderID]: workorderId,
        [fields.inspectionID]: inspectionId,
        [fields.name]: formData[fields.name],
        [fields.mainType]: formData[fields.mainType]?.Value || measurementTypes.defect,
        [fields.componentID]: formData[fields.componentID],
        [fields.defectType]: formData[fields.defectType],
        [fields.description]: formData[fields.description],
        [fields.recommendation]: formData[fields.recommendation],
        [fields.severity]: formData[fields.severity], // BE will add Severity: 1 in case the FE does not send it
        [fields.status]: defectStatus.open,
        [fields.cameraPosition]: cameraPos[fields.coordinates] ? cameraPos : { [fields.coordinates]: [0, 0, 0] },
      };

      createWorkOrderObservationCP(
        newObservation,
        data => {
          if (!data) return;
          // after the create observation fetch the list of observations for the selected WO
          getWorkOrderObservations(queryItem);

          // after the success create observation response, display a generic notification with success message
          closeModalAction();
          setGenericNotification({
            isDisplayed: true,
            type: 'success',
            text: t('WORK_ORDER.CREATE_OBSERVATION_MODAL.SUCCESS'),
            icon: 'checkmark-outline',
          });
        },
        () => {
          // after the error create observation response, display a generic notification with error message
          setGenericNotification({ isDisplayed: true, type: 'error', text: t('WORK_ORDER.CREATE_OBSERVATION_MODAL.ERROR'), icon: 'trash' });
        }
      );
    };
  };

  const onSubmit = () => {
    setSubmitting(true);
    handleSubmitChecklist(
      () => {
        setSubmitting(false);
        setIsSubmitted(true);
      },
      () => setSubmitting(false)
    );
  };

  /**
   * explicitly and deliberately setting it like this
   */
  const workOrderHasInvalidSubmitCPStatus =
    module[moduleSectionsFields.status] !== statuses.inProgress.value &&
    module[moduleSectionsFields.status] !== statuses.completed.value &&
    module[moduleSectionsFields.status] !== statuses.closed.value;

  return (
    <div className="work-order-section-checklist-details">
      <div className="sections-container">
        {showProgressBar && typeof questionsAnswered === 'number' && typeof questionsTotal === 'number' && (
          <div className="questions-answered-progress">
            <div className="questions-answered-progress-header">
              <p className="f-secondary-dark light-bold">{t('PROGRESS')}</p>
              <p className="f-primary">
                {isSubmitted ? (
                  <div className="questions-answered-progress-header__submitted-wrapper">
                    <Icon size="xs" name="checkmark" active />
                    <p className="f-primary">{t('SUBMITTED')}</p>
                  </div>
                ) : (
                  <>
                    <span>{questionsAnswered}</span>/<span>{questionsTotal}</span> {t('ANSWERED')}
                  </>
                )}
              </p>
            </div>
            <ProgressBar height={'4px'} percentages={[Helpers.getPercentage(questionsAnswered, questionsTotal)]} colors={[severityColors?.secondaryThemeColor]} />
          </div>
        )}
        {!isEmpty(customProperties) && (
          <div className="sections-container__custom-properties">
            <div className="sections-container__custom-properties-header">
              <p className="f-secondary-dark light-bold">{t('CUSTOM_PROPERTIES')}</p>
              <Icon name={isPropertiesExpanded ? 'chevron-up' : 'chevron-down'} onClick={() => setIsPropertiesExpanded(!isPropertiesExpanded)} className="toggle-action toggle-action-expand" />
            </div>
            <div className="sections-container__custom-properties-content">
              {customProperties.map(property => (
                <div className="sections-container__custom-properties-item">
                  <p className="f-secondary-dark label" title={property?.Name}>
                    {property?.Name}
                  </p>
                  <p className="f-primary">{property?.Value}</p>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="question-new-observation-section">
          <div className="question-new-observation-section__paragraph">
            <p className="f-primary">{t('WORK_ORDER.CREATE_OBSERVATION_SECTION')}</p>
          </div>
          <div className="question-new-observation-section__button">
            <Button
              className={'cta'}
              variant="success-outline"
              onClick={e => handleCreateObservation(e)}
              text={t('WORK_ORDER.CREATE_OBSERVATION_SECTION.CTA')}
              icon="plus-circle"
              iconPosition="right"
              width="sm"
              height="sm"
              iconProps={{ size: 'md', className: 'green-plus-circle' }}
              disabled={isSubmitted || showEmptyVersion}
            />
          </div>
        </div>
        {renderPaginationNavigator()}
        {checklistDetails?.Sections.map((section, index) => {
          return (
            <div className="sections-item" key={`section-details-item-${section[moduleSectionsFields.id]}`}>
              <ExpandableSection
                CustomComponent={SectionTitle}
                className="expanded-section"
                customExpanded={isSectionExpanded}
                initiallyExpanded={false}
                hideExpandAction
                onExpand={expanded => {
                  if (expanded) {
                    getChecklistProcedureSectionsQuestions(section, defaultPagingObj);
                  }
                }}
                customComponentProps={{
                  title: section[sectionTitle] || section[moduleSectionsFields.sectionName],
                  number: index + 1,
                  titleTranslatable: section.translatableTitle,
                  showError: section[moduleSectionsFields.showError],
                  isSectionExpanded: isSectionExpanded || section[moduleSectionsFields.expanded],
                  module,
                  section,
                  questions: section[moduleSectionsFields.questions],
                  formName: `module-sections-form-${section[moduleSectionsFields.id]}`,
                  sectionAction: section[moduleSectionsFields.sectionAction],
                  disabled: isSectionDisabled(section[moduleSectionsFields.disabled]) || !hasEditPermission(),
                  icon: section.icon,
                  iconClass: section.iconClass,
                  skipOrdering,
                  isChecklistProcedureTemplate: true,
                  validate: validate,
                }}
              >
                <div
                  className="section-content-item"
                  id={`module-section-content-${
                    section.translatableTitle ? t(section[sectionTitle] || section[moduleSectionsFields.sectionName]) : section[sectionTitle] || section[moduleSectionsFields.sectionName]
                  }`}
                >
                  {renderSectionContent(section)}
                </div>
              </ExpandableSection>
            </div>
          );
        })}
        {renderPaginationNavigator()}
      </div>
      <div className={`buttons ${showSubmitBtn ? 'buttons--with-submit-btn' : ''}`}>
        <Button type="button" variant="gray-outline" height="md" width="sm" text={t('CANCEL')} onClick={handleCancelChecklist} />
        {showSubmitBtn && (
          <Button
            disabled={submitting || !hasEditPermission() || hasInvalidSection || workOrderHasInvalidSubmitCPStatus || hasUnansweredMandatoryQuestions}
            type="button"
            className="buttons__save-btn"
            height="md"
            width="sm"
            variant="success"
            text={t('SUBMIT')}
            onClick={onSubmit}
          />
        )}
      </div>
      <Modal {...modalData} closeAction={closeModalAction} customCloseAction={closeModalAction} isActiveModalFormDirty={isNewObservationFormDirty} />
      <Modal {...confirmationModalData} />
    </div>
  );
};

WorkOrderSectionChecklistDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

WorkOrderSectionChecklistDetails.propTypes = {
  isSectionExpanded: PropTypes.bool,
  sectionTitle: PropTypes.string,
  module: PropTypes.shape({
    ID: PropTypes.number,
    Name: PropTypes.string,
    Archived: PropTypes.bool,
    StartDate: PropTypes.number,
    EndDate: PropTypes.number,
    Status: PropTypes.string,
    TemplateName: PropTypes.string,
  }),
  handleSectionAction: PropTypes.func,
  handleAnswerQuestion: PropTypes.func,
  finalStatus: PropTypes.string,
  moduleId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  customAction: PropTypes.string,
  skipOrdering: PropTypes.bool,
  showEmptyVersion: PropTypes.bool,
  showProgressBar: PropTypes.bool,
  checklistId: PropTypes.number.isRequired,
  inspectionId: PropTypes.number.isRequired,
  handleSubmitChecklist: PropTypes.func.isRequired,
  handleCancelChecklist: PropTypes.func.isRequired,
  categoryPropName: PropTypes.string,
  filesPropName: PropTypes.string,
};

WorkOrderSectionChecklistDetails.defaultProps = {
  showEmptyVersion: false,
  showProgressBar: true,
  categoryPropName: 'Group',
  filesPropName: 'QuestionFiles',
};

const mapStateToProps = state => ({
  workOrderCPSections: state.workOrderReducer.workOrderCPSections,
  isNewObservationFormDirty: isDirty(FORMS.newObservationForm)(state),
});

const mapDispatchToProps = dispatch => ({
  setWorkOrderCPSections: data => dispatch(setWorkOrderCPSections(data)),
  touchField: (formName, fieldName) => dispatch(touch(formName, fieldName)),
  setGenericNotification: data => dispatch(setGenericNotification(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderSectionChecklistDetails);
