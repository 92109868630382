import { find, isEmpty, isObject, values } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import ExpandableSection from '../../../../../common/expandable-section/components/expandable-section';
import CustomSectionForm from './custom-section-form';
import SectionEmptyState from './section-empty-state';
import SectionTitle from './section-title';

import Helpers from '../../../../../common/helpers';
import { questionTypes } from '../../../../../common/question-components/constants/question-constants';
import { moduleSectionQuestionFields, moduleSectionsFields } from '../../../constants/constants';
import '../../../styles/module-sections.scss';
import { fields } from '../isolation-certificate-details/constants/change-status-constants';
import { fields as detailsFormConstants } from '../isolation-certificate-details/constants/details-form-constants';
import { validate as customSectionFormValidate } from '../validators/custom-section-form-validator';

const ModuleSections = (
  {
    sections = [],
    isSectionExpanded,
    sectionTitle = moduleSectionsFields.sectionSecondaryName,
    module,
    handleSectionChange,
    handleSectionAction,
    handleAnswerQuestion,
    finalStatus = '',
    modules = [],
    projectId,
    moduleId,
    handleChangeModule,
    handleSectionActionClicked,
    handleCustomAction,
    customAction,
    statuses,
    user,
    createdById,
    customSectionDisabledStatuses,
    skipOrdering,
    debounceValues,
    ...restProps
  },
  { t }
) => {
  if (!sections || !sections.length) {
    return null;
  }

  const getFormFields = fields => {
    if (isObject(fields)) {
      return values(fields);
    }

    return fields;
  };

  // only for form change, if the section has a form
  const handleFormChange = (values, c, moduleAction, validate, fields, section) => {
    // prevent form for triggering on initial load, only on change
    if (!c.pristine) {
      // if the form has validations, validate it
      if (typeof validate === 'function') {
        const errors = validate(values);

        if (!isEmpty(errors)) {
          if (typeof c.touch === 'function') {
            c.touch(detailsFormConstants.startDate.name);
            c.touch(detailsFormConstants.endDate.name);
          }

          Helpers.scrollToFirstError(errors);

          return;
        }
      }

      const formValues = {};

      // if fields are supplied, filter them before sending to the API so we remove not needed data
      // also here perform transformation of data like converting the dates to UNIX
      if (fields) {
        fields = getFormFields(fields);

        for (let key in values) {
          const field = find(fields, { [moduleSectionsFields.fieldName]: key });

          if (field && !field.isDisabled) {
            // if the date changed and it's in string format, convert it to timestamp
            if (field[moduleSectionsFields.fieldIsDate] && typeof values[key] !== 'number' && Helpers.isDateValid(values[key])) {
              formValues[key] = Helpers.getUnixDate(new Date(values[key]).getTime());
            } else {
              formValues[key] = values[key];
            }
          }
        }
      }

      const data = {
        values: formValues,
        module,
        modules,
        section,
        handleChangeModule,
      };

      if (typeof handleSectionChange === 'function') {
        handleSectionChange(data, moduleAction);
      }
    }
  };

  // for other actions, if it's not a form, for example upload files in the section
  const handleSectionActionChange = (data, moduleAction) => {
    if (typeof handleSectionAction === 'function') {
      handleSectionAction(data, moduleAction);
    }
  };

  const getCustomSectionFormInitialValues = section => {
    const types = values(questionTypes);
    const initialValues = {};
    const sectionQuestions = Object.assign([], section[moduleSectionsFields.questions]);

    for (let i = 0; i < sectionQuestions.length; i++) {
      const type = find(types, { valueId: sectionQuestions[i][moduleSectionQuestionFields.type] });

      if (!isEmpty(type)) {
        const name = `${type.fieldName}-${sectionQuestions[i][moduleSectionQuestionFields.id]}`;
        const value =
          sectionQuestions[i][moduleSectionQuestionFields.answers] && sectionQuestions[i][moduleSectionQuestionFields.answers].length > 0
            ? sectionQuestions[i][moduleSectionQuestionFields.answers][0][type.fieldName]
            : null;

        // if the question type is "MULTI_ANSWER", we need to loop through available questions options
        // to determine is the question answered to pre-populate the field in the form
        if (type.valueId === questionTypes.MULTI_ANSWER.valueId) {
          const questionOptions = Object.assign([], sectionQuestions[i][moduleSectionQuestionFields.questionOptions]);

          for (let j = 0; j < questionOptions.length; j++) {
            if (questionOptions[j][moduleSectionQuestionFields.isAnswer]) {
              initialValues[`${name}-${questionOptions[j][moduleSectionQuestionFields.id]}`] = questionOptions[j][moduleSectionQuestionFields.id];
            }
          }
        } else if (type.valueId === questionTypes.CHECKBOX.valueId) {
          initialValues[`${name}-yes`] = value && typeof value === 'boolean' ? 'true' : null;
          initialValues[`${name}-no`] = !value && typeof value === 'boolean' ? 'false' : null;
        } else if (type.valueId === questionTypes.DATE.valueId) {
          initialValues[name] = value ? Helpers.getDateFromUnix(value) : null;
        } else {
          initialValues[name] = value;
        }
      }
    }

    return initialValues;
  };

  const getRegularFormInitialValues = section => {
    if (section && section[moduleSectionsFields.fields] && module[section[moduleSectionsFields.initialValuesProp]]) {
      const initialValues = {};
      const values = Object.assign({}, module[section[moduleSectionsFields.initialValuesProp]]);
      const formFields = Object.assign([], section[moduleSectionsFields.fields]);

      for (let key in formFields) {
        if (formFields[key][moduleSectionQuestionFields.initialValuesName] && formFields[key][moduleSectionQuestionFields.fieldName]) {
          initialValues[formFields[key][moduleSectionQuestionFields.fieldName]] = values[formFields[key][moduleSectionQuestionFields.initialValuesName]];
        }
      }

      return initialValues;
    } else if (section[moduleSectionsFields.initialValuesProp] && module && module.hasOwnProperty(moduleSectionsFields.initialValuesProp)) {
      return {
        [moduleSectionsFields.initialValuesProp]: module[moduleSectionsFields.initialValuesProp],
      };
    } else {
      // set section with order 1 to expand initially (Details section)
      if (section[moduleSectionsFields.sectionOrder] === 1) {
        section[moduleSectionsFields.initiallyExpanded] = true;
      }
      return Object.assign({}, section);
    }
  };

  // determine is section disabled for editing
  const isSectionDisabled = (disabledForStatuses, disabled, sectionEditPermission, ignoreStatusEditPermission) => {
    let isDisabled = module[moduleSectionsFields.archived] || disabled || false;

    // if the module is in final status, for example isolation certificate is in
    // status "DE_ISOLATED", section is disabled for editing
    if (module[moduleSectionsFields.status] === finalStatus) {
      isDisabled = true;
    }

    const result = values(statuses);
    const currentStatusData = result.find(statusData => statusData.value === module[moduleSectionsFields.status]);

    if (currentStatusData && currentStatusData[fields.editPermission]) {
      if (ignoreStatusEditPermission && sectionEditPermission) {
        // If ignore status edit permission it will disregard status defined permissions
        isDisabled =
          isDisabled ||
          !Helpers.hasAccess({
            user,
            visibleFor: sectionEditPermission,
          });
      } else {
        isDisabled =
          isDisabled ||
          !Helpers.hasAccess({
            user,
            visibleFor: [currentStatusData[fields.editPermission], sectionEditPermission],
            id: createdById,
            ownerRequiredPermission: currentStatusData[fields.ownershipPermission],
          });
      }
    }

    if (module[moduleSectionsFields.status] && disabledForStatuses && disabledForStatuses.length) {
      isDisabled = isDisabled || disabledForStatuses.indexOf(module[moduleSectionsFields.status]) > -1;
    }

    return isDisabled;
  };

  const renderSectionContent = section => {
    if (!section) {
      return null;
    }

    if (section.component) {
      const SectionContent = section.component;

      return (
        <SectionContent
          {...restProps}
          form={`module-sections-form-${section[moduleSectionsFields.id]}`}
          initialValues={getRegularFormInitialValues(section)}
          module={module}
          onChange={(values, _b, c) => handleFormChange(values, c, section.action, section[moduleSectionsFields.validate], section.fields, section)}
          handleSectionAction={handleSectionActionChange}
          disabled={isSectionDisabled(
            section[moduleSectionsFields.disabledForStatuses],
            section[moduleSectionsFields.disabled],
            section[fields.editPermission],
            section[fields.ignoreStatusEditPermission]
          )}
          modules={modules}
          projectId={projectId ? parseInt(projectId, 10) : null}
          moduleId={moduleId ? parseInt(moduleId, 10) : null}
          handleCustomAction={handleCustomAction}
          customAction={customAction}
          user={user}
          {...section}
        />
      );
    }

    if (section[moduleSectionsFields.questions]) {
      return (
        <CustomSectionForm
          {...restProps}
          form={`module-sections-form-${section[moduleSectionsFields.id]}`}
          handleFieldChange={handleAnswerQuestion}
          questions={section[moduleSectionsFields.questions]}
          moduleId={module[moduleSectionsFields.id]}
          initialValues={getCustomSectionFormInitialValues(section)}
          disabled={isSectionDisabled(customSectionDisabledStatuses, section[moduleSectionsFields.disabled], section[fields.editPermission], section[fields.ignoreStatusEditPermission])}
          debounceValues={debounceValues}
          {...section}
        />
      );
    }

    return <SectionEmptyState />;
  };

  return (
    <div className="sections-container">
      {sections.map((section, index) => {
        if (section.isHidden) {
          return null;
        }

        let validate = section[moduleSectionsFields.validate];
        if (section[moduleSectionsFields.questions]) {
          validate = customSectionFormValidate;
        }

        return (
          <div className="sections-item" key={`section-details-item-${section[moduleSectionsFields.id]}`}>
            <ExpandableSection
              CustomComponent={SectionTitle}
              className="expanded-section"
              customExpanded={isSectionExpanded}
              hideExpandAction
              customComponentProps={{
                title: section[sectionTitle] || section[moduleSectionsFields.sectionName],
                number: index + 1,
                titleTranslatable: section.translatableTitle,
                showError: section[moduleSectionsFields.showError],
                isSectionExpanded: isSectionExpanded || section[moduleSectionsFields.expanded],
                handleSectionActionClicked,
                module,
                section,
                validate,
                questions: section[moduleSectionsFields.questions],
                formName: `module-sections-form-${section[moduleSectionsFields.id]}`,
                sectionAction: section[moduleSectionsFields.sectionAction],
                disabled: isSectionDisabled(
                  section[moduleSectionsFields.disabledForStatuses],
                  section[moduleSectionsFields.disabled],
                  section[fields.editPermission],
                  section[fields.ignoreStatusEditPermission]
                ),
                icon: section.icon,
                iconClass: section.iconClass,
                skipOrdering,
              }}
              expanded={true}
            >
              <div
                className="section-content-item"
                id={`module-section-content-${
                  section.translatableTitle ? t(section[sectionTitle] || section[moduleSectionsFields.sectionName]) : section[sectionTitle] || section[moduleSectionsFields.sectionName]
                }`}
              >
                {renderSectionContent(section)}
              </div>
            </ExpandableSection>
          </div>
        );
      })}
    </div>
  );
};

ModuleSections.contextTypes = {
  t: PropTypes.func.isRequired,
};

ModuleSections.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
      Locked: PropTypes.bool,
      TemplateID: PropTypes.number,
      SectionQuestions: PropTypes.arrayOf(
        PropTypes.shape({
          ID: PropTypes.number,
          Name: PropTypes.string.isRequired,
          QuestionType: PropTypes.number,
          QuestionOrder: PropTypes.number,
          IsMandatory: PropTypes.bool,
          AllowMultipleAnswers: PropTypes.bool,
          EstimatedTime: PropTypes.number,
          CreatedAt: PropTypes.number,
          SectionID: PropTypes.number,
        })
      ),
      isHidden: PropTypes.bool,
    })
  ),
  isSectionExpanded: PropTypes.bool,
  sectionTitle: PropTypes.string,
  module: PropTypes.shape({
    ID: PropTypes.number,
    Name: PropTypes.string,
    Archived: PropTypes.bool,
    StartDate: PropTypes.number,
    EndDate: PropTypes.number,
    Status: PropTypes.string,
    TemplateName: PropTypes.string,
  }),
  handleSectionChange: PropTypes.func,
  handleSectionAction: PropTypes.func,
  handleAnswerQuestion: PropTypes.func,
  finalStatus: PropTypes.string,
  modules: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      Name: PropTypes.string,
      Archived: PropTypes.bool,
      Status: PropTypes.string,
    })
  ),
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  moduleId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleChangeModule: PropTypes.func,
  handleSectionActionClicked: PropTypes.func,
  handleCustomAction: PropTypes.func,
  customAction: PropTypes.string,
  customSectionDisabledStatuses: PropTypes.arrayOf(PropTypes.string),
  skipOrdering: PropTypes.bool,
};

export default ModuleSections;
