import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import PageNavigation from '../../../../common/page-navigation/components/page-navigation';
import routesConstants from '../../../../common/routes-constants';
import { params } from '../../../profile/constants/profile-constants';

import { isEmpty } from 'lodash';
import { useRef, useState } from 'react';
import Helpers from '../../../../common/helpers';
import SplitScreen from '../../../../common/resize-box/components/split-screen';
import { selectObjectTool } from '../../../inspections/actions/action-creators';
import VisibleArea from '../../../inspections/components/visible-area';
import { createViewerInstance } from '../../../inspections/potree/actions/action-creators';
import InspectionView from '../../../inspections/potree/components/main-view';
import '../../styles/stream.scss';
import StreamInspectionScreen from './stream-inspection-screen';

const Stream = (props, { t }) => {
  const {
    user,
    location,
    createViewerInstance,
    setInspectionDetails,
    viewer,
    selectTool,
    router,
    route,
    inspectionReducer: { leftCollapsed, rightCollapsed, activeDetailsPage },
  } = props;
  const {
    search,
    query: { project_id, inspection_id },
  } = location;
  const [currentInsertion, setCurrentInsertion] = useState(null);
  const inspectionRef = useRef();

  const backButtonPath = `${user[params.fullScreenEnabled] ? routesConstants.routes.protectedRoutes.fullScreen.fullPath : routesConstants.routes.protectedRoutes.inspections.fullPath}${search}`;

  const getCameraPosition = () => {
    const { viewer, inspectionDetails } = this.props;
    if (viewer && viewer.scene && viewer.scene.cameraP) {
      return { x: viewer.scene.cameraP.position.x, y: viewer.scene.cameraP.position.y, z: viewer.scene.cameraP.position.z };
    } else if (inspectionDetails.CameraPosition && !isEmpty(inspectionDetails.CameraPosition.coordinates)) {
      return { x: inspectionDetails.CameraPosition.coordinates[0], y: inspectionDetails.CameraPosition.coordinates[1], z: inspectionDetails.CameraPosition.coordinates[2] };
    } else return { x: 0, y: 0, z: 0 };
  };

  const scrollToElement = (data, generateCustomIdentificator, autoScrollOffset, delay = 500) => {
    setTimeout(() => {
      Helpers.scrollIntoView('items-table-renderer', null, autoScrollOffset, generateCustomIdentificator(data));
    }, delay);
  };

  return (
    <div className="stream">
      <PageNavigation title="TOOL_TYPE.AUGMENTED_REALITY" icon="map" backButtonPath={backButtonPath} withBottomBorder />
      <VisibleArea interactable={true} rightCollapsed={rightCollapsed || !activeDetailsPage} leftCollapsed={leftCollapsed} topCollapsed={true}>
        <SplitScreen
          direction="w"
          isActive={true}
          screenRenderers={[
            () => (
              <InspectionView
                parentRef={inspectionRef}
                createViewerInstance={createViewerInstance}
                viewer={viewer}
                currentInsertion={currentInsertion}
                activeTool={null}
                handleObjectToolClick={() => null}
                setInspectionDetails={setInspectionDetails}
                isWorkflow={false}
                isActive={true}
                openInstructionsModal={() => null}
                openSettings={() => null}
                showObjectToolbox
                showScreenToolbox
                show360
              />
            ),
            () => (
              <div>
                <h2 className="f-primary">Stream goes here!</h2>
              </div>
            ),
          ]}
        />
      </VisibleArea>
      <StreamInspectionScreen
        location={location}
        viewer={viewer}
        selectTool={selectTool}
        currentInsertion={currentInsertion}
        setCurrentInsertion={setCurrentInsertion}
        createNewDefect={(...args) => console.log('TODO: CREATE NEW DEFECT: ', args)}
        getCameraPosition={getCameraPosition}
        handleObjectToolClick={(...args) => console.log('TODO: HANDLE OBJECT TOOL CLICK: ', args)}
        getDefectType={Helpers.getDefectType}
        showObjectToolbox={true}
        scrollToElement={scrollToElement}
        router={router}
        route={route}
      />
    </div>
  );
};

Stream.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.userReducer,
  viewer: state.potreeReducer.viewerInstance,
  inspectionReducer: state.inspectionReducer,
});

const mapDispatchToProps = dispatch => ({
  createViewerInstance: potreeInstance => dispatch(createViewerInstance(potreeInstance)),
  selectTool: val => dispatch(selectObjectTool(val)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Stream));
