import { cloneDeep, filter, find, findIndex, get, groupBy, head, intersectionWith, isArray, isEmpty, isEqual, join, map, orderBy, reduce, some, takeRight, uniqBy } from 'lodash';
import { browserHistory } from 'react-router';

import moment from 'moment';
import { HYBIRD_ID, PDF_EXTENSIONS, SUPPORTED_IMAGE_EXTENSIONS } from '../common/constants';
import { genericMessage, genericTitle, supportedAwsErrors, supportedErrorsContent } from './modal/constants/modal-constants';

import { generateQueryParams } from '../api/helpers/helper';
import routesConstants from './routes-constants';

import genericFileImage from '../app/inspections/assets/file.svg';

import { fields as dmsFields } from '../app/document-management/constants/constants';
import workflowConstants from '../app/inspection-workflow/constants/inspection-workflow-constants';
import { fields as readingsAndGaugesFormConstants } from '../app/inspections/components/readings-and-gauges/constants/constants';
import { formConstants as areaConstants } from '../app/inspections/constants/areas-constants';
import { formConstants as componentConstants, mappedComponentFields } from '../app/inspections/constants/component-constants';
import { formConstants as defectConstants, defectType } from '../app/inspections/constants/defect-constants';
import { measurementConstants } from '../app/inspections/constants/ndt-constants';
import sapNotificationConstants from '../app/inspections/constants/sap-notification-constants';
import sapConstants from '../app/integrations/forms/constants/sap-constants';

import React from 'react';
import { pdfjs } from 'react-pdf';
import { ALARMS } from '../app/inspections/components/readings-and-gauges/constants/alarm-constants';
import { checklistTemplateFields } from '../app/inspections/components/work-order/constants/work-order-right-side-constants';
import { modules } from '../app/inspections/constants/constants';
import { componentHasAllCoordinatesZero } from '../app/inspections/helpers/inspection-helper';
import userConstants from '../app/login/constants/user-constants';
import DrawingHelpers from './drawing-helpers';

// Defines a constant for the regex pattern, to be used in convertFileExtensionToLowercase
const FILE_EXTENSION_REGEX = /\.[^/.]+$/;
class Helpers {
  static goTo = (path, params, data = null) => {
    if (!isEmpty(params)) {
      browserHistory.push({ pathname: path, search: generateQueryParams(params), state: data });
    } else {
      browserHistory.push(path);
    }
  };

  static openInNewTab = (e, href, target = '_blank') => {
    e.preventDefault();
    Object.assign(document.createElement('a'), {
      target: target,
      href,
    }).click();
  };

  static RGBAToHexA = (r, g, b, a) => {
    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);
    a = Math.round(a * 255).toString(16);

    if (r.length === 1) r = '0' + r;
    if (g.length === 1) g = '0' + g;
    if (b.length === 1) b = '0' + b;
    if (a.length === 1) a = '0' + a;

    return '#' + r + g + b + a;
  };

  static composeQueryParamsArray = queryObject => {
    return (
      Object.keys(queryObject).map(key => {
        if (!queryObject[key] || queryObject[key] === undefined) return {};
        const queryAliasIndex = Object.keys(routesConstants.queryAlias).findIndex(query => query === key);
        if (queryAliasIndex !== -1) {
          return { [routesConstants.queryAlias[key]]: queryObject[key] };
        } else {
          return { [key]: queryObject[key] };
        }
      }) || []
    );
  };

  static isGuestUser = user => {
    return false;
  };

  static getErrorTitle = (title, err) => {
    const errCode = this.getErrorCode(err);
    if (errCode && some(supportedAwsErrors, item => item === errCode)) {
      return 'AWS_ERROR_TITTLE.' + errCode;
    }
    return title || genericTitle;
  };

  static getErrorContent = err => {
    const errCode = this.getErrorCode(err);

    if (errCode && some(supportedErrorsContent, item => item === errCode)) {
      return 'ERROR_MODAL.' + errCode;
    } else if (errCode && some(supportedAwsErrors, item => item === errCode)) {
      return 'AWS_ERROR.' + errCode;
    }

    return genericMessage;
  };

  static getErrorData = err => {
    const errData = get(err, 'response.data.Data');

    return errData;
  };

  static getWarnings = warnings => {
    const warningsToPresent = {};
    if (warnings === null) return {};

    if (warnings?.length > 0) {
      warnings.forEach(warning => {
        warningsToPresent[warning['warning-field']] = this.getWarningMessage(warning['warning-code']);
      });
    }
    return warningsToPresent;
  };

  static getWarningMessage = warnCode => {
    return { string: `ERROR_MODAL.${warnCode}` };
  };

  static getPercentage(value, total) {
    return (value / total) * 100;
  }

  static getLoginValidation(err) {
    const errCode = get(err, 'response.data.Code');
    if (errCode) return 'LOGIN.' + errCode;

    return genericMessage;
  }

  static getDateFromUnix = (date, format) => {
    return moment(date * 1000).format(format || 'LLL');
  };

  static getDateFromUnixMs = (date, format) => {
    return moment(date).format(format || 'LLL');
  };

  static getDaysDifference = targetDate => {
    return Math.abs(
      moment()
        .startOf('day')
        .diff(moment(targetDate * 1000).startOf('day'), 'day')
    );
  };

  static checkHoursDifference = (unixTimestamp, t) => {
    const incomingDate = moment.unix(unixTimestamp);

    const currentDate = moment();

    const minutesDifference = currentDate.diff(incomingDate, 'minutes');
    const hoursDifference = currentDate.diff(incomingDate, 'hours');

    if (Math.abs(minutesDifference) < 60) {
      if (minutesDifference === 0) {
        return t('HOURS_DIFFERENCE.JUST_NOW');
      } else if (minutesDifference > 0) {
        return `${minutesDifference} ${t('HOURS_DIFFERENCE.MIN_AGO')}`;
      } else {
        return `${Math.abs(minutesDifference)} ${t('HOURS_DIFFERENCE.MIN_FUTURE')}`;
      }
    } else if (Math.abs(hoursDifference) < 24) {
      if (hoursDifference === 0) {
        return t('HOURS_DIFFERENCE.JUST_NOW');
      } else if (hoursDifference > 0) {
        return `${hoursDifference} ${t('HOURS_DIFFERENCE.HRS_AGO')}`;
      } else {
        return `${Math.abs(hoursDifference)} ${t('HOURS_DIFFERENCE.HRS_FUTURE')}`;
      }
    } else {
      return this.getDateFromUnix(unixTimestamp, 'DD MMM, YYYY HH:mm A');
    }
  };

  static getUnixDate = date => {
    date = date.toString();
    date = date.substring(0, date.length - 3);
    return parseFloat(date);
  };

  static addMonths = (date, monthsToAdd) => new Date(date.setMonth(date.getMonth() + monthsToAdd));

  static addHours = (date, hours) => {
    const modifiedDate = new Date(date);

    // Check if the input date is valid
    if (isNaN(modifiedDate.getTime())) {
      return date;
    }

    modifiedDate.setHours(modifiedDate.getHours() + hours);
    return modifiedDate;
  };

  // if negative days parameter is sent, the days will be subtracted
  static addDays = (date, days) => {
    const modifiedDate = new Date(date);

    // Check if the input date is valid
    if (isNaN(modifiedDate.getTime())) {
      return date;
    }

    modifiedDate.setDate(modifiedDate.getDate() + days);
    return modifiedDate;
  };

  static isDateInPast = unixDate => unixDate - Math.floor(Date.now() / 1000) < 0;

  static formatFileName = name => {
    name = name.trim();
    name = name.replace(/[\s,]+/g, '_');
    return encodeURIComponent(name);
  };

  static getKeyFromUrl = url => {
    if (!url) {
      return '';
    }

    url = url.split('?');
    if (url[0]) url = url[0].split('.com/');
    if (url[1]) url = url[1];

    return url;
  };

  static mapLineNDTChartData = (data, unit) => {
    let hasLowest = false;
    let hasHighest = false;
    let hasPredictive = false;
    data.forEach(item => {
      if (item.Lowest) {
        hasLowest = true;
      }
      if (item.Highest) {
        hasHighest = true;
      }
      if (item.Predictive) {
        hasPredictive = true;
      }
    });

    data = data.map(item => {
      let optionalData = [];
      if (hasLowest) {
        optionalData.push(item.Lowest || null);
      }
      if (hasHighest) {
        optionalData.push(item.Highest || null);
      }
      if (hasPredictive) {
        optionalData.push(item.Predictive || null);
      }
      // return [item.Index === null || isNaN(item.Index) ? new Date(this.getDateFromUnix(item.Date)) : item.Index, ...optionalData, item.Median];

      // for highcharts
      return [item.Index === null || isNaN(item.Index) ? item.Date * 1000 : item.Index, ...optionalData, item.Median];
    });

    data = !isEmpty(data) ? data : [];
    let optionalData = [];
    if (hasLowest) {
      optionalData.push(`Min (${unit})`);
    }
    if (hasHighest) {
      optionalData.push(`Max (${unit})`);
    }
    if (hasPredictive) {
      optionalData.push(`Predictive (${unit})`);
    }
    return { data: [['x', ...optionalData, `Median (${unit})`]].concat(data) };
  };

  static isLineChartEmpty = data => {
    return isEmpty(data) || isEmpty(data[1]);
  };

  static setSelected = (selected, files, fieldName = 'selected') => {
    return map(files, item => {
      item[fieldName] = selected;
      return item;
    });
  };

  static activateImageById = (id, files, fieldName = 'selected', callback, idFieldName = 'ID') => {
    return map(files, item => {
      if (id === item[idFieldName]) {
        item[fieldName] = !item[fieldName];
        if (callback) {
          callback(item);
        }
      }
      return item;
    });
  };

  static getExtension = name => {
    const extensionIndex = name.lastIndexOf('.');
    return name.substring(extensionIndex, name.length);
  };

  static getFileType(passedType) {
    let type = 'document';
    if (passedType.toUpperCase().indexOf('IMAGE') > -1) type = 'image';
    else if (passedType.toUpperCase().indexOf('VIDEO') > -1) type = 'video';
    return type;
  }

  static formatCell = (value, type, index, item) => {
    if (type === 'date') {
      return !isNaN(value) && value > 0 ? this.getDateFromUnix(value) : '-';
    } else if (type === 'active') {
      return value ? 'TABLE_COLUMN_DATA.ACTIVE' : 'TABLE_COLUMN_DATA.INACTIVE';
    } else if (type === 'yes-no') {
      return value ? 'TABLE_COLUMN_DATA.YES' : 'TABLE_COLUMN_DATA.NO';
    } else if (type === 'enumerated') {
      return `${index + 1}. ${value}`;
    } else if (type === 'hiddenParent' && item.ParentID === HYBIRD_ID) {
      return '-';
    }

    return value;
  };
  static getUploadImageSource = (file, url, genericImage = genericFileImage, fileType = '') => {
    return this.isImage(file, fileType) ? url : genericImage;
  };

  static isImage = (fileName, fileType = '') => {
    if (!fileName) return;
    let extension = '';
    if (isEmpty(fileType)) {
      const extensionIndex = fileName.lastIndexOf('.');
      if (!extensionIndex || extensionIndex === fileName.length) {
        return false;
      }
      extension = fileName.substring(extensionIndex + 1, fileName.length);
    } else {
      extension = fileType;
    }
    return SUPPORTED_IMAGE_EXTENSIONS.indexOf(extension ? extension.toLowerCase() : '') > -1;
  };

  static isPDF = (fileName, fileType = '') => {
    if (!fileName) return;
    let extension = '';
    if (isEmpty(fileType)) {
      const extensionIndex = fileName.lastIndexOf('.');
      if (!extensionIndex || extensionIndex === fileName.length) {
        return false;
      }
      extension = fileName.substring(extensionIndex + 1, fileName.length);
    } else {
      extension = fileType;
    }

    return PDF_EXTENSIONS.indexOf(extension ? extension.toLowerCase() : '') > -1;
  };

  static parseDecimal = value => {
    if (typeof value === 'string') {
      return isNaN(parseFloat(value)) || value.endsWith('.') ? value : parseFloat(value);
    }
    return value;
  };
  static convertSuggestionArray = array => {
    return array.map(el => ({ label: el }));
  };

  static downloadPDFFile = pdfBuffer => {
    const file = new Blob([pdfBuffer], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    let tempLink = document.createElement('a');
    tempLink.href = fileURL;
    tempLink.setAttribute('download', pdfBuffer.name || `report.pdf`);
    tempLink.click();
  };

  static mapReportData = (arr, defaultValue, remap) => {
    if (!isArray(arr)) return [];
    // remap array of objects to array of strings
    if (remap) {
      arr = arr.map(item => {
        return item.ID || item.Name;
      });
    }
    //add Default value if passed
    if (defaultValue) {
      return arr.indexOf(defaultValue) === -1 ? [defaultValue].concat(arr) : arr;
    }
    return arr;
  };

  static formatImageArray = (array, additionalArr) => {
    if (isEmpty(array)) return [];

    let res =
      array.map(el => {
        const {
          [workflowConstants.formConstants.fields.selected]: selected,
          [workflowConstants.formConstants.fields.reviewed]: reviewed,
          [workflowConstants.formConstants.fields.id]: id,
          [workflowConstants.formConstants.fields.name]: name,
          [workflowConstants.formConstants.fields.url]: src,
          [workflowConstants.formConstants.fields.thumbnailUrl]: thumbnailUrl,
          [workflowConstants.formConstants.fields.documentSizeUrl]: documentSizeUrl,
          [workflowConstants.formConstants.fields.highestSeverity]: highestSeverity,
        } = el;
        return { id, selected, reviewed, name, src, thumbnailUrl, documentSizeUrl, highestSeverity, ...el };
      }) || [];

    if (additionalArr) {
      res = res.concat(additionalArr);
    }

    return res;
  };

  static formatImageArrayWithSections = (imageArr, sections) => {
    const images = this.formatImageArray(imageArr);

    return map(sections, item => {
      return {
        ...item,
        images: filter(images, { [item.prop]: item.value }) || [],
      };
    });
  };

  static mapDownloadReportFilter = (values, defaultValue, multiSelectFields = []) => {
    let vals = Object.assign({}, values);

    /*eslint array-callback-return:*/
    Object.keys(vals).map(key => {
      if (vals[key] && vals[key][0] && vals[key].indexOf(defaultValue) > -1) {
        delete vals[key];
      }
      //convert multiselect fields(array) to coma separated string
      if (vals[key] && multiSelectFields.indexOf(key) > -1 && vals[key].length > 0) {
        vals[key] = join(vals[key], ',');
      }
    });
    return vals;
  };

  static mapInfoIconDisplayProps = (activeItem, displayProps) => {
    let props = [];
    if (activeItem) {
      let item = null;
      Object.keys(activeItem).forEach(async key => {
        item = find(displayProps, { name: key });
        if (item) {
          props = { ...props, [`DISPLAY_PROPS.${key.toUpperCase()}`]: item.type === 'date' ? (activeItem[key] ? this.getDateFromUnix(activeItem[key]) : '-') : activeItem[key] };
        }
      });
    }
    return props;
  };

  static checkIsFeatureDisabled = (currentFeature, featuresWithPermissions) => {
    if (isEmpty(featuresWithPermissions)) {
      return false;
    }

    return !some(featuresWithPermissions, { Name: currentFeature, Active: true });
  };

  static mapExternalFilesForModal = (Data, genericImage, isCompletionFile) => {
    const { getUploadImageSource, isImage, isPDF } = this;
    return map(Data, item => {
      return {
        ...item,
        src: getUploadImageSource(item.FileName, item.URL, genericImage, item.FileType),
        isImage: isImage(item.FileName, item.FileType),
        isPDF: isPDF(item.FileName, item.FileType),
        thumbnailUrl: item[workflowConstants.formConstants.fields.thumbnailUrl],
        documentSizeUrl: item[workflowConstants.formConstants.fields.documentSizeUrl],
        name: item.FileName,
        isCompletionFile: item.isCompletionFile || isCompletionFile,
      };
    });
  };

  static updateProjectArray = (prevProjects, nextProjects) => {
    if (prevProjects.length === 0) {
      return nextProjects;
    }
    return prevProjects.map(prevProject => {
      const nextProject = nextProjects.find(el => el.ID === prevProject.ID);

      if (nextProject) {
        return { ...prevProject, ...nextProject };
      }
      return prevProject;
    });
  };

  static uuid4 = () => {
    let uuid = '',
      ii;
    for (ii = 0; ii < 32; ii += 1) {
      switch (ii) {
        case 8:
        case 20:
          uuid += '-';
          uuid += ((Math.random() * 16) | 0).toString(16);
          break;
        case 12:
          uuid += '-';
          uuid += '4';
          break;
        case 16:
          uuid += '-';
          uuid += ((Math.random() * 4) | 8).toString(16);
          break;
        default:
          uuid += ((Math.random() * 16) | 0).toString(16);
      }
    }
    return uuid;
  };
  static scrollIntoView = (wrapperClassName, targetId, heightCorrection = 10000, targetClassName, horizontalScroll = false) => {
    var targetDiv = document.getElementsByClassName(wrapperClassName) && document.getElementsByClassName(wrapperClassName)[0];
    let targetEl = '';

    if (targetId) {
      targetEl = document.getElementById(targetId);
    } else if (targetClassName) {
      targetEl = document.getElementsByClassName(targetClassName)[0];
    } else {
      //scroll to bottom of the last element if there is no target el
      targetEl = targetDiv.lastChild;
      heightCorrection = targetEl.lastChild.offsetHeight;
    }
    if (targetDiv) {
      if (horizontalScroll) {
        targetEl.scrollIntoView({ block: 'nearest', inline: 'start', behavior: 'smooth' });
      } else {
        targetDiv.scroll({
          top: ((targetEl && targetEl.offsetTop) || 0) + heightCorrection,
          behavior: 'smooth',
        });
      }
    }
  };
  static scrollTop = (wrapperClassName, heightCorrection = 10000, anim = false) => {
    var targetDiv = document.getElementsByClassName(wrapperClassName) && document.getElementsByClassName(wrapperClassName)[0];

    if (targetDiv) {
      targetDiv.scroll({
        top: 0 + heightCorrection,
        behavior: anim ? 'smooth' : 'auto',
      });
    }
  };
  static fetchReviewedClass = severity => {
    const res =
      find(workflowConstants.severity, item => {
        return item.keys.indexOf(severity) > -1;
      }) || workflowConstants.severity.green;

    return res.color;
  };

  static getImageUrlfromFile = file => {
    const _URL = window.URL || window.webkitURL;
    return _URL.createObjectURL(file);
  };

  static getNotificationSatusClassName = status => {
    switch (status) {
      case sapNotificationConstants.status.inProcess:
        return 'gray';
      case sapNotificationConstants.status.new:
        return 'green';
      case sapNotificationConstants.status.published:
        return 'orange';
      case sapNotificationConstants.status.released:
        return 'yellow';
      case sapNotificationConstants.status.completed:
        return 'blue';
      case sapNotificationConstants.status.closed:
        return 'red';
      default:
        return 'gray';
    }
  };

  static getSAPIntegrationStatusClassName = status => {
    switch (status) {
      case sapConstants.integrationSatus.inProgress:
        return 'gray';
      case sapConstants.integrationSatus.notStarted:
        return 'green';
      case sapConstants.integrationSatus.completed:
        return 'blue';
      case sapConstants.integrationSatus.failed:
        return 'red';
      default:
        return 'gray';
    }
  };

  static windowOpen = (url, newTab) => {
    window.open(url, newTab ? '_blank' : '');
  };

  static getCharLabel = (currentCount, maxChars) => {
    return !isNaN(maxChars) ? `${currentCount}/${maxChars}` : `${currentCount}`;
  };

  static groupByDay = (collection, dateProp = 'Date', format = 'LLL', todayKey = 'TODAY', orderDirection = 'desc') => {
    collection = orderBy(collection, [dateProp], [orderDirection]);

    return groupBy(collection, function (item) {
      const iscurrentDate = moment(item[dateProp] * 1000).isSame(new Date(), 'day');
      if (iscurrentDate) {
        return todayKey;
      }

      return moment(item[dateProp] * 1000)
        .startOf('day')
        .format(format);
    });
  };

  static getCameraPosition = (viewer, defaultCameraPosition = [0, 0, 0]) => {
    const cameraPosition = get(viewer, 'scene.cameraP.position');
    if (cameraPosition) {
      return { x: cameraPosition.x, y: cameraPosition.y, z: cameraPosition.z };
    } else return { x: defaultCameraPosition[0], y: defaultCameraPosition[1], z: defaultCameraPosition[2] };
  };

  static setFavicon = (imgPath = '') => {
    // <link rel="shortcut icon" href="%PUBLIC_URL%/favicon.ico">
    const link = document.createElement('link');
    link.rel = 'shortcut icon';
    link.href = imgPath;
    document.head.appendChild(link);
  };

  static groupItemsByParam = (items, groupByParam, defaultGroup, restGroupParams = [], SEPARATION_KEY = '_') => {
    const withoutGroup = filter(items, item => !item[groupByParam]);
    const withGroup = filter(items, item => item[groupByParam]);
    const grouped = groupBy(withGroup, item => {
      let key = item[groupByParam];
      restGroupParams.forEach(prop => (Object(item).hasOwnProperty(prop) ? (key += `${SEPARATION_KEY}${item[prop]}`) : key));
      return key;
    });
    if (!isEmpty(withoutGroup)) grouped[defaultGroup] = withoutGroup;
    return grouped;
  };

  static downloadFile = (url, extension = '.pdf', fileName) => {
    const { getImageUrlfromFile, isStringEncoded } = this;
    const isFileNameEncoded = isStringEncoded(fileName);
    const decodedFileName = isFileNameEncoded ? decodeURIComponent(fileName) : fileName;
    const fileDownloadName = decodedFileName.includes(extension) ? decodedFileName : `${decodedFileName}${extension}`;

    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      var href = getImageUrlfromFile(this.response);
      var tag = document.createElement('a');
      tag.href = href;
      tag.download = fileDownloadName || url + extension;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  };

  static replaceEmptySpaceWithHyphen = string => {
    return string.replace(/\s/g, '-');
  };

  static convertFileExtensionToLowercase = fileName => {
    return fileName.replace(FILE_EXTENSION_REGEX, ext => ext.toLowerCase());
  };

  static getFileExtensionAndDownload = file => {
    const { downloadFile, getDateFromUnix, replaceEmptySpaceWithHyphen, convertFileExtensionToLowercase } = this;

    const extension = isEmpty(file.FileType) ? Helpers.getExtension(file.FileName) || '' : `.${file.FileType}`;
    let fileName = '';
    if (file[dmsFields.fileVersion] && file[dmsFields.versionDate]) {
      const fileNameWithoutExtension = file[dmsFields.fileName].split('.').slice(0, -1).join('.');
      const fileVersionDate = replaceEmptySpaceWithHyphen(getDateFromUnix(file[dmsFields.versionDate]));

      fileName = `${fileNameWithoutExtension}-${file[dmsFields.fileVersion]}-${fileVersionDate}`;
    } else {
      fileName = `${file[dmsFields.fileName]}`;
    }
    /**
     * lowercasing the extension due to the handling on API and S3,
     * .pdf file when uploaded can be uppercase it to .PDF which breaks the final name of the file.
     * To make sure the FileType is consistent in handling the file name, we lowercase it.
     */

    // Uses the helper method to convert the file extension to lowercase, ensures that the extension in fileName is lowercased before passing it to the downloadFile function
    const fileNameWithLowercaseExtension = convertFileExtensionToLowercase(fileName);
    const lowercasedExtension = extension.toLowerCase();
    downloadFile(file.URL, lowercasedExtension, fileNameWithLowercaseExtension);
  };

  static downloadImageWithDrawing = data => {
    const { isStringEncoded } = this;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Draw the original image
    const img = new Image();
    img.crossOrigin = 'anonymous'; // Set the crossorigin attribute
    img.src = data.URL;
    img.onload = () => {
      const recalculatedDrawingPosition = DrawingHelpers.calculateDefectImageDrawingsOffset(JSON.parse(data.Drawings), img.width, img.height);
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);

      ctx.strokeStyle = recalculatedDrawingPosition.objects[0].stroke;
      ctx.lineWidth = recalculatedDrawingPosition.objects[0].strokeWidth;
      ctx.strokeRect(
        recalculatedDrawingPosition.objects[0].left,
        recalculatedDrawingPosition.objects[0].top,
        recalculatedDrawingPosition.objects[0].width * recalculatedDrawingPosition.objects[0].scaleX,
        recalculatedDrawingPosition.objects[0].height * recalculatedDrawingPosition.objects[0].scaleY
      );

      // Convert the canvas to a data URL and create a download link
      const dataUrl = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = dataUrl;
      const isFileNameEncoded = isStringEncoded(data.FileName);
      const decodedFileName = isFileNameEncoded ? decodeURIComponent(data.FileName) : data.FileName;
      link.download = decodedFileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
  };

  static parseJson = json => {
    let object;
    try {
      object = JSON.parse(json);
    } catch (e) {
      console.warn('Cannot parse json', e);
    }
    return object || {};
  };

  static getItemSeverity = (severity, severityColors) => {
    if (workflowConstants.severity.green.keys.indexOf(severity) > -1) {
      return severityColors.severityGreen;
    } else if (workflowConstants.severity.yellow.keys.indexOf(severity) > -1) {
      return severityColors.severityYellow;
    } else if (workflowConstants.severity.orange.keys.indexOf(severity) > -1) {
      return severityColors.severityOrange;
    } else if (workflowConstants.severity.red.keys.indexOf(severity) > -1) {
      return severityColors.severityRed;
    }

    return severityColors.severityGreen;
  };

  static removeUnusedKeys = object => {
    const res = reduce(
      object,
      (newObj, value, key) => {
        if (!isEmpty(value) || value?.constructor?.name === 'Date' || (typeof value === 'boolean' && value)) {
          newObj[key] = value;
        }
        return newObj;
      },
      {}
    );
    return res;
  };

  static autoScrollConstants = {
    [modules.measurements]: {
      generateCustomIdentificator: item => {
        if (!item) {
          return '';
        }
        return `id-${item[defectConstants.fields.id]}`;
      },
      offset: -86,
    },
    [modules.defects]: {
      generateCustomIdentificator: item => {
        if (!item) {
          return '';
        }
        return `id-${item[defectConstants.fields.id]}`;
      },
      offset: -86,
    },
    [modules.components]: {
      generateCustomIdentificator: item => {
        if (!item) {
          return '';
        }
        return `id-${item[componentConstants.fields.id]}`;
      },
      offset: -60,
    },
    [modules.areas]: {
      generateCustomIdentificator: item => {
        if (!item) {
          return '';
        }
        return `id-${item[areaConstants.fields.id]}`;
      },
      offset: -60,
    },
    [modules.ndtData]: {
      generateCustomIdentificator: item => {
        if (!item) {
          return '';
        }
        return `id-${item[measurementConstants.fields.id]}`;
      },
      offset: -60,
    },
  };

  static hasAccess = ({ user, visibleFor = [], id, ownerRequiredPermission = null, operator = 'OR' }) => {
    const hasPermission = () => {
      const visibility = typeof visibleFor === 'string' || visibleFor instanceof String ? [visibleFor] : visibleFor;
      if (isEmpty(visibility)) {
        return true;
      }
      if (operator === 'OR') {
        return intersectionWith(visibility, user.DistinctPermissions, isEqual).length > 0;
      } else {
        return intersectionWith(visibility, user.DistinctPermissions, isEqual).length === visibility.length;
      }
    };
    const permissionCheck = hasPermission();
    const isMineCheck = isNaN(id) ? false : user[userConstants.userFields.userId] === id && user.DistinctPermissions?.indexOf(ownerRequiredPermission) > -1;

    return permissionCheck || isMineCheck;
  };

  static getDefectType = name => {
    switch (name) {
      case 'Distance':
        return defectType.distance;
      case 'Point':
        return defectType.point;
      case 'Area':
        return defectType.area;
      case 'Angle':
        return defectType.angle;
      case 'Height':
        return defectType.height;
      case 'Circle':
        return defectType.circle;
      default:
        return defectType.point;
    }
  };

  static scrollToFirstError = (errors = {}) => {
    let inputs;

    for (let property in errors) {
      inputs = document.querySelectorAll(`[name="${property}"]`);

      if (inputs.length && inputs[0] && inputs[0].scrollIntoView) {
        inputs[0].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
        break;
      }
    }
  };
  static remapCreateUserObject = (values, dataLength) => {
    return {
      UserEmail: values.Email,
      UserID: values.ID,
      UserName: values.Name,
      RowNumber: dataLength,
      Policies: values.Policies,
      //UserActive is false since that is its immediate state and there is no info in API's response
      UserActive: false,
    };
  };
  static formatDataForUnassignKeyBox = (values, KeyboxID) => {
    return {
      ...values,
      KeyboxID,
    };
  };
  static getSearchTermText(text, searchTerm, customClassName) {
    if (!searchTerm) return text;
    const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters in search term
    const parts = text.split(new RegExp(`(${escapedSearchTerm})`, 'gi'));
    return (
      <p className={`f-primary ${customClassName ? customClassName : ''}  `} key={searchTerm}>
        {parts.map(part => (part.toLowerCase() === searchTerm.toLowerCase() ? <span className="search-term-text">{part}</span> : part))}
      </p>
    );
  }

  static addViewerControlsListener(viewer, func) {
    if (!viewer.fpControls || !viewer.panoControls || !viewer.earthControls) {
      return;
    }
    viewer.fpControls.addEventListener('end', func);
    viewer.fpControls.addEventListener('scroll_end', func);
    viewer.panoControls.addEventListener('end', func);
    viewer.panoControls.addEventListener('scroll_end', func);
    viewer.earthControls.addEventListener('end', func);
    viewer.earthControls.addEventListener('scroll_end', func);
  }

  static removeViewerControlsListener(viewer, func) {
    if (!viewer.fpControls || !viewer.panoControls || !viewer.earthControls) {
      return;
    }
    viewer.fpControls.removeEventListener('end', func);
    viewer.fpControls.removeEventListener('scroll_end', func);
    viewer.panoControls.removeEventListener('end', func);
    viewer.panoControls.removeEventListener('scroll_end', func);
    viewer.earthControls.removeEventListener('end', func);
    viewer.earthControls.removeEventListener('scroll_end', func);
  }

  static formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes';

    const k = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    let adjustedBytes = bytes / Math.pow(k, i);

    adjustedBytes = parseFloat(adjustedBytes.toFixed(dm));

    // Check if the adjustedBytes value needs rounding
    const roundedBytes = adjustedBytes % 1 === 0 ? adjustedBytes : adjustedBytes.toFixed(dm);

    return `${roundedBytes} ${sizes[i]}`;
  }

  static getFileNumberOfPages = file => {
    const reader = new FileReader();

    if (file) {
      return new Promise((resolve, reject) => {
        reader.onload = () => {
          const arrayBuffer = reader.result;
          const pdf = new Uint8Array(arrayBuffer);
          const pdfData = pdfjs.getDocument(pdf);
          pdfData.promise
            .then(pdfDocument => {
              resolve(pdfDocument.numPages);
            })
            .catch(error => {
              console.error('Error processing PDF file:', error);
              // Handle the error as needed, but don't reject the promise to continue processing.
              resolve(-1); // Return -1 pages or another appropriate value so we can disallow upload of the file
            });
        };

        reader.onerror = () => {
          reject(new Error('Failed to read the file.'));
        };

        reader.readAsArrayBuffer(file);
      });
    }
  };

  static getShortenedText(text, chars) {
    if (!text) return '';
    const isTextLargerThanChars = text.length > chars;

    if (isTextLargerThanChars) {
      return `${text.slice(0, chars)}...`;
    }
    return text;
  }

  static isStringEncoded(str) {
    try {
      return decodeURIComponent(str) !== str;
    } catch (e) {
      return false;
    }
  }

  /**
   * The function `decodeIfStringEncoded` decodes a string if it is encoded, otherwise it returns the
   * original string.
   * @param fileName - The `fileName` parameter is a string that represents the name of a file.
   * @returns the decoded version of the fileName if it is encoded as a string. If the fileName is not
   * encoded, it is returned as is.
   */
  static decodeIfStringEncoded(fileName) {
    if (this.isStringEncoded(fileName)) {
      return decodeURIComponent(fileName);
    } else {
      return fileName;
    }
  }

  static formatComponents = components => {
    if (components && components.length > 0) {
      return components.map(item => {
        for (const [mapKey, itemKey] of Object.entries(mappedComponentFields)) {
          item[mapKey] = item[itemKey];
        }
        return item;
      });
    }

    return [];
  };

  static getErrorCode = err => {
    const errCode = get(err, 'response.data.Code') || get(err, 'response.data.Errors[0].error-code');

    return errCode;
  };

  /**
   * The function checks if a given date is valid.
   * @returns a boolean value indicating whether the given date is valid or not.
   */
  static isDateValid = date => {
    return moment(date).isValid();
  };

  /* The below code is a JavaScript function that converts a given date to Unix timestamp format. It
 uses the moment.js library to parse the date and then returns the Unix timestamp using the `unix()`
 method. */
  static dateToUnix = date => {
    return moment(date).unix();
  };

  /* The below code is defining a static method called `getCurrentDateAndTimeInMs` in JavaScript. This method uses
    the `moment` library to get the current date and time, and then returns the value of the date and
    time in milliseconds since the Unix epoch (January 1, 1970). */
  static getCurrentDateAndTimeInMs = date => {
    if (date) return moment(date).valueOf();
    return moment().valueOf();
  };

  /* The below code is defining a static method called `getDateMomentObject` in JavaScript. This method
  takes a `date` parameter and returns a moment object created from that date. */
  static getDateMomentObject = date => {
    return moment(date);
  };

  /* The below code is a JavaScript function that takes a date as input and returns a new date with the
 time set to minute until midnight (23:59). It uses the moment.js library to manipulate the date object. The
 function sets the hours to 23 (11 PM) and the minutes to 59, effectively setting the time to the
 last minute of the day. */
  static setDateTimeToMidnight = date => {
    return moment(date).set('hours', 23).set('minutes', 59);
  };

  /**
   * Get shortened path is a function which returns shortened path from an array of objects
   * @param {array} path - array of objects that are one level in the hierarchy
   * @param {string} pathKey - which object key to use to generate new path
   * @returns {string} - new path formatted
   */

  static getShortenedPath = (path, pathKey = 'Name') => {
    // TODO: Improvement for this function can be to add how much items will be added to path after 3 dots
    let newPath = '';
    let pathToCalculate = Object.assign([], path);
    const isLarger = path.length > 4;
    if (isLarger) {
      const rootFolder = head(path);
      const foldersToShow = takeRight(path, 3);
      pathToCalculate = [rootFolder, { [pathKey]: '...' }, ...foldersToShow];
    }
    pathToCalculate.forEach((folder, index) => (newPath += `${folder[pathKey]}${index === path.length - 1 ? '' : '/'}`));
    return newPath;
  };

  static removeStandaloneCPTemplatesIfLinked = checklists => {
    let newChecklist = cloneDeep(checklists) || [];
    // filter cp templates that don't have componentID and loop through them
    (filter(newChecklist, it => it.ComponentID === undefined) || []).forEach(it => {
      if (some(newChecklist, c => c.ID === it.ID && c.ComponentID !== undefined)) {
        // checklist is linked to at least one component, remove cp without component id
        const foundIndex = findIndex(newChecklist, c => c.ID === it.ID && c.ComponentID === undefined);
        if (foundIndex > -1) {
          newChecklist = [...newChecklist.slice(0, foundIndex), ...newChecklist.slice(foundIndex + 1)];
        }
      }
    });
    return newChecklist;
  };

  static addStandaloneCPTemplates = checklists => {
    let newChecklist = cloneDeep(checklists) || [];
    // filter cp templates that don't have componentID and loop through them
    (uniqBy(newChecklist, 'ID') || []).forEach(it => {
      if (it[checklistTemplateFields.componentID] && !some(newChecklist, c => c.ID === it.ID && c[checklistTemplateFields.componentID] === null)) {
        // if it is linked to at least one component and it is not already added
        newChecklist.push({ ...it, [checklistTemplateFields.componentID]: undefined, [checklistTemplateFields.component]: null, [checklistTemplateFields.linkedToEquipment]: false });
      }
    });
    return newChecklist;
  };

  static castToggleRadioInputAnswer = value => {
    return value === 'YES' || value === true ? true : value === 'NO' ? false : null;
  };

  static getModuleLocationObject = moduleItem => {
    if (isEmpty(moduleItem)) {
      return null;
    }

    if (
      (!isEmpty(moduleItem.Geometry) && !isEmpty(moduleItem.Geometry.coordinates) && !isEmpty(moduleItem.Geometry.coordinates[0])) ||
      (!isEmpty(moduleItem.CameraPosition) && !isEmpty(moduleItem.CameraPosition.coordinates))
    ) {
      return { Geometry: moduleItem.Geometry, CameraPosition: moduleItem.CameraPosition };
    } else {
      return null;
    }
  };

  static getDateRangeByTimePeriod = (timePeriod, customDateFrom, customDateTo, defaultDateTo = Date.now(), unit = 's') => {
    // Ensure defaultDateTo is a valid timestamp
    let dateTo = new Date(defaultDateTo).getTime();

    let dateFrom;

    // Calculate DateFrom based on the timePeriod value
    switch (timePeriod) {
      case 'LAST_WEEK':
        dateFrom = new Date(dateTo);
        dateFrom.setDate(dateFrom.getDate() - 7);
        break;
      case 'LAST_MONTH':
        dateFrom = new Date(dateTo);
        dateFrom.setMonth(dateFrom.getMonth() - 1);
        break;
      case 'LAST_YEAR':
        dateFrom = new Date(dateTo);
        dateFrom.setFullYear(dateFrom.getFullYear() - 1);
        break;
      case 'CUSTOM':
        return {
          DateFrom: customDateFrom,
          DateTo: customDateTo,
        };
      default:
        throw new Error("Invalid timePeriod value. Must be one of 'LAST_WEEK', 'LAST_MONTH', 'LAST_YEAR'.");
    }

    // Convert dateFrom to timestamp in milliseconds
    let dateFromTimestamp = dateFrom.getTime();

    // Convert to seconds if unit is 's'
    if (unit === 's') {
      dateTo = Math.floor(dateTo / 1000);
      dateFromTimestamp = Math.floor(dateFromTimestamp / 1000);
    } else if (unit !== 'ms') {
      throw new Error("Invalid unit value. Must be one of 'ms' or 's'.");
    }

    return {
      DateFrom: dateFromTimestamp,
      DateTo: dateTo,
    };
  };

  static getTimePeriodByDateRange = (dateFrom, dateTo, unit = 's') => {
    // Convert dates to milliseconds if unit is 's'
    if (unit === 's') {
      dateFrom *= 1000;
      dateTo *= 1000;
    } else if (unit !== 'ms') {
      throw new Error("Invalid unit value. Must be one of 'ms' or 's'.");
    }

    const oneDay = 24 * 60 * 60 * 1000;
    const oneWeek = 7 * oneDay;
    const oneMonth = 30 * oneDay; // Approximation
    const oneYear = 365 * oneDay; // Approximation

    let diff = dateTo - dateFrom;

    // Check for predefined periods
    if (diff === oneWeek) {
      return 'LAST_WEEK';
    } else if (diff >= oneMonth - oneDay && diff <= oneMonth + oneDay) {
      // Allowing 1 day margin for month differences
      return 'LAST_MONTH';
    } else if (diff >= oneYear - oneDay && diff <= oneYear + oneDay) {
      // Allowing 1 day margin for year differences
      return 'LAST_YEAR';
    } else {
      return 'CUSTOM';
    }
  };

  static parseAggregationsFromString = inputString => {
    // Remove the curly braces and any leading/trailing whitespace
    let trimmedString = inputString.replace(/[{}]/g, '').trim();
    if (isEmpty(trimmedString)) {
      return [];
    }
    // Split the string by commas and trim each resulting element
    let aggregationArray = trimmedString.split(',').map(aggregation => aggregation.trim());
    return aggregationArray || [];
  };

  static getObjectByKey = (array, key, value) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].hasOwnProperty(key) && array[i][key] === value) {
        return array[i];
      }
    }
    return null;
  };

  static truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  static mergeSeriesData = (series, scaleFactor = 1, inverted = false) => {
    try {
      const invertedMultiplier = inverted ? -1 : 1;
      scaleFactor = scaleFactor === 0 ? 1 : scaleFactor;
      series.forEach(item => {
        item.Data = (item.Data || []).map(dataPoint => {
          const [timestamp, ...rest] = dataPoint;
          return [timestamp * 1000, ...(rest || []).map(val => (val ? val * scaleFactor * invertedMultiplier : val))];
        });
      });

      return series;
    } catch (e) {
      throw e;
    }
  };

  static countDecimalPlaces = a => {
    const [, aFrac = ''] = a.split('.');

    // Return the count of significant digits after the decimal point
    return aFrac.length;
  };

  static parseIntegerFraction = a => {
    const [aInt] = a.split('.');

    return parseInt(aInt);
  };

  // Check if coordinates are valid (not empty and not all zeros)
  static extractIfValidCoordinates = coordinates => {
    return !isEmpty(coordinates) && !componentHasAllCoordinatesZero(coordinates) ? coordinates : null;
  };

  static updateMeasurementPointAlarmStatus = measurementPoint => {
    if (!measurementPoint) return measurementPoint;

    const alarmsTriggered = measurementPoint?.[readingsAndGaugesFormConstants.measurementPointTriggeredAlarms];

    return {
      ...measurementPoint,
      [readingsAndGaugesFormConstants.alarmStatusColor]: alarmsTriggered[0]?.[readingsAndGaugesFormConstants.color] || '',
      [readingsAndGaugesFormConstants.alarmStatusName]: alarmsTriggered[0]?.[readingsAndGaugesFormConstants.name] || '',
    };
  };

  static calculatePreviousLastSeen = (currentLastSeen, perPage) => {
    let prevLastSeen = currentLastSeen;

    if (prevLastSeen >= perPage) {
      if (prevLastSeen % perPage === 0) {
        prevLastSeen = prevLastSeen - perPage;
      } else {
        // Round up to the nearest multiple of perPage
        prevLastSeen = prevLastSeen - (prevLastSeen % perPage);
      }
    } else {
      prevLastSeen = 0;
    }

    return prevLastSeen;
  };

  static getAlarmDisplayName = id => {
    const alarm = ALARMS.find(alarm => alarm.id === id);
    return alarm ? alarm.displayName : null;
  };
}

export default Helpers;
