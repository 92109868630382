export const formConstants = {
  fields: {
    color: 'BackgroundColour',
    maxPoints: 'maxPoints',
    pointSize: 'pointSize',
    FOV: 'FieldOfView',
    zoomSpeed: 'ZoomSpeed',
    EDL: 'EDL',
    EDLRadius: 'EDLRadius',
    EDLStrength: 'EDLStrength',
    PointSizing: 'PointSizing',
    PointType: 'PointColorType',
    Opacity: 'Opacity',
    Quality: 'Quality',
    HighQuality: 'HighQuality',
    minNodeSize: 'minNodeSize',
    displayColor: 'displayColor',
    displayElevation: 'displayElevation',
    cameraPosition: 'CameraPosition.coordinates',
    camPosition: 'CameraPosition',
    clipTask: 'ClipTask',
    showCameras: 'ShowCameras',
    showCameraThumbnails: 'ShowCameraThumbnails',
    numberOfRings: 'NumberOfRings',
    numberOfSlices: 'NumberOfSlices',
    initialDistance: 'InitialDistance',
    multiplier: 'Multiplier',
  },
};

export const userSettings = {
  fields: {
    showNeighbouringPositions: 'ShowNeighbourPosition',
    radiusNeighbouringFootprints: 'NeighbourRadiusFootprints',
    maxTagsDistance: 'MaxTagsDistance',
    modelQuality: 'ModelQuality',
    modelQualityOption: 'ModelQualityOption',
    navigationMode: 'NavigationMode',
    navigationModeOption: 'NavigationModeOption',
    lockZAxis: 'LockZAxis',
    fieldOfView: 'FieldOfView',
    sensitivity: 'Sensitivity',
    speedOfMovement: 'SpeedOfMovement',
  },
};

export const pointSizingOptions = [
  { label: 'FIXED', value: 'FIXED' },
  { label: 'ATTENUATED', value: 'ATTENUATED' },
  { label: 'ADAPTIVE', value: 'ADAPTIVE' },
];
export const pointMaterialOptions = [
  { label: 'RGB', value: 'rgba' },
  { label: 'RGB and Elevation', value: 'RGB and Elevation' },
  { label: 'Color', value: 'color' },
  { label: 'Elevation', value: 'elevation' },
  { label: 'Intensity', value: 'intensity' },
  { label: 'Intensity Gradient', value: 'intensity gradient' },
  { label: 'Classification', value: 'classification' },
  { label: 'Return Number', value: 'return number' },
  { label: 'Source', value: 'Source' },
  { label: 'Index', value: 'Index' },
  { label: 'Level of Detail', value: 'level of detail' },
  { label: 'Composite', value: 'composite' },
];

export const navigationModeOptions = {
  pan: { label: 'Pan', value: 'PAN' },
  viewer: { label: 'Viewer', value: 'VIEWER' },
};

export const pointMaterialIndex = {
  RGB: 0,
  RGB_ELEVATION: 1,
  COLOR: 2,
  ELEVATION: 3,
};
export const pointShapeOptions = [
  { label: 'SQUARE', value: 'SQUARE' },
  { label: 'CIRCLE', value: 'CIRCLE' },
  { label: 'PARABOLOID', value: 'PARABOLOID' },
];
export const sceneSettings = {
  defaultBgColor: { hexCode: 0x8e919b, hexString: '#8E919B' },
};

export const clipTaskOptions = [
  { label: 'None', value: 'NONE' },
  { label: 'Highlight', value: 'HIGHLIGHT' },
  { label: 'Show inside', value: 'SHOW_INSIDE' },
  { label: 'Show outside', value: 'SHOW_OUTSIDE' },
];

export const presetConstants = {
  _sd: '_SD',
  _hd: '_HD',
  _4k: '_4K',
};

export const resolutionTools = [
  {
    id: '1',
    icon: null,
    title: 'SCREEN_TOOLBOX.SD',
    action: presetConstants._sd,
  },
  {
    id: '2',
    icon: null,
    title: 'SCREEN_TOOLBOX.HD',
    action: presetConstants._hd,
  },
  {
    id: '3',
    icon: null,
    title: 'SCREEN_TOOLBOX.4K',
    action: presetConstants._4k,
  },
];

export const inspectionPresets = {
  [presetConstants._sd]: {
    [formConstants.fields.maxPoints]: 3,
    [formConstants.fields.HighQuality]: false,
  },
  [presetConstants._hd]: {
    [formConstants.fields.maxPoints]: 5,
    [formConstants.fields.HighQuality]: true,
  },
  [presetConstants._4k]: {
    [formConstants.fields.maxPoints]: 10,
    [formConstants.fields.HighQuality]: true,
  },
};

export const modelQualityOptions = [
  { label: 'Medium Definition', value: presetConstants._sd },
  { label: 'High Definition', value: presetConstants._hd },
  { label: 'Ultra High Definition', value: presetConstants._4k },
];
