import PropTypes from 'prop-types';

import { isEmpty } from 'lodash';
import Button from '../../../../../common/form/components/button';
import Helpers from '../../../../../common/helpers';
import '../../../styles/submit-checklist-modal.scss';
import { checklistValidationTriggeredFields } from '../constants/work-order-right-side-constants';

const SubmitChecklistModal = (props, { t }) => {
  const { confirmAction, customCloseAction, validationErrors } = props;

  // Since the BE response offers multiple alarms triggered filter alarms triggered and which have either notification/observation true
  const alarmsMap = validationErrors?.[checklistValidationTriggeredFields.validationIssues]?.some(issue =>
    issue?.AlarmsTriggered?.some(alarm => alarm?.[checklistValidationTriggeredFields.enableDefectCreation] || alarm?.[checklistValidationTriggeredFields.enableNotificationCreation])
  )
    ? validationErrors?.ValidationIssues?.flatMap(issue =>
        (issue?.AlarmsTriggered || []) // Fallback to an empty array if AlarmsTriggered is null
          .filter(alarm => alarm?.[checklistValidationTriggeredFields.enableDefectCreation] || alarm?.[checklistValidationTriggeredFields.enableNotificationCreation])
      )
    : [];

  const renderContent = () => {
    if (!isEmpty(alarmsMap)) {
      return (
        <div className="alarms-triggered">
          <div className="alarms-triggered__wrapper">
            <p className="f-primary">{t('WORK_ORDER.TRIGGERED_ALARMS_FIRST_PARAGRAPH_FIRST')}</p>
            <p className="f-primary">{t('WORK_ORDER.TRIGGERED_ALARMS_FIRST_PARAGRAPH_SECOND')}</p>
            <br />
            <p className="f-primary">{t('WORK_ORDER.TRIGGERED_ALARMS_SECOND_PARAGRAPH')}</p>
            <div className="alarms-triggered__alarms-section">
              <p className="f-primary">{t('MEASUREMENT_POINT_FORM_TAB.ALARMS')}:</p>
              <div>
                {alarmsMap.slice(0, 5).map((alarm, index) => {
                  return (
                    <p>
                      {t('WORK_ORDER.TRIGGERD_ALARMS.ALARM', {
                        index: index + 1,
                        mpName: alarm?.[checklistValidationTriggeredFields.measurementPointName] || '',
                        value: alarm?.[checklistValidationTriggeredFields.alarmLevel] || '',
                        unit: alarm?.[checklistValidationTriggeredFields.measurementPointUnit] || '',
                        level: t(`${Helpers.getAlarmDisplayName(alarm?.[checklistValidationTriggeredFields.alarmName])}`) || '',
                      })}
                    </p>
                  );
                })}
                {alarmsMap.length > 5 && <p>...</p>}
              </div>
            </div>
            <p className="f-primary">{t('WORK_ORDER.TRIGGERED_ALARMS_THIRD_PARAGRAPH')}</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="submit-checklist-wrapper">
          <p className="f-primary">{t('WORK_ORDER.TRIGGERED_ALARMS_FIRST_PARAGRAPH_FIRST')}</p>
          <p className="f-primary">{t('WORK_ORDER.TRIGGERED_ALARMS_FIRST_PARAGRAPH_SECOND')}</p>
        </div>
      );
    }
  };

  return (
    <div className="submit-checklist-modal">
      <>{renderContent()}</>
      <div className="submit-checklist-modal__buttons">
        <Button onClick={confirmAction} type="button" text={t('WORK_ORDER.CHECKLIST_SUBMIT_CONFIRMATION_MODAL_TITLE')} height="md" className="modal-button-custom" />
        <Button onClick={customCloseAction} type="button" text={t('CANCEL')} height="md" className="modal-button-custom" variant="gray-outline" />
      </div>
    </div>
  );
};

SubmitChecklistModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
export default SubmitChecklistModal;
