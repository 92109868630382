import { values } from 'lodash';
import React from 'react';

import { statuses } from '../../constants/work-order-constants';
import ModuleSections from '../right-toolbar/common/module-sections';
import { fields } from './constants/work-order-right-side-constants';
import { sections } from './constants/work-order-sections-constants';

const WorkOrderSections = ({ moduleDetails, handleSectionActionClicked, user, debounceValues, ...restProps }) => (
  <ModuleSections
    module={moduleDetails}
    sections={values(sections)}
    handleSectionActionClicked={handleSectionActionClicked}
    handleCustomAction={handleSectionActionClicked}
    statuses={statuses}
    user={user}
    createdById={moduleDetails[fields.createdBy]}
    skipOrdering
    debounceValues={debounceValues}
    {...restProps}
  />
);

export default WorkOrderSections;
