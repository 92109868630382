import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import BackButton from '../../../../common/back-button/components/back-button';
import PageNavigation from '../../../../common/page-navigation/components/page-navigation';
import routesConstants from '../../../../common/routes-constants';

import Button from '../../../../common/form/components/button';
import { ReactComponent as ChevronDown } from '../../../../common/icon/assets/chevron-down.svg';
import Dropzone from '../../../../common/upload/components/upload-dropzone';

import { isEmpty } from 'lodash';
import TableTemplate from '../../../../common/double-table/components/table-template';

import { useState } from 'react';
import EmptyState from '../../../../common/empty-state-v2/components/empty-state';
import Modal from '../../../../common/modal/components/modal';
import ActionModal from '../../../document-management/components/modals/action-modal/action-modal';
import { formatPostVideoRelocalisationTable } from '../../actions/table-formatting';
import { fields, filterProps, postVideoRelocalisationConfig, supportedUploadFileMimeTypes } from '../../constants/post-video-relocalisation-constant';
import '../../styles/post-video-relocalisation.scss';

const PostVideoRelocalisation = (props, { t }) => {
  const { location, user } = props;
  const { search } = location;
  const [modalData, setModalData] = useState({ isOpen: false });

  const filters = {};
  const backButtonPath = `${routesConstants.routes.protectedRoutes.inspections.fullPath}${search}`;
  const navigationBackButtonPath = `${routesConstants.routes.protectedRoutes.augmentedReality.fullPath}${search}`;

  const emptyStateProps = {
    emptyStateText: t('POST_VIDEO_RELOCALISATION.TABLE.EMPTY_STATE'),
    transparent: true,
  };

  // TODO: replace with endpoint - not implemented on BE
  const data = [
    { ID: 12, UniqueLivestreamID: 'kfsdfd', Name: 'Mission d10', CreatedAt: 1233212323, Duration: '14:14' },
    { ID: 12, UniqueLivestreamID: 'asd', Name: 'Mission d11', CreatedAt: 1233212323, Duration: '14:14', IsProcessing: true },
  ];

  // TODO: Implement methods
  const onFileUpload = file => {
    console.log(file);
  };

  const chooseFromDocuments = () => {
    return;
  };

  const closeModal = () => {
    setModalData({ isOpen: false });
  };

  const toggleConfirmationModal = (isOpen, title, firstParagraph, confirmAction) => {
    if (isOpen) {
      setModalData({
        isOpen: true,
        type: '',
        firstParagraph: firstParagraph,
        title: title,
        CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
        customClassName: 'delete-reading-modal modal-large',
        customClassWrapperName: 'delete-reading-modal__picker',
        closeButtonText: t('CANCEL'),
        customConfirmAction: () => {
          confirmAction();
        },
        confirmButtonText: title,
        customCloseAction: () => toggleConfirmationModal(false),
      });
    } else {
      setModalData({ isOpen: false });
    }
  };

  const handleOpenDeleteModal = video => {
    const handleDelete = () => {
      setModalData({ isOpen: false });
    };

    const openConfirmModal = () => {
      setModalData({
        isOpen: true,
        customClassName: 'delete-reading-modal modal-large',
        customClassWrapperName: 'delete-reading-modal__picker',
        type: '',
        CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
        closeAction: closeModal,
        title: t('DOCUMENT_MANAGEMENT_MODAL.CONFIRM_DELETE_TITLE'),
        firstParagraph: 'DOCUMENT_MANAGEMENT_MODAL.CONFIRM_DELETE_WARNING',
        customCloseAction: closeModal,
        confirmButtonText: t('CONFIRM'),
        closeButtonText: t('CANCEL'),
        customConfirmAction: handleDelete,
      });
    };

    toggleConfirmationModal(true, t('DELETE_VIDEO.TITLE'), t('DELETE_VIDEO.PARAGRAPH', { name: video?.[fields.name] }), openConfirmModal);
  };

  const withMenuOptions =
    (formatFunction, menuOptions) =>
    (
      value,
      type,
      searchInputValue,
      row,
      onDropdownClick,
      t,
      pointerDotID,
      user,
      onToggleClick,
      onDeleteRow,
      statusData,
      handleChangeStatus,
      onLinkFileClick,
      selectedItem,
      cell,
      hasLinkDMSFilePermissions,
      onRowClick,
      toggleModalAction
    ) =>
      formatFunction(
        value,
        type,
        searchInputValue,
        row,
        onDropdownClick,
        t,
        pointerDotID,
        user,
        onToggleClick,
        onDeleteRow,
        statusData,
        handleChangeStatus,
        onLinkFileClick,
        selectedItem,
        cell,
        hasLinkDMSFilePermissions,
        onRowClick,
        toggleModalAction,
        menuOptions
      );

  const menuOptions = () => {
    return [
      {
        title: 'DELETE_VIDEO.TITLE',
        isHighlighted: true,
        action: item => handleOpenDeleteModal(item),
      },
    ];
  };
  const handleFormatCells = withMenuOptions(formatPostVideoRelocalisationTable, menuOptions);

  return (
    <div className="post-video-relocalisation">
      <PageNavigation title="TOOL_TYPE.AUGMENTED_REALITY" icon="map" backButtonPath={backButtonPath} withBottomBorder />
      <div className="post-video-relocalisation__wrapper">
        <div className="post-video-relocalisation__wrapper__navigation">
          <BackButton path={navigationBackButtonPath} />
          <h3 className="f-primary title">{t('AUGMENTED_REALITY.TOOLS.POST_VIDEO_RELOCALISATION.TITLE')}</h3>
          <p className="f-primary description ">{t('POST_VIDEO_RELOCALISATION.PARAGRAPH')}</p>
        </div>

        <div className="post-video-relocalisation__wrapper__content">
          <div className="post-video-relocalisation__wrapper__content-title">
            <div className="f-primary">{t('POST_VIDEO_RELOCALISATION.UPLOAD_VIDEO')}</div>
            <div className="f-secondary-dark">{t('POST_VIDEO_RELOCALISATION.UPLOAD_VIDEO.PARAGRAPH')}</div>
          </div>
          <div className="actions">
            {/* TODO: Handle when to disable buttons */}
            <Button type="button" height="md" variant="success-outline" keepOriginalText={true} icon={'documents'} onClick={chooseFromDocuments} disabled={true} text={t('CHOOSE_FROM_DOCUMENTS')} />
            <Dropzone
              {...props}
              supportedMimeTypes={supportedUploadFileMimeTypes}
              onDrop={file => onFileUpload(file)}
              CustomImage={ChevronDown}
              labelWhite={t('WORK_ORDER.UPLOAD_FILES')}
              multiple={false}
              disabled={true}
            />
          </div>
          {!isEmpty(data) && <p className="f-primary table-title">{t('POST_VIDEO_RELOCALISATION.RECENT_VIDEOS.TITLE')}</p>}
          <TableTemplate
            showSearchBar={false}
            showInfo={false}
            tableConfig={postVideoRelocalisationConfig}
            handleFormatCells={handleFormatCells}
            showOptionFor3DToggle={false}
            customTableClass={'post-video-relocalisation-table'}
            data={data}
            onRowClick={() => null}
            filters={{}}
            sortDirection={filterProps.sortDirection}
            sortByColumn={filterProps.sortByColumn}
            hasNext={null}
            TotalItems={filters[filterProps.totalNumber]}
            isLoading={false}
            onToggleClick={() => null}
            loadMoreOnClick={() => null}
            onSortClick={() => null}
            selectedItem={null}
            showEmptyState={isEmpty(data)}
            emptyStateComponent={() => <EmptyState {...emptyStateProps} />}
            user={user}
          />
          {/* TODO: Implement pagination found on C&P */}
        </div>
      </div>
      <Modal {...modalData} />
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.userReducer,
});

PostVideoRelocalisation.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, null)(PostVideoRelocalisation));
