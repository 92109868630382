import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import TableTemplate from '../../../../common/double-table/components/table-template';

import BackButton from '../../../../common/back-button/components/back-button';
import EmptyState from '../../../../common/empty-state-v2/components/empty-state';
import PageNavigation from '../../../../common/page-navigation/components/page-navigation';
import routesConstants from '../../../../common/routes-constants';
import { params } from '../../../profile/constants/profile-constants';
import { fields, filterProps, roboticLiveStreamsTableConfig, sortDirections } from '../../constants/robotic-live-streams-constant';

import { get, isEmpty, toInteger, uniqBy } from 'lodash';
import { formatRoboticLiveStreamsTable } from '../../actions/table-formatting';
import '../../styles/robotic-live-streams.scss';

import { useCallback, useEffect, useState } from 'react';
import { POLLING_INTERVAL } from '../../../../common/constants';
import useTabVisibility from '../../../../hooks/useTabVisibility';
import { fetchRoboticLiveStreams } from '../../actions/augmented-reality-actions';

const RoboticLiveStreams = (props, { t }) => {
  const { user, location, fetchRoboticLiveStreams } = props;
  const { search, query } = location;
  const inspectionID = toInteger(get(query, 'inspection_id'));
  const [data, setData] = useState([]);
  const [filters] = useState({ [fields.inspectionID]: inspectionID });
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true); // State for the first load
  const isTabActive = useTabVisibility();

  const emptyStateProps = {
    emptyStateText: t('ROBOTIC_LIVE_STREAMS.TABLE.EMPTY_STATE'),
    transparent: true,
  };

  const backButtonPath = `${user[params.fullScreenEnabled] ? routesConstants.routes.protectedRoutes.fullScreen.fullPath : routesConstants.routes.protectedRoutes.inspections.fullPath}${search}`;
  const navigationBackButtonPath = `${routesConstants.routes.protectedRoutes.liveStreams.fullPath}${search}`;

  const fetchData = useCallback(
    filters => {
      const setNewData = newData => {
        const dataToSet = uniqBy([...data, ...newData], fields.id);
        setData(dataToSet);
      };
      const setDataLoaded = isLoading => {
        setIsLoading(isLoading);
        if (isLoading === false) {
          setIsFirstLoad(false); // Update isFirstLoad after the first load completes
        }
      };
      fetchRoboticLiveStreams({ ...filters }, setNewData, setDataLoaded);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const handleSortClick = sortByColumn => {
    fetchData({
      ...filters,
      [fields.lastSeen]: 0,
      [fields.sortDirection]: filters[fields.sortDirection] === sortDirections.asc ? sortDirections.desc : sortDirections.asc,
      [fields.sortByColumn]: sortByColumn,
    });
  };

  // TODO: Implement navigaiton to live stream split screen
  const onRowClick = () => {
    return;
  };

  useEffect(() => {
    fetchData(filters);

    const intervalId = setInterval(() => {
      if (!isTabActive || isLoading) return;
      fetchData(filters);
    }, POLLING_INTERVAL);

    return () => clearInterval(intervalId); // Cleanup on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, isTabActive]);

  return (
    <div className="robotic-live-streams">
      <PageNavigation title="TOOL_TYPE.AUGMENTED_REALITY" icon="map" backButtonPath={backButtonPath} withBottomBorder />
      <div className="live-streams__wrapper">
        <div className="live-streams__wrapper__navigation">
          <BackButton path={navigationBackButtonPath} />
          <h3 className="f-primary title">{t('LIVE_STREAMS.TOOLS.ROBOTIC_LIVE_STREAMS.TITLE')}</h3>
          <p className="f-primary description ">{t('LIVE_STREAMS.TOOLS.ROBOTIC_LIVE_STREAMS.PARAGRAPH')}</p>
        </div>
        <TableTemplate
          title={''}
          showSearchBar={false}
          showInfo={false}
          tableConfig={roboticLiveStreamsTableConfig}
          handleFormatCells={formatRoboticLiveStreamsTable}
          showOptionFor3DToggle={false}
          customTableClass={'robotic-live-streams-table'}
          data={data}
          onRowClick={onRowClick}
          filters={filters}
          sortDirection={filterProps.sortDirection}
          sortByColumn={filterProps.sortByColumn}
          hasNext={null}
          TotalItems={filters[filterProps.totalNumber]}
          isLoading={isFirstLoad}
          onToggleClick={() => null}
          loadMoreOnClick={() => null}
          onSortClick={handleSortClick}
          selectedItem={null}
          showEmptyState={isEmpty(data) && !isLoading}
          emptyStateComponent={() => <EmptyState {...emptyStateProps} />}
          user={user}
        />
      </div>
    </div>
  );
};

RoboticLiveStreams.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.userReducer,
});

const mapDispatchToProps = dispatch => ({
  fetchRoboticLiveStreams: (filters, dataCallback, loadingCallback) => dispatch(fetchRoboticLiveStreams(filters, dataCallback, loadingCallback)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoboticLiveStreams));
