import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
//components
import TableTemplate from '../../../../../../common/double-table/components/table-template';
import PathViewer from '../../../../../../common/path-viewer/components/path-viewer';
import RenderIf from '../../../../../../common/render-if/components/render-if';
//constants
import { modules } from '../../../../constants/constants';
import { fields, getSingleRnGTableConfig, getTableFormat, sortDirections, viewOptions } from '../../constants/constants';
//styles
import '../../styles/single-item-table.scss';
//package imports
import { debounce } from 'lodash';
import EmptyState from '../../../../../../common/empty-state-v2/components/empty-state';

const SingleRnGItemTable = (props, { t }) => {
  const {
    view,
    isFullScreen,
    navigateToPath,
    path,
    handleBackClick,
    handlePathClick,
    fetchData,
    data,
    filters,
    defaultFilters,
    isDataLoading,
    toggleVisibilityHandler,
    selectedItem,
    allHidden,
    toggleAll,
    handleRowClick,
    showPath,
    showEmptyState = false,
    emptyStateProps,
    title,
    customTableClass,
    toggleModalAction,
    user,
    searchPlaceholder,
    showAdditionalOptions = true,
    isManageGroupModal = false,
    isNewMeasurementPointModal = false,
    maxSearchCharacters = null,
  } = props;

  // state
  const [searchText, setSearchText] = useState('');

  const handleClickOnAdditionalOptions = option => {
    const newPathItem = {
      [fields.name]: option === viewOptions.location ? t('READINGS_AND_GAUGES.ALL_ML') : t('READINGS_AND_GAUGES.ALL_MP'),
      queryParams: { type: modules.readingsAndGauges, view: option },
    };
    navigateToPath(newPathItem);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchTextChanged = useCallback(
    debounce(searchText => fetchData({ ...filters, [fields.lastSeen]: 0, [fields.searchText]: searchText }), 300),
    [fetchData]
  );

  const handleSearchInputChange = e => {
    const searchText = e.target.value;
    setSearchText(searchText);
    searchTextChanged(searchText);
  };

  const handleLoadMoreClick = () => fetchData(filters, true);

  const handleSortClick = sortByColumn =>
    fetchData({
      ...filters,
      [fields.lastSeen]: 0,
      [fields.sortDirection]: filters[fields.sortDirection] === sortDirections.ASC ? sortDirections.DESC : sortDirections.ASC,
      [fields.sortByColumn]: sortByColumn,
    });

  useEffect(() => {
    fetchData(defaultFilters);
    //eslint-disable-next-line
  }, []);

  return (
    <div className={`single-item-table ${view} ${view === viewOptions.group && isFullScreen ? 'table-template-groups-fullscreen' : ''}`}>
      <RenderIf if={view === viewOptions.group && showAdditionalOptions}>
        <div className="additional-options">
          <p className="f-primary" onClick={() => handleClickOnAdditionalOptions(viewOptions.location)}>
            {t('READINGS_AND_GAUGES.ALL_ML')}
          </p>
          <p className="f-primary hover-able-background " onClick={() => handleClickOnAdditionalOptions(viewOptions.points)}>
            {t('READINGS_AND_GAUGES.ALL_MP')}
          </p>
        </div>
      </RenderIf>
      <RenderIf if={path.length > 1 && showPath}>
        <PathViewer levels={path} propInPath={fields.name} onPathClick={handlePathClick} onBackClick={handleBackClick} />
      </RenderIf>
      <RenderIf if={view === viewOptions.group && isFullScreen}>
        <h5 className="single-item-table__table-heading">{t('READINGS_AND_GAUGES.ALL_GROUPS')}</h5>
      </RenderIf>
      <TableTemplate
        title={title}
        tableConfig={getSingleRnGTableConfig[view || viewOptions.group](isFullScreen, isManageGroupModal, isNewMeasurementPointModal)}
        handleFormatCells={getTableFormat[view]}
        searchPlaceholder={searchPlaceholder || t('SEARCH')}
        showOptionFor3DToggle={!isFullScreen && !isNewMeasurementPointModal}
        customTableClass={`table-${isFullScreen ? 'full-screen' : 'three-d'} item-table ${customTableClass} `}
        data={data}
        onRowClick={handleRowClick}
        searchInputOnChange={handleSearchInputChange}
        searchInputValue={searchText}
        maxSearchCharacters={maxSearchCharacters}
        filters={filters}
        sortDirection={fields.sortDirection}
        sortByColumn={fields.sortByColumn}
        hasNext={filters[fields.hasNext]}
        TotalItems={filters[fields.totalNumber]}
        isLoading={isDataLoading}
        onToggleClick={toggleVisibilityHandler}
        loadMoreOnClick={handleLoadMoreClick}
        onSortClick={handleSortClick}
        selectedItem={selectedItem}
        allHidden={allHidden}
        toggleAll={toggleAll}
        showEmptyState={showEmptyState}
        emptyStateComponent={() => <EmptyState {...emptyStateProps} />}
        toggleModalAction={toggleModalAction}
        user={user}
      />
    </div>
  );
};

SingleRnGItemTable.contextTypes = {
  t: PropTypes.func.isRequired,
};

SingleRnGItemTable.defaultProps = {
  toggleVisibilityHandler: () => null,
  allHidden: false,
  toggleAll: () => null,
  handleRowClick: () => null,
  showPath: true,
  emptyStateProps: {},
};

export default SingleRnGItemTable;
