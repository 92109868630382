import { get } from 'lodash';
import { fetchComponentWorkOrders, fetchWorkorderCompletionFiles, fetchWorkorderEvidenceFiles, fetchWorkorderFiles } from '../../../../../api/inspection/actions';
import { setWorkorderFilesUploaded } from '../../../../upload/actions/action-creators';

import Helpers from '../../../../../common/helpers';
import { setWorkOrderCompletionFiles } from '../../../actions/action-creators';
import genericFileImage from '../../../assets/component-generic-file.svg';

export const getComponentWorkOrders = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await fetchComponentWorkOrders(filters);
      const { Data } = res?.data;
      const { Items, ...rest } = Data;
      dataCallback && typeof dataCallback === 'function' && dataCallback(Items, { ...filters, ...rest });
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const getWorkorderDMSFilesUploaded = workorder_id => {
  return async dispatch => {
    try {
      const res = await fetchWorkorderFiles([{ workorder_id }]);
      let Data = get(res, 'data.Data');

      if (Data) {
        Data = Helpers.mapExternalFilesForModal(Data, genericFileImage);
        dispatch(setWorkorderFilesUploaded(Data));
      } else {
        dispatch(setWorkorderFilesUploaded({}));
      }
    } catch (e) {
      console.error(e);
      dispatch(setWorkorderFilesUploaded({}));
    }
  };
};

export const getWorkorderCompletionDMSFilesUploaded = workorder_id => {
  return async dispatch => {
    try {
      const res = await fetchWorkorderCompletionFiles([{ workorder_id }]);
      let Data = get(res, 'data.Data');

      if (Data) {
        Data = Helpers.mapExternalFilesForModal(Data, genericFileImage);
        dispatch(setWorkOrderCompletionFiles(Data));
      } else {
        dispatch(setWorkOrderCompletionFiles([]));
      }
    } catch (e) {
      console.error(e);
      dispatch(setWorkOrderCompletionFiles([]));
    }
  };
};

export const getWorkorderChecklistEvidenceDMSFilesUploaded = (module_question_id, successCallback, errorCallback) => {
  return async () => {
    try {
      const res = await fetchWorkorderEvidenceFiles([{ module_question_id }]);
      let Data = get(res, 'data.Data');

      if (Data) {
        Data = Helpers.mapExternalFilesForModal(Data, genericFileImage);
        successCallback && successCallback(Data);
      } else {
        successCallback && successCallback({});
      }
    } catch (e) {
      console.error(e);
      errorCallback && errorCallback(e);
    }
  };
};
