import { get } from 'lodash';
import readingsAndGaugesAPI from '../../../../../api/readings-and-gauges/actions';
import Helpers from '../../../../../common/helpers';
import genericFileImage from '../../../../inspections/assets/component-generic-file.svg';
import { setMeasurementReadingFilesUploaded } from '../../../../upload/actions/action-creators';
import { fields } from '../constants/constants';

export const saveMeasurementReading = (Values, Date, MeasurementPointID, MeasurementLocationID, MeasurementReadingID, successCallback, errorCallback) => {
  return async () => {
    try {
      const res = await readingsAndGaugesAPI.saveMeasurementReading({
        Values,
        Date,
        MeasurementPointID,
        MeasurementLocationID,
        MeasurementReadingID,
      });
      const { Data } = res.data;

      successCallback && typeof successCallback === 'function' && successCallback(Data);
    } catch (e) {
      errorCallback && typeof errorCallback === 'function' && errorCallback();
      console.error(e);
    }
  };
};

export const updateMeasurementReading = (Values, Date, MeasurementPointID, MeasurementLocationID, MeasurementReadingID, successCallback, errorCallback) => {
  return async () => {
    try {
      const res = await readingsAndGaugesAPI.updateMeasurementReading({
        Values,
        Date,
        MeasurementPointID,
        MeasurementLocationID,
        MeasurementReadingID,
      });
      const { Data } = res.data;

      successCallback && typeof successCallback === 'function' && successCallback(Data);
    } catch (e) {
      errorCallback && typeof errorCallback === 'function' && errorCallback();
      console.error(e);
    }
  };
};

export const getMeasurementReadings = (filters, callback, filtersCallback, errorCallback, loadingCallback) => {
  return async () => {
    try {
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(true);
      const res = await readingsAndGaugesAPI.fetchMeasurementReadings(filters);
      const { Items, ...newFilters } = res.data.Data;
      callback && typeof callback === 'function' && callback(Items);
      filtersCallback && typeof filtersCallback === 'function' && filtersCallback({ ...filters, ...newFilters });
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(false);
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback();
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(false);
    }
  };
};

export const deleteMeasurementReading = (MeasurementReadingID, successCallback, errorCallback) => {
  return async () => {
    try {
      await readingsAndGaugesAPI.deleteMeasurementReading({ [fields.id]: MeasurementReadingID });
      successCallback && typeof successCallback === 'function' && successCallback();
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const getMeasurementReadingDMSFilesUploaded = measurement_reading_id => {
  return async dispatch => {
    try {
      const res = await readingsAndGaugesAPI.fetchMeasurementReadingUploadedFiles([{ measurement_reading_id }]);
      let Data = get(res, 'data.Data');

      if (Data) {
        Data = Helpers.mapExternalFilesForModal(Data, genericFileImage);
        dispatch(setMeasurementReadingFilesUploaded(Data));
      } else {
        dispatch(setMeasurementReadingFilesUploaded({}));
      }
    } catch (e) {
      console.error(e);
      dispatch(setMeasurementReadingFilesUploaded({}));
    }
  };
};
