/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { change, getFormSyncErrors, getFormValues } from 'redux-form';

import { find, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { FORMS } from '../../../../../../common/constants';

import { withRouter } from 'react-router';
import Helpers from '../../../../../../common/helpers';
import Stepper from '../../../../../../common/stepper/components/stepper';
import useConfirmOnInspectionExit from '../../../../../../hooks/use-confirm-on-inspection-exit';
import { setMeasurementReadingFilesUploaded } from '../../../../../upload/actions/action-creators';
import { getDefectDetails } from '../../../../actions/inspection-actions';
import { getMeasurementReadingDMSFilesUploaded } from '../../actions/measurement-readings-actions';
import { fields } from '../../constants/constants';
import { measurementReadingFormConstants, measurementReadingFormStepperData, measurementReadingFormSteps } from '../../constants/measurement-readings-constants';
import '../../styles/measurement-reading-form.scss';
import CreateMeasurementReadingForm from './create-measurement-reading-form';

const CreateMeasurementReadingModal = (props, { t }) => {
  const {
    onSubmit,
    getDefectDetails,
    changeField,
    formErrors,
    isEdit,
    dirty,
    customCloseAction,
    closeConfirmModalAction,
    className = '',
    measurementPointId,
    initialValues,
    formValues,
    requestInProgress = false,
    router,
    defaultReadingsAndGaugesUnassignedDMSFolderID,
    getMeasurementReadingDMSFilesUploaded,
  } = props;
  const [activeStep, setActiveStep] = useState(measurementReadingFormSteps.firstStep);
  const [measurementReadingData, setMeasurementReadingData] = useState(null);

  useConfirmOnInspectionExit({
    title: t('GENERIC_UNSAVED_CHANGES_TITLE'),
    message: t('GENERIC_UNSAVED_CHANGES_MESSAGE'),
    router,
    route: router.location,
    isDirty: dirty,
    clearUnsavedChangesDirty: () => null,
  });

  const activeStepObj = find(measurementReadingFormStepperData, item => item.stepValue === activeStep);

  const setStep = forward => {
    const nextStep = forward ? activeStep + 1 : activeStep - 1;
    if (nextStep < measurementReadingFormSteps.firstStep || nextStep > measurementReadingFormSteps.secondStep) {
      closeConfirmModalAction(); // simply closes this MR modal and conducts MP details fetch
      return;
    }
    setActiveStep(nextStep);
  };

  const handleSaveMeasurementReading = values => {
    // TODO: implement the generic notification error if more than 5 files selected for upload or uploaded

    // isEmpty(measurementReadingData) provided so we know if it is a edit or create mode, if the isEmpty(measurementReadingData) is true it is create mode, otherwise it is edit mode
    const isEditMode = !isEmpty(measurementReadingData);

    // Create updatedValues as a copy of values
    let updatedValues = { ...values };
    if (!updatedValues[measurementReadingFormConstants.fields.date]) {
      // fallback for when the date is not set, it should be set to the current date and time
      updatedValues[measurementReadingFormConstants.fields.date] = Helpers.getCurrentDateAndTimeInMs();
    }

    if (isEditMode) {
      updatedValues[fields.id] = measurementReadingData[fields.measurementReadingId]; // ID used in the submit method to honor the data contract
    }

    onSubmit(updatedValues, isEditMode, data => {
      setMeasurementReadingData(data);
      setStep(true);
    });
  };

  return (
    <div className={`create-measurement-reading-modal ${className || ''}`}>
      <Stepper stepperData={measurementReadingFormStepperData} activeStep={activeStep} showInfoIcon={false} />
      <CreateMeasurementReadingForm
        activeStepObj={activeStepObj}
        activeStep={activeStep}
        getDefectDetails={getDefectDetails}
        changeField={changeField}
        formValues={formValues}
        formErrors={formErrors}
        onSubmit={handleSaveMeasurementReading}
        isEdit={isEdit}
        customCloseAction={customCloseAction}
        measurementPointId={measurementPointId}
        initialValues={initialValues}
        requestInProgress={requestInProgress}
        setStep={setStep}
        defaultReadingsAndGaugesUnassignedDMSFolderID={defaultReadingsAndGaugesUnassignedDMSFolderID}
        measurementReadingData={measurementReadingData}
        getMeasurementReadingDMSFilesUploaded={getMeasurementReadingDMSFilesUploaded}
        setMeasurementReadingFilesUploaded={setMeasurementReadingFilesUploaded}
      />
    </div>
  );
};

CreateMeasurementReadingModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  formValues: getFormValues(FORMS.measurementPointMeasurementReadingForm)(state),
  formErrors: getFormSyncErrors(FORMS.measurementPointMeasurementReadingForm)(state),
  defaultReadingsAndGaugesUnassignedDMSFolderID: state.projectDetailsReducer.DefaultReadingsAndGaugesUnassignedDMSFolderID,
});

const mapDispatchToProps = dispatch => ({
  getDefectDetails: (defect, callback, saveItem) => dispatch(getDefectDetails(defect, callback, {}, false, saveItem)),
  changeField: (fieldName, value, touch) => dispatch(change(FORMS.measurementPointMeasurementReadingForm, fieldName, value, touch)),
  getMeasurementReadingDMSFilesUploaded: id => dispatch(getMeasurementReadingDMSFilesUploaded(id)),
  setMeasurementReadingFilesUploaded: data => dispatch(setMeasurementReadingFilesUploaded(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateMeasurementReadingModal));
