import { API_BASE_URL } from '../../common/constants';
import { Api } from '../../core/api';
import routesConstants from './routes';

const fetchRoboticLiveStreams = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_ROBOTIC_LIVE_STREAMS}`, data);
};

export default {
  fetchRoboticLiveStreams,
};
