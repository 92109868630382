export const fields = {
  id: 'ID',
  name: 'Name',
  createdAt: 'CreatedAt',
  duration: 'Duration',
  actions: 'Actions',
};

export const postVideoRelocalisationConfig = [
  {
    title: 'NAME',
    key: fields.name,
    type: fields.name,
    class: 'name-column',
    isSort: true,
  },
  {
    title: 'TABLE.CREATED_DATE_AND_TIME',
    key: fields.createdAt,
    type: fields.createdAt,
    class: 'created-at-and-time-column',
    isSort: true,
  },
  {
    title: 'TABLE.DURATION',
    key: fields.duration,
    type: fields.duration,
    class: 'duration-column',
    isSort: true,
  },
  {
    title: '',
    key: fields.actions,
    type: fields.actions,
    class: 'actions-column',
    isSort: false,
  },
];

export const filterProps = {
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  searchText: 'SearchText',
  hasNext: 'HasNext',
  totalItems: 'TotalItems',
};

export const supportedUploadFileMimeTypes = {
  'video/mp4': ['.mp4', '.MP4', '.qt', '.mov', '.avi'],
};
