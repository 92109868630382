export const fields = {
  icon: 'Icon',
  id: 'ID',
  uniqueLivestreamID: 'UniqueLivestreamID',
  name: 'Name',
  startedAt: 'StartedAt',
  inspectionID: 'InspectionID',
};

export const roboticLiveStreamsTableConfig = [
  {
    title: '',
    key: fields.icon,
    type: fields.icon,
    class: 'icon-column',
    isSort: false,
  },
  {
    title: 'ROBOTIC_LIVE_STREAMS.TABLE.NAME',
    key: fields.name,
    type: fields.name,
    class: 'name-column',
    isSort: true,
  },
  {
    title: 'ROBOTIC_LIVE_STREAMS.TABLE.STARTED_AT',
    key: fields.startedAt,
    type: fields.startedAt,
    class: 'started-at-column',
    isSort: true,
  },
];

export const filterProps = {
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  searchText: 'SearchText',
  hasNext: 'HasNext',
  totalItems: 'TotalItems',
};

export const sortDirections = {
  asc: 'ASC',
  desc: 'DESC',
};
