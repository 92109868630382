import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import BackButton from '../../../../common/back-button/components/back-button';
import Card from '../../../../common/card/components/card';
import Helpers from '../../../../common/helpers';
import PageNavigation from '../../../../common/page-navigation/components/page-navigation';
import routesConstants from '../../../../common/routes-constants';

import { FEATURES } from '../../../../common/constants';
import '../../styles/live-streams.scss';

const LiveStreams = (props, { t }) => {
  const { location } = props;
  const { search } = location;

  const backButtonPath = `${routesConstants.routes.protectedRoutes.inspections.fullPath}${search}`;
  const navigationBackButtonPath = `${routesConstants.routes.protectedRoutes.augmentedReality.fullPath}${search}`;
  const liveStreamsConstant = [
    {
      title: 'LIVE_STREAMS.TOOLS.ROBOTIC_LIVE_STREAMS.TITLE',
      description: 'LIVE_STREAMS.TOOLS.ROBOTIC_LIVE_STREAMS.PARAGRAPH',
      iconName: 'live-streams',
      path: routesConstants.routes.protectedRoutes.roboticLiveStreams.fullPath,
      disabled: !FEATURES.augmentedReality.liveStreams.roboticLiveStreams.visible,
      soon: !FEATURES.augmentedReality.liveStreams.roboticLiveStreams.visible,
    },
    {
      title: 'LIVE_STREAMS.TOOLS.CALLS.TITLE',
      description: 'LIVE_STREAMS.TOOLS.CALLS.PARAGRAPH',
      iconName: 'calls',
      path: '',
      disabled: !FEATURES.augmentedReality.liveStreams.calls.visible,
      soon: !FEATURES.augmentedReality.liveStreams.calls.visible,
    },
  ];

  return (
    <div className="live-streams">
      <PageNavigation
        title="TOOL_TYPE.AUGMENTED_REALITY"
        icon="map"
        backButtonPath={backButtonPath}
        withBottomBorder
        actionButtontext="WO.CREATE_OBSERVATION_SECTION.CTA"
        secondActionButtonText="NEW_EQUIPMENT"
      />
      <div className="live-streams__wrapper">
        <div className="live-streams__wrapper__navigation">
          <BackButton path={navigationBackButtonPath} />
          <h3 className="f-primary title">{t('LIVE_STREAMS.TITLE')}</h3>
          <p className="f-primary description ">{t('AUGMENTED_REALITY.TOOLS.LIVE_STREAM.PARAGRAPH')}</p>
        </div>

        <div className="live-streams__wrapper__tools-wrapper">
          <div className="live-streams__wrapper__tools-wrapper__content">
            {liveStreamsConstant.map(({ title, description, path, disabled, soon, iconName }) => (
              <Card title={title} onClick={() => Helpers.goTo(`${path}${search}`)} description={description} disabled={disabled} buttonText={'CHOOSE'} soon={soon} iconName={iconName} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.userReducer,
});

LiveStreams.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, null)(LiveStreams));
