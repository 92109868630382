import { FEATURES } from '../../../common/constants';
import Icon from '../../../common/icon/components/icon';

import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';

export const objectTools = {
  link: 'link',
  measurement: 'measurement',
  settings: 'settings',
  component: 'component',
  inspect: 'inspect-tool',
  volumeClip: 'clipping-volume',
  defect: 'defect',
  cursor: 'cursor',
  notification: 'notification',
  rgMeasurementLocation: 'rg-measurement-location',
  rgMeasurementPoint: 'rg-measurement-point',
};
export const modules = {
  details: 'DETAILS',
  measurements: 'MEASUREMENTS',
  defects: 'OBSERVATIONS',
  components: 'COMPONENTS',
  workorders: 'WORKORDERS',
  notifications: 'NOTIFICATIONS',
  // explosiveZones: 'EXPLOSIVE_ZONES',
  areas: 'AREAS',
  ndtData: 'NDT_DATA',
  isolationCertificates: 'ISOLATION_CERTIFICATES',
  permits: 'PERMITS',
  maintenanceRegimes: 'MAINTENANCE_REGIMES',
  checklistQuestion: 'CHECKLIST_QUESTIONS',
  checklistQuestionEvidence: 'CHECKLIST_QUESTIONS_EVIDENCE',
  workorderCompletion: 'WORKORDER_COMPLETION',
  readingsAndGauges: 'READINGS_AND_GAUGES',
};

export const modulesFullScreen = {
  details: 'DETAILS',
  // measurements: 'MEASUREMENTS',
  defects: 'OBSERVATIONS',
  components: 'COMPONENTS',
  workorders: 'WORKORDERS',
  notifications: 'NOTIFICATIONS',
  ndtData: 'NDT_DATA',
  isolationCertificates: 'ISOLATION_CERTIFICATES',
  permits: 'PERMITS',
  maintenanceRegimes: 'MAINTENANCE_REGIMES',
  readingsAndGauges: 'READINGS_AND_GAUGES',
};

export const detailsPages = {
  settings: 'settings',
  defect: modules.defects,
  measurement: modules.measurements,
  component: modules.components,
  inspect: 'inspect-details',
  notifications: modules.notifications,
  // explosiveZone: modules.explosiveZones,
  areas: modules.areas,
  ndt: modules.ndtData,
  ndtGrouped: 'NDT_GROUPED',
  workorder: modules.workorders,
  isolationCertificate: modules.isolationCertificates,
  permits: modules.permits,
  maintenanceRegime: modules.maintenanceRegimes,
  measurementLocation: 'ML',
  measurementPoint: 'MP',
};

export const placements = {
  left: 'left',
  right: 'right',
  top: 'top',
  bottom: 'bottom',
};

export const averageBoxSize = 150;
export const imageHeightCorrection = 100;

export const defaultPage = modules.details;

export const toolTypes = [
  {
    icon: props => <Icon name="documents" {...props} />,
    label: 'DOCUMENT_MANAGEMENT',
    feature: FEATURES.documentManagement,
    visibleFor: [PERMISSIONS[PERMISSION_TYPES.documentManagement].view.name],
    route: 'documentManagement',
  },
  {
    icon: props => <Icon name="smartdrawing" {...props} />,
    label: 'TOOL_TYPE.PDF_TAG',
    feature: FEATURES.pdfTag,
    visibleFor: [PERMISSIONS[PERMISSION_TYPES.smartDrawing].view.name],
    route: 'pdfTagList',
  },
  {
    icon: props => <Icon name="workflow" {...props} />,
    label: 'TOOL_TYPE.WORKFLOW',
    route: 'inspectionWorkflow',
    feature: FEATURES.workflow,
  },
  {
    icon: props => <Icon name="regime" {...props} />,
    label: 'TOOL_TYPE.MAINTENANCE_REGIME',
    feature: FEATURES.maintenanceRegime,
    visibleFor: [PERMISSIONS[PERMISSION_TYPES.maintenanceRegimes].view.name],
    route: 'maintenanceRegime',
  },
  {
    icon: props => <Icon name="key" {...props} />,
    label: 'KEYBOX.TITLE',
    feature: FEATURES.workManagement,
    visibleFor: [PERMISSIONS[PERMISSION_TYPES.keybox].view.name],
    route: 'keybox',
    hidden: !FEATURES.keybox.visible,
  },
  {
    icon: props => <Icon name="contractor" {...props} />,
    label: 'TOOL_TYPE.CONTRACTOR_MANAGEMENT',
    feature: FEATURES.workManagement,
    visibleFor: [PERMISSIONS[PERMISSION_TYPES.contractors].view.name],
    route: 'contractorManagement',
    hidden: !FEATURES.contractors.visible,
  },
  {
    icon: props => <Icon name="list" {...props} />,
    label: 'TOOL_TYPE.CHECKLISTS_AND_PROCEDURES',
    feature: FEATURES.checklistsAndProcedures,
    visibleFor: [PERMISSIONS[PERMISSION_TYPES.checklists].view.name],
    route: 'checklistsProceduresList',
    hidden: !FEATURES.checklistsAndProcedures.visible,
  },
  {
    icon: props => <Icon name="map" {...props} />,
    label: 'TOOL_TYPE.AUGMENTED_REALITY',
    feature: FEATURES.augmentedReality,
    route: 'augmentedReality',
    hidden: !FEATURES.augmentedReality.visible,
  },
  {
    icon: props => <Icon name="settings" {...props} />,
    label: 'TOOL_TYPE.PROJECT_SETTINGS',
    feature: FEATURES.projectSettings,
    visibleFor: [
      PERMISSIONS[PERMISSION_TYPES.permitTemplates].view.name,
      PERMISSIONS[PERMISSION_TYPES.isolationTemplates].view.name,
      PERMISSIONS[PERMISSION_TYPES.senseChecks].view.name,
      PERMISSIONS[PERMISSION_TYPES.componentsHierarchy].view.name,
    ],
    route: 'projectSettings',
  },
  {
    icon: props => <Icon name="corrosion" {...props} />,
    label: 'TOOL_TYPE.CORROSION_DETECTION',
    route: null,
    feature: FEATURES.corrosionDetection,
  },
];

export const inspectionAssetSupportedMimeTypes = 'image/jpeg, image/png, image/svg+xml, image/tiff, image/gif';

export const maxInspectionAssetsCount = 10;
export const maxInspectionAssetsFileSize = {
  value: 50,
  unit: 'mb',
  bytes: 52428800,
};

export const activeItemsData = [
  {
    name: modules.details,
    label: 'INSPECTION_DETAILS',
  },
  {
    icon: props => <Icon name="components" {...props} />,
    name: modules.components,
    label: 'INSPECTION_COMPONENTS',
    hidden: !FEATURES.components.visible,
    dataCy: 'components',
  },
  {
    icon: props => <Icon name="observations" {...props} />,
    name: modules.defects,
    label: 'INSPECTION_DEFECTS',
    visibleFor: [PERMISSIONS[PERMISSION_TYPES.observations].view.name],
    dataCy: '',
  },
  {
    icon: props => <Icon name="ndt" {...props} />,
    name: modules.ndtData,
    label: 'NDT_DATA',
    visibleFor: [PERMISSIONS[PERMISSION_TYPES.ndt].view.name],
    dataCy: '',
  },
  {
    icon: props => <Icon name="measurements" {...props} />,
    name: modules.measurements,
    label: 'INSPECTION_MEASUREMENTS',
    visibleFor: [PERMISSIONS[PERMISSION_TYPES.measurements].view.name],
    dataCy: '',
  },
  {
    icon: props => <Icon name="workorders" {...props} />,
    name: modules.workorders,
    label: 'INSPECTION_WORKORDERS',
    hidden: !FEATURES.workorders.visible,
    visibleFor: [PERMISSIONS[PERMISSION_TYPES.workOrders].view.name],
    dataCy: '',
  },
  {
    icon: props => <Icon name="notifications" {...props} />,
    name: modules.notifications,
    label: 'INSPECTION_NOTIFICATIONS',
    hidden: !FEATURES.notifications.visible,
    visibleFor: [PERMISSIONS[PERMISSION_TYPES.notifications].view.name],
  },
  // {
  //   icon: props => <Icon name="zones" {...props} />,
  //   name: modules.explosiveZones,
  //   label: 'EXPLOSIVE_ZONES',
  //   visibleFor: [PERMISSIONS[PERMISSION_TYPES.zones].view.name],
  //   hidden: true,
  //   dataCy: '',
  // },
  {
    icon: props => <Icon name="areas" {...props} />,
    name: modules.areas,
    label: 'AREAS',
    hidden: !FEATURES.areas.visible,
    visibleFor: [PERMISSIONS[PERMISSION_TYPES.areas].view.name],
  },
  {
    icon: props => <Icon name="shield" {...props} />,
    name: modules.isolationCertificates,
    label: 'ISOLATION_CERTIFICATES',
    visibleFor: [PERMISSIONS[PERMISSION_TYPES.isolationCertificates].view.name],
    hidden: !FEATURES.isolations.visible,
  },
  {
    icon: props => <Icon name="document-checklist" {...props} />,
    name: modules.permits,
    label: 'PERMITS',
    visibleFor: [PERMISSIONS[PERMISSION_TYPES.permits].view.name],
    hidden: !FEATURES.permits.visible,
    dataCy: '',
  },
  {
    icon: props => <Icon name="ndt" {...props} />,
    name: modules.readingsAndGauges,
    label: 'READINGS_AND_GAUGES',
    visibleFor: [PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].view.name],
    hidden: !FEATURES.readingsAndGauges.visible,
    dataCy: '',
  },
];

export const measurementTypes = {
  measurement: 'MEASUREMENT',
  defect: 'DEFECT',
  component: 'Component',
  inspect: 'INSPECT',
  cluster: 'Cluster',
  notification: 'NOTIFICATION',
  rgMeasurementLocation: 'RG_MEASUREMENT_LOCATION',
  rgMeasurementPoint: 'RG_MEASUREMENT_POINT',
};

export const imagesCameraPosition = {
  ground: 'GROUND',
  uav: 'UAV',
  other: 'OTHER',
};

export const imagesCameraPositionDefaults = {
  [imagesCameraPosition.ground]: 15,
  [imagesCameraPosition.uav]: 50,
};

export const imagesCameraPositionSettins = {
  [imagesCameraPosition.ground]: {
    minValue: 1,
    maxValue: 100,
  },
  [imagesCameraPosition.uav]: {
    minValue: 1,
    maxValue: 500,
  },
};

export const realCameraTypeItems = [
  {
    name: imagesCameraPosition.uav,
    label: 'IMAGE_CAMERA_POSITION.UAV',
  },
  {
    name: imagesCameraPosition.ground,
    label: 'IMAGE_CAMERA_POSITION.GROUND',
  },
  {
    name: imagesCameraPosition.other,
    label: 'IMAGE_CAMERA_POSITION.OTHER',
  },
];

export const cameraChangedMessageDelay = 1000;

export const moduleSectionsFields = {
  id: 'ID',
  sectionName: 'Name',
  sectionSecondaryName: 'title',
  fieldName: 'name',
  fieldIsDate: 'isDate',
  locked: 'Locked',
  questions: 'SectionQuestions',
  disabled: 'disabled',
  status: 'Status',
  disabledForStatuses: 'disabledForStatuses',
  fields: 'fields',
  initialValuesProp: 'initialValuesProp',
  expanded: 'expanded',
  initiallyExpanded: 'initiallyExpanded',
  sections: 'Sections',
  sectionAction: 'sectionAction',
  components: 'Components',
  archived: 'Archived',
  files: 'Files',
  populatedDrawings: 'PopulatedDrawings',
  sectionOrder: 'SectionOrder',
  formName: 'formName',
  validate: 'validate',
  showError: 'showError',
  workAreas: 'WorkAreas',
  visibleFor: 'visibleFor',
  mandatoryQuestionsTotal: 'MandatoryQuestionsTotal',
  mandatoryQuestionsAnswered: 'MandatoryQuestionsAnswered',
};

export const moduleSectionQuestionFields = {
  id: 'ID',
  type: 'QuestionType',
  name: 'Name',
  description: 'Description',
  sectionId: 'SectionID',
  isMandatory: 'IsMandatory',
  moduleId: 'ModuleID',
  questionId: 'QuestionID',
  allowMultiple: 'AllowMultipleAnswers',
  questionOptions: 'QuestionOptions',
  answers: 'Answers',
  isAnswer: 'IsAnswer',
  initialValuesName: 'initialValuesName',
  fieldName: 'name',
  isMandatoryFileUpload: 'MandatoryFileUpload',
  evidenceFiles: 'QuestionEvidenceFiles',
  answerReadingValues: 'AnswerReadingValues',
};

export const imagesValidation = {
  maxFiles: 5,
  value: 50,
  unit: 'mb',
  bytes: 52428800,
};

export const unsavedChangesHandlerAllowedClassNames = {
  twpBody: 'twp-body',
  menuItem: 'menu-item',
  backButton: 'back-button',
};

export const viewQueryPropTypes = {
  [modules.readingsAndGauges]: {
    group: 'group',
    location: 'location',
    points: 'points',
    //not using kebab case here since it needs to reflect our query prop
    location_and_points: 'location_and_points',
  },
};
