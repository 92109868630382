import { cloneDeep, debounce, filter, find, findIndex, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, getFormSyncErrors, getFormValues, reduxForm } from 'redux-form';

import Helpers from '../../../../../common/helpers';
import { questionTypes } from '../../../../../common/question-components/constants/question-constants';
import { moduleSectionQuestionFields } from '../../../constants/constants';
import { validate } from '../validators/custom-section-form-validator';

class CustomSectionForm extends Component {
  constructor(props) {
    super(props);

    // Create debounced functions
    this.debouncedHandleFieldChange = debounce(this.handleFieldChange, this.getDebounceValue());
  }

  getFieldValue = (question, value) => {
    const { change } = this.props;
    let name = '';
    let fieldValue = '';

    switch (question[moduleSectionQuestionFields.type]) {
      case questionTypes.DATE.valueId:
        fieldValue = Helpers.isDateValid(value) ? Helpers.getUnixDate(new Date(value).getTime()) : value;
        return { value: fieldValue, question };
      case questionTypes.NUMBER.valueId:
        fieldValue = value ? Number(value) : value;
        return { value: fieldValue, question };
      case questionTypes.MULTI_ANSWER.valueId:
        const questionCopy = cloneDeep(question);

        name = `${questionTypes.MULTI_ANSWER.fieldName}-${questionCopy[moduleSectionQuestionFields.id]}`;
        const questionOptions = Object.assign([], questionCopy[moduleSectionQuestionFields.questionOptions]);
        const selectedOptions = filter(questionOptions, { [moduleSectionQuestionFields.isAnswer]: true }).map(option => option[moduleSectionQuestionFields.id]);
        const index = selectedOptions.indexOf(value);
        const questionOptionsIndex = findIndex(questionOptions, { [moduleSectionQuestionFields.id]: value });

        // if multiple answers are allowed, we need to either add or remove new value
        // base on if the value is already in the selected options
        if (questionCopy[moduleSectionQuestionFields.allowMultiple]) {
          const option = questionOptions[questionOptionsIndex];
          option[moduleSectionQuestionFields.isAnswer] = index === -1;
          questionOptions.splice(questionOptionsIndex, 1, option);
          questionCopy[moduleSectionQuestionFields.questionOptions] = questionOptions;

          if (index > -1) {
            selectedOptions.splice(index, 1);
          } else {
            selectedOptions.push(value);
          }

          return { value: selectedOptions, question: questionCopy };
        } else {
          for (let i = 0; i < questionOptions.length; i++) {
            const option = questionOptions[i];
            if (option[moduleSectionQuestionFields.id] === value && !option[moduleSectionQuestionFields.isAnswer]) {
              option[moduleSectionQuestionFields.isAnswer] = true;
            } else {
              option[moduleSectionQuestionFields.isAnswer] = false;
            }

            questionOptions.splice(i, 1, option);
            questionCopy[moduleSectionQuestionFields.questionOptions] = questionOptions;

            change(`${name}-${questionOptions[i][moduleSectionQuestionFields.id]}`, questionOptions[i][moduleSectionQuestionFields.id] === questionCopy[moduleSectionQuestionFields.id] ? value : null);
          }

          fieldValue = index > -1 ? [] : [value];
          return { value: fieldValue, question: questionCopy };
        }
      case questionTypes.CHECKBOX.valueId:
        name = `${questionTypes.CHECKBOX.fieldName}-${question[moduleSectionQuestionFields.id]}`;
        change(`${name}-yes`, value ? 'true' : null);
        change(`${name}-no`, value ? null : 'true');

        return { value, question };
      default:
        return { value, question };
    }
  };

  getDebounceValue = () => {
    const { debounceValues } = this.props;
    if (!debounceValues) return;
    return debounceValues.MULTI_ANSWER || debounceValues.CHECKBOX || 600; // Default to 600 if not provided
  };

  onFieldChange = (field, question, newValue, oldValue) => {
    const debounceValue =
      question[moduleSectionQuestionFields.type] === questionTypes.MULTI_ANSWER.valueId || question[moduleSectionQuestionFields.type] === questionTypes.CHECKBOX.valueId ? 100 : 600;
    this.debouncedHandleFieldChange.cancel(); // Cancel any pending debounced calls
    this.debouncedHandleFieldChange = debounce(this.handleFieldChange, debounceValue);
    this.debouncedHandleFieldChange(field, question, newValue, oldValue);
  };

  handleFieldChange = (field, question, newValue, oldValue) => {
    const { moduleId, handleFieldChange } = this.props;

    if (typeof handleFieldChange === 'function') {
      const fieldValue = this.getFieldValue(question, newValue);
      /* for ability to uncheck checkbox */
      const isCheckboxSameValue = field === questionTypes.CHECKBOX.fieldName && oldValue === newValue;
      /* for ability to send empty value for text or number type of input, API expects null */
      const isNewValueEmptyString = (field === questionTypes.TEXT.fieldName || field === questionTypes.NUMBER.fieldName) && typeof newValue === 'string' && newValue === '';

      const values = {
        [moduleSectionQuestionFields.moduleId]: moduleId,
        [moduleSectionQuestionFields.questionId]: question[moduleSectionQuestionFields.id],
        [moduleSectionQuestionFields.type]: question[moduleSectionQuestionFields.type],
        [field]: isCheckboxSameValue || isNewValueEmptyString ? null : fieldValue?.value,
        question: fieldValue?.question,
      };

      handleFieldChange(values);
    }
  };

  // onFieldChange = (field, question, newValue, oldValue) => {
  //   const { moduleId, handleFieldChange } = this.props;

  //   if (typeof handleFieldChange === 'function') {
  //     const fieldValue = this.getFieldValue(question, newValue);
  //     /* for ability to uncheck checkbox */
  //     const isCheckboxSameValue = field === questionTypes.CHECKBOX.fieldName && oldValue === newValue;
  //     /* for ability to send empty value for text or number type of input, API expects null */
  //     const isNewValueEmptyString = (field === questionTypes.TEXT.fieldName || field === questionTypes.NUMBER.fieldName) && typeof newValue === 'string' && newValue === '';

  //     const values = {
  //       [moduleSectionQuestionFields.moduleId]: moduleId,
  //       [moduleSectionQuestionFields.questionId]: question[moduleSectionQuestionFields.id],
  //       [moduleSectionQuestionFields.type]: question[moduleSectionQuestionFields.type],
  //       [field]: isCheckboxSameValue || isNewValueEmptyString ? null : fieldValue?.value,
  //       question: fieldValue?.question,
  //     };

  //     handleFieldChange(values);
  //   }
  // };

  getField = question => {
    const { disabled } = this.props;
    const { t } = this.context;
    const types = values(questionTypes);
    const type = find(types, { valueId: question[moduleSectionQuestionFields.type] });
    const name = type ? `${type.fieldName}-${question[moduleSectionQuestionFields.id]}` : '';
    const id = type ? `question-field-${question[moduleSectionQuestionFields.sectionId]}-${question[moduleSectionQuestionFields.id]}` : '';

    // console.log('getField question', question);
    switch (question[moduleSectionQuestionFields.type]) {
      case questionTypes.TEXT.valueId:
        return (
          <Field
            id={id}
            name={name}
            component={questionTypes.TEXT_LONG.component}
            onChange={(_e, value) => this.onFieldChange(questionTypes.TEXT.fieldName, question, value)}
            size="lg"
            enableAutoResize={true}
            disabled={disabled}
            withHiddenError
            maxChars={questionTypes.TEXT_LONG.maxChars}
          />
        );
      case questionTypes.NUMBER.valueId:
        return (
          <Field
            id={id}
            name={name}
            component={questionTypes.NUMBER.component}
            type="number"
            onChange={(_e, value) => this.onFieldChange(questionTypes.NUMBER.fieldName, question, value)}
            size="lg"
            withHiddenError
            disabled={disabled}
          />
        );
      case questionTypes.CHECKBOX.valueId: {
        const prevCheckboxValue = question.Answers && question.Answers[0] && question.Answers[0].AnswerCheckbox;
        return (
          <div className="checkboxes-question">
            <Field
              id={`${id}-yes`}
              name={`${name}-yes`}
              component={questionTypes.CHECKBOX.component}
              checkboxText={t('MODULES_SECTIONS.QUESTION_CHECKBOX_YES')}
              value={true}
              onChange={() => this.onFieldChange(questionTypes.CHECKBOX.fieldName, question, true, prevCheckboxValue)}
              restprops={{ name: `${name}-yes` }}
              disabled={disabled}
            />
            <Field
              id={`${id}-no`}
              name={`${name}-no`}
              component={questionTypes.CHECKBOX.component}
              checkboxText={t('MODULES_SECTIONS.QUESTION_CHECKBOX_NO')}
              value={false}
              onChange={() => this.onFieldChange(questionTypes.CHECKBOX.fieldName, question, false, prevCheckboxValue)}
              restprops={{ name: `${name}-no` }}
              disabled={disabled}
            />
          </div>
        );
      }
      case questionTypes.MULTI_ANSWER.valueId:
        if (question[moduleSectionQuestionFields.questionOptions] && question[moduleSectionQuestionFields.questionOptions].length > 0) {
          return (
            <div className="multi-answers-container">
              {question[moduleSectionQuestionFields.questionOptions].map((option, index) => (
                <div
                  key={`question-option-${question[moduleSectionQuestionFields.id]}-${option[moduleSectionQuestionFields.id]}`}
                  className={question[moduleSectionQuestionFields.questionOptions].length === index + 1 ? '' : 'question-option'}
                >
                  <Field
                    id={`${id}-${option[moduleSectionQuestionFields.id]}`}
                    name={`${name}-${option[moduleSectionQuestionFields.id]}`}
                    component={questionTypes.CHECKBOX.component}
                    value={option[moduleSectionQuestionFields.id]}
                    onChange={() => this.onFieldChange(questionTypes.MULTI_ANSWER.fieldName, question, option[moduleSectionQuestionFields.id])}
                    checkboxText={option[moduleSectionQuestionFields.name]}
                    shortenText
                    restprops={{ name: `${name}-${option[moduleSectionQuestionFields.id]}` }}
                    disabled={disabled}
                  />
                </div>
              ))}
            </div>
          );
        }

        return null;
      case questionTypes.DATE.valueId:
        return (
          <Field
            id={id}
            name={name}
            component={questionTypes.DATE.component}
            placeholder={t('MODULES_SECTIONS.DATE_PLACEHOLDER')}
            disabledKeyboardNavigation
            dateFormat="MMMM d, yyyy h:mm aa"
            timeFormat="h:mm aa"
            showTimeSelect
            size="lg"
            popperPlacement="top"
            withHiddenError
            onChange={value => this.onFieldChange(questionTypes.DATE.fieldName, question, value)}
            disabled={disabled}
          />
        );
      case questionTypes.NO_ANSWER.valueId:
        return <p className="f-primary">{question[moduleSectionQuestionFields.name]}</p>;
      default:
        return null;
    }
  };

  getQuestionTitle = (question, index) => {
    const { t } = this.context;

    if (question[moduleSectionQuestionFields.type] === questionTypes.NO_ANSWER.valueId) {
      return `${index + 1}. ${t('MODULES_SECTIONS.PROCEDURE_TITLE')}`;
    }

    return `${index + 1}. ${question[moduleSectionQuestionFields.name]}`;
  };

  render() {
    const { questions = [] } = this.props;

    return (
      <form className="custom-section-form" onSubmit={e => e.preventDefault()}>
        {questions &&
          questions.length > 0 &&
          questions.map((question, index) => {
            return (
              <div className="question-field-container" key={`section-question-field-${question[moduleSectionQuestionFields.id]}`}>
                <p className="f-primary bold question-label">{`${this.getQuestionTitle(question, index)}${question[moduleSectionQuestionFields.isMandatory] ? '*' : ''}`}</p>
                {question[moduleSectionQuestionFields.description] && <p className="f-secondary-dark bold question-description">{question[moduleSectionQuestionFields.description]}</p>}
                <div className="question-field">{this.getField(question)}</div>
              </div>
            );
          })}
      </form>
    );
  }
}

CustomSectionForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

CustomSectionForm.propTypes = {
  moduleId: PropTypes.number.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      Name: PropTypes.string.isRequired,
      QuestionType: PropTypes.number,
      QuestionOrder: PropTypes.number,
      IsMandatory: PropTypes.bool,
      AllowMultipleAnswers: PropTypes.bool,
      EstimatedTime: PropTypes.number,
      CreatedAt: PropTypes.number,
      SectionID: PropTypes.number,
    })
  ),
  handleFieldChange: PropTypes.func,
  disabled: PropTypes.bool,
  debounceValues: PropTypes.shape({
    MULTI_ANSWER: PropTypes.number,
    CHECKBOX: PropTypes.number,
    DEFAULT: PropTypes.number,
  }),
};

const mapStateToProps = (state, props) => ({
  formErrors: getFormSyncErrors(props.form)(state),
  formValues: getFormValues(props.form)(state),
});

export default connect(
  mapStateToProps,
  null
)(
  reduxForm({
    enableReinitialize: true,
    touchOnChange: true,
    destroyOnUnmount: true,
    validate,
  })(CustomSectionForm)
);
