import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../form/components/button';
import Icon from '../../icon/components/icon';
import '../styles/card.scss';

const Card = ({ title, description, iconName, onClick, buttonText, buttonVariant = 'success-outline', disabled, soon }, { t }) => {
  const isDisabled = disabled || soon;
  const variant = (soon && 'gray-outline') || buttonVariant;
  const icon = (soon && 'clock') || 'arrow-right-circle';
  const text = (soon && 'SOON') || buttonText;
  return (
    <div className={`card ${isDisabled ? 'disabled' : ''}`} onClick={!isDisabled ? onClick : undefined}>
      <div className="card__top">
        <Icon name={iconName} className="full-size" />
      </div>
      <div className="card__bottom">
        <div>
          <h4 className="card__title">{t(title)}</h4>
          <p className="card__description">{t(description)}</p>
        </div>
        <Button variant={variant} disabled={isDisabled} onClick={onClick} text={t(text)} width="xs" icon={icon} iconPosition={'right'} iconProps={{ size: 'sm' }} />
      </div>
    </div>
  );
};

Card.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Card;
