import { FEATURES } from './constants';

export const PERMISSION_TYPES = {
  observations: 'Observations',
  people: 'People',
  components: 'Components',
  measurements: 'Measurements',
  notifications: 'Notifications',
  areas: 'Areas',
  // zones: 'Zones',
  projects: 'Projects',
  ndt: 'NDT',
  smartDrawing: 'SmartDrawing',
  contractors: 'Contractors',
  checklists: 'CheckLists',
  keybox: 'Keyboxes',
  isolationTemplates: 'IsolationTemplates',
  permits: 'Permits',
  senseChecks: 'SenseChecks',
  permitTemplates: 'PermitTemplates',
  isolationCertificates: 'IsolationCertificates',
  workOrders: 'WorkOrders',
  maintenanceRegimes: 'MaintenanceRegimes',
  componentsHierarchy: 'ComponentsHierarchy',
  documentManagement: 'DocumentManagement',
  readingsAndGauges: 'ReadingsAndGauges',
  augmentedReality: 'AugmentedReality',
};

export const excludedProps = {
  options: 'options',
};

export const PERMISSIONS = {
  [PERMISSION_TYPES.people]: {
    view: { name: `${PERMISSION_TYPES.people}:View`, isBase: true },
    edit: { name: `${PERMISSION_TYPES.people}:Edit` },
    create: { name: `${PERMISSION_TYPES.people}:Create` },
    delete: { name: `${PERMISSION_TYPES.people}:Delete` },
  },
  [PERMISSION_TYPES.projects]: {
    view: { name: `${PERMISSION_TYPES.projects}:View`, disabled: true, value: true },
    edit: { name: `${PERMISSION_TYPES.projects}:Edit` },
  },
  [PERMISSION_TYPES.components]: {
    view: { name: `${PERMISSION_TYPES.components}:View`, disabled: true, value: true },
    edit: { name: `${PERMISSION_TYPES.components}:Edit` },
    create: { name: `${PERMISSION_TYPES.components}:Create` },
    delete: { name: `${PERMISSION_TYPES.components}:Delete` },
    addComment: { name: `${PERMISSION_TYPES.components}:AddComment` },
    chooseFromDMS: { name: `${PERMISSION_TYPES.components}:ChooseFromDMS` },
  },
  [PERMISSION_TYPES.observations]: {
    view: {
      name: `${PERMISSION_TYPES.observations}:View`,
      isBase: true,
    },
    edit: { name: `${PERMISSION_TYPES.observations}:Edit` },
    create: { name: `${PERMISSION_TYPES.observations}:Create` },
    delete: { name: `${PERMISSION_TYPES.observations}:Delete` },
    addComment: { name: `${PERMISSION_TYPES.observations}:AddComment` },
    chooseFromDMS: { name: `${PERMISSION_TYPES.observations}:ChooseFromDMS` },
    linkWorkOrders: { name: `${PERMISSION_TYPES.observations}:LinkWorkOrders` },
  },
  [PERMISSION_TYPES.measurements]: {
    view: { name: `${PERMISSION_TYPES.measurements}:View`, isBase: true },
    edit: { name: `${PERMISSION_TYPES.measurements}:Edit` },
    create: { name: `${PERMISSION_TYPES.measurements}:Create` },
    delete: { name: `${PERMISSION_TYPES.measurements}:Delete` },
    addComment: { name: `${PERMISSION_TYPES.measurements}:AddComment` },
  },
  [PERMISSION_TYPES.notifications]: {
    view: { name: `${PERMISSION_TYPES.notifications}:View`, isBase: true },
    edit: { name: `${PERMISSION_TYPES.notifications}:Edit` },
    create: { name: `${PERMISSION_TYPES.notifications}:Create` },
    delete: { name: `${PERMISSION_TYPES.notifications}:Delete` },
    addComment: { name: `${PERMISSION_TYPES.notifications}:AddComment` },
    chooseFromDMS: { name: `${PERMISSION_TYPES.notifications}:ChooseFromDMS` },
    [excludedProps.options]: { disabled: !FEATURES.notifications.visible },
  },
  [PERMISSION_TYPES.areas]: {
    view: { name: `${PERMISSION_TYPES.areas}:View`, isBase: true },
    edit: { name: `${PERMISSION_TYPES.areas}:Edit` },
    create: { name: `${PERMISSION_TYPES.areas}:Create` },
    delete: { name: `${PERMISSION_TYPES.areas}:Delete` },
    [excludedProps.options]: { disabled: !FEATURES.areas.visible },
  },
  // [PERMISSION_TYPES.zones]: {
  //   view: { name: `${PERMISSION_TYPES.zones}:View`, isBase: true },
  //   edit: { name: `${PERMISSION_TYPES.zones}:Edit` },
  //   create: { name: `${PERMISSION_TYPES.zones}:Create` },
  //   delete: { name: `${PERMISSION_TYPES.zones}:Delete` },
  // },
  [PERMISSION_TYPES.ndt]: {
    view: { name: `${PERMISSION_TYPES.ndt}:View`, isBase: true },
    edit: { name: `${PERMISSION_TYPES.ndt}:Edit` },
    create: { name: `${PERMISSION_TYPES.ndt}:Create` },
    delete: { name: `${PERMISSION_TYPES.ndt}:Delete` },
    createMeasurementPoint: { name: `${PERMISSION_TYPES.ndt}:CreateMeasurementPoint` },
    deleteMeasurementPoint: { name: `${PERMISSION_TYPES.ndt}:DeleteMeasurementPoint` },
    editMeasurementPoint: { name: `${PERMISSION_TYPES.ndt}:EditMeasurementPoint` },
  },
  [PERMISSION_TYPES.smartDrawing]: {
    view: { name: `${PERMISSION_TYPES.smartDrawing}:View`, isBase: true },
    edit: { name: `${PERMISSION_TYPES.smartDrawing}:Edit` },
    create: { name: `${PERMISSION_TYPES.smartDrawing}:Create` },
    delete: { name: `${PERMISSION_TYPES.smartDrawing}:Delete` },
    manipulate: { name: `${PERMISSION_TYPES.smartDrawing}:Manipulate` },
  },
  [PERMISSION_TYPES.contractors]: {
    view: { name: `${PERMISSION_TYPES.contractors}:View`, isBase: true },
    edit: { name: `${PERMISSION_TYPES.contractors}:Edit` },
    create: { name: `${PERMISSION_TYPES.contractors}:Create` },
    delete: { name: `${PERMISSION_TYPES.contractors}:Delete` },
    [excludedProps.options]: { disabled: !FEATURES.contractors.visible },
  },
  [PERMISSION_TYPES.checklists]: {
    view: { name: `${PERMISSION_TYPES.checklists}:View`, isBase: true },
    edit: { name: `${PERMISSION_TYPES.checklists}:Edit` },
    create: { name: `${PERMISSION_TYPES.checklists}:Create` },
    approve: { name: `${PERMISSION_TYPES.checklists}:ApproveChecklists` },
    delete: { name: `${PERMISSION_TYPES.checklists}:Delete` },
    chooseFromDMS: { name: `${PERMISSION_TYPES.checklists}:ChooseFromDMS` },
    measurementPointLink: { name: `${PERMISSION_TYPES.checklists}:MeasurementPointLink` },
    [excludedProps.options]: { disabled: !FEATURES.checklistsAndProcedures.visible },
  },
  [PERMISSION_TYPES.keybox]: {
    view: { name: `${PERMISSION_TYPES.keybox}:View`, isBase: true },
    edit: { name: `${PERMISSION_TYPES.keybox}:Edit` },
    create: { name: `${PERMISSION_TYPES.keybox}:Create` },
    manage: { name: `${PERMISSION_TYPES.keybox}:Manage` },
    delete: { name: `${PERMISSION_TYPES.keybox}:Delete` },
    [excludedProps.options]: { disabled: !FEATURES.keybox.visible },
  },
  [PERMISSION_TYPES.isolationTemplates]: {
    view: { name: `${PERMISSION_TYPES.isolationTemplates}:View`, isBase: true },
    edit: { name: `${PERMISSION_TYPES.isolationTemplates}:Edit` },
    create: { name: `${PERMISSION_TYPES.isolationTemplates}:Create` },
    delete: { name: `${PERMISSION_TYPES.isolationTemplates}:Delete` },
    live: { name: `${PERMISSION_TYPES.isolationTemplates}:Live` },
    [excludedProps.options]: { disabled: !FEATURES.isolations.visible },
  },
  [PERMISSION_TYPES.permits]: {
    view: { name: `${PERMISSION_TYPES.permits}:View`, isBase: true },
    edit: { name: `${PERMISSION_TYPES.permits}:Edit` },
    create: { name: `${PERMISSION_TYPES.permits}:Create` },
    delete: { name: `${PERMISSION_TYPES.permits}:Delete` },
    toApprove: { name: `${PERMISSION_TYPES.permits}:ToApprove` },
    approveEdit: { name: `${PERMISSION_TYPES.permits}:ApproveEdit` },
    toAuthorise: { name: `${PERMISSION_TYPES.permits}:ToAuthorise` },
    authoriseEdit: { name: `${PERMISSION_TYPES.permits}:AuthoriseEdit` },
    toIssue: { name: `${PERMISSION_TYPES.permits}:ToIssue` },
    issueEdit: { name: `${PERMISSION_TYPES.permits}:IssueEdit` },
    toSurrender: { name: `${PERMISSION_TYPES.permits}:ToSurrender` },
    surrenderEdit: { name: `${PERMISSION_TYPES.permits}:SurrenderEdit` },
    toReissue: { name: `${PERMISSION_TYPES.permits}:ToReissue` },
    reissueEdit: { name: `${PERMISSION_TYPES.permits}:ReissueEdit` },
    toHandback: { name: `${PERMISSION_TYPES.permits}:ToHandBack` },
    archive: { name: `${PERMISSION_TYPES.permits}:Archive` },
    addComment: { name: `${PERMISSION_TYPES.permits}:AddComment` },
    [excludedProps.options]: { disabled: !FEATURES.permits.visible },
  },
  [PERMISSION_TYPES.senseChecks]: {
    view: { name: `${PERMISSION_TYPES.senseChecks}:View`, isBase: true },
    edit: { name: `${PERMISSION_TYPES.senseChecks}:Edit` },
    create: { name: `${PERMISSION_TYPES.senseChecks}:Create` },
    delete: { name: `${PERMISSION_TYPES.senseChecks}:Delete` },
    [excludedProps.options]: { disabled: true },
  },
  [PERMISSION_TYPES.permitTemplates]: {
    view: { name: `${PERMISSION_TYPES.permitTemplates}:View`, isBase: true },
    edit: { name: `${PERMISSION_TYPES.permitTemplates}:Edit` },
    create: { name: `${PERMISSION_TYPES.permitTemplates}:Create` },
    delete: { name: `${PERMISSION_TYPES.permitTemplates}:Delete` },
    approve: { name: `${PERMISSION_TYPES.permitTemplates}:Live` },
    [excludedProps.options]: { disabled: !FEATURES.permits.visible },
  },
  [PERMISSION_TYPES.isolationCertificates]: {
    view: { name: `${PERMISSION_TYPES.isolationCertificates}:View`, isBase: true },
    create: { name: `${PERMISSION_TYPES.isolationCertificates}:Create` },
    edit: { name: `${PERMISSION_TYPES.isolationCertificates}:Edit` },
    delete: { name: `${PERMISSION_TYPES.isolationCertificates}:Delete` },
    archive: { name: `${PERMISSION_TYPES.isolationCertificates}:Archive` },
    toApprove: { name: `${PERMISSION_TYPES.isolationCertificates}:ToApprove` },
    approveEdit: { name: `${PERMISSION_TYPES.isolationCertificates}:ApproveEdit` },
    toAuthorise: { name: `${PERMISSION_TYPES.isolationCertificates}:ToAuthorise` },
    authoriseEdit: { name: `${PERMISSION_TYPES.isolationCertificates}:AuthoriseEdit` },
    toUnderTest: { name: `${PERMISSION_TYPES.isolationCertificates}:ToUnderTest` },
    underTestEdit: { name: `${PERMISSION_TYPES.isolationCertificates}:UnderTestEdit` },
    toLive: { name: `${PERMISSION_TYPES.isolationCertificates}:ToLive` },
    liveEdit: { name: `${PERMISSION_TYPES.isolationCertificates}:LiveEdit` },
    toLongTerm: { name: `${PERMISSION_TYPES.isolationCertificates}:ToLongTerm` },
    longTermEdit: { name: `${PERMISSION_TYPES.isolationCertificates}:LongTermEdit` },
    toDeIsolate: { name: `${PERMISSION_TYPES.isolationCertificates}:ToDeisolate` },
    addComment: { name: `${PERMISSION_TYPES.isolationCertificates}:AddComment` },
    [excludedProps.options]: { disabled: !FEATURES.isolations.visible },
  },
  [PERMISSION_TYPES.workOrders]: {
    view: { name: `${PERMISSION_TYPES.workOrders}:View`, isBase: true },
    edit: { name: `${PERMISSION_TYPES.workOrders}:Edit` },
    create: { name: `${PERMISSION_TYPES.workOrders}:Create` },
    delete: { name: `${PERMISSION_TYPES.workOrders}:Delete` },
    archive: { name: `${PERMISSION_TYPES.workOrders}:Archive` },
    toApprove: { name: `${PERMISSION_TYPES.workOrders}:ToApprove` },
    approveEdit: { name: `${PERMISSION_TYPES.workOrders}:ApproveEdit` },
    toAuthorise: { name: `${PERMISSION_TYPES.workOrders}:ToAuthorise` },
    authoriseEdit: { name: `${PERMISSION_TYPES.workOrders}:AuthoriseEdit` },
    toInProgress: { name: `${PERMISSION_TYPES.workOrders}:ToInProgress` },
    inProgressEdit: { name: `${PERMISSION_TYPES.workOrders}:InProgressEdit` },
    toComplete: { name: `${PERMISSION_TYPES.workOrders}:ToComplete` },
    completeEdit: { name: `${PERMISSION_TYPES.workOrders}:CompleteEdit` },
    toClose: { name: `${PERMISSION_TYPES.workOrders}:ToClose` },
    closeEdit: { name: `${PERMISSION_TYPES.workOrders}:CloseEdit` },
    fillInCP: { name: `${PERMISSION_TYPES.workOrders}:FillInCP` },
    workOrdersCPLink: { name: `${PERMISSION_TYPES.workOrders}:WorkOrdersCPLink` },
    addComment: { name: `${PERMISSION_TYPES.workOrders}:AddComment` },
    chooseFromDMS: { name: `${PERMISSION_TYPES.workOrders}:ChooseFromDMS` },
    workOrdersObservationLink: { name: `${PERMISSION_TYPES.workOrders}:LinkObservations` },
    [excludedProps.options]: { disabled: !FEATURES.workorders.visible },
  },
  [PERMISSION_TYPES.maintenanceRegimes]: {
    view: { name: `${PERMISSION_TYPES.maintenanceRegimes}:View`, isBase: true },
    edit: { name: `${PERMISSION_TYPES.maintenanceRegimes}:Edit` },
    create: { name: `${PERMISSION_TYPES.maintenanceRegimes}:Create` },
    delete: { name: `${PERMISSION_TYPES.maintenanceRegimes}:Delete` },
    live: { name: `${PERMISSION_TYPES.maintenanceRegimes}:Live` },
    regimeCPLink: { name: `${PERMISSION_TYPES.maintenanceRegimes}:MaintenanceRegimeCPLink` },
    [excludedProps.options]: { disabled: !FEATURES.workManagement.visible },
  },
  [PERMISSION_TYPES.componentsHierarchy]: {
    view: { name: `${PERMISSION_TYPES.componentsHierarchy}:View`, isBase: true },
    edit: { name: `${PERMISSION_TYPES.componentsHierarchy}:Edit` },
    create: { name: `${PERMISSION_TYPES.componentsHierarchy}:Create` },
    delete: { name: `${PERMISSION_TYPES.componentsHierarchy}:Delete` },
  },
  [PERMISSION_TYPES.documentManagement]: {
    view: { name: `${PERMISSION_TYPES.documentManagement}:View`, isBase: true },
    edit: { name: `${PERMISSION_TYPES.documentManagement}:Edit` },
    create: { name: `${PERMISSION_TYPES.documentManagement}:Create` },
    delete: { name: `${PERMISSION_TYPES.documentManagement}:Delete` },
    [excludedProps.options]: { disabled: !FEATURES.documentManagement.visible },
  },
  [PERMISSION_TYPES.readingsAndGauges]: {
    view: { name: `${PERMISSION_TYPES.readingsAndGauges}:View`, isBase: true },
    groupEdit: { name: `${PERMISSION_TYPES.readingsAndGauges}Group:Edit` },
    groupCreate: { name: `${PERMISSION_TYPES.readingsAndGauges}Group:Create` },
    groupDelete: { name: `${PERMISSION_TYPES.readingsAndGauges}Group:Delete` },
    [excludedProps.options]: { disabled: !FEATURES.readingsAndGauges.visible },
    locationEdit: { name: `MeasurementLocation:Edit` },
    locationCreate: { name: `MeasurementLocation:Create` },
    locationDelete: { name: `MeasurementLocation:Delete` },
    pointEdit: { name: `MeasurementPoint:Edit` },
    pointCreate: { name: `MeasurementPoint:Create` },
    pointDelete: { name: `MeasurementPoint:Delete` },
    readingEdit: { name: 'MeasurementReading:Edit' },
    readingCreate: { name: 'MeasurementReading:Create' },
    readingDelete: { name: 'MeasurementReading:Delete' },
    // chooseFromDMS: { name: 'MeasurementReading:ChooseFromDMS' },
  },
  [PERMISSION_TYPES.augmentedReality]: {
    view: { name: `${PERMISSION_TYPES.augmentedReality}:View`, isBase: true },
    [excludedProps.options]: { disabled: !FEATURES.augmentedReality.visible },
  },
};
