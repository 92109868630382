import PropTypes from 'prop-types';
import React from 'react';
import { FieldArray, reduxForm } from 'redux-form';

import { validate } from '../validators/property-validator';

import Property from './property';

import { formConstants } from '../constants/property-constants';

let CustomPropertiesForm = props => {
  const { handleChange, isDisabled, form, suggestions, deleteProperty, getNameSuggestions, clearSuggestions, properties, isEnhancedDesignCustomProp, showActions, formClassName, formErrors } = props;

  const handleFieldChange = event => {
    handleChange(event);
    // Optionally, manually trigger validation here if needed
    // validate(formValues); // Ensure formValues is available
    // TODO: make sure to trigger unsaved changes popup after custom property is edited
  };
  return (
    <form noValidate onSubmit={e => e.preventDefault()} className={formClassName}>
      <FieldArray
        id={formConstants.fields.properties}
        isDisabled={isDisabled}
        name={formConstants.fields.properties}
        formName={form}
        component={Property}
        handleChange={handleFieldChange} // Use the custom handler to ensure validation is triggered
        properties={properties}
        suggestions={suggestions}
        deleteProperty={deleteProperty}
        getNameSuggestions={getNameSuggestions}
        clearSuggestions={clearSuggestions}
        isEnhancedDesignCustomProp={isEnhancedDesignCustomProp}
        showPropActions={showActions}
        errors={formErrors}
      />
    </form>
  );
};

CustomPropertiesForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

CustomPropertiesForm.defaultProps = {
  formClassName: '',
};

CustomPropertiesForm = reduxForm({
  validate,
  enableReinitialize: true,
  touchOnChange: true,
})(CustomPropertiesForm);

export default CustomPropertiesForm;
