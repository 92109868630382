import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { arrayPush, getFormSyncErrors } from 'redux-form';
import CustomPropertiesForm from './custom-properties-form';

import { ReactComponent as AddIcon } from '../../assets/upload-component-asset.svg';

import ButtonWithIcon from '../../button-with-icon/button-with-icon';
import Modal from '../../modal/components/modal';
import RenderIf from '../../render-if/components/render-if';
import { formConstants } from '../constants/property-constants';

class CustomProperties extends Component {
  constructor(props) {
    super(props);
    const { noDebounceOnForm } = props;
    this.formChangeDebounce = debounce(this.submitForm, noDebounceOnForm ? 0 : 100);
    /**
     * if needed we can propagate debounce time in props,
     * for now it is 600ms (which is used in all non-search and non-fetch requests across the app), 2000ms was too long
     **/
    this.addPropertyDebounce = debounce(props.addProperty, noDebounceOnForm ? 0 : 600);
    this.updatePropertyDebounce = debounce(props.updateProperty, noDebounceOnForm ? 0 : 300);
    this.state = {
      suggestions: [],
      modalState: {
        isOpen: false,
      },
    };
  }

  componentDidMount() {
    const { getProperties } = this.props;
    getProperties();
  }

  componentWillUnmount() {
    this.formChangeDebounce.cancel();
    this.addPropertyDebounce.cancel();
    this.updatePropertyDebounce.cancel();
  }

  handleOnChange = value => {
    this.formChangeDebounce(value);
  };

  getSuggestions = value => {
    const { getSuggestions } = this.props;
    getSuggestions(value, suggestions => {
      this.setState({ suggestions });
    });
  };

  isValid = property => {
    if (!property || !property[formConstants.fields.value] || !property[formConstants.fields.name]) return false;
    return true;
  };

  submitForm = ({ newValue, changedField, property }) => {
    const { restProps } = this.props;
    property[changedField] = newValue;

    if (this.isValid(property)) {
      if (property.isTemp) {
        this.addPropertyDebounce(property); // we need more delay when adding property
      } else {
        this.updatePropertyDebounce({ ...property, ...restProps });
      }
    }
  };

  addTempDefectProperty = () => {
    const { pushProperty, restProps } = this.props;
    pushProperty({
      [formConstants.fields.id]: 'temp_property_' + Date.now(),
      isTemp: true,
      isNew: true,
      [formConstants.fields.name]: '',
      [formConstants.fields.value]: '',
      ...restProps,
    });
  };

  deleteConfirmationPopup = (prop, callback) => {
    const { t } = this.context;
    const { deleteProperty, triggerDeleteCallback } = this.props;
    const closeAction = () => {
      this.setState({
        modalState: {
          isOpen: false,
        },
      });
    };

    this.setState({
      modalState: {
        isOpen: true,
        confirmAction: () =>
          deleteProperty(prop, () => {
            /* Check if the triggerDeleteCallback is true or if the prop.isTemp is true to trigger fields.remove that will update
            the custom props reducer */
            (triggerDeleteCallback || prop.isTemp) && callback();
            closeAction();
          }),
        type: 'yes-no',
        title: t('CUSTOM_PROPERTY.DELETE_POPUP_TITLE'),
        content: t('CUSTOM_PROPERTY.DELETE_POPUP_DESC', { name: prop[formConstants.fields.name] }),
        closeAction,
      },
    });
  };

  render() {
    const { t } = this.context;
    const { suggestions, modalState } = this.state;
    const { isDisabled, handleInputDisabled, formName, properties, propertyValues, customButton, isEnhancedDesignCustomProp, showButton, showActions, formClassName, formErrors } = this.props;

    return (
      <>
        <CustomPropertiesForm
          isDisabled={isDisabled}
          handleChange={isDisabled ? () => null : this.handleOnChange}
          form={formName}
          properties={propertyValues}
          initialValues={{ properties }}
          deleteProperty={this.deleteConfirmationPopup}
          suggestions={suggestions || []}
          getNameSuggestions={this.getSuggestions}
          clearSuggestions={() => this.setState({ suggestions: [] })}
          isEnhancedDesignCustomProp={isEnhancedDesignCustomProp}
          showActions={showActions}
          formClassName={formClassName}
          formErrors={formErrors}
        />
        <div className={`add-property ${isDisabled ? 'disabled' : ''}`}>
          <div onClick={isDisabled ? handleInputDisabled : this.addTempDefectProperty} className="upload-image">
            <RenderIf if={showButton}>
              {customButton ? (
                customButton
              ) : (
                <>
                  <RenderIf if={isEnhancedDesignCustomProp}>
                    <ButtonWithIcon icon="plus-circle" text={t('NEW_PROPERTY')} noBorder type="success" bold />
                  </RenderIf>
                  <RenderIf if={!isEnhancedDesignCustomProp}>
                    <AddIcon className="svg-primary-g upload-image__icon" />
                    <p className="f-secondary-green upload-image__text">{t('ADD_PROPERTY')}</p>
                  </RenderIf>
                </>
              )}
            </RenderIf>
          </div>
        </div>
        <Modal {...modalState} />
      </>
    );
  }
}

CustomProperties.defaultProps = {
  formName: '',
  handleInputDisabled: () => null,
  noDebounceOnForm: false,
  triggerDeleteCallback: true,
  showButton: true,
  showActions: true,
};

CustomProperties.propTypes = {
  formName: PropTypes.string.isRequired,
  getProperties: PropTypes.func.isRequired,
  addProperty: PropTypes.func.isRequired,
  restProps: PropTypes.object,
  deleteProperty: PropTypes.func.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  // noDebounceOnForm - Flag to indicate if the debounce should happen, in case of using CTA or debounce approach
  noDebounceOnForm: PropTypes.bool,
  // triggerDeleteCallback - Flag to stop fields.remove from triggering
  triggerDeleteCallback: PropTypes.bool,
  showButton: PropTypes.bool,
};

const mapStateToProps = (state, props) => ({
  propertyValues: state.form[props.formName]?.values?.properties,
  formErrors: getFormSyncErrors(props.formName)(state),
});

const mapDispatchToProps = (dispatch, props) => ({
  pushProperty: data => dispatch(arrayPush(props.formName, formConstants.fields.properties, data)),
});

CustomProperties.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomProperties);
