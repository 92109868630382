import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import DoubleTable from '../../../../../common/double-table/components/double-table';
import Button from '../../../../../common/form/components/button';
import Helpers from '../../../../../common/helpers';
import PathViewer from '../../../../../common/path-viewer/components/path-viewer';
import RenderIf from '../../../../../common/render-if/components/render-if';
import { fetchComponentHierarchyLevels, fetchComponents } from '../../../actions/component-hierarchy-levels-actions';
import { defaultComponentsFilters, defaultFilters, filterProps, modalFirstTableConfig, modalSecondTableConfig, SearchPropName } from '../../../constants/constants';
import ComponentHierarchyHelpers from '../../../helpers/helpers';
import '../../../styles/pick-level-modal.scss';
import { formatComponentCells, formatLevelCells } from './actions/actions';

class PickLevelModal extends Component {
  constructor(props, context) {
    super(props);

    const { t } = context;
    this.state = {
      levelPath: [{ Code: t('COMPONENT_HIERARCHY.ALL_LEVELS'), Name: '' }],
      componentLevels: [],
      TotalItems: 0,
      filters: defaultFilters,
      [SearchPropName.levels]: '',
      isLoading: false,
      currentLevelID: null,
      components: [],
      componentsTotalItems: 0,
      isLoadingComponents: false,
      componentsFilters: defaultComponentsFilters,
      [SearchPropName.components]: '',
    };
    this.searchLevelsChangeDebounced = debounce(this.handleSearchLevels, 300);
    this.searchComponentsChangeDebounced = debounce(this.handleSearchComponents, 300);
  }

  componentDidMount = () => {
    const { filters } = this.state;
    this.fetchLevels(filters, false);
  };

  stateCallback = newState => this.setState({ ...newState });

  fetchLevels = (filters, loadMore) => {
    const { componentLevels } = this.state;
    const { fetchComponentHierarchyLevels, ProjectID } = this.props;

    const onSuccessFetch = newState => {
      this.setState({ ...newState });

      if (loadMore && newState.componentLevels) {
        Helpers.scrollIntoView('level-table', `row-${filters[filterProps.lastSeen] - 1}`, -350);
      }
    };

    fetchComponentHierarchyLevels({ ProjectID, ...filters }, onSuccessFetch, loadMore, componentLevels);
  };

  fetchComponents = (filters, loadMore) => {
    const { components } = this.state;
    const { fetchComponents, ProjectID } = this.props;

    const onSuccessFetch = newState => {
      this.setState({ ...newState });

      if (loadMore && newState.components) {
        Helpers.scrollIntoView('components-table', `row-${filters[filterProps.lastSeen] - 1}`, -250);
      }
    };

    fetchComponents({ ProjectID, ...filters }, onSuccessFetch, loadMore, components);
  };

  loadMoreLevels = () => {
    const { filters } = this.state;
    this.fetchLevels(filters, true);
  };

  loadMoreComponents = () => {
    const { componentsFilters } = this.state;
    this.fetchComponents(componentsFilters, true);
  };

  handleRowClick = row => {
    const { levelPath, filters, componentsFilters } = this.state;
    if (!levelPath && !levelPath[0]) return;
    ComponentHierarchyHelpers.onRowLevelClick(row, levelPath, filters, defaultFilters, this.fetchLevels, this.stateCallback, null, SearchPropName.levels);
    ComponentHierarchyHelpers.onComponentLevelClick(row, componentsFilters, defaultComponentsFilters, this.fetchComponents, this.stateCallback, null, SearchPropName.components);
  };
  handlePathBackClick = () => {
    const { levelPath, filters, componentsFilters } = this.state;
    ComponentHierarchyHelpers.onPathBackClick(levelPath, filters, defaultFilters, this.fetchLevels, this.stateCallback, null, SearchPropName.levels);
    ComponentHierarchyHelpers.onPathBackClickComponents(levelPath, componentsFilters, defaultComponentsFilters, this.fetchComponents, this.stateCallback, null, SearchPropName.components);
  };
  handlePathClick = ID => {
    const { levelPath, filters, componentsFilters } = this.state;
    ComponentHierarchyHelpers.onPathClick(ID, levelPath, filters, defaultFilters, this.fetchLevels, this.stateCallback, null, SearchPropName.levels);
    ComponentHierarchyHelpers.onPathClickComponent(ID, componentsFilters, defaultComponentsFilters, this.fetchComponents, this.stateCallback, null, SearchPropName.components);
  };

  //handling search functions
  handleSearchLevels = SearchText => {
    const { filters } = this.state;
    ComponentHierarchyHelpers.onSearch(SearchText, filters, defaultFilters, this.fetchLevels);
  };

  handleSearchComponents = SearchText => {
    const { componentsFilters, currentLevelID } = this.state;
    ComponentHierarchyHelpers.onSearch(SearchText, componentsFilters, defaultComponentsFilters, this.fetchComponents, { [filterProps.hierarchyID]: currentLevelID });
  };

  //handling input changes
  handleInputLevelChange = e => {
    this.setState({ [SearchPropName.levels]: e.target.value });
    this.searchLevelsChangeDebounced(e.target.value);
  };

  handleInputComponentsChange = e => {
    this.setState({ [SearchPropName.components]: e.target.value });
    this.searchComponentsChangeDebounced(e.target.value);
  };

  //handle sort click

  onLevelColumnSort = SortByColumn => {
    const { filters } = this.state;
    ComponentHierarchyHelpers.onColumnSort(SortByColumn, filters, this.fetchLevels);
  };

  onComponentsColumnSort = SortByColumn => {
    const { componentsFilters } = this.state;
    ComponentHierarchyHelpers.onColumnSort(SortByColumn, componentsFilters, this.fetchComponents);
  };

  render() {
    const { t } = this.context;
    const {
      levelPath,
      componentLevels,
      TotalItems,
      filters,
      isLoading,
      [SearchPropName.levels]: levelsSearchText,
      components,
      componentsFilters,
      [SearchPropName.components]: componentsSearchText,
      componentsTotalItems,
      isLoadingComponents,
      currentLevelID,
    } = this.state;
    const { customCloseAction, levelID, customConfirmAction, initialValues } = this.props;
    const isNested = levelPath.length > 1;
    return (
      <div className="pick-level-modal">
        <div className="pick-level-modal__container">
          <div className={`pick-level-modal__container__path ${isNested ? 'show' : 'hide'}`}>
            <RenderIf if={isNested}>
              <PathViewer levels={levelPath} onBackClick={this.handlePathBackClick} onPathClick={this.handlePathClick} />
            </RenderIf>
          </div>
          <div className={`pick-level-modal__container__table ${isNested ? 'path_opened' : ''}`}>
            <DoubleTable
              firstTableProps={{
                tableConfig: modalFirstTableConfig,
                data: componentLevels,
                handleFormatCells: formatLevelCells,
                sortDirection: filterProps.sortDirection,
                sortByColumn: filterProps.sortByColumn,
                filters,
                searchPlaceholder: t('ASSIGN_COMPONENTS.SEARCH_LEVEL'),
                noDataText: t('ASSIGN_COMPONENTS.LEVEL_EMPTY_STATE'),
                selectedItem: null,
                TotalItems,
                hasNext: filters[filterProps.hasNext],
                onRowClick: this.handleRowClick,
                loadMoreOnClick: this.loadMoreLevels,
                customTableClass: 'level-table',
                isLoading,
                searchInputOnChange: this.handleInputLevelChange,
                searchInputValue: levelsSearchText,
                onSortClick: this.onLevelColumnSort,
              }}
              secondTableProps={{
                tableConfig: modalSecondTableConfig,
                data: components,
                handleFormatCells: formatComponentCells,
                sortDirection: filterProps.sortDirection,
                sortByColumn: filterProps.sortByColumn,
                filters: componentsFilters,
                searchPlaceholder: t('ASSIGN_COMPONENTS.SEARCH_COMPONENTS', { currentLevel: `${levelPath[levelPath.length - 1]?.Code} ${levelPath[levelPath.length - 1]?.Name}` }),
                noDataText: t('ASSIGN_COMPONENTS.COMPONENT_EMPTY_STATE'),
                selectedItem: null,
                searchInputValue: componentsSearchText,
                customTableClass: 'components-table',
                searchInputOnChange: this.handleInputComponentsChange,
                loadMoreOnClick: this.loadMoreComponents,
                hasNext: componentsFilters[filterProps.hasNext],
                TotalItems: componentsTotalItems,
                isLoading: isLoadingComponents,
                onSortClick: this.onComponentsColumnSort,
              }}
            />
          </div>
          <div className="pick-level-modal__container__buttons">
            <Button type="button" text={t('CANCEL')} variant="gray-outline" height="md" width="sm" onClick={customCloseAction} />
            <Button
              type="button"
              text={t('COMPONENT_HIERARCHY_MODAL.DELETE_AND_MOVE_BUTTON')}
              variant="danger"
              height="md"
              width="md"
              disabled={!levelPath[levelPath.length - 1].ID || levelID === currentLevelID}
              onClick={() => customConfirmAction({ ...initialValues, MoveComponentsToHierarchyID: currentLevelID })}
            />
          </div>
        </div>
      </div>
    );
  }
}

PickLevelModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  fetchComponentHierarchyLevels: (data, callback, loadMore, componentLevels) => dispatch(fetchComponentHierarchyLevels(data, callback, loadMore, componentLevels)),
  fetchComponents: (data, callback, loadMore, components) => dispatch(fetchComponents(data, callback, loadMore, components)),
});

export default connect(null, mapDispatchToProps)(PickLevelModal);
