import { cloneDeep } from 'lodash';
import { store } from '../../../index';
import { fileFields as workOrderFileFields } from '../components/work-order/constants/constants';
import actionTypes from '../constants/action-types';
import { fields as documentFields } from '../constants/document-constants';
import { formConstants as notificationConstants } from '../constants/notification-constants';
import { formConstants as workorderConstants } from '../constants/work-order-constants';

// Helpers
export const updateElementInArray = (oldElement, oldElements) => {
  const index = oldElements.findIndex(element => element.ID === oldElement.ID);
  if (index > -1) {
    let newElements = cloneDeep(oldElements);
    newElements[index] = { ...oldElements[index], ...oldElement };
    return newElements;
  }
  return oldElements;
};

// ELEMENT ACTIONS

export const setElementDetails = data => {
  return {
    data,
    type: actionTypes.SET_SELECTED_DEFECT,
  };
};

export const updateElementDetails = data => {
  return {
    data,
    type: actionTypes.UPDATE_SELECTED_DEFECT,
  };
};

export const appendElementComment = data => {
  return {
    data,
    type: actionTypes.APPEND_ELEMENT_COMMENT,
  };
};

// COMPONENT ACTIONS
export const selectAllComponents = data => {
  return {
    data,
    type: actionTypes.SELECT_ALL_COMPONENTS,
  };
};

export const deselectAllComponents = data => {
  return {
    data,
    type: actionTypes.DESELECT_ALL_COMPONENTS,
  };
};

export const toggleComponent = data => {
  return {
    data,
    type: actionTypes.TOGGLE_COMPONENT,
  };
};

export const selectAllComponentsTemp = data => {
  return {
    data,
    type: actionTypes.SELECT_ALL_COMPONENTS_TEMP,
  };
};

export const deselectAllComponentsTemp = data => {
  return {
    data,
    type: actionTypes.DESELECT_ALL_COMPONENTS_TEMP,
  };
};

export const toggleComponentTemp = data => {
  return {
    data,
    type: actionTypes.TOGGLE_COMPONENT_TEMP,
  };
};

export const setInspectionComponents = data => {
  return {
    data,
    type: actionTypes.SET_INSPECTION_COMPONENTS,
  };
};

export const amendInspectionComponents = data => {
  return {
    data,
    type: actionTypes.AMEND_INSPECTION_COMPONENTS,
  };
};

export const setInspectionComponentsLoading = data => {
  return {
    data,
    type: actionTypes.SET_INSPECTION_COMPONENTS_LOADING,
  };
};

export const setInspectionComponentsClustered = data => {
  return {
    data,
    type: actionTypes.SET_INSPECTION_COMPONENTS_CLUSTERED,
  };
};

export const setInspectionObservationsClustered = data => {
  return {
    data,
    type: actionTypes.SET_INSPECTION_OBSERVATION_CLUSTERED,
  };
};

export const setInspectionMeasurementsClustered = data => {
  return {
    data,
    type: actionTypes.SET_INSPECTION_MEASUREMENTS_CLUSTERED,
  };
};

export const setInspectionNDTMeasurementsClustered = data => {
  return {
    data,
    type: actionTypes.SET_INSPECTION_NDT_MEASUREMENTS_CLUSTERED,
  };
};

export const appendComponent = data => {
  return {
    data,
    type: actionTypes.APPEND_COMPONENTS,
  };
};

export const appendTempComponent = data => {
  return {
    data,
    type: actionTypes.APPEND_TEMP_COMPONENTS,
  };
};

export const updateComponentSuccess = (data, method) => {
  const { components, componentsClustered } = store.getState().inspectionReducer;
  let clusters = [];

  if (method === 'update') {
    clusters = updateElementInArray(data, componentsClustered);
    data = updateElementInArray(data, components);
  } else if (method === 'delete') {
    clusters = componentsClustered.filter(defect => defect.ID !== data.ComponentID);
    data = components.filter(defect => defect.ID !== data.ComponentID);
  }
  return {
    data,
    clusters,
    type: actionTypes.UPDATE_COMPONENTS_SUCCESS,
  };
};

export const updateComponentTempSuccess = (data, method) => {
  const { componentsTemp } = store.getState().inspectionReducer;
  if (method === 'update') data = updateElementInArray(data, componentsTemp);
  else if (method === 'delete') data = componentsTemp.filter(defect => defect.ID !== data.ComponentID);
  return {
    data,
    type: actionTypes.UPDATE_TEMP_COMPONENTS_SUCCESS,
  };
};

export const saveComponentStart = () => {
  return {
    type: actionTypes.SAVE_COMPONENT_START,
  };
};

export const saveComponentSuccess = () => {
  return {
    type: actionTypes.SAVE_COMPONENT_SUCCESS,
  };
};

export const saveComponentFailure = data => {
  return {
    data,
    type: actionTypes.SAVE_COMPONENT_FAILURE,
  };
};

export const appendComponentTempComment = data => {
  return {
    data,
    type: actionTypes.APPEND_COMPONENT_TEMP_COMMENT,
  };
};

// DEFECTS ACTIONS

export const setComponentFilterData = data => {
  return {
    data,
    type: actionTypes.SET_COMPONENT_FILTER,
  };
};

export const setComponentDetailsData = data => {
  return {
    data,
    type: actionTypes.SET_COMPONENT_DETAILS_DATA,
  };
};

export const fetchDefectPropertiesSuccess = data => {
  return {
    data,
    type: actionTypes.FETCH_DEFECT_PROPERTIES_SUCCESS,
  };
};

export const fetchComponentPropertiesSuccess = data => {
  return {
    data,
    type: actionTypes.FETCH_COMPONENT_PROPERTIES_SUCCESS,
  };
};

export const fetchNDTMeasurementPropertiesSuccess = data => {
  return {
    data,
    type: actionTypes.FETCH_NDT_MEASUREMENT_PROPERTIES_SUCCESS,
  };
};

export const fetchNDTMeasurementPointPropertiesSuccess = data => {
  return {
    data,
    type: actionTypes.FETCH_NDT_MEASUREMENT_POINT_PROPERTIES_SUCCESS,
  };
};

export const appendDefectProperty = data => {
  return {
    data,
    type: actionTypes.APPEND_DEFECT_PROPERTY,
  };
};

export const appendComponentProperty = data => {
  return {
    data,
    type: actionTypes.APPEND_COMPONENT_PROPERTY,
  };
};

export const appendNDTMeasurementProperty = data => {
  return {
    data,
    type: actionTypes.APPEND_NDT_MEASUREMENT_PROPERTY,
  };
};

export const appendNDTMeasurementPointProperty = data => {
  return {
    data,
    type: actionTypes.APPEND_NDT_MEASUREMENT_POINT_PROPERTY,
  };
};

export const updateDefectProperty = data => {
  return {
    data,
    type: actionTypes.UPDATE_DEFECT_PROPERTY,
  };
};

export const updateComponentProperty = data => {
  return {
    data,
    type: actionTypes.UPDATE_COMPONENT_PROPERTY,
  };
};

export const updateNDTMeasurementProperty = data => {
  return {
    data,
    type: actionTypes.UPDATE_NDT_MEASUREMENT_PROPERTY,
  };
};

export const updateNDTMeasurementPointProperty = data => {
  return {
    data,
    type: actionTypes.UPDATE_NDT_MEASUREMENT_POINT_PROPERTY,
  };
};

export const removeDefectProperty = data => {
  return {
    data,
    type: actionTypes.REMOVE_DEFECT_PROPERTY,
  };
};

export const removeComponentProperty = data => {
  return {
    data,
    type: actionTypes.REMOVE_COMPONENT_PROPERTY,
  };
};

export const removeNDTMeasurementProperty = data => {
  return {
    data,
    type: actionTypes.REMOVE_NDT_MEASUREMENT_PROPERTY,
  };
};

export const removeNDTMeasurementPointProperty = data => {
  return {
    data,
    type: actionTypes.REMOVE_NDT_MEASUREMENT_POINT_PROPERTY,
  };
};

export const selectAllDefects = () => {
  return {
    type: actionTypes.SELECT_ALL_DEFECTS,
  };
};

export const deselectAllDefects = () => {
  return {
    type: actionTypes.DESELECT_ALL_DEFECTS,
  };
};

export const toggleDefect = data => {
  return {
    data,
    type: actionTypes.TOGGLE_DEFECT,
  };
};

export const selectAllDefectsTemp = () => {
  return {
    type: actionTypes.SELECT_ALL_DEFECTS_TEMP,
  };
};

export const deselectAllDefectsTemp = () => {
  return {
    type: actionTypes.DESELECT_ALL_DEFECTS_TEMP,
  };
};

export const toggleDefectTemp = data => {
  return {
    data,
    type: actionTypes.TOGGLE_DEFECT_TEMP,
  };
};

export const setInspectionDefects = data => {
  return {
    data,
    type: actionTypes.SET_INSPECTION_DEFECTS,
  };
};
export const amendInspectionDefects = data => {
  return {
    data,
    type: actionTypes.AMEND_INSPECTION_DEFECTS,
  };
};

export const setInspectionDefectsLoading = data => {
  return {
    data,
    type: actionTypes.SET_INSPECTION_DEFECTS_LOADING,
  };
};

export const setDefectDetailsLoading = data => {
  return {
    data,
    type: actionTypes.SET_DEFECT_DETAILS_LOADING_LOADING,
  };
};
export const setComponentDetailsLoading = data => {
  return {
    data,
    type: actionTypes.SET_COMPONENT_DETAILS_LOADING,
  };
};
export const setMeasurementDetailsLoading = data => {
  return {
    data,
    type: actionTypes.SET_MEASUREMENT_DETAILS_LOADING_LOADING,
  };
};

export const appendDefect = data => {
  return {
    data,
    type: actionTypes.APPEND_DEFECT,
  };
};

export const appendTempDefect = data => {
  return {
    data,
    type: actionTypes.APPEND_TEMP_DEFECT,
  };
};

export const updateDefectSuccess = (data, method) => {
  const { defects, observationsClustered } = store.getState().inspectionReducer;
  let clusters = [];

  if (method === 'update') {
    clusters = updateElementInArray(data, observationsClustered);
    data = updateElementInArray(data, defects);
  } else if ((method = 'delete')) {
    clusters = observationsClustered.filter(defect => defect.ID !== data.DefectID);
    data = defects.filter(defect => defect.ID !== data.DefectID);
  }
  return {
    data,
    clusters,
    type: actionTypes.UPDATE_DEFECT_SUCCESS,
  };
};

export const updateDefectTempSuccess = data => {
  const { defectsTemp } = store.getState().inspectionReducer;
  data = updateElementInArray(data, defectsTemp);
  return {
    data,
    type: actionTypes.UPDATE_TEMP_DEFECT_SUCCESS,
  };
};

export const appendDefectTempComment = data => {
  return {
    data,
    type: actionTypes.APPEND_DEFECT_TEMP_COMMENT,
  };
};

export const handleDeleteDefectModal = data => {
  return {
    data,
    type: actionTypes.HANDLE_DELETE_DEFECT_MODAL,
  };
};

export const handleSetObservationTypes = data => {
  return {
    type: actionTypes.HANDLE_SET_OBSERVATION_TYPES,
    data,
  };
};

export const handleSetObservationNotifications = data => {
  return {
    type: actionTypes.HANDLE_SET_OBSERVATION_NOTIFICATIONS,
    data,
  };
};

export const setObservationNotificationsLoading = data => {
  return {
    type: actionTypes.SET_OBSERVATION_NOTIFICATIONS_LOADING,
    data,
  };
};

// MEASUREMENT ACTIONS
export const selectAllMeasurements = data => {
  return {
    data,
    type: actionTypes.SELECT_ALL_MEASUREMENTS,
  };
};

export const deselectAllMeasurements = data => {
  return {
    data,
    type: actionTypes.DESELECT_ALL_MEASUREMENTS,
  };
};

export const toggleMeasurement = data => {
  return {
    data,
    type: actionTypes.TOGGLE_MEASUREMENT,
  };
};

export const selectAllMeasurementsTemp = data => {
  return {
    data,
    type: actionTypes.SELECT_ALL_MEASUREMENTS_TEMP,
  };
};

export const deselectAllMeasurementsTemp = data => {
  return {
    data,
    type: actionTypes.DESELECT_ALL_MEASUREMENTS_TEMP,
  };
};

export const toggleMeasurementTemp = data => {
  return {
    data,
    type: actionTypes.TOGGLE_MEASUREMENT_TEMP,
  };
};

export const setInspectionMeasurements = data => {
  return {
    data,
    type: actionTypes.SET_INSPECTION_MEASUREMENTS,
  };
};
export const amendInspectionMeasurements = data => {
  return {
    data,
    type: actionTypes.AMEND_INSPECTION_MEASUREMENTS,
  };
};

export const setInspectionMeasurementsLoading = data => {
  return {
    data,
    type: actionTypes.SET_INSPECTION_MEASUREMENTS_LOADING,
  };
};

export const appendMeasurement = data => {
  return {
    data,
    type: actionTypes.APPEND_MEASUREMENT,
  };
};

export const appendTempMeasurement = data => {
  return {
    data,
    type: actionTypes.APPEND_TEMP_MEASUREMENT,
  };
};

export const updateMeasurementSuccess = (data, method) => {
  const { measurements, measurementsClustered } = store.getState().inspectionReducer;
  let clusters = [];

  if (method === 'update') {
    clusters = updateElementInArray(data, measurementsClustered);
    data = updateElementInArray(data, measurements);
  } else if (method === 'delete') {
    clusters = measurementsClustered.filter(defect => defect.ID !== data.MeasurementID);
    data = measurements.filter(defect => defect.ID !== data.MeasurementID);
  }
  return {
    data,
    clusters,
    type: actionTypes.UPDATE_MEASUREMENT_SUCCESS,
  };
};

export const updateMeasurementTempSuccess = data => {
  const { measurementsTemp } = store.getState().inspectionReducer;
  data = updateElementInArray(data, measurementsTemp);
  return {
    data,
    type: actionTypes.UPDATE_TEMP_MEASUREMENT_SUCCESS,
  };
};

export const appendMeasurementTempComment = data => {
  return {
    data,
    type: actionTypes.APPEND_MEASUREMENT_COMMENT,
  };
};

// INSPECT POINT CLOUD ACTIONS

export const appendTempInspectPoint = data => {
  return {
    data,
    type: actionTypes.APPEND_TEMP_INSPECT_POINT,
  };
};

export const updateInspectPointTempSuccess = data => {
  return {
    data,
    type: actionTypes.UPDATE_TEMP_INSPECT_POINT_SUCCESS,
  };
};

// SHARE LINK ACTIONS

export const fetchShareLinksSuccess = data => {
  return {
    data,
    type: actionTypes.FETCH_SHARE_LINKS_SUCCESS,
  };
};

export const appendShareLink = data => {
  return {
    data,
    type: actionTypes.APPEND_SHARE_LINK,
  };
};

export const handleDeleteShareLinkModal = data => {
  return {
    data,
    type: actionTypes.HANDLE_DELETE_SHARE_MODAL,
  };
};

// TOOLS ACTIONS

export const selectObjectTool = data => {
  return {
    data,
    type: actionTypes.SELECT_OBJECT_TOOL,
  };
};

// SIDEBAR ACTIONS

export const toogleLeftToolbar = data => {
  return {
    type: actionTypes.TOGGLE_LEFT_TOOLBAR,
    data,
  };
};

export const toogleTopToolbar = data => {
  return {
    type: actionTypes.TOGGLE_TOP_TOOLBAR,
    data,
  };
};

export const toogleRightToolbar = data => {
  return {
    type: actionTypes.TOGGLE_RIGHT_TOOLBAR,
    data,
  };
};

export const setSearchText = data => {
  return {
    type: actionTypes.SET_SEARCH_TEXT_LEFT_TOOLBAR,
    data,
  };
};

// DETAILS PAGE ACTIONS

export const setActiveDetailsPage = data => {
  return {
    data,
    type: actionTypes.SELECT_DETAILS_PAGE,
  };
};
export const setInspectionDetails = data => {
  return {
    data,
    type: actionTypes.SET_INSPECTION_DETAILS,
  };
};
export const setInspectionDetailsIsLoading = data => {
  return {
    data,
    type: actionTypes.SET_INSPECTION_DETAILS_IS_LOADING,
  };
};
export const amendInspectionDetails = data => {
  return {
    data,
    type: actionTypes.UPDATE_INSPECTION_DETAILS,
  };
};

export const setProjectInspections = data => {
  return {
    data,
    type: actionTypes.SET_PROJECT_INSPECTIONS,
  };
};

export const setActiveLeftSidebar = data => {
  return {
    data,
    type: actionTypes.SET_ACTIVE_LEFT_SIDEBAR,
  };
};

export const setInspectionID = data => {
  return {
    data,
    type: actionTypes.SET_INSPECTION_ID,
  };
};

export const setProjectID = data => {
  return {
    data,
    type: actionTypes.SET_PROJECT_ID,
  };
};

export const updateInspectionSettings = data => {
  return {
    data,
    type: actionTypes.UPDATE_INSPECTION_SETTINGS,
  };
};

export const setImages360Ref = data => {
  return {
    data,
    type: actionTypes.SET_IMAGES_360_REF,
  };
};

// AUTOCOMPLETE SUGGESTIONS

// DEFECT PROPERTIES

export const setDefectLabelSuggestions = data => {
  return {
    data,
    type: actionTypes.SET_DEFECT_LABEL_SUGGESTIONS,
  };
};

export const clearDefectLabelSuggestions = () => {
  return {
    type: actionTypes.CLEAR_DEFECT_LABEL_SUGGESTIONS,
  };
};

export const setComponentLabelSuggestions = data => {
  return {
    data,
    type: actionTypes.SET_COMPONENT_LABEL_SUGGESTIONS,
  };
};

export const clearComponentLabelSuggestions = () => {
  return {
    type: actionTypes.CLEAR_COMPONENT_LABEL_SUGGESTIONS,
  };
};

export const setNDTMeasurementLabelSuggestions = data => {
  return {
    data,
    type: actionTypes.SET_NDT_MEASUREMENT_LABEL_SUGGESTIONS,
  };
};

export const setNDTMeasurementPointLabelSuggestions = data => {
  return {
    data,
    type: actionTypes.SET_NDT_MEASUREMENT_POINT_LABEL_SUGGESTIONS,
  };
};

export const clearNDTMeasurementLabelSuggestions = () => {
  return {
    type: actionTypes.CLEAR_NDT_MEASUREMENT_LABEL_SUGGESTIONS,
  };
};

export const clearNDTMeasurementPointLabelSuggestions = () => {
  return {
    type: actionTypes.CLEAR_NDT_MEASUREMENT_POINT_LABEL_SUGGESTIONS,
  };
};

//COMPONENT
export const setParentAssetSuggestions = data => {
  return {
    data,
    type: actionTypes.SET_PARENT_ASSET_SUGGESTIONS,
  };
};

export const clearParentAssetSuggestions = () => {
  return {
    type: actionTypes.CLEAR_PARENT_ASSET_SUGGESTIONS,
  };
};

export const setComponentTypeSuggestions = data => {
  return {
    data,
    type: actionTypes.SET_COMPONENT_TYPE_SUGGESTIONS,
  };
};

export const clearComponentTypeSuggestions = () => {
  return {
    type: actionTypes.CLEAR_COMPONENT_TYPE_SUGGESTIONS,
  };
};

export const setComponentSubTypeSuggestions = data => {
  return {
    data,
    type: actionTypes.SET_COMPONENT_SUB_TYPE_SUGGESTIONS,
  };
};

export const clearComponentSubTypeSuggestions = () => {
  return {
    type: actionTypes.CLEAR_COMPONENT_SUB_TYPE_SUGGESTIONS,
  };
};

export const setMaterialSuggestions = data => {
  return {
    data,
    type: actionTypes.SET_MATERIAL_SUGGESTIONS,
  };
};

export const clearMaterialSuggestions = () => {
  return {
    type: actionTypes.CLEAR_MATERIAL_SUGGESTIONS,
  };
};

export const setManufacturerSuggestions = data => {
  return {
    data,
    type: actionTypes.SET_MANUFACTURER_SUGGESTIONS,
  };
};

export const clearManufacturerSuggestions = () => {
  return {
    type: actionTypes.CLEAR_MANUFACTURER_SUGGESTIONS,
  };
};

export const setLocationSuggestions = data => {
  return {
    data,
    type: actionTypes.SET_LOCATION_SUGGESTIONS,
  };
};

export const clearLocationSuggestions = () => {
  return {
    type: actionTypes.CLEAR_LOCATION_SUGGESTIONS,
  };
};

export const setDefectTypeSuggestions = data => {
  return {
    type: actionTypes.SET_DEFECT_TYPE_SUGGESTIONS,
    data,
  };
};

export const setFetchingActive = data => {
  return {
    data,
    type: actionTypes.SET_FETCHING_REPORT_DATA_ACTIVE,
  };
};

export const setComponentRelatedDefects = data => {
  return {
    type: actionTypes.SET_COMPONENT_RELATED_DEFECTS,
    data,
  };
};
export const setComponentRelatedDefectsLoading = data => {
  return {
    type: actionTypes.SET_COMPONENT_RELATED_DEFECTS_LOADING,
    data,
  };
};

//download report

export const setDownloadReportData = data => {
  return {
    data,
    type: actionTypes.SET_DOWNLOAD_REPORT_HISTORY_DATA,
  };
};

export const setDownloadReportPreferences = data => {
  return {
    data,
    type: actionTypes.SET_DOWNLOAD_REPORT_PREFERENCES,
  };
};

export const setDownloadReportPreferencesField = data => {
  return {
    data,
    type: actionTypes.SET_DOWNLOAD_REPORT_PREFERENCES_FIELD,
  };
};
export const setSortByFilter = data => {
  return {
    type: actionTypes.SET_SORT_BY_FILTER,
    data,
  };
};

//start notifications

export const setNotifications = data => {
  return {
    data,
    type: actionTypes.SET_NOTIFICATIONS,
  };
};

export const setNotificationDetailsLoading = data => {
  return {
    data,
    type: actionTypes.SET_NOTIFICATION_DETAILS_LOADING,
  };
};

export const setNotificationsLoading = data => {
  return {
    data,
    type: actionTypes.SET_NOTIFICATIONS_LOADING,
  };
};

export const amendNotifications = data => {
  return {
    data,
    type: actionTypes.AMEND_MOTIFICATIONS,
  };
};

export const setNotificationWorkOrders = data => {
  return {
    data,
    type: actionTypes.SET_NOTIFICATION_WORK_ORDERS,
  };
};

export const amendNotificationWorkOrders = data => {
  return {
    data,
    type: actionTypes.AMEND_NOTIFICATION_WORK_ORDERS,
  };
};

export const setWorkOrderDetailsLoading = data => {
  return {
    data,
    type: actionTypes.SET_WORK_ORDER_DETAILS_LOADING,
  };
};

export const handleRemoveNotificationByProp = (item, prop = notificationConstants.fields.id) => {
  return {
    type: actionTypes.REMOVE_NOTIFICATION_BY_PROP,
    data: {
      item,
      prop,
    },
  };
};

export const handleUpdateNotificationByProp = (item, prop = notificationConstants.fields.id) => {
  return {
    type: actionTypes.UPDATE_NOTIFICATION_BY_PROP,
    data: {
      item,
      prop,
    },
  };
};

export const handleDeleteNotificationModal = data => {
  return {
    data,
    type: actionTypes.HANDLE_DELETE_NOTIFICATION_MODAL,
  };
};

export const fetchNotificationPropertiesSuccess = data => {
  return {
    data,
    type: actionTypes.FETCH_NOTIFICATION_PROPERTIES_SUCCESS,
  };
};

export const addNotificationCustomProperties = data => {
  return {
    data,
    type: actionTypes.ADD_NOTIFICATION_PROPERTIES,
  };
};

export const setNotificationLabelSuggestions = data => {
  return {
    data,
    type: actionTypes.SET_NOTIFICATION_LABEL_SUGGESTIONS,
  };
};

export const clearNotificationLabelSuggestions = () => {
  return {
    type: actionTypes.CLEAR_NOTIFICATION_LABEL_SUGGESTIONS,
  };
};

export const setNotificationsClustered = data => {
  return {
    data,
    type: actionTypes.SET_NOTIFICATIONS_CLUSTERED,
  };
};

export const setToggledNotification = data => {
  return {
    data,
    type: actionTypes.SET_TOGGLED_NOTIFICATION,
  };
};

export const setToggleNotificationsHidden = () => {
  return {
    type: actionTypes.SET_TOGGLE_NOTIFICATIONS_HIDDEN,
  };
};

export const setNotificationLocationEditing = data => {
  return {
    data,
    type: actionTypes.SET_NOTIFICATION_LOCATION_EDITING,
  };
};

export const setNotificationLocationAdding = data => {
  return {
    data,
    type: actionTypes.SET_NOTIFICATION_LOCATION_ADDING,
  };
};

export const updateNotificationSuccess = (data, method) => {
  const { notifications, notificationsClustered } = store.getState().notificationReducer;
  let clusters = [];

  if (method === 'update') {
    clusters = updateElementInArray(data, notificationsClustered);
    data = updateElementInArray(data, notifications);
  } else if (method === 'delete') {
    clusters = notificationsClustered.filter(notification => {
      return notification.ID !== data.ID;
    });
    data = notifications.filter(notification => notification.ID !== data.ID);
  }
  return {
    data,
    clusters,
    type: actionTypes.UPDATE_NOTIFICATIONS_SUCCESS,
  };
};

export const setNotificationFormState = data => {
  return {
    data,
    type: actionTypes.SET_NOTIFICATION_FORM_STATE,
  };
};

export const setNotificationsFilters = data => {
  return {
    data,
    type: actionTypes.SET_NOTIFICATIONS_FILTER,
  };
};

// resetNotificationFiltersProp - supports only one prop, but can be altered to support multiple
export const resetNotificationFiltersProp = data => {
  return {
    data,
    type: actionTypes.RESET_NOTIFICATION_FILTERS_PROP,
  };
};

export const removeDeletedNotificationCustomProperty = data => {
  return {
    data,
    type: actionTypes.REMOVE_DELETED_NOTIFICATION_CUSTOM_PROPERTY,
  };
};

export const replaceAddedNotificationCustomProperty = data => {
  return {
    data,
    type: actionTypes.REPLACE_ADDED_NOTIFICATION_CUSTOM_PROPERTY,
  };
};

//end notifications

export const setExplosiveZones = data => {
  return {
    data,
    type: actionTypes.SET_EXPLOSIVE_ZONES,
  };
};

export const setExplosiveZonesLodaing = data => {
  return {
    data,
    type: actionTypes.SET_EXPLOSIVE_ZONES_LOADING,
  };
};
export const seExplosiveZoneDetailsLoading = data => {
  return {
    data,
    type: actionTypes.SET_EXPLOSIVE_ZONE_DETAILS_LOADING,
  };
};

export const handleDeleteExplosiveZoneModal = data => {
  return {
    data,
    type: actionTypes.HANDLE_DELETE_EXPLOSIVE_ZONE_MODAL,
  };
};

export const setExplosiveZoneAreas = data => {
  return {
    data,
    type: actionTypes.SET_EXPLOSIVE_ZONE_AREAS,
  };
};

export const amendExplosiveZones = data => {
  return {
    data,
    type: actionTypes.AMEND_EXPLOSIVE_ZONE_AREAS,
  };
};

export const toggleExplosiveZone = data => {
  return {
    data,
    type: actionTypes.TOGGLE_EXPLOSIVE_ZONE,
  };
};

export const selectAllZones = () => {
  return {
    type: actionTypes.SELECT_ALL_ZONES,
  };
};

export const deselectAllZones = () => {
  return {
    type: actionTypes.DESELECT_ALL_ZONES,
  };
};

export const setInspectionExplosiveZonesClustered = data => {
  return {
    data,
    type: actionTypes.SET_INSPECTION_EXPLOSIVE_ZONES_CLUSTERED,
  };
};

//areas

export const setAreas = data => {
  return {
    data,
    type: actionTypes.SET_AREAS,
  };
};

export const amendAreas = data => {
  return {
    data,
    type: actionTypes.AMEND_AREAS,
  };
};

export const setAreasLoading = data => {
  return {
    data,
    type: actionTypes.SET_AREAS_LOADING,
  };
};
export const setAreaDetailsLoading = data => {
  return {
    data,
    type: actionTypes.SET_AREA_DETAILS_LOADING,
  };
};

export const handleDeleteAreaModal = data => {
  return {
    data,
    type: actionTypes.HANDLE_DELETE_AREA_MODAL,
  };
};

export const updateAreaInArray = (id, item) => {
  return {
    type: actionTypes.UPDATE_AREA_IN_ARRAY,
    data: {
      id,
      item,
    },
  };
};

export const deleteAreaInArray = data => {
  return {
    type: actionTypes.DELETE_AREA_IN_ARRAY,
    data,
  };
};

export const deselectAllAreas = data => {
  return {
    type: actionTypes.DESELECT_ALL_AREAS,
    data,
  };
};

export const selectAllAreas = data => {
  return {
    type: actionTypes.SELECT_ALL_AREAS,
    data,
  };
};

export const toggleArea = data => {
  return {
    type: actionTypes.TOGGLE_AREA,
    data,
  };
};

export const showArea = data => {
  return {
    type: actionTypes.SHOW_AREA,
    data,
  };
};

export const hideArea = data => {
  return {
    type: actionTypes.HIDE_AREA,
    data,
  };
};

export const setAreaGroupsDropdown = data => {
  return {
    type: actionTypes.FETCH_AREA_GROUPS_DROPDOWN,
    data,
  };
};

export const setAreaGroupsFilterDropdown = data => {
  return {
    type: actionTypes.FETCH_AREA_GROUPS_FILTER_DROPDOWN,
    data,
  };
};

export const addAreaGroupsDropdownItemAtIndex = (item, index = 0) => {
  return {
    type: actionTypes.ADD_AREA_GROUPS_DROPDOWN_ITEM_AT_INDEX,
    data: {
      item,
      index,
    },
  };
};

export const updateAreaGroupsDropdownItemByProp = (item, prop = 'ID') => {
  return {
    type: actionTypes.UPDATE_AREA_GROUPS_DROPDOWN_ITEM_BY_PROP,
    data: {
      item,
      prop,
    },
  };
};
export const removeAreaGroupsDropdownItemByProp = (item, prop = 'ID') => {
  return {
    type: actionTypes.REMOVE_AREA_GROUPS_DROPDOWN_ITEM_BY_PROP,
    data: {
      item,
      prop,
    },
  };
};

export const handleInspectionModal = data => {
  return {
    data,
    type: actionTypes.HANDLE_INSPECTION_MODAL,
  };
};

export const setInspectionAreasClustered = data => {
  return {
    data,
    type: actionTypes.SET_INSPECTION_AREAS_CLUSTERED,
  };
};

// NDT

export const setNdtMeasurementLocations = data => {
  return {
    data,
    type: actionTypes.SET_NDT_MEASUREMENT_LOCATIONS,
  };
};

export const amendNdtMeasurementLocations = data => {
  return {
    data,
    type: actionTypes.AMEND_NDT_MEASUREMENT_LOCATIONS,
  };
};

export const setNdtMeasurementLocationsLoading = data => {
  return {
    data,
    type: actionTypes.SET_NDT_MEASUREMENT_LOCATIONS_LOADING,
  };
};

export const handleDeleteNDTMeasurementModal = data => {
  return {
    data,
    type: actionTypes.HANDLE_DELETE_NDT_MEASUREMENT_MODAL,
  };
};

export const toggleNDTMeasurement = data => {
  return {
    data,
    type: actionTypes.TOGGLE_NDT_MEASUREMENT,
  };
};

export const showAllNDTMeasurementByComponentName = data => {
  return {
    data,
    type: actionTypes.SHOW_ALL_NDT_MEASUREMENT,
  };
};

export const hideAllNDTMeasurementByComponentName = data => {
  return {
    data,
    type: actionTypes.HIDE_ALL_NDT_MEASUREMENT,
  };
};

export const setNdtMeasurementPoints = data => {
  return {
    data,
    type: actionTypes.SET_NDT_MEASUREMENT_POINTS,
  };
};

export const setActiveNDTMeasurementPoint = data => {
  return {
    data,
    type: actionTypes.SET_ACTIVE_NDT_MEASUREMENT_POINT,
  };
};

export const handleNDTMeasurementPointModal = data => {
  return {
    data,
    type: actionTypes.HANDLE_NDT_MEASUREMENT_POINT_MODAL,
  };
};

export const setNDTChartFilter = data => {
  return {
    data,
    type: actionTypes.SET_NDT_CHART_FILTER,
  };
};

export const setNDTChartTypesDropdownData = data => {
  return {
    data,
    type: actionTypes.SET_NDT_CHART_TYPES_DATA,
  };
};

export const setNDTChartPeriodsDropdownData = data => {
  return {
    data,
    type: actionTypes.SET_NDT_CHART_PERIODS_DATA,
  };
};

export const setNDTChartPointGroupsData = data => {
  return {
    data,
    type: actionTypes.SET_NDT_CHART_PPERIOD_GROUPS_DATA,
  };
};

export const setNDTChartGroupsDropdownData = data => {
  return {
    data,
    type: actionTypes.SET_NDT_CHART_GROUPS_DATA,
  };
};

export const setNDTMainChartData = data => {
  return {
    data,
    type: actionTypes.SET_NDT_MAIN_CHART_DATA,
  };
};

export const setNDTSmallChartData = data => {
  return {
    data,
    type: actionTypes.SET_NDT_SMALL_CHART_DATA,
  };
};
export const setNDTSmallChartDataLoading = data => {
  return {
    data,
    type: actionTypes.SET_NDT_SMALL_CHART_DATA_LOADING,
  };
};

export const fetchPredictedResultsStart = data => {
  return {
    data,
    type: actionTypes.FETCH_PREDICTIVE_RESULTS_START,
  };
};

export const fetchPredictedResultsSuccess = data => {
  return {
    data,
    type: actionTypes.FETCH_PREDICTIVE_RESULTS_SUCCESS,
  };
};

export const fetchPredictedResultsFailure = data => {
  return {
    data,
    type: actionTypes.FETCH_PREDICTIVE_RESULTS_FAILURE,
  };
};

export const setFilterGroupSuggestions = data => {
  return {
    data,
    type: actionTypes.SET_FILTER_GROUP_SUGGESTIONS,
  };
};

export const clearFilterGroupSuggestions = () => {
  return {
    type: actionTypes.CLEAR_FILTER_GROUP_SUGGESTIONS,
  };
};

export const handleSetNDTMeasurementNotifications = data => {
  return {
    type: actionTypes.HANDLE_SET_NDT_MEASUREMENT_NOTIFICATIONS,
    data,
  };
};

export const setNDTMeasurementDetailsLoading = data => {
  return {
    type: actionTypes.SET_NDT_MEASUREMENT_DETAILS_LOADING,
    data,
  };
};

// NDT - END

//NDT - ALARMS

export const setNdtAlarms = data => {
  return {
    data,
    type: actionTypes.SET_NDT_ALARMS,
  };
};

export const setDescriptorData = data => {
  return {
    data,
    type: actionTypes.SET_DESCRIPTOR_DATA,
  };
};

export const setNdtEmails = data => {
  return {
    data,
    type: actionTypes.SET_NDT_EMAILS,
  };
};

export const addAlarmEmailIntoArray = (item, type) => {
  return {
    data: {
      item,
      type,
    },
    type: actionTypes.ADD_ALARM_EMAIL_INTO_ARRAY,
  };
};

export const removeAlarmEmailFromArray = (id, type) => {
  return {
    data: {
      id,
      type,
    },
    type: actionTypes.REMOVE_ALARM_EMAIL_FROM_ARRAY,
  };
};

export const setNDTPredictiveTypesDropdownData = data => {
  return {
    data,
    type: actionTypes.SET_NDT_PREDICTIVE_TYPES_DATA,
  };
};

export const setNDTPredictiveSettingsData = data => {
  return {
    data,
    type: actionTypes.SET_NDT_PREDICTIVE_SETTINGS_DATA,
  };
};

//ndt report

export const setNdtMeasurementsComponents = data => {
  return {
    data,
    type: actionTypes.SET_NDT_MEASUREMENTS_COMPONENTS,
  };
};
export const setNdtMeasurementsUnits = data => {
  return {
    data,
    type: actionTypes.SET_NDT_MEASUREMENTS_UNITS,
  };
};
export const setNdtAlarmsTriggered = data => {
  return {
    data,
    type: actionTypes.SET_NDT_ALARMS_TRIGGERED,
  };
};

//documents
export const handleAddDocumentInArray = data => {
  return {
    data,
    type: actionTypes.HANDLE_ADD_DOCUMENT_IN_ARRAY,
  };
};

export const handleRemoveDocumentByProp = (id, prop = documentFields.id) => {
  return {
    type: actionTypes.HANDLE_REMOVE_ITEM_BY_PROP,
    data: {
      prop,
      id,
    },
  };
};

export const handleDefectRelatedComponentsVisible = data => {
  return {
    type: actionTypes.HANDLE_DEFECT_RELATED_COMPONENTS_VISIBLE,
    data,
  };
};

//component tasks

export const changeRealCameraType = data => {
  return {
    type: actionTypes.HANDLE_REAL_CAMERA_TYPE_CHANGE,
    data,
  };
};

// work-orders

export const setWorkOrders = data => {
  return {
    type: actionTypes.SET_WORK_ORDERS,
    data,
  };
};
export const amendWorkOrders = data => {
  return {
    type: actionTypes.AMEND_WORK_ORDERS,
    data,
  };
};

export const amendRegimeWorkOrders = data => {
  return {
    type: actionTypes.AMEND_REGIME_WORK_ORDERS,
    data,
  };
};

export const setWorkOrdersLoading = data => {
  return {
    type: actionTypes.HANDLE_WORK_ORDERS_LOADING,
    data,
  };
};

export const handleDeleteWorkorderModal = data => {
  return {
    data,
    type: actionTypes.HANDLE_DELETE_WORK_ORDER_MODAL,
  };
};

export const removeWorkorder = data => {
  return {
    data,
    type: actionTypes.HANDLE_REMOVE_WORK_ORDER,
  };
};

export const handleRemoveWorkorderByProp = (item, prop = workorderConstants.fields.id) => {
  return {
    type: actionTypes.REMOVE_WORKORDER_BY_PROP,
    data: {
      item,
      prop,
    },
  };
};

export const handleUpdateWorkorderByProp = (item, prop = workorderConstants.fields.id) => {
  return {
    type: actionTypes.UPDATE_WORKORDER_BY_PROP,
    data: {
      item,
      prop,
    },
  };
};

export const handleCreateWorkOrderModal = data => {
  return {
    data,
    type: actionTypes.HANDLE_CREATE_WORK_ORDER_MODAL,
  };
};

export const setWorkOrderComponents = data => {
  return {
    type: actionTypes.SET_WORK_ORDER_COMPONENTS,
    data,
  };
};

export const amendWorkOrderComponent = data => {
  return {
    type: actionTypes.AMEND_WORK_ORDER_COMPONENT,
    data,
  };
};

export const removeWorkOrderComponent = (item, prop = 'ID') => {
  return {
    type: actionTypes.REMOVE_WORK_ORDER_COMPONENT,
    data: {
      item,
      prop,
    },
  };
};

export const setWorkOrderObservations = data => {
  return {
    type: actionTypes.SET_WORK_ORDER_OBSERVATIONS,
    data,
  };
};

export const setWorkOrderContractors = data => {
  return {
    type: actionTypes.SET_WORK_ORDER_ASSIGNEES,
    data,
  };
};

export const amendWorkOrderContractors = data => {
  return {
    type: actionTypes.AMEND_WORK_ORDER_ASSIGNEES,
    data,
  };
};

export const removeWorkOrderContractors = (item, prop = 'ID') => {
  return {
    type: actionTypes.REMOVE_WORK_ORDER_ASSIGNEES,
    data: {
      item,
      prop,
    },
  };
};

export const setWorkOrderContributors = data => {
  return {
    type: actionTypes.SET_WORK_ORDER_CONTRIBUTORS,
    data,
  };
};

export const amendWorkOrderContributor = data => {
  return {
    type: actionTypes.AMEND_WORK_ORDER_CONTRIBUTOR,
    data,
  };
};

export const removeWorkOrderContributor = (item, prop = 'ID') => {
  return {
    type: actionTypes.REMOVE_WORK_ORDER_CONTRIBUTOR,
    data: {
      item,
      prop,
    },
  };
};

export const setWorkOrderChecklists = data => {
  return {
    type: actionTypes.SET_WORK_ORDER_CHECKLISTS,
    data,
  };
};

export const setWorkOrderChecklistsLoading = data => {
  return {
    type: actionTypes.SET_WORK_ORDER_CHECKLISTS_LOADING,
    data,
  };
};

export const amendWorkOrderChecklist = data => {
  return {
    type: actionTypes.AMEND_WORK_ORDER_CHECKLIST,
    data,
  };
};

export const removeWorkOrderChecklist = (item, prop = 'ID') => {
  return {
    type: actionTypes.REMOVE_WORK_ORDER_CHECKLIST,
    data: {
      item,
      prop,
    },
  };
};

export const updateWorkOrderChecklist = (item, prop = 'ID') => {
  return {
    type: actionTypes.UPDATE_WORK_ORDER_CHECKLIST_IN_LIST,
    data: {
      item,
      prop,
    },
  };
};

export const setWorkOrderAreas = data => {
  return {
    type: actionTypes.SET_WORK_ORDER_AREAS,
    data,
  };
};

export const amendWorkOrderArea = data => {
  return {
    type: actionTypes.AMEND_WORK_ORDER_AREA,
    data,
  };
};

export const updateWorkOrderAreaData = (item, prop = 'ID') => {
  return {
    type: actionTypes.UPDATE_WORK_ORDER_AREA,
    data: {
      item,
      prop,
    },
  };
};

export const removeWorkOrderArea = (item, prop = 'ID') => {
  return {
    type: actionTypes.REMOVE_WORK_ORDER_AREA,
    data: {
      item,
      prop,
    },
  };
};

export const setWorkOrderFiles = data => {
  return {
    type: actionTypes.SET_WORK_ORDER_FILES,
    data,
  };
};

export const setWorkOrderCompletionFiles = data => {
  return {
    type: actionTypes.SET_WORK_ORDER_COMPLETION_FILES,
    data,
  };
};

export const setWorkOrderComments = data => {
  return {
    type: actionTypes.SET_WORK_ORDER_COMMENTS,
    data,
  };
};

export const removeFileBySection = (item, section, prop = workOrderFileFields.id) => {
  return {
    type: actionTypes.REMOVE_FILE_BY_SECTION,
    data: {
      item,
      section,
      prop,
    },
  };
};

export const removeWorkOrderCompletionFileBySection = (item, section, prop = workOrderFileFields.id) => {
  return {
    type: actionTypes.REMOVE_WORK_ORDER_COMPLETION_FILE_BY_SECTION,
    data: {
      item,
      section,
      prop,
    },
  };
};

export const updateCountField = (item, prop, field, amount) => {
  return {
    type: actionTypes.HANDLE_WORK_ORDER_UPDATE_COUNT_FIELD,
    data: {
      item,
      prop,
      field,
      amount,
    },
  };
};

export const setIsolationCertificates = (isLoading, isolationCertificates) => {
  return {
    data: {
      isLoading,
      isolationCertificates,
    },
    type: actionTypes.SET_ISOLATION_CERTIFICATES,
  };
};

export const setIsolationCertificatesFilters = filters => {
  return {
    type: actionTypes.SET_ISOLATION_CERTIFICATES_FILTERS,
    data: filters,
  };
};

export const setPermitsData = (isLoading, permits) => {
  return {
    data: {
      isLoading,
      permits,
    },
    type: actionTypes.SET_PERMITS_DATA,
  };
};

export const setPermitDetailsData = permitDetailsData => {
  return {
    data: {
      permitDetailsData,
    },
    type: actionTypes.SET_PERMIT_DETAILS_DATA,
  };
};

export const setWorkOrderPermits = data => {
  return {
    type: actionTypes.SET_WORK_ORDER_PERMITS,
    data,
  };
};

export const amendWorkOrderPermit = data => {
  return {
    type: actionTypes.AMEND_WORK_ORDER_PERMIT,
    data,
  };
};

export const removeWorkOrderPermit = (item, prop = 'ID') => {
  return {
    type: actionTypes.REMOVE_WORK_ORDER_PERMIT,
    data: {
      item,
      prop,
    },
  };
};

export const setPermitsFilters = filters => {
  return {
    type: actionTypes.SET_PERMITS_FILTERS,
    data: filters,
  };
};

export const setWorkOrderFilters = filters => {
  return {
    type: actionTypes.SET_WORK_ORDER_FILTERS,
    data: filters,
  };
};

export const setRegimeWorkOrderFilters = filters => {
  return {
    type: actionTypes.SET_REGIME_WORK_ORDER_FILTERS,
    data: filters,
  };
};

export const setWorkOrderPaging = pagingObject => {
  return {
    type: actionTypes.SET_WORK_ORDER_PAGING,
    data: pagingObject,
  };
};

export const setWorkOrderPagingRegime = pagingObject => {
  return {
    type: actionTypes.SET_WORK_ORDER_PAGING_REGIME,
    data: pagingObject,
  };
};

export const setCHshow = chShow => {
  return {
    type: actionTypes.SET_CH_SHOW,
    data: chShow,
  };
};

export const setHierarchyID = hierarchyID => {
  return {
    type: actionTypes.SET_HIERARCHY_ID,
    data: hierarchyID,
  };
};
export const setShowCHSubLevels = showSubLevel => {
  return {
    type: actionTypes.SET_SHOW_CH_SUB_LEVELS,
    data: showSubLevel,
  };
};
export const setCHLevels = levels => {
  return {
    type: actionTypes.SET_CH_LEVELS,
    data: levels,
  };
};
export const toggleLevel = levelID => {
  return {
    type: actionTypes.TOGGLE_LEVEL,
    data: levelID,
  };
};
export const setCHHideAll = chHideAll => {
  return {
    type: actionTypes.SET_CH_HIDE_ALL,
    data: chHideAll,
  };
};

export const setRegimeWorkOrdersLoading = data => {
  return {
    type: actionTypes.HANDLE_REGIME_WORK_ORDERS_LOADING,
    data,
  };
};

export const setComponentsFilters = data => {
  return {
    type: actionTypes.SET_COMPONENTS_FILTER,
    data,
  };
};

export const setPanoramicImages = data => {
  return {
    data,
    type: actionTypes.SET_PANORAMIC_IMAGES,
  };
};
export const setHideUnassignedComponents = data => {
  return {
    type: actionTypes.SET_HIDE_UNASSIGNED_COMPONENTS,
    data,
  };
};

export const setDisplayUnassignedComponentsLevel = data => {
  return {
    type: actionTypes.SET_DISPLAY_UNASSIGNED_COMPONENTS_LEVEL,
    data,
  };
};

export const setIs3DViewModeActive = data => {
  return {
    type: actionTypes.SET_IS_3D_VIEW_MODE_ACTIVE,
    data,
  };
};

export const setDMSCategories = data => {
  return {
    data,
    type: actionTypes.SET_DMS_CATEGORIES,
  };
};

export const setWorkOrderProperties = data => {
  return {
    data,
    type: actionTypes.SET_WORK_ORDER_PROPERTIES,
  };
};

export const setComponentLocationEditing = data => {
  return {
    data,
    type: actionTypes.SET_COMPONENT_LOCATION_EDITING,
  };
};

export const setComponentLocationAdding = data => {
  return {
    data,
    type: actionTypes.SET_COMPONENT_LOCATION_ADDING,
  };
};

export const setDefectLocationEditing = data => {
  return {
    data,
    type: actionTypes.SET_DEFECT_LOCATION_EDITING,
  };
};

export const setDefectLocationAdding = data => {
  return {
    data,
    type: actionTypes.SET_DEFECT_LOCATION_ADDING,
  };
};

export const setObservationSubTypesSuggestions = data => {
  return {
    data,
    type: actionTypes.SET_OBSERVATION_SUBTYPES_SUGGESTIONS,
  };
};

export const clearSubTypesSuggestions = () => {
  return {
    type: actionTypes.CLEAR_OBSERVATION_SUBTYPES_SUGGESTIONS,
  };
};

export const setObservationContributors = data => {
  return {
    type: actionTypes.SET_OBSERVATION_CONTRIBUTORS,
    data,
  };
};

export const amendObservationContributor = data => {
  return {
    type: actionTypes.AMEND_OBSERVATION_CONTRIBUTOR,
    data,
  };
};

export const removeObservationContributor = (item, prop = 'ID') => {
  return {
    type: actionTypes.REMOVE_OBSERVATION_CONTRIBUTOR,
    data: {
      item,
      prop,
    },
  };
};

export const setWorkorderLabelSuggestions = data => {
  return {
    data,
    type: actionTypes.SET_WORKORDER_LABEL_SUGGESTIONS,
  };
};

export const clearWorkorderLabelSuggestions = () => {
  return {
    type: actionTypes.CLEAR_WORKORDER_LABEL_SUGGESTIONS,
  };
};

export const setWorkOrderCPTemplates = data => {
  return {
    type: actionTypes.SET_WORK_ORDER_CP_TEMPLATES,
    data,
  };
};

export const amendWorkOrderCPTemplate = data => {
  return {
    type: actionTypes.AMEND_WORK_ORDER_CP_TEMPLATE,
    data,
  };
};

export const removeWorkOrderCPTemplate = (item, prop = 'ID') => {
  return {
    type: actionTypes.REMOVE_WORK_ORDER_CP_TEMPLATE,
    data: {
      item,
      prop,
    },
  };
};

export const linkWorkOrderCPTemplateToComponents = (item, prop = 'ID') => {
  return {
    type: actionTypes.LINK_WORK_ORDER_CP_TEMPLATE_TO_COMPONENTS,
    data: {
      item,
      prop,
    },
  };
};

export const unlinkWorkOrderCPTemplateFromComponents = (item, prop = 'ID') => {
  return {
    type: actionTypes.UNLINK_WORK_ORDER_CP_TEMPLATE_FROM_COMPONENTS,
    data: {
      item,
      prop,
    },
  };
};

export const setWorkOrderCPSections = data => {
  return {
    type: actionTypes.SET_WORK_ORDER_CP_SECTIONS,
    data,
  };
};

export const removeWorkOrderComponentFromCPTemplate = (item, component, prop = 'ID') => {
  return {
    type: actionTypes.REMOVE_WORK_ORDER_COMPONENT_FROM_CP_TEMPLATE,
    data: {
      item,
      component,
      prop,
    },
  };
};

export const setObservationFormState = data => {
  return {
    type: actionTypes.SET_OBSERVATION_FORM_STATE,
    data,
  };
};

export const replaceAddedObservationCustomProperty = data => {
  return {
    data,
    type: actionTypes.REPLACE_ADDED_OBSERVATION_CUSTOM_PROPERTY,
  };
};

export const setCreateNotificationFormSubmitting = data => {
  return {
    type: actionTypes.SET_CREATE_NOTIFICATION_FORM_SUBMITTING,
    data,
  };
};

export const setObservationWorkOrders = data => {
  return {
    type: actionTypes.SET_OBSERVATION_WORK_ORDERS,
    data,
  };
};

export const setComponentFormState = data => {
  return {
    type: actionTypes.SET_COMPONENT_FORM_STATE,
    data,
  };
};

export const replaceComponentCustomProperty = data => {
  return {
    data,
    type: actionTypes.REPLACE_ADDED_COMPONENT_CUSTOM_PROPERTY,
  };
};

export const setUnsavedChangesDirty = data => {
  return {
    type: actionTypes.SET_UNSAVED_CHANGES_DIRTY,
    data,
  };
};

export const clearUnsavedChangesDirty = () => {
  return {
    type: actionTypes.CLEAR_UNSAVED_CHANGES_DIRTY,
  };
};
