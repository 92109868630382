/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { formConstants } from '../../constants/measurement-point-constants';

import { Field, FieldArray } from 'redux-form';
import CustomDatePicker from '../../../../../../common/form/components/date-picker';
import UneditableInfo from '../../../../../../common/form/components/uneditable-info';
import Loader from '../../../../../../common/global-loader/components/simple-loader';
import Helpers from '../../../../../../common/helpers';
import RenderIf from '../../../../../../common/render-if/components/render-if';
import { measurementTypes } from '../../../../constants/constants';
import { measurementReadingFormConstants } from '../../constants/measurement-readings-constants';
import '../../styles/measurement-reading-form.scss';
import ReadingFormFields from './reading-form-fields';

const MeasurementReadingFormDetails = props => {
  const { isEdit, getDefectDetails, measurementPointId, formValues, changeField } = props;
  const [data, setData] = useState([]);
  const [dateValue, setDateValue] = useState(!isEdit ? Helpers.getCurrentDateAndTimeInMs() : formValues?.[measurementReadingFormConstants.fields.date]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchMeasurementPointDetails = useCallback(() => {
    setIsLoading(true);
    getDefectDetails(
      { [formConstants.fields.id]: measurementPointId, SystemType: measurementTypes.rgMeasurementPoint },
      measurementPointDetails => {
        setData(measurementPointDetails);
        setIsLoading(false);
      },
      false
    );
  }, [measurementPointId, measurementTypes.rgMeasurementPoint]);

  useEffect(() => {
    fetchMeasurementPointDetails();
  }, []);

  const handleDateChange = date => {
    setDateValue(date);
    changeField(dateField, date, false);
  };

  const dateField = measurementReadingFormConstants.fields.date;

  return (
    <>
      <RenderIf if={isLoading}>
        <Loader isLoading={isLoading} />
      </RenderIf>
      <RenderIf if={!isLoading}>
        <div className="create-measurement-reading-form__content">
          <div className="create-measurement-reading-form__double-input">
            <Field
              id={formConstants.fields.description}
              name={formConstants.fields.description}
              component={UneditableInfo}
              placeholder={'READINGS_AND_GAUGES.MEASUREMENT_READINGS_FORM.DESCRIPTION'}
              label={'READINGS_AND_GAUGES.MEASUREMENT_READINGS_FORM.DESCRIPTION'}
              labelClass="defect-form__label"
              enableAutoResize={true}
              input={{ value: data ? data[formConstants.fields.description] : '' }}
            />
            <Field
              id={formConstants.fields.expectedRange}
              name={formConstants.fields.expectedRange}
              component={UneditableInfo}
              placeholder={'READINGS_AND_GAUGES.MEASUREMENT_READINGS_FORM.EXPECTED_RANGE'}
              label={'READINGS_AND_GAUGES.MEASUREMENT_READINGS_FORM.EXPECTED_RANGE'}
              labelClass="defect-form__label"
              enableAutoResize={true}
              value={data ? data[formConstants.fields.expectedRange] : ''}
              input={{ value: data ? data[formConstants.fields.expectedRange] : '' }}
            />
          </div>
          <Field
            disabledKeyboardNavigation={true}
            id={dateField}
            name={dateField}
            component={CustomDatePicker}
            popperPlacement="bottom-end"
            placeholder={'READINGS_AND_GAUGES.MEASUREMENT_READINGS_FORM.DATE_TIME'}
            label={'READINGS_AND_GAUGES.MEASUREMENT_READINGS_FORM.DATE_TIME'}
            size="lg"
            dateFormat="MMMM d, yyyy h:mm aa"
            timeFormat="h:mm aa"
            showTimeSelect
            validateUsingReduxForm
            isEnd
            isRequired
            input={{
              value: dateValue,
              onChange: handleDateChange,
            }}
          />
          <FieldArray name={measurementReadingFormConstants.fields.values} component={ReadingFormFields} unit={data ? data[formConstants.fields.unit] : ''} isEdit={isEdit} />
        </div>
      </RenderIf>
    </>
  );
};

MeasurementReadingFormDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MeasurementReadingFormDetails;
