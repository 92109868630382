import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { debounce, pick } from 'lodash';
import { changeUser360Settings } from '../../../profile/actions/profile-actions';
import { updateInspectionSettings } from '../../actions/action-creators';
import { updateInspectionDetails } from '../../actions/inspection-actions';
import SettingsForm from './inspection-settings-form';

import { deselectAllCameraPositions, deselectAllCameraThumbnails, selectAllCameraPositions, selectAllCameraThumbnails } from '../../../start-workflow/actions/action-creators';
import { formConstants, userSettings } from '../../constants/inspection-settings';

import { isPristine } from 'redux-form';
import { FORMS } from '../../../../common/constants';
import Loader from '../../../../common/global-loader/components/simple-loader';
import '../../styles/inspection-settings.scss';

class Settings extends Component {
  constructor(props) {
    super(props);

    this.formChangeDebounce = debounce(this.handleChange, 100);
  }

  componentWillUnmount() {
    this.formChangeDebounce.cancel();
  }

  handleChange = values => {
    const { isFormPristine } = this.props;
    if (isFormPristine) return; // Prevent handleChange on initial load

    const { updateInspectionDetails, changeUser360Settings, user } = this.props;
    const userSettingsProperties = Object.values(userSettings.fields);
    const inspectionSettingsProperties = Object.values(formConstants.fields);
    const userSettingsValues = pick(values, userSettingsProperties);
    const inspectionSettingsValues = pick(values, inspectionSettingsProperties);
    changeUser360Settings({ ...user.Setting360View, ...userSettingsValues });
    updateInspectionDetails(inspectionSettingsValues);
  };

  selectAllCameras = () => {
    const { selectAllCameras } = this.props;
    selectAllCameras();
  };

  deselectAllCameras = () => {
    const { deselectAllCameras } = this.props;
    deselectAllCameras();
  };

  render() {
    const { t } = this.context;
    const { user, isWorkflow, selectAllCameraThumbnails, deselectAllCameraThumbnails, is3DViewModeActive, inspectionDetailsIsLoading } = this.props;

    if (inspectionDetailsIsLoading) {
      return <Loader />;
    }

    return (
      <div className="inspection-settings">
        <h5 className="f-primary inspection-settings__title padding">{t('INSPECTION_SETTINGS')}</h5>
        <SettingsForm
          onChange={this.formChangeDebounce}
          isWorkflow={isWorkflow}
          selectAllCameras={this.selectAllCameras}
          deselectAllCameras={this.deselectAllCameras}
          selectAllCameraThumbnails={selectAllCameraThumbnails}
          deselectAllCameraThumbnails={deselectAllCameraThumbnails}
          user={user}
          is3DViewModeActive={is3DViewModeActive}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.userReducer,
  is3DViewModeActive: state.inspectionReducer.is3DViewModeActive,
  inspectionDetailsIsLoading: state.inspectionReducer.inspectionDetailsIsLoading,
  isFormPristine: isPristine(FORMS.inspectionSettings)(state),
});

const mapDispatchToProps = dispatch => ({
  updateInspectionSettings: data => dispatch(updateInspectionSettings(data)),
  updateInspectionDetails: data => dispatch(updateInspectionDetails(data)),
  selectAllCameras: () => dispatch(selectAllCameraPositions()),
  deselectAllCameras: () => dispatch(deselectAllCameraPositions()),
  selectAllCameraThumbnails: () => dispatch(selectAllCameraThumbnails()),
  deselectAllCameraThumbnails: () => dispatch(deselectAllCameraThumbnails()),
  changeUser360Settings: data => dispatch(changeUser360Settings(data)),
});

Settings.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
