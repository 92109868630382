import PropTypes from 'prop-types';
import React from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { FORMS } from '../../../../../../common/constants';
import Property from '../../../../../../common/custom-property/components/property';
import Button from '../../../../../../common/form/components/button';
import CustomInput from '../../../../../../common/form/components/input';
import Icon from '../../../../../../common/icon/components/icon';
import InfoTooltip from '../../../../../../common/tooltip/components/info-tooltip';
import CustomInputRange from '../../../common/input/input-range';
import { defectNotificationFields } from '../constants/constants';
import '../styles/defect-notification-form.scss';
import { validate } from '../validators/validators';
import DefectNotificationComponentPopover from './defect-notifications-component-popover';

const DefectNotificationCreationForm = (props, { t }) => {
  const { initialValues, handleSubmit, properties, handleOnPropChange, closeAction, submitDisabled, formErrors } = props;
  const { LinkedComponent } = initialValues;
  return (
    <form noValidate className="defect-notification-creation-form" onSubmit={handleSubmit}>
      <div>
        <div className="defect-notification-creation-form__row">
          <div className="defect-notification-creation-form__row__field">
            <Field
              id={defectNotificationFields.name}
              name={defectNotificationFields.name}
              component={CustomInput}
              isRequired
              size="lg"
              label={t('NOTIFICATION.NAME')}
              placeholder={t('NOTIFICATION.NAME')}
              labelClass="mb-unset"
            />
          </div>
          <div className="defect-notification-creation-form__row__field">
            <Field
              id={defectNotificationFields.severity}
              name={defectNotificationFields.severity}
              component={CustomInputRange}
              label={t('NOTIFICATION_DETAILS.FORM_SEVERITY')}
              isRequired
              labelClass="f-primary"
              minValue={1}
              maxValue={10}
            />
          </div>
        </div>

        <p className="f-primary light-bold mt-20">{t('NOTIFICATION.LINKED_COMPONENT')}</p>
        <p className="defect-notification-creation-form__component-title">
          {LinkedComponent?.[defectNotificationFields.name]}
          <InfoTooltip
            customComponent={<DefectNotificationComponentPopover component={LinkedComponent} />}
            offsetY={8}
            offsetX={8}
            Component={() => <Icon name="info" size="sm" />}
            componentProps={{ title: '' }}
            containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
          />
          <InfoTooltip
            customComponent={<p>{t('NOTIFICATION.LINKED_COMPONENT_LOCKED')}</p>}
            offsetY={8}
            offsetX={8}
            Component={() => <Icon name="lock" size="sm" />}
            componentProps={{ title: '' }}
            containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
            className="locked-popover"
          />
        </p>

        <div className="defect-notification-creation-form__cp-form">
          <FieldArray
            id={defectNotificationFields.properties}
            name={defectNotificationFields.properties}
            component={Property}
            properties={properties}
            isEnhancedDesignCustomProp
            getProperties={() => {}}
            addProperty={() => {}}
            getNameSuggestions={() => {}}
            updateProperty={() => {}}
            suggestions={[]}
            handleChange={handleOnPropChange}
            showPropActions={false}
            // Pass errors to the Property component
            errors={formErrors}
          />
        </div>
      </div>
      <div className="defect-notification-creation-form__button-section">
        <Button onClick={closeAction} type="button" text={t('CANCEL')} variant="gray-outline" className="modal-button-custom h-md" />
        <Button text={t('CREATE')} variant="success" className="modal-button-custom h-md" disabled={submitDisabled} />
      </div>
    </form>
  );
};

DefectNotificationCreationForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({ form: FORMS.createNotificationFromObservationForm, validate, enableReinitialize: true, touchOnChange: true, destroyOnUnmount: true })(DefectNotificationCreationForm);
